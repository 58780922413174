import { IconButton } from '../../../components/buttons';
import { TextField } from '../../../components/inputs';
import {
  AlertIcon,
  CheckmarkIcon,
  DeleteIcon
} from '../../../components/icons';

import { isValidHexColor } from '../utils';
import { ColorMapping, ColorSlot } from '../types';
import { ColorSample } from './ColorSample';
import { InvalidColorSample } from './InvalidColorSample';
import { Tooltip } from '../../../components/tooltips';

interface ColorSlotViewProps {
  color: ColorSlot;
  colorMapping: ColorMapping | null;
  error?: boolean;
  onChangeColorHex: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDeleteColorSlot: () => void;
  canDelete?: boolean;
}

export function ColorSlotView({
  color,
  colorMapping,
  error = false,
  onChangeColorHex: changeColorValueHandler,
  onDeleteColorSlot: deleteColorSlotHandler,
  canDelete
}: ColorSlotViewProps) {
  const isStaticColor =
    color.print_key === 'white' || color.print_key === 'black';

  const isValid = isValidHexColor(color.color_hex);

  const colorMappingText =
    colorMapping &&
    `C: ${colorMapping.c} M ${colorMapping.m} Y: ${colorMapping.y} K: ${colorMapping.k}`;

  return (
    <div
      key={color.print_key}
      className="mb-3 flex w-1/2 items-center justify-between gap-7 px-3 align-middle"
    >
      {isValid && color.color_hex ? (
        <ColorSample colorHex={color.color_hex} />
      ) : (
        <InvalidColorSample />
      )}
      <h2 className="w-20">{color.display_name}</h2>
      {isStaticColor ? (
        <span className="px-3">{color.color_hex}</span>
      ) : (
        <TextField
          error={error}
          onChange={changeColorValueHandler}
          className="flex-1"
          value={color.color_hex || '#'}
          data-testid={color.print_key}
          contentAfter={
            color.color_hex?.length === 7 && (
              <div className="flex align-middle">
                {colorMapping && colorMapping.swatch_name && (
                  <div className="mr-2">{colorMapping.swatch_name}</div>
                )}
                {colorMapping ? (
                  <Tooltip text={colorMappingText ?? ''}>
                    <IconButton
                      className="text-aqua"
                      icon={<CheckmarkIcon />}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip text="No mapping - will print as is.">
                    <IconButton className="text-lemon" icon={<AlertIcon />} />
                  </Tooltip>
                )}
              </div>
            )
          }
        />
      )}

      {canDelete && (
        <IconButton
          onClick={deleteColorSlotHandler}
          className="text-blush"
          icon={<DeleteIcon />}
        />
      )}
    </div>
  );
}
