import { useRef, LegacyRef, ReactNode, CSSProperties } from 'react';
import { useDrag, useDrop, XYCoord } from 'react-dnd';

interface DraggableItemProps {
  index: number;
  onDropItem(dragIndex: number, hoverIndex: number): void;
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
}
export function DraggableItem({
  index,
  onDropItem,
  className,
  style,
  children
}: DraggableItemProps) {
  const [, dragRef] = useDrag({
    type: 'item',
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const [, dropRef] = useDrop({
    accept: 'item',
    hover: (item: { name: string; index: number }, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;

      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      const hoverBoundingRect = (ref.current as any)?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY =
        (monitor.getClientOffset() as XYCoord).y - hoverBoundingRect.top;

      // if dragging down, continue only when hover is smaller than middle Y
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) {
        return;
      }
      // if dragging up, continue only when hover is bigger than middle Y
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) {
        return;
      }

      onDropItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });

  const ref = useRef(null);
  const dragDropRef = dragRef(dropRef(ref)) as
    | LegacyRef<HTMLDivElement>
    | undefined;

  return (
    <div
      ref={dragDropRef}
      style={{
        ...style,
        cursor: 'move',
        backgroundColor: 'white'
      }}
      className={className}
    >
      {children}
    </div>
  );
}
