import { useMemo, useState } from 'react';
import { uniqueId } from 'lodash';
import { UploadButtonComponent } from '../../../../components/buttons';
import { ColorSlot } from '../../../palettes/types';
import { LayerField } from './layers/LayerField';
import { useUpdater } from './state/update';
import { Dict, LabelUpdater, LabelVariantLayerState } from '../../types/state';
import { sortByOrder } from '../../types/convert';

export interface LabelVariantLayersProps {
  layers: Dict<LabelVariantLayerState>;
  error?: string;
  onUpdate: LabelUpdater<Dict<LabelVariantLayerState>>;
  colorOptions: ColorSlot[];
}

export function LabelVariantLayers({
  layers,
  error,
  onUpdate,
  colorOptions
}: LabelVariantLayersProps) {
  const [dragging, setDragging] = useState(-1);
  const [landing, setLanding] = useState(-1);
  const paletteOptions = [
    {
      value: '',
      label: 'Static'
    },
    ...colorOptions.map(slot => ({
      value: slot.print_key,
      label: slot.display_name
    }))
  ];
  const order = (i: number) => {
    if (dragging === i) {
      return landing;
    }
    if (i > dragging && i <= landing) {
      return i - 1;
    }
    if (i < dragging && i >= landing) {
      return i + 1;
    }
    return i;
  };

  const { update } = useUpdater(onUpdate);

  const orderedLayerMap = useMemo(() => {
    return new Map(
      Object.entries(layers).sort(([, a], [, b]) => sortByOrder(a, b))
    );
  }, [layers]);

  const orderedLayerIds = useMemo(() => {
    return Array.from(orderedLayerMap.keys());
  }, [orderedLayerMap]);

  return (
    <div className="flex flex-col items-stretch gap-2 p-2">
      <UploadButtonComponent
        accept={['image/svg+xml']}
        multiple={true}
        text="Upload"
        onUpload={e => {
          const target = e.currentTarget;
          const files = target.files;
          if (files) {
            let startOrder = Object.keys(layers).length;
            for (const file of files) {
              const reader = new FileReader();
              const order = startOrder++;

              reader.onload = event => {
                const { result: svgText } = event.target as { result: string };
                const id = uniqueId('layer');

                onUpdate({
                  [id]: {
                    svgText,
                    print_key: '',
                    width: 1,
                    height: 1,
                    x: 0,
                    y: 0,
                    order
                  }
                });

                target.value = '';
              };

              reader.readAsText(file);
            }
          }
        }}
      />
      {error && !layers?.length ? (
        <p className="text-error">{error}</p>
      ) : (
        <div className="bg-light-blue flex flex-col gap-2 rounded-lg p-2">
          Layers
          <div
            className="flex flex-col gap-2"
            onDragOver={e => {
              // This communicates to the browser that we are handling the drag event
              e.preventDefault();
            }}
            onDrop={() => {
              if (dragging < 0 || landing < 0) return;
              const newLayers = orderedLayerIds.slice();
              const oldLayer = newLayers.splice(dragging, 1)[0];
              newLayers.splice(landing, 0, oldLayer);
              onUpdate(
                Object.fromEntries(newLayers.map((id, i) => [id, { order: i }]))
              );
              setDragging(-1);
              setLanding(-1);
            }}
          >
            {orderedLayerIds.map((id, i) => {
              const layer = layers[id];
              return (
                <LayerField
                  key={layer.label_layer_id || i}
                  value={layer}
                  options={paletteOptions}
                  onUpdate={update(id)}
                  onDelete={() => {
                    onUpdate({
                      [id]: undefined
                    });
                  }}
                  order={orderedLayerIds.length - order(i)}
                  onDrag={() => {
                    if (i !== dragging) {
                      setDragging(i);
                      setLanding(i);
                    }
                  }}
                  onDragOver={() => {
                    if (dragging < 0 || i === dragging) return;
                    const change = order(i) < landing ? -1 : 1;
                    setLanding(landing + change);
                  }}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
