import { useTRPCRequest } from '../../../hooks';
import ColorMappingValidation from '../validations';
import { useCallback, useEffect, useState } from 'react';
import {
  ConfirmDeleteModalComponent,
  LoadingModalComponent,
  UnsavedChangesModalComponent
} from '../../../components/modals';
import {
  useBlocker,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import { SystemNameComponent, TextField } from '../../../components/inputs';
import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';
import {
  ColorMapping,
  emptyColorMapping,
  ColorMappingErrors,
  COLOR_MAPPING_FIELDS,
  defaultColorMappingErrors
} from '../types';
import {
  BackButtonComponent,
  SaveButtonComponent
} from '../../../components/buttons';
import { validateSchemaAndNotifyErrors } from '../../../utils';
import { toast } from 'react-toastify';
import { CMYKColor, RGBColor } from '../components/colors';
import { Title } from '../../../components/Title';
import { removeNullishValues } from '../../labels/components/settings/variantUtils';
import { ColorMappingAssociations } from '../components/ColoMappingAssociations';

interface ColorMappingDetailsProps {
  create?: boolean;
}

export function ColorMappingDetails({ create }: ColorMappingDetailsProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { handleTRPCRequest } = useTRPCRequest();

  /** State */
  const [colorMapping, setColorMapping] =
    useState<ColorMapping>(emptyColorMapping);
  const [formErrors, setFormErrors] = useState<ColorMappingErrors>(
    defaultColorMappingErrors
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const unsavedChangesBlocker = useBlocker(unsavedChanges);

  const syncFromRouter = useCallback(async () => {
    setIsLoading(true);
    if (location?.state?.colorMappingToDuplicate) {
      const colorMappingDuplicate = location?.state
        ?.colorMappingToDuplicate as ColorMapping;

      if (colorMappingDuplicate) {
        setColorMapping(colorMappingDuplicate);
      }
    } else if (location?.state?.colorMappingIdToCopy) {
      const { res: colorMappingDuplicate, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.get,
        resourceType: TRPCResourceEnum.colorMappings,
        requestBody: { color_mapping_id: location?.state?.colorMappingIdToCopy }
      });

      if (!error) {
        colorMappingDuplicate.system_name += ' - Copy';
        setColorMapping(colorMappingDuplicate);
      }
    }
    setIsLoading(false);
  }, [handleTRPCRequest, location?.state]);

  const syncFromApi = useCallback(async () => {
    setIsLoading(true);
    if (params.color_mapping_id) {
      const { res: colorMappingDuplicate, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.get,
        resourceType: TRPCResourceEnum.colorMappings,
        requestBody: { color_mapping_id: params.color_mapping_id }
      });
      if (!error) {
        setColorMapping(colorMappingDuplicate);
      }
    }
    setIsLoading(false);
  }, [params, handleTRPCRequest]);

  useEffect(() => {
    if (create) {
      syncFromRouter();
    } else {
      syncFromApi();
    }
    setUnsavedChanges(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [create]);

  const handleUpdateColorMapping = useCallback(
    (key: string, value: string) => {
      setColorMapping(oldColorMapping => ({
        ...oldColorMapping,
        [key]: value
      }));
      setFormErrors({ ...formErrors, [key]: null });
      setUnsavedChanges(true);
    },
    [formErrors, setFormErrors, setUnsavedChanges]
  );

  const handleUpdateHexCode = useCallback(
    (newHexCode: string) => {
      newHexCode = newHexCode.substring(0, 6);
      newHexCode = newHexCode.toUpperCase();
      newHexCode = newHexCode.replace(/[^A-F0-9]/g, '');
      handleUpdateColorMapping(COLOR_MAPPING_FIELDS.hex_code, newHexCode);
    },
    [handleUpdateColorMapping]
  );

  const handleUpdateCMYK = useCallback(
    (key: string, value: string) => {
      value = value.replace(/[^\d.]/g, '');
      value = value.replace(/(\d*\.\d{1,2})\d*/g, '$1');
      handleUpdateColorMapping(key, value);
    },
    [handleUpdateColorMapping]
  );

  const handleSaveColorMapping = useCallback(async () => {
    setIsSaving(true);
    const colorMappingToSave = { ...colorMapping };
    if (create) {
      delete colorMappingToSave.color_mapping_id;
    }
    const { errors: validationErrors, validatedSchema } =
      validateSchemaAndNotifyErrors(
        create
          ? ColorMappingValidation.createColorMappingSchema
          : ColorMappingValidation.updateColorMappingSchema,
        removeNullishValues(colorMappingToSave)
      );

    if (validationErrors) {
      setFormErrors(validationErrors as unknown as ColorMappingErrors);
    } else {
      const { res, error } = await handleTRPCRequest({
        method: create ? TRPCMethodEnum.create : TRPCMethodEnum.update,
        resourceType: TRPCResourceEnum.colorMappings,
        requestBody: validatedSchema
      });

      if (res && res.error && res.error === 'duplicate hex_code') {
        toast.error(
          `hex_code value #${colorMappingToSave.hex_code} already exists, please choose a different value`
        );
        setFormErrors({
          ...formErrors,
          hex_code: true
        });
      } else if (error || (res && res.error)) {
        toast.error('Error saving color mapping');
      } else {
        navigate('/color-mappings/' + res?.color_mapping_id, { replace: true });
      }
      setUnsavedChanges(false);
    }
    setIsSaving(false);
  }, [colorMapping, create, formErrors, handleTRPCRequest, navigate]);

  const handleDeleteColorMapping = useCallback(async () => {
    setUnsavedChanges(false);
    setConfirmDelete(false);
    setIsDeleting(true);
    if (colorMapping.color_mapping_id) {
      const { error } = await handleTRPCRequest({
        method: TRPCMethodEnum.delete,
        resourceType: TRPCResourceEnum.colorMappings,
        requestBody: { color_mapping_id: colorMapping.color_mapping_id }
      });

      if (!error) {
        navigate('/color-mappings', { replace: true });
      }
      setIsDeleting(false);
    }
  }, [colorMapping, handleTRPCRequest, navigate]);

  if (isSaving) {
    return <LoadingModalComponent isOpen message="Saving..." />;
  }

  if (isDeleting) {
    return <LoadingModalComponent isOpen message="Deleting..." />;
  }

  if (isLoading) {
    return <LoadingModalComponent isOpen />;
  }

  return (
    <div className="flex h-full flex-col">
      {create ? (
        <Title title="Create Color Mappings" />
      ) : (
        <Title title="Existing Color Mappings" />
      )}
      <SystemNameComponent
        create={create}
        systemName={colorMapping?.system_name || ''}
        onSystemNameChange={ev =>
          handleUpdateColorMapping(
            COLOR_MAPPING_FIELDS.system_name,
            ev.target.value
          )
        }
        errors={formErrors}
        onDelete={() => setConfirmDelete(true)}
      />
      <div className="flex w-full space-x-2.5 text-base font-semibold">
        <TextField
          error={formErrors.hex_code}
          contentBefore="Hex Code: #"
          size="small"
          className={'mb-5 w-[20%]'}
          value={colorMapping.hex_code}
          onChange={ev => handleUpdateHexCode(ev.target.value)}
          data-testid={COLOR_MAPPING_FIELDS.hex_code}
        />
        <RGBColor colorMapping={colorMapping} />
        <TextField
          error={formErrors.c}
          contentBefore="C:"
          size="small"
          className="w-[10%]"
          value={colorMapping.c}
          onChange={ev =>
            handleUpdateCMYK(COLOR_MAPPING_FIELDS.c, ev.target.value)
          }
          data-testid={COLOR_MAPPING_FIELDS.c}
        />
        <TextField
          error={formErrors.m}
          contentBefore="M:"
          size="small"
          className="w-[10%]"
          value={colorMapping.m}
          onChange={ev =>
            handleUpdateCMYK(COLOR_MAPPING_FIELDS.m, ev.target.value)
          }
          data-testid={COLOR_MAPPING_FIELDS.m}
        />
        <TextField
          error={formErrors.y}
          contentBefore="Y:"
          size="small"
          className="w-[10%]"
          value={colorMapping.y}
          onChange={ev =>
            handleUpdateCMYK(COLOR_MAPPING_FIELDS.y, ev.target.value)
          }
          data-testid={COLOR_MAPPING_FIELDS.y}
        />
        <TextField
          error={formErrors.k}
          contentBefore="K:"
          size="small"
          className="w-[10%]"
          value={colorMapping.k}
          onChange={ev =>
            handleUpdateCMYK(COLOR_MAPPING_FIELDS.k, ev.target.value)
          }
          data-testid={COLOR_MAPPING_FIELDS.k}
        />
        <CMYKColor colorMapping={colorMapping} />
        <TextField
          error={false}
          contentBefore="Swatch Name:"
          size="small"
          className={'mb-5 w-[20%]'}
          value={colorMapping.swatch_name}
          onChange={ev =>
            handleUpdateColorMapping(
              COLOR_MAPPING_FIELDS.swatch_name,
              ev.target.value
            )
          }
          data-testid={COLOR_MAPPING_FIELDS.swatch_name}
        />
      </div>
      <ColorMappingAssociations hexCode={colorMapping.hex_code} />
      <div className="mt-10 flex gap-3">
        <BackButtonComponent destination="/color-mappings" />
        <SaveButtonComponent
          isSaving={isSaving}
          onSave={handleSaveColorMapping}
        />
      </div>
      <UnsavedChangesModalComponent
        unsavedChanges={unsavedChanges}
        unsavedChangesBlocker={unsavedChangesBlocker}
      />
      <ConfirmDeleteModalComponent
        isOpen={confirmDelete}
        entityName={colorMapping.system_name}
        onCancel={() => setConfirmDelete(false)}
        onConfirm={handleDeleteColorMapping}
      />
    </div>
  );
}
