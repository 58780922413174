export function CloseOutlineIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17.0235 15.615L13.4085 12L17.0235 8.38498L15.615 6.97653L12 10.5915L8.38498 6.97653L6.97653 8.38498L10.5915 12L6.97653 15.615L8.38498 17.0235L12 13.4085L15.615 17.0235L17.0235 15.615ZM4.9108 4.95775C6.88263 2.98592 9.2457 2 12 2C14.7543 2 17.1017 2.98592 19.0423 4.95775C21.0141 6.89828 22 9.2457 22 12C22 14.7543 21.0141 17.1174 19.0423 19.0892C17.1017 21.0297 14.7543 22 12 22C9.2457 22 6.88263 21.0297 4.9108 19.0892C2.97027 17.1174 2 14.7543 2 12C2 9.2457 2.97027 6.89828 4.9108 4.95775Z"
        fill="currentColor"
      />
    </svg>
  );
}
