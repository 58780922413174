const loadedFonts = new Set();

export type LoadFont = {
  url: string;
  family: string;
  weight?: string;
  style?: string;
};

export function loadFont(font: LoadFont) {
  const id = `${font.family};${font.url}`;
  if (loadedFonts.has(id)) {
    return;
  }

  const newFont = new FontFace(font.family, `url(${font.url})`, {
    weight: font.weight ?? '400',
    style: font.style || 'normal'
  });
  document.fonts.add(newFont);
  loadedFonts.add(id);
}
