import clsx from 'clsx';
import { LabelVariantLayer } from '../../../../../api/trpcApi/services/labels/types';
import { DragIcon } from '../../../../../components/icons';
import { Select } from '../../../../../components/inputs';
import { OptionProps } from '../../../../../components/inputs/Select';
import { DeleteButton } from '../DeleteButton';
import { useState } from 'react';
import SVG from 'react-inlinesvg';
import { LabelUpdater } from '../../../types/state';

export interface LayerFieldProps {
  value: LabelVariantLayer;
  options: OptionProps[];
  onUpdate: LabelUpdater<LabelVariantLayer>;
  onDelete: () => void;
  order?: number;
  onDrag: () => void;
  onDragOver: () => void;
}

export function LayerField({
  value,
  options,
  onUpdate,
  onDelete,
  order,
  onDrag,
  onDragOver
}: LayerFieldProps) {
  const [dragging, setDragging] = useState(false);

  return (
    <div
      draggable={true}
      onDrag={() => {
        setDragging(true);
        onDrag();
      }}
      onDragEnd={() => setDragging(false)}
      onDragOver={onDragOver}
      className={clsx('bg-white p-2', dragging && 'opacity-50')}
      style={{
        order: order
      }}
    >
      <div className="flex items-center justify-stretch gap-4">
        <DragIcon />
        <div className="aspect-square basis-10">
          <SVG src={value.svgText} className="h-full w-full" />
        </div>
        <Select
          className="grow"
          value={value.print_key}
          options={options}
          onChange={e => {
            const selected = e.currentTarget.value;
            onUpdate({ print_key: selected });
          }}
        />
        <DeleteButton onClick={onDelete} />
      </div>
    </div>
  );
}
