import clsx from 'clsx';
import { SizeEnum } from '../../types';

export interface SelectProps
  extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'size'> {
  error?: boolean;
  size?: SizeEnum;
  contentBefore?: React.ReactNode;
  options: OptionProps[];
  contentAfter?: React.ReactNode;
  placeholder?: string;
}

export function Select(props: SelectProps) {
  const {
    error,
    className,
    disabled,
    size = SizeEnum.large,
    options,
    contentBefore,
    contentAfter,
    placeholder,
    ...rest
  } = props;

  const wrapperClassList = [
    'flex',
    'flex-shrink-0',
    'items-center',
    'gap-[10px]',
    'rounded-[4px]',
    'px-[12px]',
    'py-[8px]',
    'align-middle',
    'text-input-shadow'
  ];

  const errorClassList = {
    'border-2': error,
    'border-blush': error
  };

  const disabledClassList = {
    'cursor-not-allowed': disabled,
    'bg-white': !disabled,
    'bg-[#e0e0e0]': disabled,
    'text-[#a6a6a6]': disabled
  };

  const sizeClass = {
    'h-[30px]': size === 'x-small',
    'h-[40px]': size === 'small',
    'h-[58px]': size === 'large'
  };

  const selectClasses = [
    'flex-[1_0_0]',
    'bg-transparent',
    'outline-none',
    'w-full'
  ];

  const optionElements = options.map((optionProps, i) => (
    <Option {...optionProps} key={optionProps.key ?? i.toString()} />
  ));

  return (
    <div
      className={clsx(
        wrapperClassList,
        disabledClassList,
        errorClassList,
        sizeClass,
        errorClassList,
        className
      )}
    >
      {contentBefore}
      <select
        disabled={disabled}
        className={clsx(selectClasses, disabledClassList)}
        {...rest}
      >
        {placeholder && (
          <option value="" hidden>
            {placeholder}
          </option>
        )}
        {optionElements}
      </select>
      {contentAfter}
    </div>
  );
}

export interface OptionProps
  extends Omit<React.OptionHTMLAttributes<HTMLOptionElement>, 'selected'> {
  key?: string;
}

function Option(props: OptionProps) {
  return <option {...props} />;
}
