import { useEffect, useState } from 'react';
import { ChevronDownIcon } from '../../../../../components/icons/ChevronDownIcon';
import { Checkbox, Select } from '../../../../../components/inputs';
import { DeleteButton } from '../DeleteButton';
import clsx from 'clsx';
import { LabelColor } from '../../../types';
import { NumberInput } from '../../../../../components/inputs/NumberInput';
import { Font } from '../../../../fonts/types';
import { LabelVariantTextField } from '../../../../../api/trpcApi/services/labels/types';
import { AngleIcon } from '../../../../../components/icons';
import { TextField } from '../../../../text-fields/types';
import { CurveIcon } from '../../../../../components/icons/CurveIcon';
import { AlignField } from './AlignField';
import { ErrorValue } from '../../../../../utils/validationUtils';
import { useUpdater } from '../state/update';
import { LabelUpdater } from '../../../types/state';

export interface NameFieldProps {
  label: string;
  value: LabelVariantTextField;
  textField: TextField;
  textFieldOptions: TextField[];
  colorOptions: LabelColor[];
  fontOptions: Font[];
  // onChange: (value: LabelVariantTextField) => void;
  onUpdate: LabelUpdater<LabelVariantTextField>;
  onDelete: () => void;
  open: boolean;
  onClick: () => void;
  errors?: ErrorValue<LabelVariantTextField>;
}

const numberOptions = new Array(150).fill(0).map((_, i) => ({
  value: i + 1,
  label: (i + 1).toString()
}));

export function NameField({
  label,
  value,
  textField,
  textFieldOptions,
  colorOptions,
  fontOptions,
  onUpdate,
  onDelete,
  open,
  onClick,
  errors
}: NameFieldProps) {
  const [showCurve, setShowCurve] = useState(value.curve !== 0);
  const { change: onChange, update } = useUpdater(onUpdate);

  const defaultFontOptionArr = textField.default_font_system_name
    ? [
        {
          label: `Default: (${textField.default_font_system_name})`
        }
      ]
    : [];

  const fontOptionsToDisplay = fontOptions.filter(
    fontOption => fontOption.system_name !== textField.default_font
  );

  useEffect(() => {
    const black =
      colorOptions?.find(option => option.print_key === 'black') ||
      colorOptions[0];
    if (!value.print_key && black) {
      onChange('print_key', black.print_key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.print_key, colorOptions]);

  return (
    <div className="flex flex-col gap-2">
      <div
        className="bg-light-blue flex justify-between rounded p-2"
        onClick={onClick}
      >
        <div className="flex items-center gap-2">
          <div className={clsx('transition-transform', open && 'rotate-180')}>
            <ChevronDownIcon />
          </div>
          {label}
        </div>
        <DeleteButton onClick={onDelete} />
      </div>
      {open && (
        <>
          <div className="grid grid-cols-3 gap-2">
            <Select
              placeholder="Name Field"
              value={value.text_field_id}
              options={textFieldOptions.map(field => ({
                value: field.text_field_id,
                label: field.display_name
              }))}
              onChange={e => {
                onChange('text_field_id', e.currentTarget.value);
              }}
            />
            <Select
              placeholder="Color"
              error={!!errors?.print_key}
              value={value.print_key}
              options={[
                ...colorOptions.map(color => ({
                  value: color.print_key,
                  label: color.display_name
                }))
              ]}
              onChange={e => {
                onChange('print_key', e.currentTarget.value);
              }}
            />
            <Select
              contentBefore="Char Limit:"
              options={[
                {
                  value: textField?.default_length,
                  label: `Default (${textField.default_length.toString()})`
                },
                ...numberOptions
              ]}
              value={value.override_character_count || 0}
              onChange={e => {
                const selected = parseInt(e.currentTarget.value) || null;
                onChange('override_character_count', selected);
              }}
            />
          </div>
          <div className="grid grid-cols-2 gap-2">
            <Select
              contentBefore="Required:"
              value={
                value.override_is_required == null // undefined isn't valid json
                  ? ''
                  : value.override_is_required
                  ? 'Yes'
                  : 'No'
              }
              options={[
                {
                  value: '',
                  label: `Default (${
                    textField.required_by_default ? 'Yes' : 'No'
                  })`
                },
                {
                  value: 'Yes',
                  label: 'Yes'
                },
                {
                  value: 'No',
                  label: 'No'
                }
              ]}
              onChange={e => {
                const selected = e.currentTarget.value;
                let value = null;
                if (['Yes', 'No'].includes(selected)) {
                  value = selected === 'Yes';
                }
                onChange('override_is_required', value);
              }}
            />
            <Select
              contentBefore="Font:"
              options={[
                ...defaultFontOptionArr,
                { value: 'client choice', label: 'Client Choice' },
                ...fontOptionsToDisplay.map(font => ({
                  value: font.font_id,
                  label: font.system_name
                }))
              ]}
              value={
                value.override_font_id ||
                (value.client_choice_font ? 'client choice' : '')
              }
              onChange={e => {
                let override_font_id: string | null =
                  e.currentTarget.value || null;
                let client_choice_font = false;
                if (override_font_id === 'client choice') {
                  client_choice_font = true;
                  override_font_id = null;
                }
                onUpdate({
                  ...value,
                  client_choice_font,
                  override_font_id
                });
              }}
            />
          </div>
          <div className="grid grid-cols-4 gap-2">
            <div className="col-span-2 flex justify-between">
              <div className="flex items-center gap-1">
                <NumberInput
                  contentBefore={
                    <div className="flex items-center">
                      <AngleIcon />:
                    </div>
                  }
                  contentAfter="°"
                  min={-180}
                  max={180}
                  className="shrink *:text-right"
                  tight
                  value={value.rotation}
                  onChange={update('rotation')}
                />
                Rotate
              </div>
              <div className="flex grow items-center justify-center">
                <Checkbox
                  checked={showCurve}
                  onChange={e => {
                    setShowCurve(e.currentTarget.checked);
                    onChange('curve', 0);
                  }}
                />
                Curve
              </div>
            </div>
            {showCurve ? (
              <div className="col-span-2 flex items-center gap-2">
                <NumberInput
                  contentBefore={
                    <div className="flex items-center gap-1">
                      <CurveIcon />:
                    </div>
                  }
                  contentAfter="%"
                  min={-100}
                  max={100}
                  className="shrink *:text-right"
                  value={value.curve}
                  onChange={update('curve')}
                />
                Curve amount
              </div>
            ) : (
              <>
                <div className="flex shrink items-center justify-center">
                  <Checkbox
                    checked={value.wrap || false}
                    onChange={e => {
                      onChange('wrap', e.currentTarget.checked);
                    }}
                  />
                  Wrap
                </div>
                <div className="flex items-center gap-2">
                  <AlignField
                    align_x={value.align_x}
                    align_y={value.align_y}
                    onUpdate={onUpdate}
                  />
                  Align
                </div>
              </>
            )}

            <NumberInput
              contentBefore="X:"
              contentAfter="px"
              tight
              className="*:text-right"
              value={value.x}
              onChange={update('x')}
            />
            <NumberInput
              contentBefore="Y:"
              contentAfter="px"
              tight
              className="*:text-right"
              value={value.y}
              onChange={update('y')}
            />
            <NumberInput
              contentBefore="W:"
              contentAfter="″"
              tight
              min={0}
              step={0.05}
              className="*:text-right"
              value={value.width}
              onChange={update('width')}
            />
            <NumberInput
              contentBefore="H:"
              contentAfter="″"
              tight
              min={0}
              step={0.05}
              className="*:text-right"
              value={value.height}
              onChange={update('height')}
            />
          </div>
        </>
      )}
    </div>
  );
}
