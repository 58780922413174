import { useState, useCallback, useEffect, useMemo } from 'react';
import {
  useBlocker,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { isEqual } from 'lodash';

import {
  BackButtonComponent,
  SaveButtonComponent
} from '../../../components/buttons';
import {
  ConfirmDeleteModalComponent,
  LoadingModalComponent,
  UnsavedChangesModalComponent,
  SearchLabelsModal
} from '../../../components/modals';
import {
  Checkbox,
  SystemNameComponent,
  EditVdpCodeComponent
} from '../../../components/inputs';
import { useTRPCRequest } from '../../../hooks';
import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';
import { validateSchemaAndNotifyErrorsRecursive } from '../../../utils/validationUtils';
import { Title } from '../../../components/Title';

import { LabelVariant } from '../../labels/types';
import { useMaterials } from '../../materials/routes/hooks';

import ProductValidation from '../validations';
import {
  LabelSlotValues,
  LabelSlots,
  PRODUCT_FIELDS,
  Product,
  ProductErrors,
  ProductTemplateSelections,
  UpsellOptions
} from '../types';
import {
  AddLabelsComponent,
  GraphComponent,
  TemplateSelections,
  MultiSelect
} from '../components';
import ManageUpsellProductsComponent from '../components/upsellProducts/ManageUpsellProductsComponent';
import { formatLabelSlots } from '../utils';
import { useLabels } from '../hooks';

export function ProductDetails({ create }: { create?: boolean }) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [labelsModalOpen, setLabelsModalOpen] = useState<boolean>(false);
  const [stylesSelectOpen, setStylesSelectOpen] = useState<boolean>(false);
  const [materialsSelectOpen, setMaterialsSelectOpen] = useState<{
    label_id: string;
    open: boolean;
  }>({ label_id: '', open: false });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const unsavedChangesBlocker = useBlocker(unsavedChanges);
  const { handleTRPCRequest } = useTRPCRequest();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { labels, isLoading: labelsLoading } = useLabels();
  const [isOption, setIsOption] = useState<boolean>(false);
  const { materials } = useMaterials(undefined, true);

  const [product, setProduct] = useState<Product>({
    system_name: '',
    upsells: [],
    style_ids: [],
    only_upsell: false,
    all_styles: false,
    use_mixed_template: false,
    vdpcode: ''
  });
  const [upsells, setUpsells] = useState<UpsellOptions>({});
  const [labelSlots, setLabelSlots] = useState<LabelSlots>({});
  const [templateSelectionsList, setTemplateSelectionsList] = useState<
    Partial<ProductTemplateSelections>[]
  >([]);

  const [errors, setErrors] = useState<ProductErrors>({
    system_name: false,
    label_slots: [
      {
        options: [{ quantity: false, material_id: false, coordinates: false }]
      }
    ],
    style_ids: false,
    vdpcode: false
  });

  useEffect(() => setIsLoading(labelsLoading), [labelsLoading]);

  const handleUpdateProduct = useCallback(
    (key: string) =>
      (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const updatedValue: string = event.target.value;
        if (key === 'style_ids') {
          setProduct({
            ...product,
            style_ids: [...product.style_ids, updatedValue]
          });
        } else if (key == 'only_upsell') {
          setProduct({ ...product, only_upsell: !product.only_upsell });
        } else {
          setProduct({ ...product, [key]: updatedValue });
        }
        setErrors({ ...errors, [key]: null });
        setUnsavedChanges(true);
      },
    [product, errors]
  );

  const handleUpdateLabelSlots = useCallback(
    (updatedLabelSlot: LabelSlotValues, labelId: string) => {
      if (updatedLabelSlot.label_id) {
        const updatedLabelSlots = {
          ...labelSlots,
          [labelId]: updatedLabelSlot
        };
        setLabelSlots(updatedLabelSlots);
      } else {
        const { [labelId]: _removedItem, ...updatedLabelSlots } = labelSlots;
        setLabelSlots(updatedLabelSlots);
        if (
          Object.keys(updatedLabelSlots).length === 1 ||
          Object.keys(updatedLabelSlots).length === 0
        ) {
          setIsOption(false);
        }
        setSelectedIndex(0);
      }

      setErrors({
        ...errors,
        label_slots: [
          {
            options: [
              {
                quantity: false,
                material_id: false,
                coordinates: false
              }
            ]
          }
        ]
      });

      setUnsavedChanges(true);
    },
    [errors, labelSlots]
  );

  const handleUpdateLabelList = useCallback(
    (updatedLabelSlotList: LabelSlotValues[]) => {
      const reducedList = updatedLabelSlotList.reduce(
        (candidate: LabelSlots, label) => {
          candidate[label.label_id] = label;
          return candidate;
        },
        {}
      );

      setLabelSlots(reducedList);

      setErrors({
        ...errors,
        label_slots: [
          {
            options: [
              {
                quantity: false,
                material_id: false,
                coordinates: false
              }
            ]
          }
        ]
      });
      setUnsavedChanges(true);
    },
    [errors]
  );

  const hasLaminatedClothingOption = useMemo(() => {
    return Object.values(labelSlots).some((slot: LabelSlotValues) => {
      const label = labels.find(l => l.label_id === slot.label_id);
      const isClothingLabel = label?.system_name
        .toLowerCase()
        .includes('clothing');

      return (
        isClothingLabel &&
        slot.material_ids?.some((material_id: string) =>
          materials.some(
            m =>
              m.material_id === material_id &&
              m.system_name.toLowerCase() === 'laminated'
          )
        )
      );
    });
  }, [labelSlots, labels, materials]);

  const hasLaminatedNonClothingOption = useMemo(() => {
    return Object.values(labelSlots).some((slot: LabelSlotValues) => {
      const label = labels.find(l => l.label_id === slot.label_id);
      const isClothingLabel = label?.system_name
        .toLowerCase()
        .includes('clothing');

      return (
        !isClothingLabel &&
        slot.material_ids?.some((material_id: string) =>
          materials.some(
            m =>
              m.material_id === material_id &&
              m.system_name.toLowerCase() === 'laminated'
          )
        )
      );
    });
  }, [labelSlots, labels, materials]);

  const hasBothLaminatedOptions = useMemo(
    () => hasLaminatedClothingOption && hasLaminatedNonClothingOption,
    [hasLaminatedClothingOption, hasLaminatedNonClothingOption]
  );

  const updateUseMixedTemplate = (use_mixed_template: boolean) => {
    setProduct(product => ({ ...product, use_mixed_template }));
  };

  useEffect(() => {
    if (!hasBothLaminatedOptions) {
      updateUseMixedTemplate(false);
    }
  }, [hasBothLaminatedOptions]);

  const productMaterialIds = useMemo(
    () =>
      Array.from(
        new Set(
          Object.values(labelSlots).flatMap((slot: LabelSlotValues) =>
            slot.material_ids && slot.material_ids.length > 0
              ? slot.material_ids
              : []
          )
        )
      ),
    [labelSlots]
  );

  useEffect(() => {
    const newTemplateSelections = productMaterialIds.reduce(
      (list, materialId) => {
        const material = materials.find(m => m.material_id === materialId);
        if (!material) return list;

        let materialType = 'Iron-On';
        if (material.system_name.toLowerCase() === 'laminated') {
          materialType = 'Laminated';
        } else if (material.system_name.toLowerCase() === 'unlaminated') {
          materialType = 'Unlaminated';
        }

        const selections = templateSelectionsList.filter(
          (s: Partial<ProductTemplateSelections>) =>
            s.material_id === materialId
        );

        const findSelection = (
          templateType: 'clothing' | 'non-clothing' | 'mixed'
        ): Partial<ProductTemplateSelections> => {
          return (
            selections.find(
              (s: Partial<ProductTemplateSelections>) =>
                s.template_type === templateType
            ) || {
              material_id: materialId,
              left_icon_id: undefined,
              left_print_key: undefined,
              left_text_id: undefined,
              middle_icon_id: undefined,
              middle_print_key: undefined,
              additional_text_id: undefined,
              footer_type_id: undefined,
              template_type: templateType
            }
          );
        };

        if (materialType === 'Laminated' && product.use_mixed_template) {
          list.push(findSelection('mixed'));
        } else if (materialType === 'Laminated') {
          if (hasLaminatedNonClothingOption) {
            list.push(findSelection('non-clothing'));
          }
          if (hasLaminatedClothingOption) {
            list.push(findSelection('clothing'));
          }
        } else {
          list.push(findSelection('clothing'));
        }

        return list;
      },
      [] as Partial<ProductTemplateSelections>[]
    );

    // Update state only if the new selections are different
    if (!isEqual(templateSelectionsList, newTemplateSelections)) {
      setTemplateSelectionsList(newTemplateSelections);
    }
  }, [
    productMaterialIds,
    templateSelectionsList,
    hasLaminatedNonClothingOption,
    hasLaminatedClothingOption,
    materials,
    product.use_mixed_template
  ]);

  const fetchAndDuplicateProduct = useCallback(
    async (productId?: string) => {
      setIsLoading(true);
      if (productId && !labelsLoading) {
        const { res: copiedProduct, error } = await handleTRPCRequest({
          method: TRPCMethodEnum.get,
          resourceType: TRPCResourceEnum.products,
          requestBody: { product_id: productId }
        });

        const { system_name, ...rest } = copiedProduct;

        const { res: products } = await handleTRPCRequest({
          method: TRPCMethodEnum.list,
          resourceType: TRPCResourceEnum.products,
          requestBody: { product_id: productId }
        });

        const product_ids_to_upsell = copiedProduct.upsells.map(
          (upsell: { product_to_upsell_id: string }) =>
            upsell.product_to_upsell_id
        );

        const productMaterialIds = Array.from(
          new Set<string>(
            copiedProduct.label_slots.flatMap(
              (slot: { options: { material_ids: string[] }[] }) =>
                slot.options.flatMap(option => option.material_ids)
            )
          )
        );

        interface labelSlot {
          options: {
            label_id: string;
            material_ids: string[];
          }[];
        }

        const hasLaminatedClothingOption = copiedProduct.label_slots.some(
          (slot: labelSlot) =>
            slot.options.some(slotOption => {
              const label = labels.find(
                label => label.label_id === slotOption.label_id
              );
              const isClothingLabel = label?.system_name
                .toLowerCase()
                .includes('clothing');

              return (
                isClothingLabel &&
                slotOption.material_ids.some((material_id: string) =>
                  materials.some(
                    m =>
                      m.material_id === material_id &&
                      m.system_name.toLowerCase() === 'laminated'
                  )
                )
              );
            })
        );

        const hasLaminatedNonClothingOption = copiedProduct.label_slots.some(
          (slot: labelSlot) =>
            slot.options.some(slotOption => {
              const label = labels.find(
                label => label.label_id === slotOption.label_id
              );
              const isClothingLabel = label?.system_name
                .toLowerCase()
                .includes('clothing');

              return (
                !isClothingLabel &&
                slotOption.material_ids.some((material_id: string) =>
                  materials.some(
                    m =>
                      m.material_id === material_id &&
                      m.system_name.toLowerCase() === 'laminated'
                  )
                )
              );
            })
        );

        const templateSelections = productMaterialIds.reduce(
          (list, materialId) => {
            const material = materials.find(m => m.material_id === materialId);
            if (!material) return list;

            let materialType = 'Iron-On';
            if (material.system_name.toLowerCase() === 'laminated') {
              materialType = 'Laminated';
            } else if (material.system_name.toLowerCase() === 'unlaminated') {
              materialType = 'Unlaminated';
            }

            const selections = copiedProduct.template_selections.filter(
              (s: Partial<ProductTemplateSelections>) =>
                s.material_id === materialId
            );

            const findSelection = (
              templateType: 'clothing' | 'non-clothing' | 'mixed'
            ): Partial<ProductTemplateSelections> => {
              return (
                selections.find(
                  (s: Partial<ProductTemplateSelections>) =>
                    s.template_type === templateType
                ) || {
                  material_id: materialId,
                  left_icon_id: undefined,
                  left_print_key: undefined,
                  left_text_id: undefined,
                  middle_icon_id: undefined,
                  middle_print_key: undefined,
                  additional_text_id: undefined,
                  footer_type_id: undefined,
                  template_type: templateType
                }
              );
            };

            if (
              materialType === 'Laminated' &&
              copiedProduct.use_mixed_template
            ) {
              list.push(findSelection('mixed'));
            } else if (materialType === 'Laminated') {
              if (hasLaminatedNonClothingOption) {
                list.push(findSelection('non-clothing'));
              }
              if (hasLaminatedClothingOption) {
                list.push(findSelection('clothing'));
              }
            } else {
              list.push(findSelection('clothing'));
            }

            return list;
          },
          [] as Partial<ProductTemplateSelections>[]
        );

        templateSelections.forEach(selection => {
          delete selection.product_template_selections_id;
          delete selection.product_id;
        });

        setTemplateSelectionsList(templateSelections);

        const upsellList = products.filter((product: Product) =>
          product_ids_to_upsell.includes(product.product_id)
        );

        const allUpsells = upsellList.reduce(
          (acc: UpsellOptions, { ...product }: Product) => {
            if (product.product_id) {
              const upsell = copiedProduct.upsells.find(
                (upsell: { product_to_upsell_id: string }) =>
                  upsell.product_to_upsell_id === product.product_id
              );

              product.sku = upsell?.sku;

              acc[product.product_id] = product;
              return acc;
            }
          },
          {}
        );
        setUpsells(allUpsells);

        if (error) {
          toast.error('Error copying product');
        } else {
          if (create) {
            delete rest.product_id;
            setProduct({ system_name: `${system_name} - Copy`, ...rest });
          } else {
            setProduct({ system_name, ...rest });
          }
          const option = copiedProduct.label_slots[0].options.length > 1;
          setIsOption(option);
          const slots = formatLabelSlots(labels, option, copiedProduct);
          setLabelSlots(slots);
        }
      }

      setIsLoading(false);
    },
    [create, handleTRPCRequest, labels, labelsLoading, materials]
  );

  const syncFromRouter = useCallback(async () => {
    const productToDuplicate = location?.state?.product;
    if (productToDuplicate)
      fetchAndDuplicateProduct(productToDuplicate.product_id);
  }, [fetchAndDuplicateProduct, location?.state?.product]);

  const syncFromApi = useCallback(async () => {
    fetchAndDuplicateProduct(params.product_id);
  }, [fetchAndDuplicateProduct, params.product_id]);

  useEffect(() => {
    if (create) {
      syncFromRouter();
    } else {
      syncFromApi();
      setUnsavedChanges(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, create, labels]);

  const handleDeleteProduct = useCallback(async () => {
    setUnsavedChanges(false);
    setConfirmDelete(false);
    setIsDeleting(true);
    const { res, error } = await handleTRPCRequest({
      method: TRPCMethodEnum.delete,
      resourceType: TRPCResourceEnum.products,
      requestBody: { product_id: product.product_id }
    });

    if (res && !error) {
      toast.success('Product deleted successfully!');
      navigate('/products');
    }
    setIsDeleting(false);
  }, [handleTRPCRequest, navigate, product.product_id]);

  const handleSaveProduct = useCallback(async () => {
    setIsSaving(true);

    let prunedLabelSlots = Object.values(labelSlots).map(label => {
      const slotIdObj = create
        ? {}
        : { product_label_slot_id: label.product_label_slot_id };
      return {
        ...slotIdObj,
        options: [
          {
            label_id: label.label_id.split('-')[0],
            coordinates: label.coordinates.map(coords => {
              return {
                x: coords.x,
                y: coords.y,
                height: coords.height,
                width: coords.width
              };
            }),
            quantity: label.quantity,
            endcap_text: label.endcap_text,
            endcap_is_footer: label.endcap_is_footer,
            endcap_print_mode: label.endcap_print_mode,
            material_ids: label.material_ids
          }
        ]
      };
    });

    if (isOption) {
      const foundSlot = prunedLabelSlots.find(
        slot => slot.product_label_slot_id
      );

      const product_label_slot_id = foundSlot?.product_label_slot_id;

      const slotIdObj = product_label_slot_id ? { product_label_slot_id } : {};

      prunedLabelSlots = [
        {
          ...slotIdObj,
          options: prunedLabelSlots.flatMap(slot => slot.options)
        }
      ];
    }

    const toSave = { ...product };
    if (product.style_ids.indexOf('all') >= 0) {
      toSave.style_ids = ['all'];
    }

    delete toSave.tag;
    delete toSave.all_styles;
    toSave.upsells = Object.keys(upsells).map(key => {
      const upsell = upsells[key];
      return { product_id: key, sku: upsell.sku! };
    });

    const { errors, validatedSchema } = validateSchemaAndNotifyErrorsRecursive(
      create
        ? ProductValidation.createProductSchema
        : ProductValidation.updateProductSchema,
      {
        ...toSave,
        label_slots: prunedLabelSlots,
        template_selections: templateSelectionsList
      }
    );

    if (errors) {
      setErrors(errors as unknown as ProductErrors);
    } else {
      setUnsavedChanges(false);
      const { error } = await handleTRPCRequest({
        method: create ? TRPCMethodEnum.create : TRPCMethodEnum.update,
        resourceType: TRPCResourceEnum.products,
        requestBody: validatedSchema,
        options: {
          customErrorMessage: true,
          autoCloseError: false
        }
      });
      if (!error) {
        setErrors({
          system_name: false,
          label_slots: [
            {
              options: [
                { quantity: false, material_id: false, coordinates: false }
              ]
            }
          ],
          style_ids: false,
          vdpcode: false
        });
        toast.success('Product saved successfully');
      }
    }
    setIsSaving(false);
  }, [
    create,
    handleTRPCRequest,
    isOption,
    labelSlots,
    product,
    upsells,
    templateSelectionsList
  ]);

  const handleAddLabel = useCallback(
    async (labelId: string) => {
      setIsLoading(true);
      setLabelsModalOpen(false);
      const addedLabel = labels.filter(
        label => label.label_id == labelId.split('-')[0]
      )[0];
      if (!addedLabel?.label_id) {
        setIsLoading(false);
        return;
      }
      const labelVariantsRequest = {
        method: TRPCMethodEnum.list,
        resourceType: TRPCResourceEnum.labelVariants,
        requestBody: { label_id: addedLabel.label_id }
      };
      const { res } = await handleTRPCRequest(labelVariantsRequest);
      let maxWidth = 1;
      let maxHeight = 1;
      if (res) {
        let biggestArea = 0;
        (res as LabelVariant[]).map(labelVariant => {
          if (labelVariant.height * labelVariant.width > biggestArea) {
            biggestArea = labelVariant.height * labelVariant.width;
            maxWidth = labelVariant.width;
            maxHeight = labelVariant.height;
          }
        });
      }

      setLabelSlots({
        ...labelSlots,
        [labelId]: {
          system_name: addedLabel.system_name,
          label_id: labelId,
          material_ids: addedLabel.material_ids,
          coordinates: [
            {
              x: 0,
              y: 0,
              width: maxWidth * 72,
              height: maxHeight * 72,
              showBorder: true
            }
          ],
          endcap_text: null,
          endcap_is_footer: null,
          endcap_print_mode: null
        }
      });
      if (isOption) {
        setSelectedIndex(Object.values(labelSlots).length);
      }
      setIsLoading(false);
    },
    [handleTRPCRequest, isOption, labelSlots, labels]
  );

  const handleDuplicateProduct = useCallback(() => {
    navigate(`/products/new`, {
      state: { product },
      replace: true
    });
  }, [product, navigate]);

  const handleUpdateVdpCode = (newVdpCode: string) => {
    setProduct(prevProduct => ({ ...prevProduct, vdpcode: newVdpCode }));
  };

  if (isLoading) {
    return <LoadingModalComponent isOpen={isLoading} message="Loading..." />;
  }
  if (isSaving) {
    return <LoadingModalComponent isOpen={isSaving} message="Saving..." />;
  }
  if (isDeleting) {
    return <LoadingModalComponent isOpen={isDeleting} message="Deleting..." />;
  }

  return (
    <div className="flex flex-col gap-3">
      {create ? (
        <Title title="Create Product" />
      ) : (
        <Title title="Existing Product" />
      )}
      <SystemNameComponent
        create={create}
        systemName={product.system_name}
        onDelete={() => setConfirmDelete(true)}
        onDuplicate={handleDuplicateProduct}
        onSystemNameChange={handleUpdateProduct(PRODUCT_FIELDS.system_name)}
        errors={{ system_name: !!errors.system_name }}
        className=""
      />
      <div className="flex items-center gap-2">
        <MultiSelect
          isOpen={stylesSelectOpen}
          setIsOpen={setStylesSelectOpen}
          allStyles={product.all_styles}
          error={errors.style_ids}
          onStylesListUpdate={styleIDList => {
            setProduct({
              ...product,
              all_styles: styleIDList.includes('all'),
              style_ids: styleIDList
            });
            setErrors({ ...errors, style_ids: false });
          }}
          value={product.style_ids}
        />
        {!create && (
          <div className="text-input-shadow flex flex-shrink-0 items-center gap-[10px] rounded-[4px] px-[12px] py-[8px] align-middle">
            Tag: {product.tag}
          </div>
        )}

        <Checkbox
          checked={product.only_upsell}
          label="This is an upsell"
          onChange={handleUpdateProduct(PRODUCT_FIELDS.only_upsell)}
        />
        <EditVdpCodeComponent
          vdpCode={product.vdpcode || ''}
          handleUpdateVdpCode={handleUpdateVdpCode}
          error={!!errors.vdpcode}
        />
      </div>
      <ManageUpsellProductsComponent
        currentProductId={product.product_id}
        error={false}
        onChange={setUpsells}
        upsellOptions={upsells}
      />
      <div className="items-top flex gap-5">
        <div className="w-1/2">
          <AddLabelsComponent
            materialsSelectOpen={materialsSelectOpen}
            setMaterialsSelectOpen={setMaterialsSelectOpen}
            isOption={isOption}
            labelsList={labels}
            setSelectedIndex={setSelectedIndex}
            setIsOption={setIsOption}
            labelSlots={labelSlots}
            onUpdateLabelSlots={handleUpdateLabelSlots}
            onUpdateLabelList={handleUpdateLabelList}
            setModalOpen={() => {
              setLabelsModalOpen(true);
              setStylesSelectOpen(false);
              setMaterialsSelectOpen({ label_id: '', open: false });
            }}
            error={errors.label_slots}
          />
        </div>

        <DndProvider backend={HTML5Backend}>
          <GraphComponent
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            isOption={isOption}
            labelSlots={labelSlots}
            onUpdateLabelList={handleUpdateLabelList}
          />
        </DndProvider>
      </div>
      <TemplateSelections
        hasBothLaminatedOptions={hasBothLaminatedOptions}
        templateSelectionsList={templateSelectionsList}
        setTemplateSelectionsList={setTemplateSelectionsList}
        usingMixedTemplate={product.use_mixed_template}
        updateUseMixedTemplate={updateUseMixedTemplate}
      />
      <div className="mt-10 flex gap-3">
        <BackButtonComponent destination="/products" />
        <SaveButtonComponent isSaving={isSaving} onSave={handleSaveProduct} />
      </div>
      <UnsavedChangesModalComponent
        unsavedChanges={unsavedChanges}
        unsavedChangesBlocker={unsavedChangesBlocker}
      />
      <ConfirmDeleteModalComponent
        isOpen={confirmDelete}
        entityName={product.system_name}
        onCancel={() => setConfirmDelete(false)}
        onConfirm={handleDeleteProduct}
      />
      <SearchLabelsModal
        onAddLabel={handleAddLabel}
        labelSlots={labelSlots}
        isOpen={labelsModalOpen}
        isOption={isOption}
        onClose={() => {
          if (Object.values(labelSlots).length < 2) {
            setIsOption(false);
          }
          setLabelsModalOpen(false);
        }}
      />
    </div>
  );
}
