export function MinusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        x="1.35349"
        y="10.8535"
        width="22"
        height="3"
        rx="1.5"
        fill="currentColor"
      />
    </svg>
  );
}
