export function PrintDisabledIcon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="material-symbols:print-disabled">
        <path
          id="Vector"
          d="M19.775 22.625L18 20.85V21H6V17H2V11C2 10.15 2.29167 9.43738 2.875 8.86205C3.45833 8.28672 4.16667 7.99938 5 8.00005H5.15L1.375 4.22505L2.8 2.80005L21.2 21.2001L19.775 22.625ZM8 19H16V18.85L12.15 15H8V19ZM19.85 17L10.85 8.00005H19C19.85 8.00005 20.5627 8.28771 21.138 8.86305C21.7133 9.43838 22.0007 10.1507 22 11V17H19.85ZM16 7.00005H9.85L5.85 3.00005H18V7.00005H16ZM18 12.5C18.2833 12.5 18.521 12.404 18.713 12.212C18.905 12.02 19.0007 11.7827 19 11.5C19 11.2167 18.904 10.979 18.712 10.787C18.52 10.595 18.2827 10.4994 18 10.5C17.7167 10.5 17.479 10.596 17.287 10.788C17.095 10.98 16.9993 11.2174 17 11.5C17 11.7834 17.096 12.021 17.288 12.213C17.48 12.405 17.7173 12.5007 18 12.5Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
