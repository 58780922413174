import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import clsx from 'clsx';

import { IconButton } from '../../../components/buttons';
import { Chip } from '../../../components/chips';
import { CloseOutlineIcon, PlusIcon } from '../../../components/icons';
import { SizeEnum } from '../../../types';

import { Material } from '../../materials/types';
import { Label } from '../../labels/types';

import {
  LABEL_SLOT_FIELDS,
  LabelSlotErrors,
  LabelSlotValues,
  LabelSlots
} from '../types';

import { MultiSelectMaterials } from './MultiSelectMaterials';
import { QuantityInput } from './QuantityInput';
import { EndcapOptions } from './EndcapOptions';

interface EditMaterialsProps {
  materialsSelectOpen: { label_id: string; open: boolean };
  setMaterialsSelectOpen: Dispatch<
    SetStateAction<{ label_id: string; open: boolean }>
  >;
  labelSlots: LabelSlots;
  onUpdateLabelList: (updatedLabelSlotList: LabelSlotValues[]) => void;
  setModalOpen(): void;
  isOption: boolean;
  materials: Material[];
  labelsList: Label[];
  setIsOption: Dispatch<SetStateAction<boolean>>;
  setSelectedIndex: Dispatch<SetStateAction<number>>;
  errors: [
    {
      options: LabelSlotErrors[];
    }
  ];
  onUpdateLabelSlots(updatedLabelSlot: LabelSlotValues, labelId: string): void;
}

export function EditMaterialsComponent({
  materialsSelectOpen,
  setMaterialsSelectOpen,
  labelSlots,
  onUpdateLabelList,
  errors,
  setModalOpen,
  isOption,
  materials,
  labelsList,
  setIsOption,
  setSelectedIndex,
  onUpdateLabelSlots
}: EditMaterialsProps) {
  const [shownIndex, setShownIndex] = useState(0);
  const [labelSlotsList, setLabelSlotsList] = useState<LabelSlotValues[]>(
    Object.values(labelSlots)
  );
  useEffect(() => {
    setLabelSlotsList(Object.values(labelSlots));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelSlots]);
  const handleUpdateLabelSlot = useCallback(
    (
      label_id: string,
      updatedValue: number,
      attributeKey: LABEL_SLOT_FIELDS
    ) => {
      const updatedLabelSlot: LabelSlotValues = {
        ...labelSlots[label_id],
        [attributeKey]: updatedValue
      };
      onUpdateLabelSlots(updatedLabelSlot, label_id);
    },
    [labelSlots, onUpdateLabelSlots]
  );
  const handleRemoveLabel = (label_id: string) => {
    onUpdateLabelSlots(
      {
        system_name: '',
        label_id: '',
        quantity: 0,
        material_ids: [],
        coordinates: [],
        endcap_text: null,
        endcap_is_footer: null,
        endcap_print_mode: null
      },
      label_id
    );
  };

  const handleAddPreview = (index: number) => {
    const existingCoords = labelSlotsList[index].coordinates;
    setLabelSlotsList(prevLabelSlotsList => {
      const updatedLabelSlotsList = [...prevLabelSlotsList];
      updatedLabelSlotsList[index] = {
        ...prevLabelSlotsList[index],
        coordinates: [
          ...existingCoords,
          {
            x: existingCoords[existingCoords.length - 1]?.x + 50 || 100,
            y: existingCoords[existingCoords.length - 1]?.y + 50 || 100,
            width: existingCoords[0]?.width || 100,
            height: existingCoords[0]?.height || 100,
            showBorder: false
          }
        ]
      };
      onUpdateLabelList(updatedLabelSlotsList);
      return updatedLabelSlotsList;
    });
  };

  const handleShowBoxWithDots = (index: number) => {
    if (labelSlotsList[index].coordinates.length > 0) {
      if (isOption) {
        setSelectedIndex(
          prevIndex =>
            (prevIndex + 1) % labelSlotsList[index].coordinates.length
        );
      }
      setLabelSlotsList(prevLabelSlotsList => {
        const updatedLabelSlotsList = [...prevLabelSlotsList];
        const updatedCoordinates = [
          ...updatedLabelSlotsList[index].coordinates
        ];

        // Increment shownIndex by 1 or set it to 0 if it exceeds the length of coordinates
        let indexToShow = shownIndex + 1;
        if (indexToShow >= labelSlotsList[index].coordinates.length) {
          indexToShow = 0;
        }

        updatedCoordinates[indexToShow] = {
          ...updatedCoordinates[indexToShow],
          showBorder: true
        };

        updatedLabelSlotsList[index] = {
          ...updatedLabelSlotsList[index],
          coordinates: updatedCoordinates
        };

        onUpdateLabelList(updatedLabelSlotsList);
        return updatedLabelSlotsList;
      });

      setShownIndex(
        prevIndex => (prevIndex + 1) % labelSlotsList[index].coordinates.length
      );
    }
  };

  const labelList = Object.values(labelSlots);

  return (
    <div className="flex max-h-[20rem] flex-col gap-2 overflow-y-auto">
      {labelList?.map((label, index) => {
        return (
          <div
            key={label.label_id}
            className={clsx(
              isOption
                ? index !== labelList.length - 1 && 'pb-2'
                : index !== labelList.length - 1 &&
                    'border-b border-[#D3D3D3] pb-2',
              'flex items-center gap-2'
            )}
          >
            <div className="max-w-1/2 flex cursor-pointer items-center gap-3">
              <Chip
                onClick={() => handleShowBoxWithDots(index)}
                size={SizeEnum.medium}
              >
                <span className="max-h-10 max-w-[9.5rem] overflow-y-auto">
                  {label.system_name}
                </span>
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    handleRemoveLabel(label.label_id);
                  }}
                  icon={<CloseOutlineIcon />}
                />
              </Chip>
            </div>
            {!isOption && (
              <div
                className={clsx(
                  'flex h-full items-center',
                  labelList.length !== 1 && 'grow'
                )}
              >
                <button
                  className="bg-light-gray rounded-[5px] p-1 pl-[.2rem] text-white shadow"
                  onClick={() => handleAddPreview(index)}
                >
                  <div className="flex items-center gap-1">
                    <PlusIcon width="14px" height="14px" />
                    <div className="m-[.1rem] h-5 w-5 border-2 border-dashed"></div>
                  </div>
                </button>
              </div>
            )}

            {labelList.length === 1 ||
            (index === labelList.length - 1 && isOption) ? (
              <button
                className="border-navy border-navy text-navy flex items-center gap-2 rounded border bg-white px-2 text-sm"
                onClick={() => {
                  setModalOpen();
                  setIsOption(true);
                }}
              >
                <PlusIcon /> Option
              </button>
            ) : null}

            {(isOption || labelList.length === 1) && <div className="grow" />}

            <div className="flex items-center justify-end gap-2">
              <EndcapOptions
                onUpdateLabelSlots={onUpdateLabelSlots}
                label={label}
              />
              <QuantityInput
                errors={errors}
                index={index}
                label={label}
                handleUpdateLabelSlot={handleUpdateLabelSlot}
              />
              <MultiSelectMaterials
                open={materialsSelectOpen}
                setOpen={setMaterialsSelectOpen}
                labelsList={labelsList}
                onMaterialsUpdate={updatedList => {
                  const updatedLabelSlot: LabelSlotValues = {
                    ...labelSlots[label.label_id],
                    material_ids: updatedList
                  };
                  onUpdateLabelSlots(updatedLabelSlot, label.label_id);
                }}
                materials={materials}
                error={errors && errors[0]?.options[index]?.material_id}
                value={label.material_ids}
                label_id={label.label_id}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
