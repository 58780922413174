import { createContext, useContext } from 'react';
import { TextField } from '../../../text-fields/types';
import { Font } from '../../../fonts/types';

export interface VariantState {
  selectedTextFields: string[];
  selectedIconFields: string[];
  toggleTextField: (idx: string) => void;
  toggleIconField: (idx: string) => void;

  textFields: TextField[];
  exampleFont?: Font;
}

export const VariantContext = createContext<VariantState>({
  selectedTextFields: [],
  selectedIconFields: [],
  toggleTextField: () => {},
  toggleIconField: () => {},
  textFields: []
});

export function useVariantState() {
  return useContext(VariantContext);
}
