import { IconButton } from '../../../components/buttons';
import { Chip } from '../../../components/chips';
import { CloseOutlineIcon } from '../../../components/icons';
import { GroupRegistrations } from '../types';

interface GroupRegistrationChipsProps {
  groupRegistrations: GroupRegistrations;
  onUpdateGroupRegistrations(
    updatedGroupRegistrations: GroupRegistrations
  ): void;
}

export const GroupRegistrationChipsComponent = ({
  groupRegistrations,
  onUpdateGroupRegistrations
}: GroupRegistrationChipsProps) => {
  const handleRemoveGroupRegistration = (groupMemberId: string) => () => {
    const updatedGroupRegistrations = { ...groupRegistrations };
    delete updatedGroupRegistrations[groupMemberId];
    onUpdateGroupRegistrations(updatedGroupRegistrations);
  };

  return (
    <div>
      {Object.values(groupRegistrations).map(groupRegistration => {
        return (
          <Chip
            key={
              groupRegistration.group_registration_id ??
              groupRegistration.group_member_id
            }
            classes={['m-2']}
          >
            {groupRegistration.displayName ?? groupRegistration.system_name}
            <IconButton
              onClick={handleRemoveGroupRegistration(
                groupRegistration.group_member_id
              )}
              icon={<CloseOutlineIcon />}
            />
          </Chip>
        );
      })}
    </div>
  );
};
