import { toPx } from './svgUtils';

export interface IconFieldPreviewProps {
  x: number;
  y: number;
  radius: number;
  rotation: number;
  focus: boolean;
  onClick: () => void;
}

export function IconFieldPreview({
  x: xIn,
  y: yIn,
  radius: radiusIn,
  rotation,
  focus,
  onClick
}: IconFieldPreviewProps) {
  const radius = toPx(radiusIn);
  const x = toPx(xIn);
  const y = toPx(yIn);
  const left = -radius;
  const right = radius;
  const top = -radius;
  return (
    <g
      style={{
        transform: `translate(${x}px, ${-y}px) rotate(${rotation}deg)`
      }}
      onClick={onClick}
    >
      {focus && (
        <>
          <rect
            x={left}
            y={top}
            width={radius * 2}
            height={radius * 2}
            stroke="#004876"
            fillOpacity={0}
          />
        </>
      )}
      <circle
        r={right}
        stroke="#004876"
        fill="#ffffff"
        className="drop-shadow"
      />
      <text
        fontSize={radius / 2}
        fill="#004876"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        Icon
      </text>
    </g>
  );
}
