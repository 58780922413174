import clsx from 'clsx';

interface ColorSampleProps {
  colorHex: string;
  className?: string;
}

export function ColorSample({
  colorHex,
  className = 'h-11 w-11 '
}: ColorSampleProps) {
  return (
    <div
      className={clsx(className, 'rounded-full', {
        border: colorHex === '#FFFFFF',
        'border-[#B7B7B7]': colorHex === '#FFFFFF'
      })}
      style={{ background: colorHex! }}
    ></div>
  );
}
