import { useCallback } from 'react';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import { UploadButtonComponent } from '../../../components/buttons';

import { IconLayer } from '../types';

interface UploadIconLayersProps {
  error: boolean;
  iconLayers: IconLayer[];
  onUpdateIconLayers(iconLayers: IconLayer[]): void;
}

export const UploadIconLayersComponent = ({
  error,
  iconLayers,
  onUpdateIconLayers
}: UploadIconLayersProps) => {
  const handleUploadIconLayers = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files) {
        const promises = [];
        for (const file of files) {
          if (file.type === 'image/svg+xml') {
            const reader = new FileReader();
            const previousIconLayers = [...iconLayers];
            const promise = new Promise<IconLayer>((resolve, reject) => {
              reader.onload = event => {
                if (event.target && typeof event.target.result === 'string') {
                  resolve({
                    svgText: event.target.result as string,
                    index: previousIconLayers.length,
                    print_key: 'color1'
                  });
                } else {
                  reject('Icon layer must be an SVG');
                  toast.error('Icon layer must be an SVG');
                }
              };

              reader.readAsText(file);
            });
            promises.push(promise);
          }
        }
        onUpdateIconLayers([...iconLayers, ...(await Promise.all(promises))]);
      }
    },
    [iconLayers, onUpdateIconLayers]
  );

  return (
    <div
      className={clsx('flex flex-col items-center rounded  p-4', {
        'bg-light-blue': !error,
        'bg-blush': error
      })}
    >
      <UploadButtonComponent
        accept={['image/svg+xml']}
        text="Upload Layer(s)"
        buttonClassName="m-3"
        data-testid="layer-upload-button"
        onUpload={handleUploadIconLayers}
        multiple
      />
      <div>Add Layers as Separate SVGs</div>
    </div>
  );
};
