import { useNavigate } from 'react-router-dom';
import { SearchBar } from '../../../components/search-bar';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalStorage, useTRPCRequest } from '../../../hooks';
import { Label } from '../../../api/trpcApi/services/labels/types';
import {
  EntityDependencies,
  TRPCMethodEnum,
  TRPCResourceEnum
} from '../../../api/trpcApi/types';
import { RecordTable } from '../../../components/tables';
import { TableRecord } from '../../../types/record-table';
import { ListThumbnail } from '../../../components/images';
import { toast } from 'react-toastify';
import {
  DeleteAssetButton,
  DuplicateAssetButton
} from '../../../components/buttons';
import {
  CannotDeleteModalComponent,
  ConfirmDeleteModalComponent,
  LoadingModalComponent
} from '../../../components/modals';
import { useMaterials } from '../../materials/routes/hooks';
import { Title } from '../../../components/Title';

export function Labels() {
  const navigate = useNavigate();

  const [search, setSearch] = useLocalStorage<string>(
    window.location.pathname,
    ''
  );
  const [labels, setLabels] = useState<Label[]>([]);
  const [labelToDelete, setLabelToDelete] = useState<Label>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { handleTRPCRequest } = useTRPCRequest();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);
  const [dependencies, setDependencies] = useState<EntityDependencies>({});

  const fetchLabels = useCallback(
    async (search?: string) => {
      setIsLoading(true);
      const trpcRequest = {
        method: TRPCMethodEnum.list,
        resourceType: TRPCResourceEnum.labels,
        requestBody: {}
      };
      if (search) {
        trpcRequest.requestBody = { keyword: search };
      }
      const { res: materials } = await handleTRPCRequest(trpcRequest);
      setLabels(materials);
      setIsLoading(false);
    },
    [handleTRPCRequest]
  );

  useEffect(() => {
    fetchLabels(search || undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const { materials } = useMaterials();

  const materialIdToName = useMemo(() => {
    return new Map(materials?.map(m => [m.material_id!, m.display_name]));
  }, [materials]);

  const handleDuplicateLabel = useCallback(
    (row: TableRecord) => (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      navigate('/labels/new', {
        state: {
          labelToDuplicateId: row.label_id
        }
      });
    },
    [navigate]
  );

  const handleDeleteLabel = useCallback(
    (label: TableRecord) => (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setLabelToDelete(label as Label);
      setConfirmDelete(true);
    },
    []
  );

  const handleConfirmedDeleteMaterial = useCallback(async () => {
    setConfirmDelete(false);
    const { res, error } = await handleTRPCRequest({
      method: TRPCMethodEnum.delete,
      resourceType: TRPCResourceEnum.labels,
      requestBody: { label_id: labelToDelete?.label_id }
    });

    if (res?.success === false) {
      setShowCannotDeleteModal(true);
      setDependencies(res.relatedEntities);
    } else if (!error && res) {
      toast.success('Label deleted successfully!');
      fetchLabels();
    }
  }, [fetchLabels, handleTRPCRequest, labelToDelete?.label_id]);

  if (isLoading) return <LoadingModalComponent isOpen />;

  const noResults = !labels?.length;

  return (
    <>
      <Title title="Labels Search" />
      <SearchBar
        searchTerm={search}
        onSearch={search => setSearch(search)}
        onAddNew={() => navigate('/labels/new')}
      />
      {noResults && !search ? (
        <>
          <h2>No labels exist yet.</h2>
          <p> Click "+ Add New" to create your first label!</p>
        </>
      ) : noResults ? (
        <h2>No labels found for "{search}".</h2>
      ) : (
        <RecordTable
          data={labels}
          idKey="label_id"
          calculateRowHref={label => `/labels/${label.label_id}`}
          rowClassNames={[
            'card',
            'mb-[6px]',
            'flex',
            'h-[54px]',
            'cursor-pointer',
            'items-center',
            'align-middle'
          ]}
          columns={[
            {
              columnKey: 'thumbnail_path',
              onRender: (label: TableRecord) => (
                <ListThumbnail src={label.thumbnail_path} />
              ),
              widthClassNames: ['w-[54px]']
            },
            {
              columnKey: 'systemName',
              header: 'System Name',
              onRender: label => label.system_name,
              widthClassNames: ['w-[30%]', 'ml-[30px]']
            },
            {
              columnKey: 'displayName',
              header: 'Display Name',
              onRender: label => label.display_name,
              widthClassNames: ['w-[30%]']
            },
            {
              columnKey: 'material',
              header: 'Material',
              onRender: label =>
                label.material_ids
                  ?.map((id: string) => materialIdToName.get(id))
                  .filter((x: string) => x)
                  .join(', '),
              widthClassNames: ['w-[30%]', 'max-h-full', 'overflow-auto']
            },
            {
              columnKey: 'actions',
              widthClassNames: ['ml-auto'],
              onRender: label => (
                <div className="flex gap-3 pr-3">
                  <DuplicateAssetButton
                    onDuplicateAsset={handleDuplicateLabel(label)}
                  />
                  <DeleteAssetButton onDeleteAsset={handleDeleteLabel(label)} />
                </div>
              )
            }
          ]}
        />
      )}
      <ConfirmDeleteModalComponent
        isOpen={!!confirmDelete}
        entityName={labelToDelete?.system_name ?? ''}
        onConfirm={handleConfirmedDeleteMaterial}
        onCancel={() => {
          setLabelToDelete(undefined);
          setConfirmDelete(false);
        }}
      />
      <CannotDeleteModalComponent
        entityName={labelToDelete?.system_name ?? ''}
        dependencies={dependencies}
        isOpen={showCannotDeleteModal}
        onCancel={() => setShowCannotDeleteModal(false)}
      />
    </>
  );
}
