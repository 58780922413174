import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLocalStorage } from '../../../hooks';
import { SearchBar } from '../../../components/search-bar';
import { TableRecord } from '../../../types/record-table';
import { RecordTable } from '../../../components/tables';
import {
  DuplicateAssetButton,
  DeleteAssetButton
} from '../../../components/buttons';
import { TextField } from '../types';
import { useTRPCRequest } from '../../../hooks';
import {
  EntityDependencies,
  TRPCMethodEnum,
  TRPCResourceEnum
} from '../../../api/trpcApi/types';
import {
  CannotDeleteModalComponent,
  ConfirmDeleteModalComponent
} from '../../../components/modals';
import { toast } from 'react-toastify';
import { Title } from '../../../components/Title';

export function TextFieldsList() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useLocalStorage<string>(
    window.location.pathname,
    ''
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [textFields, setTextFields] = useState<TextField[]>([]);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [textFieldToDelete, setTextFieldToDelete] =
    useState<TableRecord | null>(null);
  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);
  const [dependencies, setDependencies] = useState<EntityDependencies>({});

  const { handleTRPCRequest } = useTRPCRequest();

  const fetchTextFields = useCallback(
    async (search?: string) => {
      setIsLoading(true);
      const trpcRequest = {
        method: TRPCMethodEnum.list,
        resourceType: TRPCResourceEnum.textFields,
        requestBody: {}
      };
      if (search) {
        trpcRequest.requestBody = { keyword: search };
      }
      const { res: textFields } = await handleTRPCRequest(trpcRequest);
      setTextFields(textFields);
      setIsLoading(false);
    },
    [handleTRPCRequest]
  );

  useEffect(() => {
    fetchTextFields(searchTerm ?? undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleAddNew = useCallback(() => {
    navigate(`/text-fields/new`);
  }, [navigate]);

  const handleDuplicateTextField = useCallback(
    (textField: TableRecord) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      navigate(`/text-fields/new`, {
        state: { textFieldToCopy: textField.text_field_id }
      });
    },
    [navigate]
  );

  const handleDeleteTextField = useCallback(
    (textField: TableRecord) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setTextFieldToDelete(textField as TextField);
      setConfirmDelete(true);
    },
    []
  );

  const handleConfirmDeleteTextField = useCallback(async () => {
    if (textFieldToDelete) {
      setConfirmDelete(false);
      const { text_field_id } = textFieldToDelete as TextField;
      const { res, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.delete,
        resourceType: TRPCResourceEnum.textFields,
        requestBody: { text_field_id }
      });

      if (res?.success === false) {
        setShowCannotDeleteModal(true);
        setDependencies(res.relatedEntities);
      } else if (res && !error) {
        toast.success('Text Field deleted successfully!');
        const newTextFields = textFields.filter(
          textField => textField.text_field_id !== text_field_id
        );
        setTextFields(newTextFields);
      }
    }
  }, [textFields, handleTRPCRequest, textFieldToDelete]);

  const noResults = textFields?.length === 0;
  const noSearch = searchTerm === '';

  return (
    <>
      <Title title="Text Fields Search" />
      <SearchBar
        searchTerm={searchTerm}
        onSearch={search => setSearchTerm(search)}
        onAddNew={handleAddNew}
        classNames={['pb-2']}
      />
      {isLoading ? (
        <h2>Loading...</h2>
      ) : noResults && noSearch ? (
        <>
          <h2>No text fields exist yet.</h2>
          <p> Click "+ Add New" to create your first text field!</p>
        </>
      ) : noResults ? (
        <h2>No text fields found for "{searchTerm}".</h2>
      ) : (
        <RecordTable
          data={textFields ?? []}
          idKey="text_field_id"
          rowClassNames={[
            'card',
            'mb-[6px]',
            'flex',
            'h-[54px]',
            'cursor-pointer',
            'items-center',
            'align-middle'
          ]}
          calculateRowHref={textField =>
            `/text-fields/${textField.text_field_id}`
          }
          columns={[
            {
              columnKey: 'systemName',
              header: 'System Name',
              onRender: textField => textField.system_name,
              widthClassNames: ['w-[15%]', 'ml-[30px]']
            },
            {
              columnKey: 'displayName',
              header: 'Display Name',
              onRender: textField => textField.display_name,
              widthClassNames: ['w-[15%]', 'ml-[30px]']
            },
            {
              columnKey: 'displayName',
              header: 'Display Name 2',
              onRender: textField => textField.display_name_2,
              widthClassNames: ['w-[15%]', 'ml-[30px]']
            },
            {
              columnKey: 'defaultFont',
              header: 'Default Font',
              onRender: textField =>
                textField?.default_font_system_name ?? 'Client Choice',
              widthClassNames: ['w-[15%]', 'ml-[15px]']
            },
            {
              columnKey: 'defaultLength',
              header: 'Default Character Limit',
              onRender: textField => textField.default_length,
              widthClassNames: ['w-[15%]', 'ml-[30px]']
            },
            {
              columnKey: 'requiredByDefault',
              onRender: textField => (
                <span className="text-blush">
                  {textField.required_by_default ? 'Required' : ''}
                </span>
              )
            },
            {
              columnKey: 'actions',
              widthClassNames: ['ml-auto'],
              onRender: textField => (
                <div className="flex gap-3 pr-3">
                  <DuplicateAssetButton
                    onDuplicateAsset={handleDuplicateTextField(textField)}
                  />
                  <DeleteAssetButton
                    onDeleteAsset={handleDeleteTextField(textField)}
                  />
                </div>
              )
            }
          ]}
        />
      )}
      <ConfirmDeleteModalComponent
        entityName={textFieldToDelete?.system_name}
        isOpen={confirmDelete}
        onConfirm={handleConfirmDeleteTextField}
        onCancel={() => {
          setTextFieldToDelete(null);
          setConfirmDelete(false);
        }}
      />
      <CannotDeleteModalComponent
        entityName={textFieldToDelete?.system_name ?? ''}
        dependencies={dependencies}
        isOpen={showCannotDeleteModal}
        onCancel={() => setShowCannotDeleteModal(false)}
      />
    </>
  );
}
