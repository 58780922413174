import clsx from 'clsx';

import { IconLayer } from '../types';

import { IconPreviewComponent } from './IconPreviewComponent';
import { IconLayersListComponent } from './IconLayersListComponent';
import { UploadIconLayersComponent } from './UploadIconLayersComponent';

interface ManageIconLayersProps {
  error: boolean;
  iconLayers: IconLayer[];
  svgRef: React.Ref<HTMLDivElement>;
  colorSlotsCount: number;
  onUpdateIconLayers: (iconLayers: IconLayer[]) => void;
}

export const ManageIconLayersComponent = ({
  error,
  iconLayers,
  svgRef,
  colorSlotsCount,
  onUpdateIconLayers
}: ManageIconLayersProps) => {
  return (
    <div
      className={clsx('m-0 flex justify-between rounded shadow', {
        'bg-blush': error,
        'bg-lighter-gray': !error && iconLayers.length,
        'bg-light-blue': !error && !iconLayers.length
      })}
    >
      <div className="m-2 flex flex-1 flex-col justify-between">
        <IconLayersListComponent
          iconLayers={iconLayers}
          colorSlotsCount={colorSlotsCount}
          onUpdateIconLayers={onUpdateIconLayers}
        />
        <UploadIconLayersComponent
          error={error}
          iconLayers={iconLayers}
          onUpdateIconLayers={onUpdateIconLayers}
        />
      </div>

      <IconPreviewComponent iconLayers={iconLayers} svgRef={svgRef} />
    </div>
  );
};
