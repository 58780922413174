export function CheckmarkIcon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="mingcute:check-fill" clipPath="url(#clip0_56_1333)">
        <g id="Group">
          <path
            id="Vector"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.546 5.11107C21.8272 5.39236 21.9852 5.77382 21.9852 6.17157C21.9852 6.56931 21.8272 6.95077 21.546 7.23207L10.303 18.4751C10.1544 18.6237 9.97803 18.7416 9.78388 18.822C9.58974 18.9024 9.38165 18.9438 9.1715 18.9438C8.96136 18.9438 8.75327 18.9024 8.55913 18.822C8.36498 18.7416 8.18858 18.6237 8.04 18.4751L2.454 12.8901C2.31074 12.7517 2.19646 12.5862 2.11785 12.4032C2.03924 12.2202 1.99786 12.0233 1.99613 11.8242C1.9944 11.625 2.03235 11.4275 2.10777 11.2431C2.18319 11.0588 2.29457 10.8913 2.43541 10.7505C2.57625 10.6096 2.74373 10.4983 2.92807 10.4228C3.11242 10.3474 3.30994 10.3095 3.50911 10.3112C3.70827 10.3129 3.9051 10.3543 4.08811 10.4329C4.27112 10.5115 4.43663 10.6258 4.575 10.7691L9.171 15.3651L19.424 5.11107C19.5633 4.97168 19.7287 4.8611 19.9108 4.78566C20.0928 4.71022 20.2879 4.67139 20.485 4.67139C20.6821 4.67139 20.8772 4.71022 21.0593 4.78566C21.2413 4.8611 21.4067 4.97168 21.546 5.11107Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_56_1333">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
