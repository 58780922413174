import { ReactNode, useCallback, useEffect, useState } from 'react';
import { VariantContext } from './VariantState';
import { useTextFields } from '../../../text-fields/routes/hooks';
import { useFonts } from '../../../fonts/routes/hooks';
import { Font } from '../../../fonts/types';

function filterOut<T>(idx: T) {
  return (selected: T[]) => {
    if (selected.includes(idx)) {
      return selected.filter(x => x !== idx);
    }
    return [...selected, idx];
  };
}

export function VariantStateContext(props: { children: ReactNode }) {
  const [selectedTexts, setSelectedTexts] = useState<string[]>([]);
  const [selectedIcons, setSelectedIcons] = useState<string[]>([]);
  const [exampleFont, setExampleFont] = useState<Font>();

  const toggleTextField = useCallback(
    (idx: string) => setSelectedTexts(filterOut(idx)),
    []
  );
  const toggleIconField = useCallback(
    (idx: string) => setSelectedIcons(filterOut(idx)),
    []
  );

  const { textFields } = useTextFields();
  const { fonts } = useFonts();
  useEffect(() => {
    if (fonts?.length && !exampleFont) {
      const poppins = fonts.filter(font => font.google_family === 'Poppins');
      if (poppins.length) {
        const regular = poppins.find(font => font.weight === '400');
        if (regular) {
          setExampleFont(regular);
        } else {
          setExampleFont(poppins[0]);
        }
        return;
      }
      const example = fonts[Math.floor(Math.random() * fonts.length)];
      setExampleFont(example);
    }
  }, [exampleFont, fonts]);

  return (
    <VariantContext.Provider
      value={{
        selectedTextFields: selectedTexts,
        selectedIconFields: selectedIcons,
        toggleTextField,
        toggleIconField,
        textFields,
        exampleFont
      }}
    >
      {props.children}
    </VariantContext.Provider>
  );
}
