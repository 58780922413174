import { useCallback, useMemo, useState } from 'react';
import { uniqueId } from 'lodash';
import { ContainedButton } from '../../../../components/buttons';
import { IconField } from './fields/IconField';
import { NameField } from './fields/NameField';
import {
  LabelVariantIcon,
  LabelVariantTextField
} from '../../../../api/trpcApi/services/labels/types';
import { ColorSlot } from '../../../palettes/types';
import { Font } from '../../../fonts/types';
import { TextField } from '../../../text-fields/types';
import { useVariantState } from './VariantState';
import { DropdownOption } from './DropdownOption';
import { ErrorValue } from '../../../../utils/validationUtils';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { useUpdater } from './state/update';
import {
  Dict,
  LabelUpdater,
  LabelUpdaterData,
  LabelVariantState
} from '../../types/state';

const defaultText: LabelVariantTextField = {
  text_field_id: '',
  print_key: '',
  x: 0,
  y: 0,
  width: 1,
  height: 0.2,
  rotation: 0,
  curve: 0,
  align_x: 0,
  align_y: 0,
  client_choice_font: false,
  override_character_count: null,
  override_font_id: null,
  override_is_required: null
};

const defaultIcon: LabelVariantIcon = {
  rotation: 0,
  radius: 0.25,
  x: 0,
  y: 0
};

export interface LabelVariantFieldsProps {
  text_fields: Dict<LabelVariantTextField>;
  icons: Dict<LabelVariantIcon>;
  onUpdate: LabelUpdater<LabelVariantState>;

  colorOptions: ColorSlot[];
  fontOptions: Font[];
  textFieldOptions: TextField[];

  textErrors?: ErrorValue<Dict<LabelVariantTextField>>;
  iconErrors?: ErrorValue<Dict<LabelVariantIcon>>;
}

export function LabelVariantFields({
  text_fields,
  icons,
  onUpdate,
  colorOptions,
  fontOptions,
  textFieldOptions,
  textErrors
}: LabelVariantFieldsProps) {
  const [open, setOpen] = useState(false);
  const { update } = useUpdater(onUpdate);
  const { update: updateTextField } = useUpdater<
    Dict<LabelVariantTextField>,
    LabelUpdaterData
  >(update('text_fields'));
  const { update: updateIcons } = useUpdater<
    Dict<LabelVariantIcon>,
    LabelUpdaterData
  >(update('icons'));

  const {
    selectedTextFields,
    selectedIconFields,
    toggleTextField,
    toggleIconField
  } = useVariantState();

  const addTextField = useCallback(
    (field: LabelVariantTextField) => {
      const id = uniqueId('text_field');
      onUpdate({
        text_fields: {
          [id]: field
        }
      });
      setOpen(false);
      toggleTextField(id);
    },
    [onUpdate, toggleTextField]
  );

  const addIcon = useCallback(
    (field: LabelVariantIcon) => {
      const id = uniqueId('icon');
      onUpdate({
        icons: {
          [id]: field
        }
      });
      setOpen(false);
      toggleIconField(id);
    },
    [onUpdate, toggleIconField]
  );

  const fieldMap = useMemo(
    () =>
      new Map(
        textFieldOptions.map(field => [field.text_field_id ?? '', field])
      ),
    [textFieldOptions]
  );

  const ref = useOutsideClick(() => {
    setOpen(false);
  });

  return (
    <div className="flex flex-col items-stretch gap-2 p-2" ref={ref}>
      <ContainedButton onClick={() => setOpen(!open)}>
        <div className="w-full">+ Add</div>
      </ContainedButton>
      {open && (
        <div className="relative w-full">
          <div className="absolute z-10 flex w-full flex-col overflow-hidden rounded-lg bg-white">
            {textFieldOptions.map(option => (
              <DropdownOption
                key={option.text_field_id}
                onClick={() => {
                  addTextField({
                    ...defaultText,
                    text_field_id: option.text_field_id ?? ''
                  });
                }}
              >
                {option.system_name}
              </DropdownOption>
            ))}
            <DropdownOption
              onClick={() => {
                addIcon({
                  ...defaultIcon
                });
              }}
            >
              Icon
            </DropdownOption>
          </div>
        </div>
      )}
      {Object.entries(text_fields).map(([key, field]) => {
        const textField = fieldMap.get(field.text_field_id);
        if (!textField) {
          return null;
        }
        return (
          <NameField
            key={key}
            errors={textErrors?.[key]}
            open={selectedTextFields.includes(key)}
            onClick={() => toggleTextField(key)}
            label={textField.system_name || 'Text Field'}
            textField={textField}
            textFieldOptions={textFieldOptions}
            colorOptions={colorOptions ?? []}
            fontOptions={fontOptions}
            value={field}
            onUpdate={updateTextField(key)}
            onDelete={() => {
              onUpdate({
                text_fields: {
                  [key]: undefined
                }
              });
            }}
          />
        );
      })}
      {Object.entries(icons).map(([key, icon]) => (
        <IconField
          key={key}
          open={selectedIconFields.includes(key)}
          onClick={() => toggleIconField(key)}
          value={icon}
          onUpdate={updateIcons(key)}
          onDelete={() => {
            onUpdate({
              icons: {
                [key]: undefined
              }
            });
          }}
        />
      ))}
    </div>
  );
}
