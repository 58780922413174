import { ContainedButton } from '../../../components/buttons';
import { PlusIcon } from '../../../components/icons';
import { EditMaterialsComponent } from './EditMaterialsComponent';
import { Dispatch, SetStateAction } from 'react';
import { LabelSlotErrors, LabelSlotValues, LabelSlots } from '../types';
import { Tooltip } from '../../../components/tooltips';
import { LoadingDiv } from '../../../components/loading';
import { useMaterials } from '../../materials/routes/hooks';
import { Label } from '../../labels/types';

interface AddLabelsComponentProps {
  materialsSelectOpen: { label_id: string; open: boolean };
  setMaterialsSelectOpen: Dispatch<
    SetStateAction<{ label_id: string; open: boolean }>
  >;
  setModalOpen: () => void;
  error: [
    {
      options: LabelSlotErrors[];
    }
  ];
  labelSlots: LabelSlots;
  isOption: boolean;
  labelsList: Label[];
  onUpdateLabelList: (updatedLabelSlotList: LabelSlotValues[]) => void;
  setIsOption: Dispatch<SetStateAction<boolean>>;
  setSelectedIndex: Dispatch<SetStateAction<number>>;
  onUpdateLabelSlots(updatedLabelSlot: LabelSlotValues, labelID: string): void;
}

export function AddLabelsComponent({
  materialsSelectOpen,
  setMaterialsSelectOpen,
  setModalOpen,
  error,
  labelSlots,
  isOption,
  labelsList,
  onUpdateLabelList,
  setIsOption,
  setSelectedIndex,
  onUpdateLabelSlots
}: AddLabelsComponentProps) {
  const { materials, isLoading } = useMaterials(undefined, true);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <h1>Labels</h1>
        {isOption ? (
          <Tooltip
            text="Cannot add label slot if multiple options are used"
            arrow
          >
            <ContainedButton disabled={true}>
              <PlusIcon />
              Add Label
            </ContainedButton>
          </Tooltip>
        ) : (
          <ContainedButton onClick={setModalOpen}>
            <PlusIcon />
            Add Label
          </ContainedButton>
        )}
      </div>
      <div className="bg-light-blue relative rounded-lg border p-3">
        {isLoading ? (
          <LoadingDiv />
        ) : (
          <EditMaterialsComponent
            materialsSelectOpen={materialsSelectOpen}
            setMaterialsSelectOpen={setMaterialsSelectOpen}
            isOption={isOption}
            labelsList={labelsList}
            setIsOption={setIsOption}
            setSelectedIndex={setSelectedIndex}
            setModalOpen={setModalOpen}
            errors={error}
            materials={materials}
            labelSlots={labelSlots}
            onUpdateLabelSlots={onUpdateLabelSlots}
            onUpdateLabelList={onUpdateLabelList}
          />
        )}
      </div>
    </div>
  );
}
