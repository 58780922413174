export function CloseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 14.1211L17.303 19.4251C17.5844 19.7065 17.966 19.8646 18.364 19.8646C18.762 19.8646 19.1436 19.7065 19.425 19.4251C19.7064 19.1437 19.8645 18.7621 19.8645 18.3641C19.8645 17.9662 19.7064 17.5845 19.425 17.3031L14.12 12.0001L19.424 6.69711C19.5633 6.55778 19.6737 6.39238 19.7491 6.21036C19.8244 6.02834 19.8632 5.83326 19.8631 5.63626C19.8631 5.43926 19.8242 5.2442 19.7488 5.06221C19.6734 4.88022 19.5628 4.71488 19.4235 4.57561C19.2842 4.43634 19.1188 4.32588 18.9367 4.25054C18.7547 4.17519 18.5596 4.13644 18.3626 4.13648C18.1656 4.13653 17.9706 4.17538 17.7886 4.25081C17.6066 4.32624 17.4413 4.43678 17.302 4.57611L12 9.87911L6.697 4.57611C6.5587 4.43278 6.39323 4.31843 6.21027 4.23973C6.0273 4.16103 5.83049 4.11956 5.63132 4.11774C5.43215 4.11591 5.23462 4.15377 5.05024 4.22911C4.86586 4.30444 4.69833 4.41574 4.55742 4.55652C4.41652 4.69729 4.30506 4.86471 4.22955 5.04902C4.15404 5.23333 4.116 5.43083 4.11763 5.63C4.11927 5.82917 4.16056 6.02602 4.23908 6.20906C4.31761 6.3921 4.4318 6.55767 4.575 6.69611L9.88 12.0001L4.576 17.3031C4.29461 17.5845 4.13652 17.9662 4.13652 18.3641C4.13652 18.7621 4.29461 19.1437 4.576 19.4251C4.8574 19.7065 5.23905 19.8646 5.637 19.8646C6.03495 19.8646 6.41661 19.7065 6.698 19.4251L12 14.1201V14.1211Z"
        fill="currentColor"
      />
    </svg>
  );
}
