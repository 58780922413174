import { Label, LabelVariant } from '.';
import { LabelState, LabelVariantState } from './state';

export function labelToLabelState(label: Label): LabelState {
  const state: LabelState = {
    label_id: label.label_id,
    display_name: label.display_name,
    system_name: label.system_name,
    thumbnail: label.thumbnail,
    thumbnail_path: label.thumbnail_path,
    material_ids: label.material_ids ?? [],
    iterative_style_ids: label.iterative_style_ids,
    iterative_defaults: label.iterative_defaults,
    vdpcode: label.vdpcode ?? '',
    label_variants:
      label.label_variants &&
      Object.fromEntries(
        label.label_variants.map((variant, i) => [
          variant.label_variant_id!,
          variantToVariantState(variant, i)
        ])
      )
  };
  if (!state.label_variants) {
    delete state.label_variants;
  }
  return state;
}

export function variantToVariantState(
  variant: LabelVariant,
  order?: number
): LabelVariantState {
  const state: LabelVariantState = {
    order,
    label_id: variant.label_id,
    label_variant_id: variant.label_variant_id,
    style_id: variant.style_id,
    system_name: variant.system_name,
    width: variant.width,
    height: variant.height,
    thumbnail: variant.thumbnail,
    vdpcode: variant.vdpcode ?? '',
    layers: Object.fromEntries(
      variant.layers?.map((layer, i) => [
        layer.label_layer_id!,
        {
          order: i,
          ...layer
        }
      ]) ?? []
    ),
    text_fields: Object.fromEntries(
      variant.text_fields?.map(field => [
        field.label_text_field_id,
        {
          ...field,
          wrap: !!field.wrap,
          client_choice_font: !!field.client_choice_font
        }
      ]) ?? []
    ),
    icons: Object.fromEntries(
      variant.icons?.map(icon => [icon.label_icon_id!, icon]) ?? []
    )
  };
  return state;
}

type HasOrder = {
  order?: number;
};

export function sortByOrder(a: HasOrder, b: HasOrder) {
  return (a.order ?? 0) - (b.order ?? 0);
}

export function variantStateToVariant(state: LabelVariantState): LabelVariant {
  const { order: _, text_fields, icons, layers, ...rest } = state;
  return {
    ...rest,
    text_fields: Object.values(text_fields),
    icons: Object.values(icons),
    layers: Object.values(layers).sort(sortByOrder)
  };
}

export function labelStateToLabel(state: LabelState): Label {
  return {
    ...state,
    label_id: state.label_id!,
    label_variants: Object.values(state.label_variants ?? {})
      .sort(sortByOrder)
      .map(variantStateToVariant)
  };
}
