import { ContainedButton } from '../../../components/buttons';
import { SizeEnum } from '../../../types';

import { ColorSlotView } from '../components';
import { ColorMappings, ColorSlot } from '../types';

interface ColorsListProps {
  colors: ColorSlot[];
  colorMappings: ColorMappings;
  colorErrors?: boolean[];
  onAddColorSlot: () => void;
  onChangeColorHex: (
    color: ColorSlot
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDeleteColorSlot: (color: ColorSlot) => () => void;
}

export function ColorsList({
  colors,
  colorMappings,
  colorErrors = [],
  onAddColorSlot,
  onChangeColorHex,
  onDeleteColorSlot
}: ColorsListProps) {
  const Colors = colors?.map((color: ColorSlot, index: number) => {
    const isLastColor = index === colors.length - 1;
    const isGreaterThanTen = index > 11;

    const colorMapping = color.color_hex
      ? colorMappings[color.color_hex]
      : null;

    return (
      <ColorSlotView
        key={color.print_key}
        color={color}
        colorMapping={colorMapping}
        error={colorErrors[index]}
        onChangeColorHex={onChangeColorHex(color)}
        onDeleteColorSlot={onDeleteColorSlot(color)}
        canDelete={isLastColor && isGreaterThanTen}
      />
    );
  });

  return (
    <div className="flex flex-wrap">
      {Colors}
      <div className="w-1/2">
        <ContainedButton
          onClick={onAddColorSlot}
          size={SizeEnum.large}
          color="secondary"
          className="w-full"
          data-testid="add-global-color-slot-button"
        >
          <span>Add Global Color Slot +</span>
        </ContainedButton>
      </div>
    </div>
  );
}
