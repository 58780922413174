import { ProductTemplateSelections } from '../types';
import { useMaterials } from '../../materials/routes/hooks';

import { EditTemplateSelections } from './EditTemplateSelections';
import { Checkbox } from '../../../components/inputs';

interface TemplateSelectionsProps {
  hasBothLaminatedOptions: boolean;
  templateSelectionsList: Partial<ProductTemplateSelections>[];
  setTemplateSelectionsList: (
    templateSelectionsList: Partial<ProductTemplateSelections>[]
  ) => void;
  usingMixedTemplate: boolean;
  updateUseMixedTemplate: (usingMixedTemplate: boolean) => void;
}

export function TemplateSelections({
  templateSelectionsList,
  setTemplateSelectionsList,
  usingMixedTemplate,
  updateUseMixedTemplate,
  hasBothLaminatedOptions
}: TemplateSelectionsProps) {
  const { materials } = useMaterials(undefined, true);

  return (
    <div>
      <div className="flex gap-5 pb-3 pt-3">
        <h1>PDF Elements</h1>
        {hasBothLaminatedOptions && (
          <Checkbox
            label="Use mixed template"
            checked={usingMixedTemplate}
            onChange={e => {
              updateUseMixedTemplate(e.target.checked);
            }}
          />
        )}
      </div>

      <div className="flex flex-wrap gap-5">
        {templateSelectionsList.map(templateSelections => {
          const material = materials.find(
            material => material.material_id === templateSelections.material_id
          );

          if (!material) return null;

          const key = `${templateSelections.material_id}-${templateSelections.template_type}`;

          return (
            <EditTemplateSelections
              key={key}
              templateSelections={templateSelections}
              templateSelectionsList={templateSelectionsList}
              setTemplateSelectionsList={setTemplateSelectionsList}
              material={material}
            />
          );
        })}
      </div>
    </div>
  );
}
