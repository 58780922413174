import clsx from 'clsx';
import { SizeEnum } from '../../types';

export interface ChipProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: SizeEnum;
  classes?: string[];
  backgroundColor?: string;
}

export function Chip(props: ChipProps) {
  const {
    size = 'default',
    classes,
    backgroundColor = 'bg-navy',
    ...rest
  } = props;

  const baseClassList = [
    'inline-flex',
    'flex-shrink-1',
    'items-center',
    'rounded-[5px]',
    'px-[12px]',
    'text-white',
    'gap-[6px]'
  ];

  const sizeClasses = {
    'x-small': 'py-[0px] body-5',
    small: 'py-[2px] body-4',
    medium: 'py-[4px] body-3',
    large: 'py-[6px] body-3',
    default: 'py-[6px] body-3'
  };

  return (
    <div
      className={clsx(
        baseClassList,
        sizeClasses[size],
        classes,
        backgroundColor
      )}
      {...rest}
    >
      {props.children}
    </div>
  );
}
