export function AlertIcon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="jam:alert-f">
        <path
          id="Vector"
          d="M12 21.8931C6.477 21.8931 2 17.4161 2 11.8931C2 6.37107 6.477 1.89307 12 1.89307C17.523 1.89307 22 6.37107 22 11.8931C22 17.4161 17.523 21.8931 12 21.8931ZM12 6.89307C11.7348 6.89307 11.4804 6.99842 11.2929 7.18596C11.1054 7.3735 11 7.62785 11 7.89307V12.8931C11 13.1583 11.1054 13.4126 11.2929 13.6002C11.4804 13.7877 11.7348 13.8931 12 13.8931C12.2652 13.8931 12.5196 13.7877 12.7071 13.6002C12.8946 13.4126 13 13.1583 13 12.8931V7.89307C13 7.62785 12.8946 7.3735 12.7071 7.18596C12.5196 6.99842 12.2652 6.89307 12 6.89307ZM12 16.8931C12.2652 16.8931 12.5196 16.7877 12.7071 16.6002C12.8946 16.4126 13 16.1583 13 15.8931C13 15.6278 12.8946 15.3735 12.7071 15.186C12.5196 14.9984 12.2652 14.8931 12 14.8931C11.7348 14.8931 11.4804 14.9984 11.2929 15.186C11.1054 15.3735 11 15.6278 11 15.8931C11 16.1583 11.1054 16.4126 11.2929 16.6002C11.4804 16.7877 11.7348 16.8931 12 16.8931Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
