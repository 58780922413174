import { useCallback, useEffect, useMemo, useState } from 'react';

import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';
import { useTRPCRequest } from '../../../hooks';
import { AssociatedEntityComponent } from '../../../components/associatedEntities/AssociatedEntityComponent';

interface ColorMappingAssociationsProps {
  hexCode: string;
}

interface Association {
  system_name: string;
  display_name: string;
  palette_id: string;
}

export const ColorMappingAssociations = ({
  hexCode
}: ColorMappingAssociationsProps) => {
  const [associations, setAssociations] = useState<Association[]>();
  const { handleTRPCRequest } = useTRPCRequest();

  const consolidatedAssociations = useMemo(() => {
    return Object.values(
      associations?.reduce(
        (acc, association) => {
          acc[association.system_name] = association;
          return acc;
        },
        {} as Record<string, Association>
      ) ?? {}
    );
  }, [associations]);

  const fetchAssociations = useCallback(async () => {
    const { res } = await handleTRPCRequest({
      method: TRPCMethodEnum.listByHexCode,
      resourceType: TRPCResourceEnum.palettes,
      requestBody: {
        hexCode: `#${hexCode}`
      }
    });

    setAssociations(res);
  }, [hexCode, handleTRPCRequest, setAssociations]);

  useEffect(() => {
    fetchAssociations();
  }, [fetchAssociations]);

  return (
    associations && (
      <div className="flex max-h-[60vh] flex-nowrap items-baseline overflow-y-auto">
        <span className="font-bold">Palettes:</span>{' '}
        <div className="m-2 flex flex-wrap items-baseline space-x-2 space-y-2">
          {consolidatedAssociations.map(association => (
            <AssociatedEntityComponent
              system_name={association.system_name}
              link={`/palettes/${association.palette_id}`}
            />
          ))}
        </div>
      </div>
    )
  );
};
