import { Label } from '../../labels/types';
import { Coordinates, LabelSlotValues, LabelSlots } from '../types';

export function formatLabelSlots(
  labels: Label[],
  isOption: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  productToDuplicate: any //fix this
) {
  if (isOption) {
    const product_label_slot_id =
      productToDuplicate.label_slots[0].product_label_slot_id;

    const labelCandidateState =
      productToDuplicate.label_slots[0].options.reduce(
        (
          candidates: LabelSlots,
          result: {
            label_id: string;
            system_name: string;
            endcap_text: string | null;
            endcap_is_footer: boolean | null;
            endcap_print_mode: string | null;
            coordinates: Coordinates[];
            quantity: string;
            material_ids: string[];
          }
        ) => {
          const labelId = result.label_id;

          if (labelId) {
            const foundLabel = labels.find(label => label.label_id === labelId);
            if (foundLabel) {
              if (candidates[labelId]) {
                let index = 1;
                let newId = `${labelId}-${index}`;
                while (candidates[newId]) {
                  index++;
                  newId = `${labelId}-${index}`;
                }
                candidates[newId] = {
                  product_label_slot_id,
                  system_name: newId,
                  label_id: result.label_id,
                  coordinates: result.coordinates as unknown as Coordinates[],
                  quantity: parseInt(result.quantity),
                  endcap_text: result.endcap_text,
                  endcap_is_footer: result.endcap_is_footer,
                  endcap_print_mode: result.endcap_print_mode,
                  material_ids: result.material_ids as unknown as string[] //janky
                };
              } else {
                candidates[labelId] = {
                  product_label_slot_id,
                  system_name: foundLabel?.system_name,
                  label_id: result.label_id,
                  coordinates: result.coordinates as unknown as Coordinates[],
                  quantity: parseInt(result.quantity),
                  endcap_text: result.endcap_text,
                  endcap_is_footer: result.endcap_is_footer,
                  endcap_print_mode: result.endcap_print_mode,
                  material_ids: result.material_ids as unknown as string[] //janky
                };
              }
            }

            return candidates;
          }
        },
        {} as LabelSlots
      );

    return fixLabelSlotMaterials(labelCandidateState, labels);
  } else {
    const labelCandidateState = productToDuplicate.label_slots.reduce(
      (
        candidates: LabelSlots,
        result: {
          product_label_slot_id?: string;
          options: LabelSlotValues[];
        }
      ) => {
        const labelID = result.options[0].label_id;

        if (labelID) {
          const foundLabel = labels.find(label => label.label_id === labelID);
          if (foundLabel && result.options[0].quantity)
            if (candidates[labelID]) {
              let index = 1;
              let newId = `${labelID}-${index}`;
              while (candidates[newId]) {
                index++;
                newId = `${labelID}-${index}`;
              }
              candidates[newId] = {
                product_label_slot_id: result.product_label_slot_id,
                system_name: foundLabel?.system_name,
                label_id: newId,
                coordinates: result.options[0]
                  .coordinates as unknown as Coordinates[],
                quantity: result.options[0].quantity,
                endcap_text: result.options[0].endcap_text,
                endcap_is_footer: result.options[0].endcap_is_footer,
                endcap_print_mode: result.options[0].endcap_print_mode,
                material_ids: result.options[0].material_ids
              };
            } else {
              candidates[labelID] = {
                product_label_slot_id: result.product_label_slot_id,
                system_name: foundLabel?.system_name,
                label_id: result.options[0].label_id,
                coordinates: result.options[0]
                  .coordinates as unknown as Coordinates[],
                quantity: result.options[0].quantity,
                endcap_text: result.options[0].endcap_text,
                endcap_is_footer: result.options[0].endcap_is_footer,
                endcap_print_mode: result.options[0].endcap_print_mode,
                material_ids: result.options[0].material_ids
              };
            }

          return candidates;
        }
      },
      {} as LabelSlots
    );

    return fixLabelSlotMaterials(labelCandidateState, labels);
  }
}

export function fixLabelSlotMaterials(labelSlots: LabelSlots, labels: Label[]) {
  const slots = { ...labelSlots };
  const labelMaterials = new Map(
    labels.map(label => [label.label_id, new Set(label.material_ids ?? [])])
  );
  Object.values(slots).forEach(slot => {
    const materials = labelMaterials.get(slot.label_id.split('-')[0]);
    if (materials && slot.material_ids) {
      slot.material_ids = slot.material_ids.filter(id => materials.has(id));
    }
  });
  return slots;
}
