export function UploadIcon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="mingcute:upload-2-fill" clipPath="url(#clip0_65_1367)">
        <g id="Group">
          <path
            id="Vector"
            d="M20 14.5C20.3978 14.5 20.7794 14.658 21.0607 14.9394C21.342 15.2207 21.5 15.6022 21.5 16V20C21.5 20.6631 21.2366 21.2989 20.7678 21.7678C20.2989 22.2366 19.663 22.5 19 22.5H5C4.33696 22.5 3.70107 22.2366 3.23223 21.7678C2.76339 21.2989 2.5 20.6631 2.5 20V16C2.5 15.6022 2.65804 15.2207 2.93934 14.9394C3.22064 14.658 3.60218 14.5 4 14.5C4.39782 14.5 4.77936 14.658 5.06066 14.9394C5.34196 15.2207 5.5 15.6022 5.5 16V19.5H18.5V16C18.5 15.6022 18.658 15.2207 18.9393 14.9394C19.2206 14.658 19.6022 14.5 20 14.5ZM13.237 3.12001L17.303 7.18701C17.4463 7.32538 17.5605 7.4909 17.6392 7.67391C17.7178 7.85691 17.7591 8.05374 17.7609 8.25291C17.7626 8.45208 17.7247 8.6496 17.6492 8.83394C17.5738 9.01829 17.4624 9.18577 17.3216 9.32661C17.1808 9.46744 17.0133 9.57882 16.8289 9.65425C16.6446 9.72967 16.4471 9.76762 16.2479 9.76589C16.0487 9.76416 15.8519 9.72278 15.6689 9.64417C15.4859 9.56555 15.3204 9.45128 15.182 9.30801L13.5 7.62601V16C13.5 16.3978 13.342 16.7794 13.0607 17.0607C12.7794 17.342 12.3978 17.5 12 17.5C11.6022 17.5 11.2206 17.342 10.9393 17.0607C10.658 16.7794 10.5 16.3978 10.5 16V7.62601L8.818 9.30801C8.67963 9.45128 8.51411 9.56555 8.33111 9.64417C8.1481 9.72278 7.95127 9.76416 7.7521 9.76589C7.55293 9.76762 7.35541 9.72967 7.17107 9.65425C6.98672 9.57882 6.81925 9.46744 6.67841 9.32661C6.53757 9.18577 6.42619 9.01829 6.35077 8.83394C6.27535 8.6496 6.23739 8.45208 6.23912 8.25291C6.24085 8.05374 6.28223 7.85691 6.36085 7.67391C6.43946 7.4909 6.55373 7.32538 6.697 7.18701L10.763 3.12101C11.0912 2.79309 11.5361 2.60889 12 2.60889C12.4639 2.60889 12.9088 2.79309 13.237 3.12101V3.12001Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_65_1367">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
