/** @type {import('tailwindcss').Config} */
import plugin from 'tailwindcss/plugin';

export default {
  content: [
    './index.html',
    './src/**/*.{html,js,jsx,ts,tsx}',
    '../configurator-ui/src/**/*.{js,ts,jsx,tsx}'
  ],
  theme: {
    extend: {
      fontFamily: {
        poppins: ['Poppins', 'system-ui', 'sans-serif']
      },
      strokeWidth: {
        lg: '2.5px',
        3: '3px'
      },
      boxShadow: {
        btn: '0px 2px 7px -4px'
      },
      dropShadow: {
        product: [
          '0 10px 8px rgb(0 0 0 / 0.04)',
          '0 0px 3px rgb(0 0 0 / 0.25)'
        ],
        textbox: ['0 1px 2px rgb(0 0 0 / 0.1)', '0 0px 1px rgb(0 0 0 / 0.25)']
      }
    },
    colors: {
      navy: '#004876',
      turquoise: '#6BDAD5',
      aqua: '#38D4AE',
      lemon: '#FDD756',
      coral: '#FF8189',
      blush: '#E54360',
      'lighter-gray': '#DADADA',
      'light-gray': '#555555',
      'dark-gray': '#424242',
      'light-blue': '#DFEDED',
      cream: '#FFFCF4',
      'light-purple': '#EED5E2',
      white: '#FFFFFF',
      black: '#000000',
      transparent: '#00000000',
      error: '#e54361',
      red: '#e54361'
    }
  },
  plugins: [
    plugin(function ({ addBase, addComponents, addVariant, theme }) {
      addBase({
        html: {
          fontFamily: theme('fontFamily.poppins'),
          color: theme('colors.dark-gray'),
          fontWeight: '400',
          fontSize: '18px',
          minHeight: '100vh'
        },
        body: {
          minHeight: '100vh'
        },
        h1: {
          fontSize: '24px',
          fontWeight: '600',
          lineHeight: '2',
          display: 'inline-block'
        },
        h2: {
          fontSize: '20px',
          fontWeight: '600',
          lineHeight: '2',
          display: 'inline-block'
        },
        h3: {
          fontSize: '16px',
          fontWeight: '600',
          lineHeight: '2',
          display: 'inline-block'
        },
        h4: {
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '2',
          display: 'inline-block'
        },
        h5: {
          fontSize: '12px',
          fontWeight: '600',
          lineHeight: '2',
          display: 'inline-block'
        }
      });

      addComponents({
        '.content': {
          minHeight: 'calc(100vh - 60px)'
        },
        'h1.large-header': { fontSize: '42px' },
        'h2.large-header': { fontSize: '35px' },
        'h3.large-header': { fontSize: '28px' },
        'h4.large-header': { fontSize: '22px' },
        'h5.large-header': { fontSize: '18px' },
        'h6.large-header': { fontSize: '15px' },
        '.body-1': { fontSize: '18px' },
        '.body-2': { fontSize: '16px' },
        '.body-3': { fontSize: '14px' },
        '.body-4': { fontSize: '12px' },
        '.body-5': { fontSize: '10px' },
        '.card': {
          borderRadius: '10px',
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.15)'
        },
        '.text-input-shadow': {
          boxShadow:
            '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 1px 0px rgba(0, 0, 0, 0.20)'
        },
        '.button-shadow:hover': {
          boxShadow:
            '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 1px 0px rgba(0, 0, 0, 0.20)'
        }
      });

      addVariant('child', '& > *');
      addVariant('hover-child', '&:hover > *');
    })
  ]
};
