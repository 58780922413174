import { useCallback } from 'react';
import { IconLayer } from '../types';
import { IconLayersListItemComponent } from './IconLayersListItemComponent';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

interface IconLayersListProps {
  iconLayers: IconLayer[];
  colorSlotsCount: number;
  onUpdateIconLayers: (iconLayers: IconLayer[]) => void;
}

export const IconLayersListComponent = ({
  iconLayers,
  colorSlotsCount,
  onUpdateIconLayers
}: IconLayersListProps) => {
  const handleDropItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragItem = iconLayers[dragIndex];
      const hoverItem = iconLayers[hoverIndex];

      const updatedIconLayers = [...iconLayers];
      updatedIconLayers[dragIndex] = hoverItem;
      updatedIconLayers[hoverIndex] = dragItem;

      onUpdateIconLayers(updatedIconLayers);
    },
    [iconLayers, onUpdateIconLayers]
  );

  const handleChangePrintKey = useCallback(
    (index: number, print_key: string) => {
      const updatedIconLayers = [...iconLayers];
      updatedIconLayers[index].print_key = print_key;
      onUpdateIconLayers(updatedIconLayers);
    },
    [iconLayers, onUpdateIconLayers]
  );

  const handleDeleteLayer = useCallback(
    (index: number) => {
      const updatedIconLayers = [...iconLayers];
      updatedIconLayers.splice(index, 1);
      onUpdateIconLayers(updatedIconLayers);
    },
    [iconLayers, onUpdateIconLayers]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        {iconLayers
          .map((iconLayer, index) => ({ iconLayer, index }))
          .reverse()
          .map(({ iconLayer, index }) => (
            <IconLayersListItemComponent
              key={index}
              index={index}
              printKey={iconLayer.print_key}
              colorSlotsCount={colorSlotsCount}
              onDropItem={handleDropItem}
              onChangePrintKey={handleChangePrintKey}
              onDelete={handleDeleteLayer}
            />
          ))}
      </div>
    </DndProvider>
  );
};
