import clsx from 'clsx';

import { BaseButton, BaseButtonProps } from './BaseButton';

export interface IconButtonProps extends Omit<BaseButtonProps, 'children'> {
  icon: React.ReactNode;
}

export function IconButton(props: IconButtonProps) {
  const { className, icon, ...rest } = props;

  const baseClassList = ['disabled:text-[#a6a6a6]'];

  return (
    <BaseButton className={clsx(baseClassList, className)} {...rest}>
      {icon}
    </BaseButton>
  );
}
