export interface Material {
  material_id?: string;
  display_name: string;
  system_name: string;
  description: string;
  upsell_title: string;
  thumbnail?: string;
  thumbnail_path?: string;
  vdpcode?: string;
}

export interface MaterialErrors {
  display_name: boolean;
  system_name: boolean;
  description: boolean;
  upsell_title: boolean;
  vdpcode: boolean;
}

export enum MATERIAL_FIELDS {
  display_name = 'display_name',
  system_name = 'system_name',
  description = 'description',
  upsell_title = 'upsell_title',
  thumbnail = 'thumbnail'
}

export type MaterialType = Material;
