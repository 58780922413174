import { useCallback, useEffect, useState } from 'react';
import { Material } from '../types';
import { useRawImageData, useTRPCRequest } from '../../../hooks';
import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';

export function useMaterials(search?: string, ignoreThumbnails?: boolean) {
  const [materials, setMaterials] = useState<Material[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { handleTRPCRequest } = useTRPCRequest();
  const { getRawImageData } = useRawImageData();

  const fetchMaterials = useCallback(
    async (search?: string) => {
      setIsLoading(true);
      const trpcRequest = {
        method: TRPCMethodEnum.list,
        resourceType: TRPCResourceEnum.materials,
        requestBody: {}
      };
      if (search) {
        trpcRequest.requestBody = { keyword: search };
      }
      const { res: materials } = await handleTRPCRequest(trpcRequest);

      const matsWithRawImagePromises = materials.map(
        async (material: Material) => {
          let rawImage;
          if (ignoreThumbnails) {
            rawImage = '';
          } else if (material.thumbnail_path) {
            rawImage = await getRawImageData(material.thumbnail_path);
          }
          return {
            ...material,
            thumbnail: rawImage
          };
        }
      );

      const matsWithRawImage = await Promise.all(matsWithRawImagePromises);
      setMaterials(matsWithRawImage);
      setIsLoading(false);
    },
    [getRawImageData, handleTRPCRequest, ignoreThumbnails]
  );

  useEffect(() => {
    fetchMaterials(search ?? undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return { materials, isLoading };
}
