import clsx from 'clsx';

interface ColumnHeaderProps {
  columnKey: string;
  header?: string;
  headerClassNames?: string[];
  widthClassNames?: string[];
}

export function ColumnHeader({
  columnKey,
  header = '',
  headerClassNames,
  widthClassNames
}: ColumnHeaderProps) {
  const headerClasses = clsx(headerClassNames, widthClassNames);

  return (
    <h4 key={columnKey} className={headerClasses}>
      {header}
    </h4>
  );
}
