export interface TextField {
  text_field_id?: string;
  system_name: string;
  display_name: string;
  display_name_2?: string | null;
  default_length: number;
  default_font?: string;
  default_font_system_name?: string;
  required_by_default: boolean;
}

export interface TextFieldErrors {
  display_name: boolean;
  display_name_2: boolean;
  system_name: boolean;
  default_length: boolean;
  default_font: boolean;
}

export enum TEXT_FIELD_FIELDS {
  display_name = 'display_name',
  display_name_2 = 'display_name_2',
  system_name = 'system_name',
  default_font = 'default_font',
  default_length = 'default_length',
  required_by_default = 'required_by_default'
}
