import { useState } from 'react';
import Switch from 'react-switch';
import { getHexFromTailwindConfig } from '../../utils';

export interface ToggleProps {
  id: string;
  size?: 'small' | 'large';
  disabled?: boolean;
  checked?: boolean;
  className?: string;
}

export function Toggle(props: ToggleProps) {
  const { id, size = 'small', disabled = false, ...rest } = props;
  const [checked, setChecked] = useState(false);

  const handleChange = (newChecked: boolean) => {
    setChecked(newChecked);
  };

  const isSmall = size === 'small';
  return (
    <>
      {isSmall ? (
        <Switch
          disabled={disabled}
          checked={checked}
          onChange={handleChange}
          onColor="#1976d2"
          onHandleColor={getHexFromTailwindConfig('navy')}
          handleDiameter={30}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={20}
          width={48}
          id={id}
          {...rest}
        />
      ) : (
        <Switch
          disabled={disabled}
          checked={checked}
          onChange={handleChange}
          onColor="#1976d2"
          onHandleColor={getHexFromTailwindConfig('navy')}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={35}
          id={id}
          {...rest}
        />
      )}
    </>
  );
}
