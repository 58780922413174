import clsx from 'clsx';
import { LabelVariantTextField } from '../../../../../api/trpcApi/services/labels/types';
import { LabelUpdater } from '../../../types/state';

export interface AlignFieldValues {
  align_x: number;
  align_y: number;
}

export interface AlignFieldProps extends AlignFieldValues {
  onUpdate: LabelUpdater<LabelVariantTextField>;
}

export function AlignField({ align_x, align_y, onUpdate }: AlignFieldProps) {
  const justify = ['items-start', 'items-center', 'items-end'][align_x + 1];
  const longOrder = ['order-first', '', 'order-last'][align_y + 1];

  return (
    <div>
      <div className="border-navy grid h-[69px] w-[69px] grid-cols-3 grid-rows-3 rounded border p-[2px]">
        {new Array(9).fill(null).map((_, i) => {
          const [hrz, vrt] = [(i % 3) - 1, Math.floor(i / 3) - 1];
          if (hrz === align_x && vrt === align_y) {
            return (
              <div
                key="display"
                className={clsx(
                  'flex flex-col justify-center gap-[3px] rounded',
                  justify
                )}
              >
                <div className="bg-navy h-[4px] w-[11px]" />
                <div className={clsx('bg-navy h-[4px] w-[19px]', longOrder)} />
                <div className="bg-navy h-[4px] w-[11px]" />
              </div>
            );
          }
          return (
            <button
              key={i}
              className="hover:bg-light-blue flex items-center justify-center rounded"
              onClick={() => {
                onUpdate({
                  align_x: hrz,
                  align_y: vrt
                });
              }}
            >
              <div className="bg-navy/50 aspect-square w-[6px] rounded-full" />
            </button>
          );
        })}
      </div>
    </div>
  );
}
