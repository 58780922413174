import {
  CreateGroupRequest,
  DeleteGroupRequest,
  GetGroupRequest,
  ListGroupsRequest,
  UpdateGroupRequest
} from '../services/groups/types';
import {
  CreateIconRequest,
  DeleteIconRequest,
  GetIconRequest,
  ListIconsRequestType,
  UpdateIconRequest
} from '../services/icons/types';

import {
  CreateFontRequest,
  GetFontRequest,
  ListFontRequest
} from '../services/fonts/types';

import {
  CreateMaterialRequest,
  DeleteMaterialRequest,
  ListMaterialsRequest,
  UpdateMaterialRequest
} from '../services/materials/types';

import {
  BlankPaletteRequest,
  DeletePaletteRequest,
  GetPaletteRequest,
  ListPalettesByHexCodeRequest,
  ListPalettesRequest,
  UsesColorSlotPalettesRequest
} from '../services/palettes/types';
import {
  CreateTextFieldRequest,
  GetTextFieldRequest,
  ListTextFieldsRequest,
  UpdateTextFieldRequest
} from '../services/textFields/types';
import { CreateStyleRequest } from '../services/styles/types';
import {
  CreateColorMappingRequest,
  GetColorMappingRequest,
  UpdateColorMappingRequest,
  DeleteColorMappingRequest,
  ListColorMappingsRequest,
  ListByHexCodesRequest
} from '../services/colorMappings/types';
import { GetOrderRequest } from '../services/orders/types';
import {
  GeneratePdfByCartItemId,
  GeneratePdfByOrderId
} from '../services/pdfs/types';
import {
  CreateLabelRequest,
  UpdateLabelRequest
} from '../services/labels/types';

export enum TRPCMethodEnum {
  blank = 'blank',
  create = 'create',
  delete = 'delete',
  get = 'get',
  list = 'list',
  update = 'update',
  usingColorSlot = 'usingColorSlot',
  generateReport = 'generateReport',
  generateReportNoFinancials = 'generateReportNoFinancials',
  usingLabelAndMaterial = 'usingLabelAndMaterial',
  generatePdfsByOrderId = 'generatePdfsByOrderId',
  listByHexCode = 'listByHexCode',
  generatePdfsByCartItemId = 'generatePdfsByCartItemId',
  getByHexCode = 'getByHexCode',
  listByHexCodes = 'listByHexCodes'
}

export enum TRPCResourceEnum {
  groups = 'groups',
  fonts = 'fonts',
  icons = 'icons',
  labels = 'labels',
  labelVariants = 'labelVariants',
  materials = 'materials',
  palettes = 'palettes',
  styles = 'styles',
  textFields = 'textFields',
  products = 'products',
  reports = 'reports',
  colorMappings = 'colorMappings',
  orders = 'orders',
  cartItems = 'cartItems',
  pdfs = 'pdfs'
}

export type RequestBody =
  | BlankPaletteRequest
  | CreateFontRequest
  | CreateGroupRequest
  | CreateIconRequest
  | CreateMaterialRequest
  | CreateTextFieldRequest
  | CreateStyleRequest
  | CreateColorMappingRequest
  | DeleteGroupRequest
  | DeleteIconRequest
  | DeleteMaterialRequest
  | DeletePaletteRequest
  | DeleteColorMappingRequest
  | GetFontRequest
  | GetGroupRequest
  | GetIconRequest
  | GetPaletteRequest
  | GetTextFieldRequest
  | GetColorMappingRequest
  | ListByHexCodesRequest
  | ListFontRequest
  | ListIconsRequestType
  | ListPalettesRequest
  | ListMaterialsRequest
  | ListTextFieldsRequest
  | ListGroupsRequest
  | ListColorMappingsRequest
  | UpdateGroupRequest
  | UpdateIconRequest
  | UpdateMaterialRequest
  | UsesColorSlotPalettesRequest
  | UpdateTextFieldRequest
  | UpdateColorMappingRequest
  | GetOrderRequest
  | GeneratePdfByCartItemId
  | GeneratePdfByOrderId
  | CreateLabelRequest
  | UpdateLabelRequest
  | ListPalettesByHexCodeRequest;

export interface TRPCRequestProps {
  method: TRPCMethodEnum;
  resourceType: TRPCResourceEnum;
  requestBody: RequestBody;
  token: string | null;
}

export interface EntityDependencies {
  [key: string]: { name: string; id: string }[];
}
