import clsx from 'clsx';

import { BaseButton, BaseButtonProps } from './BaseButton';

export interface TextButtonProps extends Omit<BaseButtonProps, 'children'> {
  text: string;
}

export function TextButton(props: TextButtonProps) {
  const { className, text, ...rest } = props;

  const baseClassList = ['disabled:text-[#a6a6a6]'];

  return (
    <BaseButton className={clsx(baseClassList, className)} {...rest}>
      {text}
    </BaseButton>
  );
}
