import { useMemo, useState } from 'react';
import { TabButton } from '../../../../components/buttons';
import { LabelVariantLayers } from './LabelVariantLayers';
import { LabelVariantFields } from './LabelVariantFields';
import { useBlankPalette } from '../../../palettes/routes/hooks';
import { useFonts } from '../../../fonts/routes/hooks';
import { ErrorValue } from '../../../../utils/validationUtils';
import { useVariantState } from './VariantState';
import { useUpdater } from './state/update';
import { LabelUpdater, LabelVariantState } from '../../types/state';
import { isEqual } from 'lodash';

export interface LabelVariantElementsProps {
  value: LabelVariantState;
  errors: ErrorValue<LabelVariantState>;
  onUpdate: LabelUpdater<LabelVariantState>;
}

export function LabelVariantElementsEditor({
  value,
  errors,
  onUpdate
}: LabelVariantElementsProps) {
  const [showLayers, setShowLayers] = useState(true);

  const { palette } = useBlankPalette();
  const { fonts } = useFonts();
  const { textFields: textFieldOptions } = useVariantState();

  const { update } = useUpdater(onUpdate);

  const layer_error = useMemo(
    () => isEqual(errors?.layers?.value, value.layers) && errors?.layers?.error,
    [errors?.layers?.error, errors?.layers?.value, value.layers]
  );

  return (
    <div className="flex flex-col items-stretch gap-2">
      <div className="grid grow grid-cols-2">
        <TabButton
          className="grow"
          error={!!layer_error}
          active={showLayers}
          onClick={() => setShowLayers(true)}
        >
          SVGs
        </TabButton>
        <TabButton
          className="grow"
          active={!showLayers}
          onClick={() => setShowLayers(false)}
        >
          TEXT & ICONS
        </TabButton>
      </div>
      {showLayers ? (
        <LabelVariantLayers
          layers={value.layers}
          onUpdate={update('layers')}
          colorOptions={palette?.color_slots ?? []}
          error={(layer_error && 'Please add at least one layer') || undefined}
        />
      ) : (
        <LabelVariantFields
          text_fields={value.text_fields}
          icons={value.icons}
          textErrors={errors?.text_fields}
          iconErrors={errors.icons}
          onUpdate={onUpdate}
          colorOptions={palette?.color_slots ?? []}
          fontOptions={fonts ?? []}
          textFieldOptions={textFieldOptions ?? []}
        />
      )}
    </div>
  );
}
