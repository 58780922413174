import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchBar } from '../../../components/search-bar';
import { useLocalStorage, useTRPCRequest } from '../../../hooks';
import { RecordTable } from '../../../components/tables';
import { TableRecord } from '../../../types/record-table';
import { DeleteAssetButton } from '../../../components/buttons';
import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';
import {
  ConfirmDeleteModalComponent,
  LoadingModalComponent
} from '../../../components/modals';
import { ColorMapping } from '../types';
import { toast } from 'react-toastify';
import { CMYKColor, RGBColor } from '../components/colors';
import { Title } from '../../../components/Title';

export function ColorMappingsList() {
  const navigate = useNavigate();
  const { handleTRPCRequest } = useTRPCRequest();

  // State
  const [colorMappings, setColorMappings] = useState<ColorMapping[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [colorMappingToDelete, setColorMappingToDelete] = useState<
    ColorMapping | undefined
  >(undefined);
  const [searchTerm, setSearchTerm] = useLocalStorage<string>(
    window.location.pathname,
    ''
  );

  // Fetch color mappings
  useEffect(() => {
    async function fetchColorMappings() {
      setIsLoading(true);
      const req = {
        method: TRPCMethodEnum.list,
        resourceType: TRPCResourceEnum.colorMappings,
        requestBody: {}
      };
      if (searchTerm) {
        req.requestBody = { keyword: searchTerm };
      }
      const { res, error } = await handleTRPCRequest(req);
      if (error || res.success === false) {
        toast.error('Error fetching color mappings');
      } else {
        setColorMappings(res);
      }
      setIsLoading(false);
    }
    fetchColorMappings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleAddNew = useCallback(() => {
    navigate('/color-mappings/new');
  }, [navigate]);

  const handleDeleteColorMapping = useCallback(
    (colorMapping: TableRecord) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setColorMappingToDelete(colorMapping as ColorMapping);
      setConfirmDelete(true);
    },
    []
  );

  const handleConfirmedDeleteColorMapping = useCallback(async () => {
    setConfirmDelete(false);
    await handleTRPCRequest({
      method: TRPCMethodEnum.delete,
      resourceType: TRPCResourceEnum.colorMappings,
      requestBody: {
        color_mapping_id: colorMappingToDelete?.color_mapping_id
      }
    });
    const filteredColorMappings = colorMappings.filter(
      cm => cm.color_mapping_id !== colorMappingToDelete?.color_mapping_id
    );
    setColorMappings(filteredColorMappings);
  }, [
    colorMappingToDelete?.color_mapping_id,
    colorMappings,
    handleTRPCRequest
  ]);

  if (isLoading) {
    return <LoadingModalComponent isOpen />;
  }

  const noResults = colorMappings.length === 0;
  const noSearch = searchTerm === '';

  return (
    <>
      <Title title="Color Mappings Search" />
      <SearchBar
        searchTerm={searchTerm}
        onSearch={search => setSearchTerm(search)}
        onAddNew={handleAddNew}
        classNames={['pb-2']}
      />

      {noResults && noSearch ? (
        <>
          <h2>No color mappings exist yet.</h2>
          <p> Click "+ Add New" to create your first color mapping!</p>
        </>
      ) : noResults ? (
        <h2>No color mappings found for "{searchTerm}".</h2>
      ) : (
        <RecordTable
          data={colorMappings}
          idKey="color_mapping_id"
          rowClassNames={[
            'card',
            'mb-[6px]',
            'flex',
            'h-[54px]',
            'cursor-pointer',
            'items-center',
            'align-middle'
          ]}
          calculateRowHref={colorMapping =>
            `/color-mappings/${colorMapping.color_mapping_id}`
          }
          columns={[
            {
              columnKey: 'system_name',
              header: 'System Name',
              onRender: (colorMapping: TableRecord) => colorMapping.system_name,
              widthClassNames: ['w-[30%]', 'ml-[30px]']
            },
            {
              columnKey: 'hex_code',
              header: 'Hex Code',
              onRender: (colorMapping: TableRecord) => (
                <div className="flex items-center gap-2">
                  <RGBColor colorMapping={colorMapping as ColorMapping} />
                  {colorMapping.hex_code}
                </div>
              ),
              widthClassNames: ['w-[15%]']
            },
            {
              columnKey: 'print',
              header: 'Print',
              onRender: (cm: TableRecord) => (
                <div className="flex items-center gap-2">
                  <CMYKColor colorMapping={cm as ColorMapping} />
                  C: {cm.c} M: {cm.m} Y: {cm.y} K: {cm.k}
                </div>
              ),
              widthClassNames: ['ml-[30px]', 'w-[30%]']
            },
            {
              columnKey: 'swatch_name',
              header: 'Swatch Name',
              onRender: (colorMapping: TableRecord) => colorMapping.swatch_name,
              widthClassNames: ['ml-[30px]']
            },
            {
              columnKey: 'actions',
              widthClassNames: ['ml-auto'],
              onRender: (colorMapping: TableRecord) => {
                return (
                  <div className="flex gap-3 pr-3">
                    <DeleteAssetButton
                      onDeleteAsset={handleDeleteColorMapping(colorMapping)}
                    />
                  </div>
                );
              }
            }
          ]}
        ></RecordTable>
      )}
      <ConfirmDeleteModalComponent
        isOpen={confirmDelete}
        entityName={colorMappingToDelete?.system_name || ''}
        onConfirm={handleConfirmedDeleteColorMapping}
        onCancel={() => setConfirmDelete(false)}
      />
    </>
  );
}
