import { Palette } from '../types';

export const BLANK_PALETTE: Palette = {
  palette_id: '',
  display_name: '',
  system_name: '',
  color_slots: []
};

export const BLANK_COLOR_SLOT = {
  color_hex: null,
  display_name: 'unknown',
  print_key: 'unknown',
  index: -1
};
