import { useCallback, useEffect, useState } from 'react';
import {
  useNavigate,
  useParams,
  useLocation,
  useBlocker
} from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  DisplayName,
  EditVdpCodeComponent,
  SystemNameComponent
} from '../../../components/inputs';
import { Title } from '../../../components/Title';
import {
  CannotDeleteModalComponent,
  ConfirmDeleteModalComponent,
  UnsavedChangesModalComponent
} from '../../../components/modals';
import { useRawImageData, useTRPCRequest } from '../../../hooks';
import {
  EntityDependencies,
  TRPCMethodEnum,
  TRPCResourceEnum
} from '../../../api/trpcApi/types';
import {
  BackButtonComponent,
  SaveButtonComponent
} from '../../../components/buttons';
import { LoadingDiv } from '../../../components/loading';
import { validateSchemaAndNotifyErrors } from '../../../utils';

import StyleValidation from '../validations';
import {
  duplicateStyle,
  formatRegisteredEntities,
  generateList
} from '../utils';
import {
  STYLE_FIELDS,
  Style,
  StyleEntities,
  StyleEntityCandidates,
  StyleErrors,
  StyleTypeEnum
} from '../types';
import { ManageStyleEntitiesComponenet } from '../components/ManageStyleEntities';

export function StyleDetails({ create }: { create?: boolean }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const { handleTRPCRequest } = useTRPCRequest();
  const params = useParams();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);
  const [dependencies, setDependencies] = useState<EntityDependencies>({});
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const unsavedChangesBlocker = useBlocker(unsavedChanges);
  const [style, setStyle] = useState<Style>({
    display_name: '',
    system_name: '',
    thumbnail: '',
    vdpcode: ''
  });
  const [formErrors, setFormErrors] = useState<StyleErrors>({
    display_name: false,
    system_name: false,
    thumbnail: false,
    icons: false,
    palettes: false,
    fonts: false,
    vdpcode: false
  });

  const [styleEntities, setStyleEntities] = useState<StyleEntities>({
    palettes: {},
    fonts: {},
    icons: {}
  });

  const { getRawImageData } = useRawImageData();

  const fetchStyle = useCallback(
    async (style_id: string) => {
      const { res: style, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.get,
        resourceType: TRPCResourceEnum.styles,
        requestBody: {
          style_id
        }
      });
      return { style, error };
    },
    [handleTRPCRequest]
  );

  const syncFromRouter = useCallback(async () => {
    const { state } = location;
    if (!state) {
      return;
    }
    const { styleIdToCopy } = state;
    const { style: copiedStyle, error } = await fetchStyle(styleIdToCopy);
    const {
      registered_entities: registeredEntities,
      thumbnail_path: thumbnail_path,
      ...rest
    } = copiedStyle;

    const rawImage = await getRawImageData(thumbnail_path);

    const formattedEntities = formatRegisteredEntities(registeredEntities);
    const { registered_entities, ...duplicatedStyle } = await duplicateStyle(
      { thumbnail_path, ...rest },
      formattedEntities,
      rawImage
    );
    if (!error) {
      setStyle(duplicatedStyle);
      setStyleEntities(registered_entities);
    }
  }, [fetchStyle, getRawImageData, location]);

  const syncFromApi = useCallback(async () => {
    if (params.style_id) {
      const { style } = await fetchStyle(params.style_id);
      const { registered_entities, ...rest } = style;
      setStyle(rest);
      const formattedEntities = formatRegisteredEntities(registered_entities);
      setStyleEntities(formattedEntities);
    }
  }, [fetchStyle, params.style_id]);

  const syncData = useCallback(async () => {
    setIsLoading(true);
    const textFieldData = create ? syncFromRouter() : syncFromApi();
    await textFieldData;

    setIsLoading(false);
  }, [create, syncFromApi, syncFromRouter]);

  useEffect(() => {
    syncData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [create]);

  const handleUpdateStyle = useCallback(
    (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue: string = event.target.value;
      setStyle({ ...style, [key]: newValue });
      setFormErrors({ ...formErrors, [key]: null });
      setUnsavedChanges(true);
    },
    [formErrors, style]
  );

  const handleDuplicateStyle = useCallback(async () => {
    navigate('/styles/new', { state: { styleIdToCopy: style.style_id } });
    setUnsavedChanges(true);
  }, [navigate, style]);

  const handleConfirmedDelete = useCallback(async () => {
    setUnsavedChanges(false);
    if (style.style_id) {
      setConfirmDelete(false);
      const { res, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.delete,
        resourceType: TRPCResourceEnum.styles,
        requestBody: { style_id: style.style_id }
      });

      if (!error && res.success) {
        toast.success('Style deleted successfully!');
        navigate('/styles');
      }
      if (!res.success) {
        setShowCannotDeleteModal(true);
        setDependencies(res.relatedEntities);
      }
    }
  }, [style.style_id, navigate, handleTRPCRequest]);

  const handleUpdateStyleEntity = useCallback(
    (updatedStyleEntities: StyleEntityCandidates, type: StyleTypeEnum) => {
      setStyleEntities({
        ...styleEntities,
        [type]: updatedStyleEntities
      });
      setFormErrors({ ...formErrors, [type]: false });
    },
    [formErrors, styleEntities]
  );

  const handleSaveStyle = useCallback(async () => {
    setIsSaving(true);

    const iconsList = generateList(styleEntities.icons);
    const palettesList = generateList(styleEntities.palettes);
    const fontsList = generateList(styleEntities.fonts);
    const toSave = { ...style };
    delete toSave.thumbnail_path;

    const { errors, validatedSchema } = validateSchemaAndNotifyErrors(
      create
        ? StyleValidation.createStyleSchema
        : StyleValidation.updateStyleSchema,
      {
        ...toSave,
        registered_entities: {
          icons: iconsList,
          palettes: palettesList,
          fonts: fontsList
        }
      }
    );
    if (errors) {
      setFormErrors(errors as unknown as StyleErrors);
    } else {
      const { res, error } = await handleTRPCRequest({
        method: create ? TRPCMethodEnum.create : TRPCMethodEnum.update,
        resourceType: TRPCResourceEnum.styles,
        requestBody: validatedSchema
      });

      if (!error) {
        setFormErrors({
          display_name: false,
          system_name: false,
          thumbnail: false,
          icons: false,
          palettes: false,
          fonts: false,
          vdpcode: false
        });
        setUnsavedChanges(false);
        navigate(`/styles/${res?.style_id}`);
      }
    }
    setIsSaving(false);
  }, [
    create,
    handleTRPCRequest,
    navigate,
    style,
    styleEntities.fonts,
    styleEntities.icons,
    styleEntities.palettes
  ]);
  const handleUpdateVdpCode = (newVdpCode: string) => {
    setStyle(prevStyle => ({ ...prevStyle, vdpcode: newVdpCode }));
  };
  if (isLoading) {
    return <LoadingDiv />;
  }

  return (
    <>
      {create ? (
        <Title title="Create Style" />
      ) : (
        <Title title="Existing Style" />
      )}
      <SystemNameComponent
        create={create}
        systemName={style.system_name}
        errors={formErrors}
        onSystemNameChange={handleUpdateStyle(STYLE_FIELDS.system_name)}
        uploadButtonText={
          style.thumbnail_path?.length || style.thumbnail
            ? 'Update'
            : 'Upload Thumbnail'
        }
        onThumbnailUpload={handleUpdateStyle(STYLE_FIELDS.thumbnail)}
        thumbnailPath={style.thumbnail_path}
        onDuplicate={handleDuplicateStyle}
        onDelete={() => setConfirmDelete(true)}
      />
      <div className={'mb-5 flex gap-2 pb-5'}>
        <div className={'w-[75%]'}>
          <DisplayName
            displayName={style.display_name}
            displayNameError={formErrors.display_name}
            onDisplayNameChange={handleUpdateStyle(STYLE_FIELDS.display_name)}
          />
        </div>
        <EditVdpCodeComponent
          className={'flex w-[25%] flex-col gap-2'}
          vdpCode={style.vdpcode || ''}
          handleUpdateVdpCode={handleUpdateVdpCode}
          overrideClassName={true}
          error={formErrors.vdpcode}
        />
      </div>
      <div className="flex w-full items-start gap-3">
        <ManageStyleEntitiesComponenet
          error={formErrors.palettes}
          type={StyleTypeEnum.palettes}
          styleEntities={styleEntities[StyleTypeEnum.palettes]}
          onUpdateStyleEntities={handleUpdateStyleEntity}
        />
        <ManageStyleEntitiesComponenet
          error={formErrors.fonts}
          type={StyleTypeEnum.fonts}
          styleEntities={styleEntities[StyleTypeEnum.fonts]}
          onUpdateStyleEntities={handleUpdateStyleEntity}
        />
        <ManageStyleEntitiesComponenet
          error={formErrors.icons}
          type={StyleTypeEnum.icons}
          styleEntities={styleEntities[StyleTypeEnum.icons]}
          onUpdateStyleEntities={handleUpdateStyleEntity}
        />
      </div>
      <div className="mt-10 flex gap-3">
        <BackButtonComponent destination="/styles" />
        <SaveButtonComponent isSaving={isSaving} onSave={handleSaveStyle} />
      </div>
      <UnsavedChangesModalComponent
        unsavedChanges={unsavedChanges}
        unsavedChangesBlocker={unsavedChangesBlocker}
      />
      <ConfirmDeleteModalComponent
        isOpen={confirmDelete}
        onConfirm={handleConfirmedDelete}
        onCancel={() => setConfirmDelete(false)}
        entityName={style.system_name}
      />
      <CannotDeleteModalComponent
        entityName={style?.system_name ?? ''}
        dependencies={dependencies}
        isOpen={showCannotDeleteModal}
        onCancel={() => setShowCannotDeleteModal(false)}
      />
    </>
  );
}
