import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { ConfiguratorHeader } from '../../../components/headers';
import { SHOPIFY_PRODUCT_NAME, SHOPIFY_PRODUCT_TAG } from '../../../config';
import { AppStates } from '../../../types';
import { CartErrors } from '../../../utils/getCartErrors';
import { ContainedButton } from '../../../components/buttons';
import { useRootSelector } from '../../../store';

import { Steps } from '../../accordion/Steps';
import { selectEntity, selectSeeAll } from '../../accordion/accordionSlice';
import { AddToCartButton } from '../../cart/AddToCartButton';
import AllFonts from '../../fonts/AllFonts';
import AllIcons from '../../iconAndLabel/AllIcons';
import { MobilePreviewWrapper } from '../../preview/components/MobilePreviewWrapper';
import AllColors from '../../stylesAndColors/AllColors';
import AllStyles from '../../stylesAndColors/AllStyles';
import { ShowUpsellsButton } from '../../upsells';
import { selectHasUpsells } from '../../upsells/store/upsellsSlice';
import AllLabelVariants from '../../iconAndLabel/AllLabelVariants';
import { selectProductTag } from '../../item/store/itemSlice';

interface CustomerConfiguratorProps {
  admin?: boolean;
  productTag?: string;
  isMobileView: boolean;
  priceCents: number;
  setAppState: Dispatch<SetStateAction<AppStates>>;
  errors?: CartErrors;
  loading: boolean;
  addToCartButton?: boolean;
  cartItemId?: string;
}

function displayErrors(errors?: CartErrors | Record<string, string>) {
  Object.values(errors ?? {}).forEach(value => {
    if (typeof value === 'string') {
      toast.error(value, {
        toastId: value,
        position: 'bottom-right'
      });
    } else {
      displayErrors(value);
    }
  });
}

export function CustomerConfigurator({
  admin = false,
  productTag,
  isMobileView,
  priceCents,
  setAppState,
  errors,
  loading,
  addToCartButton = true,
  cartItemId
}: CustomerConfiguratorProps) {
  const isReadyForCart = !errors;
  const [showErrors, setShowErrors] = useState(false);

  const seeAll = useSelector(selectSeeAll);
  const entity = useSelector(selectEntity);
  const hasUpsells = useSelector(selectHasUpsells);

  useEffect(() => {
    if (isReadyForCart) setShowErrors(false);
  }, [isReadyForCart]);

  const loadedProductTag = useRootSelector(selectProductTag);
  // do not render children until we have a product tag - they will break
  if (!loadedProductTag) {
    return <p>Loading...</p>;
  }
  return (
    <>
      <div className="overflow-y-auto">
        <ConfiguratorHeader
          title={SHOPIFY_PRODUCT_NAME}
          priceCents={priceCents}
        />
        {isMobileView && <MobilePreviewWrapper />}

        {seeAll && (
          <div className="z-10 bg-white">
            {entity == 'Styles' && <AllStyles />}
            {entity == 'Colors' && <AllColors />}
            {entity == 'Fonts' && <AllFonts />}
            {entity == 'Icons' && <AllIcons />}
            {entity == 'LabelVariants' && <AllLabelVariants />}
          </div>
        )}
        <Steps
          admin={admin}
          productTag={productTag || SHOPIFY_PRODUCT_TAG}
          seeAll={seeAll}
          errors={showErrors ? errors : undefined}
        />
      </div>

      {!addToCartButton ? null : loading ? (
        <ContainedButton disabled={true} size="large" className="mt-5 flex-1">
          Loading...
        </ContainedButton>
      ) : !hasUpsells ? (
        <AddToCartButton
          productTag={productTag}
          setAppState={setAppState}
          isReadyForCart={isReadyForCart}
          cartItemId={cartItemId}
          onClick={() => {
            setShowErrors(true);
            displayErrors(errors);
          }}
        />
      ) : (
        <ShowUpsellsButton
          setAppState={setAppState}
          isReadyForCart={isReadyForCart}
          cartItemId={cartItemId}
          onClick={() => {
            setShowErrors(true);
            displayErrors(errors);
          }}
        />
      )}
    </>
  );
}
