export function CurveIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_211_1312)">
        <path
          d="M0.833313 12.7499H4.49998V16.4166H0.833313V12.7499ZM15.5 12.7499H19.1666V16.4166H15.5V12.7499ZM8.16665 3.58325H11.8333V7.24992H8.16665V3.58325Z"
          fill="black"
          stroke="black"
          strokeWidth="1.83333"
          strokeLinejoin="round"
        />
        <path
          d="M8.16665 5.41675H0.833313M19.1666 5.41675H11.8333M2.66665 12.7501C2.66665 9.33321 5.00415 6.46175 8.16665 5.64775M11.8333 5.64775C14.9958 6.46175 17.3333 9.33275 17.3333 12.7501"
          stroke="black"
          strokeWidth="1.83333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_211_1312">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
