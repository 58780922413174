export enum GroupTypeEnum {
  palettes = 'palettes',
  icons = 'icons',
  fonts = 'fonts',
  styles = 'styles',
  labelVariants = 'labelVariants'
}

export interface Group {
  group_id?: string;
  display_name: string;
  system_name: string;
  type: GroupTypeEnum;
  client_filtering: boolean;
}

export interface GroupErrors {
  display_name: boolean;
  system_name: boolean;
  type: boolean;
  client_filtering: boolean;
  group_member_ids: boolean;
}

export enum GroupFields {
  display_name = 'display_name',
  system_name = 'system_name',
  client_filtering = 'client_filtering',
  type = 'type'
}

export interface GroupRegistration {
  group_registration_id?: string;
  group_id?: string;
  group_member_id: string;
  system_name: string;
  displayName?: string;
}

export interface GroupRegistrations {
  [key: string]: GroupRegistration;
}

export interface RegistrationCandidates {
  [key: string]: {
    all_styles?: boolean;
    system_name: string;
    selected: boolean;
    is_group?: boolean;
    registered_entity_id: string;
  };
}

export interface UpdateGroupRegistrationsOptions {
  memberIdsToAdd: GroupRegistration;
  memberIdsToRemove: GroupRegistration;
}
