export function CartIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18 18C18.5304 18 19.0391 18.2107 19.4142 18.5858C19.7893 18.9609 20 19.4696 20 20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22C17.4696 22 16.9609 21.7893 16.5858 21.4142C16.2107 21.0391 16 20.5304 16 20C16 18.89 16.89 18 18 18ZM2 2H5.27L6.21 4H21C21.2652 4 21.5196 4.10536 21.7071 4.29289C21.8946 4.48043 22 4.73478 22 5C22 5.17 21.95 5.34 21.88 5.5L18.3 11.97C17.96 12.58 17.3 13 16.55 13H9.1L8.2 14.63L8.17 14.75C8.17 14.8163 8.19634 14.8799 8.24322 14.9268C8.29011 14.9737 8.3537 15 8.42 15H20V17H8C7.46957 17 6.96086 16.7893 6.58579 16.4142C6.21071 16.0391 6 15.5304 6 15C6 14.65 6.09 14.32 6.24 14.04L7.6 11.59L4 4H2V2ZM8 18C8.53043 18 9.03914 18.2107 9.41421 18.5858C9.78929 18.9609 10 19.4696 10 20C10 20.5304 9.78929 21.0391 9.41421 21.4142C9.03914 21.7893 8.53043 22 8 22C7.46957 22 6.96086 21.7893 6.58579 21.4142C6.21071 21.0391 6 20.5304 6 20C6 18.89 6.89 18 8 18ZM17 11L19.78 6H7.14L9.5 11H17Z"
        fill="currentColor"
      />
    </svg>
  );
}
