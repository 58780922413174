import type { TableColumn } from '../../../types/record-table';

import { ColumnHeader } from './ColumnHeader';

interface TableHeaderProps {
  columns: TableColumn[];
}

export function TableHeader({ columns }: TableHeaderProps) {
  const Headers = columns.map(column => {
    const { columnKey, headerClassNames, header, widthClassNames } = column;
    return (
      <ColumnHeader
        key={columnKey}
        columnKey={columnKey}
        header={header}
        headerClassNames={headerClassNames}
        widthClassNames={widthClassNames}
      />
    );
  });

  return <div className="flex min-h-[36px] items-center">{Headers}</div>;
}
