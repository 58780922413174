import { Product, SlotOptionCoordinates, Variant } from '../../../types';

import { VariantPreviewComponent } from './VariantPreview';
import { useMemo } from 'react';
import { UpsellProduct } from '../../upsells/types';
import { useRootSelector } from '../../../store';
import { selectItemProperties } from '../../item/store/itemSlice';

interface SharedPreviewProps {
  product?: Product | UpsellProduct;
  isUpsell?: boolean;
  mobile?: boolean;
}

export function SharedPreviewComponent({
  product,
  isUpsell,
  mobile = false
}: SharedPreviewProps) {
  const item = useRootSelector(selectItemProperties);

  const variantCoordinatePairs = useMemo(() => {
    if (!product || !item?.style) return [];

    const { label_slots, labels } = product;

    // Handle the non-upsell case with fewer than 2 label slots
    if (!isUpsell && label_slots.length < 2) {
      const chosenLabel = item.labelShape;
      const variantId = Object.values(item.slots)[0].variantId;
      const variant = chosenLabel?.variants.find(
        v => v.label_variant_id === variantId
      );
      if (!variant) return [];

      const coordinates = label_slots[0].slot_options.find(
        so => so.label_id == chosenLabel?.label_id
      )?.coordinates[0];
      return coordinates ? [{ variant, coordinates }] : [];
    }

    return label_slots.reduce<
      { variant: Variant; coordinates: SlotOptionCoordinates }[]
    >((acc, slot) => {
      const slotOption = slot.slot_options[0];
      const label = labels.find(l => l.label_id == slotOption.label_id);
      if (!label) return acc;

      const styleId = item.style?.style_id;
      if (!styleId) return acc;

      let variant = label.variants.find(v => v.style_id === styleId);

      const isStyleIterative = label.iterative_style_ids?.includes(styleId);
      const iterativeDefaultId = isStyleIterative
        ? label.iterative_defaults?.[styleId]
        : null;

      const selectedVariantId = !isUpsell
        ? item.slots[slot.product_label_slot_id]?.variantId
        : null;

      if (isStyleIterative && iterativeDefaultId) {
        variant = label.variants.find(
          v => v.label_variant_id === iterativeDefaultId
        );
      } else if (selectedVariantId) {
        variant = label.variants.find(
          v => v.label_variant_id === selectedVariantId
        );
      }

      if (!isUpsell && item.slots[slot.product_label_slot_id].variantId) {
        variant = label.variants.find(
          v =>
            v.label_variant_id ==
            item.slots[slot.product_label_slot_id].variantId
        );
      }

      const coordinates = slotOption.coordinates[0];
      if (variant && coordinates) {
        acc.push({ variant, coordinates });
      }

      return acc;
    }, []);
  }, [item, isUpsell, product]);

  const allCoordinatePairs = useMemo(() => {
    if (!(product && item?.style)) {
      return [];
    }
    const { label_slots } = product;
    const slotOptions = label_slots.flatMap(slot => slot.slot_options);
    return slotOptions.flatMap(option => option.coordinates);
  }, [item?.style, product]);

  const single_slot = product?.label_slots.length === 1;

  const bounds = useMemo(() => {
    const coords = allCoordinatePairs;
    const nice = (num: number) => {
      return Math.min(Math.max(0, num || 0), 2000);
    };
    // Do not consider x/y if there is only one slot
    const s = single_slot ? 0 : 1;
    const minX = nice(Math.min(...coords.map(({ x }) => x * s)));
    const maxX = nice(Math.max(...coords.map(({ x, width }) => x * s + width)));
    const minY = nice(Math.min(...coords.map(({ y }) => y * s)));
    const maxY = nice(
      Math.max(...coords.map(({ y, height }) => y * s + height))
    );

    return {
      left: minX,
      right: maxX,
      top: minY,
      bottom: maxY,
      width: Math.max(maxX - minX, 0),
      height: Math.max(maxY - minY, 0)
    };
  }, [allCoordinatePairs, single_slot]);

  return (
    // `w-full` is necessary for safari/ios. safari requires a width
    // on all svgs.
    <svg
      id={product ? `product-preview-${product.product_id}` : undefined}
      className="drop-shadow-product h-full max-h-full w-full"
      preserveAspectRatio={mobile ? 'xMinYMid meet' : ''}
      viewBox={`${bounds.left} ${bounds.top} ${bounds.width} ${bounds.height}`}
    >
      {product
        ? variantCoordinatePairs.map(({ variant, coordinates }, i) => {
            const x = single_slot
              ? bounds.width / 2 - coordinates.width / 2
              : coordinates.x;
            const y = single_slot
              ? bounds.height / 2 - coordinates.height / 2
              : coordinates.y;
            return (
              <g transform={`translate(${x} ${y})`} key={'VariantPreview-' + i}>
                <VariantPreviewComponent
                  width={coordinates.width}
                  height={coordinates.height}
                  key={'VariantPreview-' + i}
                  variant={variant}
                  itemProperties={item}
                  isUpsell={isUpsell}
                />
              </g>
            );
          })
        : null}
    </svg>
  );
}
