import { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';

import { Label } from '../../../features/labels/types';
import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';
import { useTRPCRequest } from '../../../hooks';
import { LabelSlots } from '../../../features/products/types';

import { SearchBar } from '../../search-bar';
import { ContainedButton } from '../../buttons';
import { LoadingDiv } from '../../loading';

import { ModalComponent } from '..';

interface SearchLabelsModalProps {
  labelSlots: LabelSlots;
  isOpen: boolean;
  isOption: boolean;
  onClose: () => void;
  onAddLabel(labelId: string): void;
}

export function SearchLabelsModal({
  labelSlots,
  isOpen,
  isOption,
  onClose,
  onAddLabel
}: SearchLabelsModalProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { handleTRPCRequest } = useTRPCRequest();
  const [labels, setLabels] = useState<Label[]>([]);

  const fetchLabels = useCallback(async () => {
    setIsLoading(true);

    const labelsRequest = {
      method: TRPCMethodEnum.list,
      resourceType: TRPCResourceEnum.labels,
      requestBody: { keyword: searchTerm }
    };
    const { res } = await handleTRPCRequest(labelsRequest);

    setIsLoading(false);
    setLabels(res);
    return res;
  }, [handleTRPCRequest, searchTerm]);

  useEffect(() => {
    fetchLabels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleAddLabel = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const id = event.currentTarget.id;
      if (!labelSlots[id]) {
        onAddLabel(id);
      } else if (isOption) {
        return;
      } else {
        let index = 1;
        let newId = `${id}-${index}`;
        while (labelSlots[newId]) {
          index++;
          newId = `${id}-${index}`;
        }
        onAddLabel(newId);
      }
    },
    [isOption, labelSlots, onAddLabel]
  );

  return (
    <ModalComponent
      isOpen={isOpen}
      contentLabel="Search Labels"
      onRequestClose={onClose}
    >
      <SearchBar
        searchTerm={searchTerm}
        onSearch={search => setSearchTerm(search)}
        placeHolder="Search Labels"
      />
      {isLoading && <LoadingDiv />}
      {labels && (
        <div className="flex h-[35vh] flex-col gap-1 overflow-scroll py-2">
          {labels.map(label => (
            <button
              id={label.label_id}
              key={label.label_id}
              onClick={handleAddLabel}
              className={clsx(
                ' w-full rounded  p-2 text-left shadow',
                labelSlots[label.label_id] && isOption
                  ? 'bg-lighter-gray'
                  : 'border-navy border'
              )}
              disabled={isOption && labelSlots[label.label_id] !== undefined}
            >
              {label.system_name}
            </button>
          ))}
        </div>
      )}
      <ContainedButton
        className="mt-5 w-full"
        color="secondary"
        onClick={onClose}
      >
        Close
      </ContainedButton>
    </ModalComponent>
  );
}
