import Joi from 'joi';

const groupFormSchema = Joi.object({
  group_id: Joi.string().label('Group Id'),
  system_name: Joi.string().label('System Name').required(),
  display_name: Joi.string().label('Display Name').required(),
  client_filtering: Joi.boolean().required(),
  type: Joi.string().required(),
  group_member_ids: Joi.array()
    .label('Group Registration')
    .min(1)
    .items(Joi.string())
    .required()
});

export default {
  groupFormSchema
};
