import clsx from 'clsx';

import { BaseButton, BaseButtonProps } from './BaseButton';

export interface TabButtonProps extends BaseButtonProps {
  active?: boolean;
  error?: boolean;
}

export function TabButton(props: TabButtonProps) {
  const { active = false, error, className, children, ...rest } = props;

  const baseClassList = [
    'bg-white',
    'font-poppins',
    'font-size-[15px]',
    'font-weight-[600]',
    'px-[16px]',
    'disabled:text-[#a6a6a6]',
    'disabled:border-[#a6a6a6]'
  ];

  const dynamicClasses = {
    'text-navy': active,
    'border-b-[2px]': active,
    'border-b-navy': active,
    'text-[#0000008a]': !active,
    '!text-error': error,
    '!border-b-error': error && active
  };

  return (
    <BaseButton
      className={clsx(baseClassList, dynamicClasses, className)}
      {...rest}
    >
      {children}
    </BaseButton>
  );
}
