export type ColorSlot = {
  print_key: string;
  display_name: string;
  color_hex: string | null;
  index: number;
  color_slot_id?: string | undefined;
  palette_id?: string | undefined;
  created?: string | undefined;
  updated?: string | undefined;
};

export interface ColorMapping {
  color_mapping_id?: string;
  system_name: string;
  hex_code: string;
  swatch_name?: string;
  c: number;
  m: number;
  y: number;
  k: number;
}

export interface ColorMappings {
  [color_hex: string]: ColorMapping;
}

export type Palette = {
  display_name: string;
  system_name: string;
  thumbnail_path?: string;
  thumbnail?: string;
  color_slots?: ColorSlot[];
  palette_id?: string | undefined;
  vdpcode?: string | undefined;
};

export interface PaletteFormErrors {
  display_name?: boolean;
  system_name?: boolean;
  thumbnail_path?: boolean;
  color_slots?: boolean[];
  vdpcode?: boolean;
}

export enum PALETTE_FIELDS {
  palette_id = 'palette_id',
  display_name = 'display_name',
  system_name = 'system_name',
  thumbnail_path = 'thumbnail_path',
  color_slots = 'color_slots',
  thumbnail = 'thumbnail',
  vdpcode = 'vdpcode'
}

export enum COLOR_SLOT_FIELDS {
  color_slot_id = 'color_slot_id',
  palette_id = 'palette_id',
  print_key = 'print_key',
  display_name = 'display_name',
  color_hex = 'color_hex',
  index = 'index'
}
