import { Material } from '../../materials/types';

import { LabelMaterialChipComponent } from './LabelMaterialChipComponent';

interface LabelMaterialChipsProps {
  label_id?: string;
  materials: Material[];
  onChange(materials: Material[]): void;
}

export const LabelMaterialChipsComponent = ({
  label_id,
  materials,
  onChange
}: LabelMaterialChipsProps) => {
  const handleRemoveMaterial = (material: Material) => {
    const newMaterials = materials
      .slice()
      .filter(m => m.material_id !== material.material_id);
    onChange(newMaterials);
  };

  return (
    <div>
      {materials.map(material => {
        return (
          <LabelMaterialChipComponent
            key={material.material_id}
            material={material}
            label_id={label_id}
            handleRemoveMaterial={handleRemoveMaterial}
          />
        );
      })}
    </div>
  );
};
