import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SearchBar } from '../../../components/search-bar';
import {
  useLocalStorage,
  useRawImageData,
  useTRPCRequest
} from '../../../hooks';
import { Material } from '../types';
import { RecordTable } from '../../../components/tables';
import { TableRecord } from '../../../types/record-table';
import { ListThumbnail } from '../../../components/images';
import {
  DeleteAssetButton,
  DuplicateAssetButton
} from '../../../components/buttons';
import {
  EntityDependencies,
  TRPCMethodEnum,
  TRPCResourceEnum
} from '../../../api/trpcApi/types';
import {
  CannotDeleteModalComponent,
  ConfirmDeleteModalComponent,
  LoadingModalComponent
} from '../../../components/modals';
import { toast } from 'react-toastify';
import { Title } from '../../../components/Title';

export function MaterialList() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useLocalStorage<string>(
    window.location.pathname,
    ''
  );

  const [materials, setMaterials] = useState<Material[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { handleTRPCRequest } = useTRPCRequest();
  const { getRawImageData } = useRawImageData();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [materialToDelete, setMaterialToDelete] = useState<Material>({
    material_id: '',
    system_name: '',
    display_name: '',
    thumbnail_path: '',
    upsell_title: '',
    thumbnail: '',
    description: ''
  });
  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);
  const [dependencies, setDependencies] = useState<EntityDependencies>({});

  const fetchMaterials = useCallback(
    async (search?: string) => {
      setIsLoading(true);
      const trpcRequest = {
        method: TRPCMethodEnum.list,
        resourceType: TRPCResourceEnum.materials,
        requestBody: {}
      };
      if (search) {
        trpcRequest.requestBody = { keyword: search };
      }
      const { res: materials } = await handleTRPCRequest(trpcRequest);

      const matsWithRawImagePromises = materials.map(
        async (material: Material) => {
          let rawImage;
          if (material.thumbnail_path) {
            rawImage = await getRawImageData(material.thumbnail_path);
          }
          return {
            ...material,
            thumbnail: rawImage
          };
        }
      );

      const matsWithRawImage = await Promise.all(matsWithRawImagePromises);
      setMaterials(matsWithRawImage);
      setIsLoading(false);
    },
    [getRawImageData, handleTRPCRequest]
  );

  useEffect(() => {
    fetchMaterials(searchTerm ?? undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleAddNew = useCallback(() => {
    navigate('/materials/new');
  }, [navigate]);

  const handleDuplicateMaterial = useCallback(
    (material: TableRecord) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      navigate('/materials/new', {
        state: { material }
      });
    },
    [navigate]
  );

  const handleDeleteMaterial = useCallback(
    (material: TableRecord) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setMaterialToDelete(material as Material);
      setConfirmDelete(true);
    },
    [setMaterialToDelete, setConfirmDelete]
  );

  const handleConfirmedDeleteMaterial = useCallback(async () => {
    setConfirmDelete(false);
    const { res, error } = await handleTRPCRequest({
      method: TRPCMethodEnum.delete,
      resourceType: TRPCResourceEnum.materials,
      requestBody: { material_id: materialToDelete.material_id }
    });
    if (res?.success === false) {
      setShowCannotDeleteModal(true);
      setDependencies(res.relatedEntities);
    } else if (res && !error) {
      toast.success('Material deleted successfully!');
      const filteredMaterials = materials.filter(
        (material: Material) =>
          material.material_id !== materialToDelete.material_id
      );
      setMaterials(filteredMaterials);
    }
  }, [handleTRPCRequest, materialToDelete.material_id, materials]);

  if (isLoading) {
    return <LoadingModalComponent isOpen={isLoading} />;
  }
  const noResults = materials?.length === 0;
  const noSearch = searchTerm === '';

  return (
    <>
      <Title title="Materials Search" />
      <SearchBar
        searchTerm={searchTerm}
        onSearch={search => setSearchTerm(search)}
        onAddNew={handleAddNew}
        classNames={['pb-2']}
      />

      {noResults && noSearch ? (
        <>
          <h2>No materials exist yet.</h2>
          <p> Click "+ Add New" to create your first material!</p>
        </>
      ) : noResults ? (
        <h2>No materials found for "{searchTerm}".</h2>
      ) : (
        <RecordTable
          data={materials}
          idKey="material_id"
          rowClassNames={[
            'card',
            'mb-[6px]',
            'flex',
            'h-[54px]',
            'cursor-pointer',
            'items-center',
            'align-middle'
          ]}
          calculateRowHref={material => `/materials/${material.material_id}`}
          columns={[
            {
              columnKey: 'thumbnail',
              onRender: (material: TableRecord) => (
                <ListThumbnail src={material.thumbnail} />
              ),
              widthClassNames: ['w-[54px]']
            },
            {
              columnKey: 'system_name',
              header: 'System Name',
              onRender: (material: TableRecord) => material.system_name,
              widthClassNames: ['w-[32%]', 'ml-[30px]']
            },
            {
              columnKey: 'display_name',
              header: 'Display Name',
              onRender: (material: TableRecord) => material.display_name,
              widthClassNames: ['w-[32%]', 'ml-[30px]']
            },
            {
              columnKey: 'upsell_title',
              header: 'Upsell Title',
              onRender: (material: TableRecord) => material.upsell_title
            },
            {
              columnKey: 'actions',
              widthClassNames: ['ml-auto'],
              onRender: (material: TableRecord) => {
                return (
                  <div className="flex gap-3 pr-3">
                    <DuplicateAssetButton
                      onDuplicateAsset={handleDuplicateMaterial(material)}
                    />
                    <DeleteAssetButton
                      onDeleteAsset={handleDeleteMaterial(material)}
                    />
                  </div>
                );
              }
            }
          ]}
        />
      )}
      <ConfirmDeleteModalComponent
        isOpen={confirmDelete}
        entityName={materialToDelete.system_name}
        onConfirm={handleConfirmedDeleteMaterial}
        onCancel={() => setConfirmDelete(false)}
      />
      <CannotDeleteModalComponent
        entityName={materialToDelete?.system_name ?? ''}
        dependencies={dependencies}
        isOpen={showCannotDeleteModal}
        onCancel={() => setShowCannotDeleteModal(false)}
      />
    </>
  );
}
