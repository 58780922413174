import { ChevronDownIcon } from '../../../../../components/icons/ChevronDownIcon';
import { DeleteButton } from '../DeleteButton';
import clsx from 'clsx';
import { NumberInput } from '../../../../../components/inputs/NumberInput';
import { AngleIcon } from '../../../../../components/icons';
import { LabelVariantIcon } from '../../../../../api/trpcApi/services/labels/types';
import { useUpdater } from '../state/update';
import { LabelUpdater } from '../../../types/state';

export interface IconFieldProps {
  value: LabelVariantIcon;
  onUpdate: LabelUpdater<LabelVariantIcon>;
  onDelete: () => void;
  open: boolean;
  onClick: () => void;
}

export function IconField({
  value,
  onUpdate,
  onDelete,
  open,
  onClick
}: IconFieldProps) {
  const { update } = useUpdater(onUpdate);
  return (
    <div className="flex flex-col gap-2">
      <div
        className="bg-light-blue flex justify-between rounded p-2"
        onClick={() => onClick()}
      >
        <div className="flex items-center gap-2">
          <div className={clsx('transition-transform', open && 'rotate-180')}>
            <ChevronDownIcon />
          </div>
          Icon
        </div>
        <DeleteButton onClick={onDelete} />
      </div>
      {open && (
        <div className="grid grid-cols-2 gap-2">
          <NumberInput
            contentBefore="X:"
            contentAfter="″"
            step={0.05}
            className="*:text-right"
            value={value.x}
            onChange={update('x')}
          />
          <NumberInput
            contentBefore="Y:"
            contentAfter="″"
            step={0.05}
            className="*:text-right"
            value={value.y}
            onChange={update('y')}
          />
          <NumberInput
            contentBefore={
              <div className="flex items-center gap-1">
                <AngleIcon />:
              </div>
            }
            contentAfter="°"
            min={-180}
            max={180}
            className="*:text-right"
            value={value.rotation}
            onChange={update('rotation')}
          />
          <NumberInput
            contentBefore="Radius:"
            contentAfter="″"
            min={0}
            step={0.05}
            className="*:text-right"
            value={value.radius}
            onChange={update('radius')}
          />
        </div>
      )}
    </div>
  );
}
