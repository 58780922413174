import { Palette } from '../types';

export async function duplicatePalette(
  palette: Palette,
  rawImage?: string
): Promise<Palette> {
  const newPalette: Palette = {
    display_name: palette.display_name,
    system_name: palette.system_name + ' - Copy',
    thumbnail: rawImage,
    thumbnail_path: palette.thumbnail_path,
    color_slots: palette.color_slots!.map(color => {
      const newColorSlot = { ...color };
      delete newColorSlot.color_slot_id;
      delete newColorSlot.palette_id;
      return newColorSlot;
    })
  };
  return newPalette;
}

export function isValidHexColor(hexColor: string | null): boolean {
  if (hexColor) {
    return /^#[0-9A-F]{6}$/i.test(hexColor);
  }
  return false;
}

export function formatHexColor(
  hexColor: string,
  includePoundSymbol: boolean = true
): string {
  if (!hexColor) {
    return includePoundSymbol ? '#' : '';
  }
  let formattedColor = hexColor
    .trim()
    .toUpperCase()
    .replace(/[^0-9A-F]/gi, '');
  if (formattedColor.length > 6) {
    formattedColor = formattedColor.slice(0, 6);
  }
  return includePoundSymbol ? '#' + formattedColor : formattedColor;
}

export function buildNewColorSlot(palette: Palette) {
  const index = palette.color_slots!.length;
  const newColorNumber = index - 1;
  const newColor = {
    print_key: `color${newColorNumber}`,
    display_name: `Color ${newColorNumber}`,
    color_hex: null,
    index
  };
  return newColor;
}

export function colorSlotPrintKeyToDisplayName(printKey: string): string {
  if (printKey === 'black') {
    return 'Black';
  } else if (printKey === 'white') {
    return 'White';
  } else if (printKey.startsWith('color')) {
    const colorNumber = printKey.slice(5);
    return `Color ${colorNumber}`;
  } else {
    return printKey;
  }
}

export function colorSlotDisplayNameToPrintKey(displayName: string): string {
  if (displayName === 'Black') {
    return 'black';
  } else if (displayName === 'White') {
    return 'white';
  } else if (displayName.startsWith('Color ')) {
    const colorNumber = displayName.slice(6);
    return `color${colorNumber}`;
  } else {
    return displayName;
  }
}
