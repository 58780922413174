import { toast } from 'react-toastify';
import { ErrorValue } from '../../../../utils/validationUtils';
import { LabelVariantState } from '../../types/state';
import cloneDeep from 'lodash/cloneDeep';

export const PRINT_DIMENSIONS = {
  // Subtract 0.07 * 2 for 0.07" margins on each side
  x: 4.5 - 0.07 * 2,
  // Subtract 1.5 for the header/footer
  y: 8.5 - 0.07 * 2 - 1.5
};

function isDefined<T>(value: T | undefined | null): value is T {
  return value != null;
}

export function removeNullishValues<T>(obj: T): unknown {
  if (Array.isArray(obj)) {
    return obj.filter(isDefined).map(removeNullishValues);
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([, value]) => isDefined(value))
        .map(([key, value]) => [key, removeNullishValues(value)])
    );
  }
  return obj ?? undefined;
}

export function cleanVariant(res: LabelVariantState, duplicate?: boolean) {
  const variant = cloneDeep(res);
  if (duplicate) {
    delete variant.label_variant_id;
    delete variant.label_id;
    Object.values(variant.text_fields).forEach(field => {
      delete field.label_text_field_id;
      delete field.label_variant_id;
    });
  }
  return variant;
}

export function checkVariantSize(
  variant: LabelVariantState
): ErrorValue<LabelVariantState> | undefined {
  if (
    Math.max(variant.width, variant.height) >
      Math.max(PRINT_DIMENSIONS.x, PRINT_DIMENSIONS.y) ||
    Math.min(variant.width, variant.height) >
      Math.min(PRINT_DIMENSIONS.x, PRINT_DIMENSIONS.y)
  ) {
    const str = `Label variant must be smaller than ${PRINT_DIMENSIONS.x}″x${PRINT_DIMENSIONS.y}″ to fit print area`;
    toast.error(str);
    return {
      width: {
        error: str,
        value: variant.width
      },
      height: {
        error: str,
        value: variant.height
      }
    } as ErrorValue<LabelVariantState>;
  }
}
