import { IconButton } from '..';
import { DeleteIcon } from '../../icons';

interface DeleteAssetButtonProps {
  onDeleteAsset: (event: React.MouseEvent<HTMLElement>) => void;
}

export function DeleteAssetButton({
  onDeleteAsset: onDeleteAsset
}: DeleteAssetButtonProps) {
  return (
    <IconButton
      onClick={onDeleteAsset}
      className="text-blush"
      data-testid="delete-asset-button"
      icon={<DeleteIcon />}
    />
  );
}
