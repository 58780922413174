import clsx from 'clsx';
import { TextField } from '..';

interface DisplayNameProps {
  fieldName?: string;
  displayName: string;
  displayNameError?: boolean;
  className?: string;
  onDisplayNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function DisplayName({
  fieldName = 'Display Name',
  displayName,
  displayNameError,
  className,
  onDisplayNameChange
}: DisplayNameProps) {
  return (
    <TextField
      error={displayNameError}
      contentBefore={`${fieldName}:`}
      size="small"
      className={clsx('w-full text-base font-semibold', className ?? 'mb-5')}
      value={displayName}
      onChange={onDisplayNameChange}
      data-testid="display-name"
    />
  );
}
