import { useEffect, useRef, useCallback, useState } from 'react';
import SVG from 'react-inlinesvg';
import clsx from 'clsx';

import { IconButton, UploadButtonComponent } from '../../buttons';
import { DeleteIcon, DuplicateIcon } from '../../icons';

import { Checkbox, TextField } from '..';

interface SystemNameProps {
  create?: boolean;
  systemName: string;
  errors?: {
    system_name?: boolean | undefined;
    thumbnail?: boolean | undefined;
  };
  uploadButtonText?: string;
  thumbnailPath?: string;
  requiredLabel?: string;
  required?: boolean;
  onSystemNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onThumbnailUpload?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRequiredChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDelete: () => void;
  onDuplicate?: () => void;
  className?: string;
  svg?: boolean;
}

export function SystemNameComponent({
  create = false,
  systemName,
  errors,
  uploadButtonText,
  thumbnailPath,
  requiredLabel,
  required,
  onSystemNameChange,
  onThumbnailUpload,
  onDuplicate,
  onDelete,
  handleRequiredChange,
  className,
  svg
}: SystemNameProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const [imageSrc, setImageSrc] = useState<string | null>(
    thumbnailPath || null
  );

  useEffect(() => {
    if (create) {
      inputRef.current?.focus();
    }
  }, [create]);

  const handleThumbnailUpload = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (files) {
        const [file] = files;
        const isSvg = file.type === 'image/svg+xml';

        const reader = new FileReader();

        reader.onload = event => {
          const { result: value } = event.target as { result: string };

          isSvg ? setImageSrc(URL.createObjectURL(file)) : setImageSrc(value);

          onThumbnailUpload?.({
            target: { value }
          } as React.ChangeEvent<HTMLInputElement>);
        };

        isSvg ? reader.readAsText(file) : reader.readAsDataURL(file);
      }
    },
    [onThumbnailUpload]
  );

  useEffect(() => {
    if (thumbnailPath) {
      setImageSrc(thumbnailPath);
    }
  }, [thumbnailPath]);

  return (
    <div
      className={clsx(
        'flex gap-3',
        className ?? 'mb-5 border-b border-[#D3D3D3] pb-5'
      )}
    >
      <TextField
        error={errors?.system_name}
        ref={inputRef}
        className="flex-1 text-2xl font-semibold"
        value={systemName}
        onChange={onSystemNameChange}
        data-testid="system-name"
      ></TextField>

      {onThumbnailUpload && uploadButtonText && (
        <UploadButtonComponent
          text={uploadButtonText}
          onUpload={handleThumbnailUpload}
          error={errors?.thumbnail}
        />
      )}
      {imageSrc &&
        (svg && thumbnailPath === imageSrc ? (
          <SVG src={imageSrc} className="h-12 w-12" />
        ) : (
          <img
            className="h-12 w-12 object-contain"
            src={imageSrc}
            alt="thumbnail image"
          />
        ))}
      {requiredLabel && (
        <Checkbox
          label={requiredLabel}
          checked={required}
          onChange={handleRequiredChange}
        />
      )}

      {onDuplicate && (
        <IconButton
          disabled={create}
          onClick={onDuplicate}
          className="text-navy text-[24px]"
          icon={<DuplicateIcon />}
          data-testid="copy-button"
        />
      )}

      <IconButton
        disabled={create}
        onClick={onDelete}
        className="text-blush text-[24px]"
        icon={<DeleteIcon />}
        data-testid="delete-button"
      />
    </div>
  );
}
