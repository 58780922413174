import clsx from 'clsx';

import { Material } from '../../materials/types';

import { SearchMaterialCandidatesComponent } from './SearchMaterialCandidatesComponent';
import { LabelMaterialChipsComponent } from './LabelMaterialChipsComponent';

interface ManageLabelMaterialsProps {
  error: boolean;
  materials: Material[];
  onChange: (materials: Material[]) => void;
  label_id?: string;
}

export function ManageLabelMaterialsComponent({
  error,
  materials,
  onChange,
  label_id
}: ManageLabelMaterialsProps) {
  return (
    <div
      className={clsx(
        {
          'bg-light-blue': !error,
          'bg-blush': error
        },
        'p-2'
      )}
    >
      <SearchMaterialCandidatesComponent
        materials={materials}
        onChange={onChange}
      />
      <LabelMaterialChipsComponent
        materials={materials}
        onChange={onChange}
        label_id={label_id}
      />
    </div>
  );
}
