import { LayeredSVGComponent } from '../../../components/svg';

import { IconLayer } from '../types';

interface IconPreviewProps {
  iconLayers: IconLayer[];
  svgRef: React.Ref<HTMLDivElement>;
}

export const IconPreviewComponent = ({
  iconLayers,
  svgRef
}: IconPreviewProps) => {
  return (
    <LayeredSVGComponent
      layers={iconLayers}
      svgRef={svgRef}
      svgStyle={{ width: '80%', height: '80%' }}
    />
  );
};
