import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { ContainedButton } from '../../../components/buttons';
import { TextField } from '../../../components/inputs';
import { SizeEnum } from '../../../types';

interface EditVdpCodeComponentProps {
  handleUpdateVdpCode: (vdpCode: string) => void;
  vdpCode: string;
  className?: string;
  overrideClassName?: boolean;
  type?: string;
  error?: boolean;
}

/*
  * EditVdpCode Component

  * EditVdpCode component is a component that allows the user to edit the VDP code on a feature.
  * This component is intended to be feature agnostic and can be used in any feature that requires a VDP code.
  * Note that this component should be implemented in conjunction with the relevant changes to the api endpoint 
  * and the feature component.

  * Required Params:
  *   vdpCode: string - the current VDP code. This should be pulled from the feature object or set to an empty string.
  *   handleUpdateVdpCode: function - a function that updates the VDP code in the feature object.
  * Optional Params:
  *   className: string - a string that can be used to add additional classes to the component.
  *   overrideClassName: boolean - a string that can be used to override the default class name of the component.  
  *   type: string - the type of input field. Defaults to 'text'.
  *   error: boolean - a boolean that can be used to indicate if there is an error with the input field. Defaults to false.
  
  * Example:
  * export function FeatureComponent() {
  * 
  *   const [feature, setFeature] = useState<Feature>({
  *     vdpCode: 'ABCD',
  *    });
  * 
  *   const handleUpdateVdpCode = (vdpCode: string) => {
  *     setFeature({ ...prevFeature, vdpCode });
  *   };
  * 
  *   return (
  *     <EditVdpCode
  *       vdpCode={vdpCode}
  *       handleUpdateVdpCode={handleUpdateVdpCode}
  *     />
  *   ); 
  * }
*/
export function EditVdpCodeComponent({
  className,
  vdpCode,
  handleUpdateVdpCode,
  overrideClassName,
  type = 'text',
  error = false
}: EditVdpCodeComponentProps) {
  const [isEditingVdpCode, setIsEditingVdpCode] = useState<boolean>(false);
  const [text, setText] = useState<string>(vdpCode);

  useEffect(() => {
    setText(vdpCode);
  }, [vdpCode]);

  className = overrideClassName ? clsx(className) : clsx('flex flex-col gap-2');

  const handleEditVdpCode = () => {
    if (isEditingVdpCode) {
      if (vdpCode !== text) {
        handleUpdateVdpCode(text);
      }
    }
    setIsEditingVdpCode(prevState => {
      return !prevState;
    });
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value.toUpperCase());
  };

  return (
    <div className={className}>
      <TextField
        className={clsx('small')}
        id="vdp-code-input"
        disabled={!isEditingVdpCode}
        value={text}
        onChange={handleTextChange}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') {
            handleEditVdpCode();
          }
        }}
        size={'small'}
        error={error}
        type={type}
        contentBefore={<p>VDP Code:</p>}
        contentAfter={
          <ContainedButton
            size={SizeEnum.small}
            onClick={() => {
              handleEditVdpCode();
            }}
          >
            {isEditingVdpCode ? 'Save' : 'Edit'}
          </ContainedButton>
        }
        style={{ width: '85px' }}
      />
    </div>
  );
}
