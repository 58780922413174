import { useCallback, useEffect, useState } from 'react';
import { useTRPCRequest } from '../../../hooks';
import { Palette } from '../types';
import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';

export function useBlankPalette() {
  const { handleTRPCRequest } = useTRPCRequest();

  const [palette, setPalette] = useState<Palette>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getBlankPalette = useCallback(async () => {
    const { res: palette } = await handleTRPCRequest({
      method: TRPCMethodEnum.blank,
      resourceType: TRPCResourceEnum.palettes,
      requestBody: {}
    });

    setPalette(palette);
    setIsLoading(false);
  }, [handleTRPCRequest]);

  useEffect(() => {
    getBlankPalette();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { palette, isLoading };
}
