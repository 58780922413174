export function ZoomInIcon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="mingcute:zoom-in-line" clipPath="url(#clip0_96_94)">
        <g id="Group">
          <path
            id="Vector"
            d="M10.5 2C12.0683 1.99989 13.606 2.43364 14.943 3.25327C16.28 4.07289 17.3642 5.24643 18.0757 6.64403C18.7871 8.04163 19.098 9.6088 18.974 11.1721C18.85 12.7355 18.2959 14.234 17.373 15.502L17.176 15.762L20.828 19.414C21.0093 19.5935 21.115 19.8356 21.1237 20.0905C21.1323 20.3455 21.0432 20.5942 20.8745 20.7856C20.7059 20.977 20.4704 21.0967 20.2164 21.1202C19.9623 21.1437 19.7089 21.0692 19.508 20.912L19.414 20.828L15.762 17.176C14.6975 18.015 13.4477 18.5872 12.117 18.8449C10.7864 19.1026 9.41333 19.0384 8.11252 18.6576C6.81171 18.2768 5.62084 17.5904 4.63925 16.6557C3.65766 15.7211 2.91382 14.5652 2.46979 13.2846C2.02576 12.004 1.89442 10.6357 2.08672 9.29404C2.27902 7.95235 2.78939 6.67609 3.57525 5.57175C4.3611 4.46742 5.39966 3.56702 6.60427 2.94569C7.80887 2.32436 9.1446 2.00012 10.5 2ZM10.5 4C8.7761 4 7.1228 4.68482 5.90382 5.90381C4.68483 7.12279 4.00001 8.77609 4.00001 10.5C4.00001 12.2239 4.68483 13.8772 5.90382 15.0962C7.1228 16.3152 8.7761 17 10.5 17C12.2239 17 13.8772 16.3152 15.0962 15.0962C16.3152 13.8772 17 12.2239 17 10.5C17 8.77609 16.3152 7.12279 15.0962 5.90381C13.8772 4.68482 12.2239 4 10.5 4ZM10.5 6C10.7449 6.00003 10.9813 6.08996 11.1644 6.25272C11.3474 6.41547 11.4644 6.63975 11.493 6.883L11.5 7V9.5H14C14.2549 9.50028 14.5 9.59788 14.6854 9.77285C14.8707 9.94782 14.9822 10.187 14.9972 10.4414C15.0121 10.6958 14.9293 10.9464 14.7657 11.1418C14.6022 11.3373 14.3701 11.4629 14.117 11.493L14 11.5H11.5V14C11.4997 14.2549 11.4021 14.5 11.2272 14.6854C11.0522 14.8707 10.8131 14.9822 10.5586 14.9972C10.3042 15.0121 10.0536 14.9293 9.85818 14.7657C9.66272 14.6021 9.53711 14.3701 9.50701 14.117L9.50001 14V11.5H7.00001C6.74513 11.4997 6.49998 11.4021 6.31464 11.2272C6.1293 11.0522 6.01777 10.813 6.00284 10.5586C5.9879 10.3042 6.07068 10.0536 6.23427 9.85817C6.39786 9.66271 6.62991 9.5371 6.88301 9.507L7.00001 9.5H9.50001V7C9.50001 6.73478 9.60537 6.48043 9.7929 6.29289C9.98044 6.10536 10.2348 6 10.5 6Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_96_94">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
