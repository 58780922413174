import { ContainedButton } from '../../buttons';

import { ModalComponent } from '../ModalComponent';

interface ConfirmModalProps {
  isOpen: boolean;
  contentLabel: string;
  title: string;
  message?: string;
  cancelText?: string;
  confirmText?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export function ConfirmModalComponent({
  isOpen,
  contentLabel,
  title,
  message,
  cancelText = 'Back',
  confirmText = 'Confirm',
  onConfirm,
  onCancel
}: ConfirmModalProps) {
  return (
    <ModalComponent
      isOpen={isOpen}
      onRequestClose={onCancel}
      contentLabel={contentLabel}
    >
      <h1 className="mb-3 block">{title}</h1>
      {message && <p className="mb-3">{message}</p>}
      <div className="flex gap-3 pt-5">
        <ContainedButton
          className="flex-1"
          color="secondary"
          onClick={onCancel}
          data-testid="confirm-modal-back-button"
        >
          {cancelText}
        </ContainedButton>
        <ContainedButton
          onClick={onConfirm}
          className="flex-1"
          data-testid="confirm-modal-confirm-button"
        >
          {confirmText}
        </ContainedButton>
      </div>
    </ModalComponent>
  );
}
