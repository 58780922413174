import { Dispatch, SetStateAction } from 'react';

import { ContainedButton } from '../../../components/buttons';
import { AppStates } from '../../../types';

interface ShowUpsellsButtonProps {
  isReadyForCart: boolean;
  cartItemId?: string;
  setAppState: Dispatch<SetStateAction<AppStates>>;
  onClick?: () => void;
}

export function ShowUpsellsButton({
  isReadyForCart,
  setAppState,
  onClick,
  cartItemId
}: ShowUpsellsButtonProps) {
  const params = new URLSearchParams(window.location.search);
  const legacyItemKey = params.get('legacy_item_key') || undefined;

  const updatingCartItem = !!(cartItemId || legacyItemKey);
  const buttonText = updatingCartItem ? 'Continue' : 'Add to Cart';

  const handleClick = () => {
    if (onClick) onClick();
    if (isReadyForCart) setAppState(AppStates.SHOW_UPSELLS);
  };

  return (
    <ContainedButton size="large" className="mt-5" onClick={handleClick}>
      {buttonText}
    </ContainedButton>
  );
}
