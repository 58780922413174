export function AngleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_76_71)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.2 0C1.86274 0 2.4 0.537258 2.4 1.2V9.65384C8.7327 10.2245 13.7755 15.2673 14.3462 21.6H22.8C23.4627 21.6 24 22.1373 24 22.8C24 23.4627 23.4627 24 22.8 24H13.2097C13.2064 24 13.2032 24 13.2 24H1.2C0.537258 24 0 23.4628 0 22.8V22.8V10.8V1.2C0 0.537258 0.537258 0 1.2 0Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_76_71">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
