import { TableHeader } from './TableHeader';
import { TableBody } from './TableBody';

import type { TableColumn, TableRecord } from '../../../types/record-table';

interface RecordTableProps {
  data: TableRecord[];
  columns: TableColumn[];
  idKey: string;
  rowClassNames?: string[];
  onRowClick?: (
    record: TableRecord
  ) => (event: React.MouseEvent<HTMLElement>) => void;
  calculateRowHref?: (record: TableRecord) => string;
}

export function RecordTable({
  columns,
  data,
  idKey,
  rowClassNames,
  onRowClick,
  calculateRowHref
}: RecordTableProps) {
  return (
    <>
      <TableHeader columns={columns} />
      <TableBody
        columns={columns}
        data={data}
        idKey={idKey}
        rowClassNames={rowClassNames}
        onRowClick={onRowClick}
        calculateRowHref={calculateRowHref}
      />
    </>
  );
}
