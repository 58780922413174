export function parseLegacyProductCode() {
  const params = new URLSearchParams(window.location.search);
  const productCode = params.get('legacy_product_code') || '';

  if (!productCode) return;

  let name = params.get('name') || '';
  name = name.replace(/\s/, '\n');

  return {
    productType: productCode.substring(0, 4),
    productCategory: productCode.substring(4, 7),
    style: productCode.substring(7, 14),
    palette: productCode.substring(14, 16),
    icon: productCode.substring(16, 20),
    font: productCode.substring(20, 22),
    packShape: productCode.substring(22, 25),
    shoe: productCode.substring(25, 26),
    material: productCode.substring(26, 30),
    name,
    line1: params.get('line1') || '',
    line2: params.get('line2') || '',
    line3: params.get('line3') || '',
    line4: params.get('line4') || '',
    line5: params.get('line5') || '',
    alert: params.get('alert') || '',
    number: params.get('number') || '',
    tagline: params.get('tagline') || ''
  };
}
