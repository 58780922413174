import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { ConfiguratorHeader } from '../../../components/headers';
import { SHOPIFY_PRODUCT_PRICE_CENTS } from '../../../config';
import { AppStates } from '../../../types';
import { useConfiguratorDispatch } from '../../../store';

import { AddToCartButton } from '../../cart/AddToCartButton';
import { ProductPreviewComponent } from '../../preview';
import { CartItemState } from '../../app/Configurator';
import { useMaterials } from '../../clothingType/Materials.hooks';

import {
  addUpsell,
  getChosenUpsellsPrice,
  selectAvailableUpsellItems,
  selectChosenUpsellItems
} from '../store/upsellsSlice';

import { UpsellSelector } from './UpsellSelector';
import { BackToConfiguratorButton } from '.';

interface UpsellsProps {
  isReadyForCart: boolean;
  isMobileView: boolean;
  preSelectedUpsells: CartItemState[];
  cartItemId?: string;
  setAppState: Dispatch<SetStateAction<AppStates>>;
}

export function Upsells({
  isReadyForCart,
  setAppState,
  isMobileView,
  cartItemId,
  preSelectedUpsells
}: UpsellsProps) {
  const dispatch = useConfiguratorDispatch();
  const upsellOptions = useSelector(selectAvailableUpsellItems);
  const selectedUpsells = useSelector(selectChosenUpsellItems);
  const upsellPrices = useSelector(getChosenUpsellsPrice);
  const { materials, isLoading } = useMaterials();
  const totalPrice = parseFloat(SHOPIFY_PRODUCT_PRICE_CENTS) + upsellPrices;
  const preSelectedUsed = useRef(false);

  useEffect(() => {
    if (isLoading || preSelectedUsed.current) {
      return;
    }
    preSelectedUsed.current = true;

    const params = new URLSearchParams(window.location.search);
    const legacyUpsellShopifyVariantIds = params
      .getAll('upsell')
      .map(key => parseInt(key.split(':')[0]));
    for (const variantId of legacyUpsellShopifyVariantIds) {
      const upsell = upsellOptions.find(u => u.shopify_id === variantId);
      if (upsell) dispatch(addUpsell({ upsell }));
    }

    for (const preSelectedUpsell of preSelectedUpsells) {
      const upsell = upsellOptions.find(
        u => u.product_id === preSelectedUpsell.product_id
      );

      const isUpsellAlreadyChosen = selectedUpsells.some(
        u => u.upsell.product_id === preSelectedUpsell.product_id
      );

      if (!upsell || isUpsellAlreadyChosen) continue;

      const material = materials.find(
        material =>
          material?.material_id ===
          preSelectedUpsell.label_slots?.[0]?.material_id
      );

      dispatch(
        addUpsell({
          cartItemId: preSelectedUpsell.cart_item_id,
          upsell,
          material
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, upsellOptions, materials, preSelectedUpsells, dispatch]);

  return (
    <>
      <div className="flex flex-col gap-5">
        <ConfiguratorHeader
          title="Frequently Bought Together"
          priceCents={totalPrice}
        />
        {isMobileView && (
          <div className="h-0 w-0 overflow-hidden">
            <ProductPreviewComponent />
          </div>
        )}
        {upsellOptions?.map(upsell => (
          <UpsellSelector key={upsell.product_id} upsell={upsell} />
        ))}
      </div>
      <div className="flex gap-3">
        <BackToConfiguratorButton
          style={{ flexGrow: 1 }}
          setAppState={setAppState}
        />

        <AddToCartButton
          style={{ flexGrow: 1 }}
          setAppState={setAppState}
          isReadyForCart={isReadyForCart}
          cartItemId={cartItemId}
          preSelectedUpsells={preSelectedUpsells}
        />
      </div>
    </>
  );
}
