import clsx from 'clsx';

import { BaseButton, BaseButtonProps } from './BaseButton';

export interface ContainedButtonProps extends BaseButtonProps {
  color?: 'primary' | 'secondary' | 'danger';
}

export function ContainedButton(props: BaseButtonProps) {
  const { className, children, color = 'primary', ...rest } = props;
  const isPrimary = color === 'primary';
  const isSecondary = color === 'secondary';
  const isDanger = color === 'danger';

  const baseClassList = [
    'button-shadow',
    'font-size-[15px]',
    'font-weight-[600]',
    'px-[16px]',
    'disabled:bg-[#e0e0e0]',
    'disabled:text-[#a6a6a6]',
    'rounded-[50px]'
  ];

  const colorClass = {
    'bg-navy': isPrimary,
    'bg-[#e0e0e0]': isSecondary,
    'bg-blush': isDanger,
    'text-white': isPrimary || isDanger,
    'text-navy': isSecondary
  };

  return (
    <BaseButton
      className={clsx(baseClassList, colorClass, className)}
      {...rest}
    >
      {children}
    </BaseButton>
  );
}
