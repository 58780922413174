import { RefObject } from 'react';

export function createSvgElement(svgString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(svgString, 'image/svg+xml');
  return doc.documentElement;
}

export function prepThumbnailForSave(
  svgRef: RefObject<HTMLDivElement>
): string {
  const svg = createSvgElement(svgRef.current?.innerHTML || '');
  svg.removeAttribute('style');
  // remove die-cut lines
  for (const child of svg.querySelectorAll(
    'path,circle,rect,polygon,ellipse'
  )) {
    const id = child.getAttribute('id') || '';
    if (id?.startsWith('dyeCut_')) {
      child.setAttribute('visibility', 'hidden');
    }
  }
  return svg.outerHTML;
}
