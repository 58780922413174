import invalidColor from '../../../assets/invalid-color.svg';

interface InvalidColorSampleProps {
  className?: string;
}
export function InvalidColorSample({
  className = 'h-11 w-11'
}: InvalidColorSampleProps) {
  return <img className={className} src={invalidColor} alt="Invalid color" />;
}
