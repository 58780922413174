import { TRPCResourceEnum } from '../../../api/trpcApi/types';

export const GroupMemberTypeToPrimaryKeyName = new Map<
  TRPCResourceEnum,
  string
>([
  [TRPCResourceEnum.icons, 'icon_id'],
  [TRPCResourceEnum.palettes, 'palette_id'],
  [TRPCResourceEnum.fonts, 'font_id'],
  [TRPCResourceEnum.products, 'product_id'],
  [TRPCResourceEnum.styles, 'style_id'],
  [TRPCResourceEnum.labelVariants, 'label_variant_id']
]);
