import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { SearchBar } from '../../../components/search-bar';
import { RecordTable } from '../../../components/tables';
import {
  DeleteAssetButton,
  DuplicateAssetButton
} from '../../../components/buttons';
import { ListThumbnail } from '../../../components/images';
import { TableRecord } from '../../../types/record-table';
import {
  EntityDependencies,
  TRPCMethodEnum,
  TRPCResourceEnum
} from '../../../api/trpcApi/types';
import { useTRPCRequest, useLocalStorage } from '../../../hooks';

import { Icon } from '../types';
import {
  CannotDeleteModalComponent,
  ConfirmDeleteModalComponent
} from '../../../components/modals';
import { Title } from '../../../components/Title';

export function IconsList() {
  const navigate = useNavigate();

  const [icons, setIcons] = useState<Icon[]>([]);
  const [searchTerm, setSearchTerm] = useLocalStorage<string>(
    window.location.pathname,
    ''
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [iconToDelete, setIconToDelete] = useState<TableRecord | null>(null);
  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);
  const [dependencies, setDependencies] = useState<EntityDependencies>({});

  const { handleTRPCRequest } = useTRPCRequest();

  const fetchIcons = useCallback(
    async (search?: string) => {
      setIsLoading(true);
      const trpcRequest = {
        method: TRPCMethodEnum.list,
        resourceType: TRPCResourceEnum.icons,
        requestBody: {}
      };
      if (search) {
        trpcRequest.requestBody = { keyword: search };
      }
      const { res: icons, error } = await handleTRPCRequest(trpcRequest);

      if (error) {
        console.log('ERROR');
      }
      setIcons(icons);
      setIsLoading(false);
    },
    [handleTRPCRequest]
  );

  useEffect(() => {
    fetchIcons(searchTerm ?? undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleAddNew = useCallback(() => {
    navigate(`/icons/new`);
  }, [navigate]);

  const handleDeleteIcon = useCallback(
    (icon: TableRecord) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setIconToDelete(icon);
      setConfirmDelete(true);
    },
    []
  );

  const handleConfirmedDeleteIcon = useCallback(async () => {
    if (iconToDelete) {
      setConfirmDelete(false);
      const { icon_id } = iconToDelete as Icon;
      const { res, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.delete,
        resourceType: TRPCResourceEnum.icons,
        requestBody: { icon_id }
      });

      if (!error && res.success) {
        const newIcons = icons.filter(icon => icon.icon_id !== icon_id);
        setIcons(newIcons);
        toast.success('Icon deleted successfully!');
      }

      if (!res?.success) {
        setShowCannotDeleteModal(true);
        setDependencies(res.relatedEntities);
      }
    }
  }, [icons, handleTRPCRequest, iconToDelete]);

  const handleDuplicateIcon = useCallback(
    (icon: TableRecord) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      navigate(`/icons/new`, { state: { iconToCopyId: icon.icon_id } });
    },
    [navigate]
  );

  const noResults = icons.length === 0;
  const noSearch = searchTerm === '';

  return (
    <>
      <Title title="Icons Search" />
      <SearchBar
        searchTerm={searchTerm}
        onSearch={search => setSearchTerm(search)}
        onAddNew={handleAddNew}
        classNames={['pb-2']}
      />
      {isLoading ? (
        <h2>Loading...</h2>
      ) : noResults && noSearch ? (
        <>
          <h2>No icons exist yet.</h2>
          <p> Click "+ Add New" to create your first icon!</p>
        </>
      ) : noResults ? (
        <h2>No icons found for "{searchTerm}".</h2>
      ) : (
        <RecordTable
          data={icons}
          idKey="icon_id"
          rowClassNames={[
            'card',
            'mb-[6px]',
            'flex',
            'h-[54px]',
            'cursor-pointer',
            'items-center',
            'align-middle'
          ]}
          calculateRowHref={icon => `/icons/${icon.icon_id}`}
          columns={[
            {
              columnKey: 'thumbnail_path',
              onRender: (icon: TableRecord) => (
                <ListThumbnail src={icon.thumbnail_path} />
              ),
              widthClassNames: ['w-[54px]']
            },
            {
              columnKey: 'systemName',
              header: 'System Name',
              onRender: icon => icon.system_name,
              widthClassNames: ['w-[32%]', 'ml-[30px]']
            },
            {
              columnKey: 'displayName',
              header: 'Display Name',
              onRender: icon => icon.display_name
            },
            {
              columnKey: 'actions',
              widthClassNames: ['ml-auto'],
              onRender: icon => (
                <div className="flex gap-3 pr-3">
                  <DuplicateAssetButton
                    onDuplicateAsset={handleDuplicateIcon(icon)}
                  />
                  <DeleteAssetButton onDeleteAsset={handleDeleteIcon(icon)} />
                </div>
              )
            }
          ]}
        />
      )}
      <ConfirmDeleteModalComponent
        entityName={iconToDelete?.system_name}
        isOpen={confirmDelete}
        onConfirm={handleConfirmedDeleteIcon}
        onCancel={() => {
          setIconToDelete(null);
          setConfirmDelete(false);
        }}
      />
      <CannotDeleteModalComponent
        entityName={iconToDelete?.system_name ?? ''}
        dependencies={dependencies}
        isOpen={showCannotDeleteModal}
        onCancel={() => setShowCannotDeleteModal(false)}
      />
    </>
  );
}
