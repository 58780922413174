import { useNavigate } from 'react-router-dom';
import { SearchBar } from '../../../components/search-bar';
import { useCallback, useEffect, useState } from 'react';
import { useLocalStorage, useTRPCRequest } from '../../../hooks';
import { Product } from '../../../api/trpcApi/services/products/types';
import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';
import { RecordTable } from '../../../components/tables';
import { TableRecord } from '../../../types/record-table';
import { toast } from 'react-toastify';
import {
  DeleteAssetButton,
  DuplicateAssetButton
} from '../../../components/buttons';
import {
  ConfirmDeleteModalComponent,
  LoadingModalComponent
} from '../../../components/modals';
import { PackIcon } from '../../../components/icons';
import { Title } from '../../../components/Title';

export function ProductsList() {
  const navigate = useNavigate();

  const [search, setSearch] = useLocalStorage<string>(
    window.location.pathname,
    ''
  );
  const [products, setProducts] = useState<Product[]>([]);
  const [productToDelete, setProductToDelete] = useState<Product>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { handleTRPCRequest } = useTRPCRequest();

  const fetchProducts = useCallback(
    async (search?: string) => {
      setIsLoading(true);
      const trpcRequest = {
        method: TRPCMethodEnum.list,
        resourceType: TRPCResourceEnum.products,
        requestBody: {}
      };
      if (search) {
        trpcRequest.requestBody = { keyword: search };
      }
      const { res: products, error } = await handleTRPCRequest(trpcRequest);
      if (!error) {
        setProducts(products);
      }
      setIsLoading(false);
    },
    [handleTRPCRequest]
  );

  useEffect(() => {
    fetchProducts(search || undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleDuplicateProduct = useCallback(
    (row: TableRecord) => (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      navigate('/products/new', {
        state: {
          product: row
        }
      });
    },
    [navigate]
  );

  const handleDeleteProduct = useCallback(
    (product: TableRecord) => (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setProductToDelete(product as Product);
    },
    []
  );

  const handleConfirmedDeleteProduct = useCallback(async () => {
    setProductToDelete(undefined);
    await handleTRPCRequest({
      method: TRPCMethodEnum.delete,
      resourceType: TRPCResourceEnum.products,
      requestBody: { product_id: productToDelete?.product_id }
    });
    toast.success('Product deleted');
    fetchProducts();
  }, [fetchProducts, handleTRPCRequest, productToDelete?.product_id]);

  if (isLoading) return <LoadingModalComponent isOpen />;

  const noResults = !products?.length;

  return (
    <>
      <Title title="Products Search" />
      <SearchBar
        searchTerm={search}
        onSearch={search => setSearch(search)}
        onAddNew={() => navigate('/products/new')}
        classNames={['pb-2']}
      />
      {noResults && !search ? (
        <>
          <h2>No products exist yet.</h2>
          <p> Click "+ Add New" to create your first product!</p>
        </>
      ) : noResults ? (
        <h2>No products found for "{search}".</h2>
      ) : (
        <RecordTable
          data={products}
          idKey="product_id"
          calculateRowHref={product => `/products/${product.product_id}`}
          rowClassNames={[
            'card',
            'mb-[6px]',
            'flex',
            'h-[54px]',
            'cursor-pointer',
            'items-center',
            'align-middle'
          ]}
          columns={[
            {
              columnKey: 'systemName',
              header: 'System Name',
              onRender: product => product.system_name,
              widthClassNames: ['w-[32%]', 'ml-[30px]']
            },
            {
              columnKey: 'tag',
              header: 'Tag',
              onRender: product => (
                <div className="flex">
                  <div className="mr-2">{product.tag}</div>
                  <div
                    className="text-navy underline hover:font-bold"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigator.clipboard.writeText(product.tag);
                    }}
                  >
                    copy
                  </div>
                </div>
              )
            },
            {
              columnKey: 'pack',
              header: '',
              widthClassNames: ['ml-auto', 'mr-12'],
              onRender: product =>
                product.is_pack ? (
                  <div className="flex items-center gap-2">
                    <PackIcon /> Pack
                  </div>
                ) : null
            },
            {
              columnKey: 'actions',
              onRender: product => (
                <div className="flex gap-3 pr-3">
                  <DuplicateAssetButton
                    onDuplicateAsset={handleDuplicateProduct(product)}
                  />
                  <DeleteAssetButton
                    onDeleteAsset={handleDeleteProduct(product)}
                  />
                </div>
              )
            }
          ]}
        />
      )}
      <ConfirmDeleteModalComponent
        isOpen={!!productToDelete}
        entityName={productToDelete?.system_name ?? ''}
        onConfirm={handleConfirmedDeleteProduct}
        onCancel={() => setProductToDelete(undefined)}
      />
    </>
  );
}
