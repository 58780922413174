import { useRef, useCallback } from 'react';

import { ContainedButton } from '..';
import { UploadIcon } from '../../icons';
import clsx from 'clsx';

interface UploadButtonProps {
  text: string;
  accept?: string[];
  multiple?: boolean;
  buttonClassName?: string;
  error?: boolean | undefined;
  disabled?: boolean;
  onUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function UploadButtonComponent({
  text,
  accept = ['image/svg+xml'],
  multiple = false,
  buttonClassName,
  error,
  disabled,
  onUpload
}: UploadButtonProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const errorClassList = {
    'border-2': error,
    'border-blush': error
  };

  return (
    <>
      <input
        type="file"
        accept={accept.join(',')}
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={onUpload}
        multiple={multiple}
        disabled={disabled}
        data-testid="file-upload-button"
      />

      <ContainedButton
        className={clsx(buttonClassName, errorClassList)}
        disabled={disabled}
        onClick={handleClick}
      >
        <UploadIcon />
        <span>{text}</span>
      </ContainedButton>
    </>
  );
}
