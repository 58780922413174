import Joi from 'joi';

// Note: the order of fields in the `Joi` object is the order any errors
// will be displayed on the page.

export const upsertLabelVariantSchema = Joi.object({
  label_id: Joi.string().optional(),
  label_variant_id: Joi.string().label('Label Variant Id').optional(),
  order: Joi.number().optional(),
  style_id: Joi.string().label('Style').required(),
  system_name: Joi.string().label('System Name').required(),
  width: Joi.number().min(0).required(),
  height: Joi.number().min(0).required(),
  thumbnail: Joi.string().label('Thumbnail').required(),
  thumbnail_path: Joi.string().label('Thumbnail Path'),
  vdpcode: Joi.string()
    .regex(/^[Z0-9]{3}$|^[A-Z]{1}$/)
    .label('VDP Code')
    .allow(null, '')
    .messages({
      'string.pattern.base': `"VDP code" must be three numbers`
    })
    .optional(),
  text_fields: Joi.object()
    .pattern(
      Joi.string(),
      Joi.object({
        text_field_id: Joi.string().label('Text Field ID').required(),
        label_variant_id: Joi.string().optional(),
        label_text_field_id: Joi.string().optional(),
        print_key: Joi.string().label('Color').required(),
        client_choice_font: Joi.boolean().label('Client Choice'),
        override_character_count: Joi.number().allow(null).optional(),
        override_font_id: Joi.string().allow(null).optional(),
        override_is_required: Joi.boolean().allow(null).optional(),
        x: Joi.number().required(),
        y: Joi.number().required(),
        height: Joi.number().required(),
        width: Joi.number().required(),
        rotation: Joi.number().required(),
        curve: Joi.number().required(),
        wrap: Joi.boolean().allow(null),
        align_x: Joi.number().required(),
        align_y: Joi.number().required()
      })
    )
    .label('Text Field')
    .required(),
  icons: Joi.object()
    .pattern(
      Joi.string(),
      Joi.object({
        label_icon_id: Joi.string().optional(),
        label_variant_id: Joi.string().optional(),
        x: Joi.number().required(),
        y: Joi.number().required(),
        radius: Joi.number().required(),
        rotation: Joi.number().required()
      })
    )
    .label('Icon')
    .required(),
  layers: Joi.object()
    .pattern(
      Joi.string(),
      Joi.object({
        label_layer_id: Joi.string().label('Layer ID').optional(),
        label_variant_id: Joi.string().optional(),
        order: Joi.number().optional(),
        print_key: Joi.string().allow('').required(),
        width: Joi.number().required(),
        height: Joi.number().required(),
        x: Joi.number().required(),
        y: Joi.number().required(),
        svgText: Joi.string().optional(),
        index: Joi.number().optional(),
        file_path: Joi.string().optional()
      })
    )
    .label('Layer')
    .min(1)
    .required()
    .messages({
      'object.min': 'At least one layer is required'
    })
});

export const createLabelSchema = Joi.object({
  system_name: Joi.string().label('System Name').required(),
  display_name: Joi.string().label('Display Name').required(),
  thumbnail: Joi.string().label('Thumbnail').required(),
  vdpcode: Joi.string()
    .regex(/^[A-Z]{3}$|^[A-Z]{1}$/)
    .allow(null, '')
    .label('VDP Code')
    .messages({
      'string.pattern.base': `"VDP code" must be three uppercase letters`
    })
    .optional(),
  iterative_style_ids: Joi.array()
    .label('Iterative Styles')
    .items(Joi.string())
    .required(),
  iterative_defaults: Joi.object().pattern(Joi.string(), Joi.string()),
  material_ids: Joi.array()
    .min(1)
    .label('Materials')
    .items(Joi.string())
    .required(),
  label_variants: Joi.object()
    .pattern(Joi.string(), upsertLabelVariantSchema)
    .min(1)
    .label('Label Variants')
    .required()
});

export const updateLabelSchema = Joi.object({
  label_id: Joi.string().label('Label Id').required(),
  system_name: Joi.string().label('System Name').required(),
  display_name: Joi.string().label('Display Name').required(),
  thumbnail: Joi.string().allow(''),
  vdpcode: Joi.string()
    .optional()
    .allow(null, '')
    .label('VDP Code')
    .regex(/^[A-Z]{3}$|^[A-Z]{1}$/)
    .messages({
      'string.pattern.base': `"VDP code" must be three uppercase letters`
    }),
  iterative_style_ids: Joi.array()
    .label('Iterative Styles')
    .items(Joi.string())
    .required(),
  iterative_defaults: Joi.object().pattern(Joi.string(), Joi.string()),
  material_ids: Joi.array()
    .min(1)
    .label('Materials')
    .items(Joi.string())
    .required()
});
