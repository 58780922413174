import { ReactNode } from 'react';
import { CheckmarkIcon, PlusIcon } from '../../../../components/icons';
import clsx from 'clsx';

export function DropdownOption(props: {
  children: ReactNode;
  onClick: () => void;
  selected?: boolean;
}) {
  return (
    <button
      onClick={props.onClick}
      className={clsx(
        'flex items-center justify-between p-2 shadow',
        props.selected
          ? 'bg-light-blue'
          : 'shadow-light-gray/5 hover:bg-light-blue '
      )}
    >
      {props.children}
      <div className="text-light-gray/40">
        {props.selected ? <CheckmarkIcon /> : <PlusIcon />}
      </div>
    </button>
  );
}
