import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import clsx from 'clsx';
import { PlusIcon } from '../../../components/icons';
import { DropdownOption } from '../../labels/components/settings/DropdownOption';
import { ContainedButton } from '../../../components/buttons';
import { Material } from '../../materials/types';
import { Label } from '../../labels/types';

interface MultiSelectMaterialsProps {
  open: { label_id: string; open: boolean };
  setOpen: Dispatch<SetStateAction<{ label_id: string; open: boolean }>>;
  onMaterialsUpdate(materialIdList: string[]): void;
  error: boolean;
  materials: Material[];
  value?: string[];
  label_id?: string;
  labelsList: Label[];
}

export function MultiSelectMaterials({
  open,
  setOpen,
  onMaterialsUpdate,
  error,
  materials,
  value,
  label_id,
  labelsList
}: MultiSelectMaterialsProps) {
  const [selectedList, setSelectedList] = useState<Material[]>([]);

  const selectableMaterialIds = useMemo(() => {
    const label = labelsList.find(
      label => label.label_id && label_id && label_id.startsWith(label.label_id)
    );
    return label?.material_ids;
  }, [label_id, labelsList]);

  const selectableMaterials = materials.filter(
    material =>
      material.material_id &&
      selectableMaterialIds?.includes(material.material_id)
  );

  const materialNames = useMemo(
    () => new Map(selectableMaterials.map(m => [m.material_id, m.system_name])),
    [selectableMaterials]
  );

  return (
    <div className="grow">
      <button
        className={clsx(
          'text-input-shadow flex h-[1.7rem] w-full items-center justify-between gap-4 overflow-hidden rounded-lg bg-white p-3',
          error && 'border-error border-2'
        )}
        onClick={() => {
          if (!selectableMaterials?.length) {
            return;
          }
          label_id && setOpen({ label_id, open: true });
          const selectedMaterials = selectableMaterials?.filter(material => {
            return (
              material.material_id &&
              value &&
              value.includes(material.material_id)
            );
          });
          setSelectedList(selectedMaterials);
        }}
      >
        {value?.length || 'Select'}
        {value?.length === 0
          ? ''
          : value?.length && value?.length > 1
          ? ' Materials'
          : ' Material'}
        <div className="text-aqua">
          <PlusIcon />
        </div>
      </button>
      {open.open && open.label_id == label_id && (
        <div className="text-input-shadow absolute z-10 flex w-[25%] flex-col overflow-hidden rounded-lg bg-white">
          <div className="flex max-h-[40vh] w-full flex-col overflow-y-scroll">
            {selectableMaterials?.map(material => {
              const styleName =
                materialNames.get(material.material_id) ?? 'Unknown';
              const id = material.material_id ?? '';
              return (
                <DropdownOption
                  key={id}
                  selected={selectedList.some(
                    material => material.material_id === id
                  )}
                  onClick={() => {
                    let updatedList = [];
                    if (
                      !selectedList.some(
                        m => m.material_id === material.material_id
                      )
                    ) {
                      updatedList = [...selectedList, material];
                      setSelectedList(updatedList);
                    } else {
                      updatedList = selectedList.filter(
                        m => m.material_id !== material.material_id
                      );

                      setSelectedList(updatedList);
                    }
                    const materialIds = updatedList
                      .filter(material => material.material_id !== undefined)
                      .map(material => material.material_id as string);

                    onMaterialsUpdate(materialIds);
                  }}
                >
                  {styleName}
                </DropdownOption>
              );
            })}
          </div>
          <div className="flex justify-around p-3">
            <ContainedButton
              color="secondary"
              onClick={() => setOpen({ label_id: '', open: false })}
            >
              Close
            </ContainedButton>
          </div>
        </div>
      )}
    </div>
  );
}
