import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { SHOPIFY_PRODUCT_NAME } from '../../../config';
import { useRootSelector } from '../../../store';
import { formatCentsToDollarString } from '../../../utils';

import { selectProductTag, selectSlots } from '../../item/store/itemSlice';
import {
  getChosenUpsellsPrice,
  selectChosenUpsellItems
} from '../../upsells/store/upsellsSlice';
import { UpsellPreview } from '../../preview/components/UpsellPreview';
import { SharedPreviewComponent } from '../../preview/components/SharedPreview';
import { useGetProductQuery } from '../../api';

import { ContinueShoppingButton } from './ContinueShoppingButton';
import { ViewCartButton } from './ViewCartButton';

export function AddedToCart({ productPrice }: { productPrice: string }) {
  const chosenUpsells = useSelector(selectChosenUpsellItems);
  const productTag = useRootSelector(selectProductTag);
  const selectedSlots = useRootSelector(selectSlots);
  const { data: product } = useGetProductQuery(productTag);
  const upsellPrices = useSelector(getChosenUpsellsPrice);
  const itemCount = useMemo(() => chosenUpsells.length + 1, [chosenUpsells]);
  const totalPriceNumber = parseFloat(productPrice) + upsellPrices;
  const totalPrice = formatCentsToDollarString(totalPriceNumber);
  const formattedPrice = formatCentsToDollarString(parseFloat(productPrice));

  const quantity = useMemo(() => {
    return Object.entries(selectedSlots).reduce((acc, [slotId, slot]) => {
      const slotOption = product?.label_slots
        ?.find(labelSlot => labelSlot.product_label_slot_id === slotId)
        ?.slot_options?.find(option => option.label_id === slot.labelId);
      return acc + (slotOption ? slotOption.quantity : 0);
    }, 0);
  }, [product, selectedSlots]);

  const quantityComponent = (productName: string, productQuantity: number) =>
    productName.includes('Waterproof Stickers') ? (
      <p className="font-semibold">1 Sheet</p>
    ) : (
      <p className="font-semibold">{productQuantity} Labels</p>
    );

  return (
    <div className="flex flex-col gap-5">
      <div className="my-4 flex gap-3 self-center">
        <p className="text-xl font-semibold	">Added to your Cart!</p>
        <p>{totalPrice}</p>
      </div>
      <div className="my-4 flex flex-col gap-3">
        <div className="flex gap-4">
          <div className="flex w-[150px] items-center justify-center">
            <SharedPreviewComponent product={product} />
          </div>
          <div className="flex-1">
            <div style={{ fontWeight: 600 }}>{SHOPIFY_PRODUCT_NAME}</div>
            <div className="flex gap-4">
              {quantityComponent(SHOPIFY_PRODUCT_NAME, quantity)}
              <p>{formattedPrice}</p>
            </div>
          </div>
        </div>
        {chosenUpsells.map((chosen, index) => {
          const { upsell } = chosen;
          return (
            <div className="flex gap-4">
              <div
                key={index}
                className="flex w-[150px] items-center justify-center"
              >
                <UpsellPreview upsell={upsell} />
              </div>
              <div className="flex-1">
                <div style={{ fontWeight: 600 }}>{upsell?.name}</div>
                <div className="flex gap-4">
                  {upsell.quantity
                    ? quantityComponent(upsell.name || '', upsell.quantity)
                    : null}
                  <p>
                    {upsell.priceCents &&
                      formatCentsToDollarString(upsell.priceCents)}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex px-4">
        <div>
          Subtotal ({itemCount} {itemCount == 1 ? 'item' : 'items'}):
        </div>
        <div className="flex-1 text-right">{totalPrice}</div>
      </div>
      <ContinueShoppingButton />
      <ViewCartButton />
    </div>
  );
}
