import { useCallback, useEffect, useState } from 'react';
import {
  EntityDependencies,
  TRPCMethodEnum,
  TRPCResourceEnum
} from '../../../api/trpcApi/types';
import { useTRPCRequest } from '../../../hooks';
import {
  Group,
  GroupErrors,
  GroupFields,
  GroupRegistration,
  GroupRegistrations,
  GroupTypeEnum
} from '../types';
import {
  useBlocker,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  Checkbox,
  DisplayName,
  SystemNameComponent
} from '../../../components/inputs';
import {
  BackButtonComponent,
  SaveButtonComponent
} from '../../../components/buttons';
import { validateSchemaAndNotifyErrors } from '../../../utils';
import GroupValidation from '../validations';
import { LoadingDiv } from '../../../components/loading';
import { TypeComponent } from '../components/TypeComponent';
import { duplicateGroup, formatGroupRequestBody } from '../utils';
import { ManageGroupRegistrationsComponent } from '../components/ManageGroupRegistrations';
import { GroupMemberTypeToPrimaryKeyName } from '../conts';
import {
  CannotDeleteModalComponent,
  ConfirmDeleteModalComponent,
  UnsavedChangesModalComponent
} from '../../../components/modals';
import { toast } from 'react-toastify';
import { Title } from '../../../components/Title';
import { startCase } from 'lodash';
interface GroupDetailsProps {
  create?: boolean;
}

export const GroupDetails = ({ create }: GroupDetailsProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const unsavedChangesBlocker = useBlocker(unsavedChanges);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);
  const [dependencies, setDependencies] = useState<EntityDependencies>({});

  const [group, setGroup] = useState<Group>({
    display_name: '',
    system_name: '',
    type: GroupTypeEnum.icons,
    client_filtering: false
  });

  const [groupRegistrations, setGroupRegistrations] =
    useState<GroupRegistrations>({});

  const [formErrors, setFormErrors] = useState<GroupErrors>({
    display_name: false,
    system_name: false,
    client_filtering: false,
    type: false,
    group_member_ids: false
  });

  const { handleTRPCRequest } = useTRPCRequest();

  const fetchGroup = useCallback(
    async (group_id: string) => {
      const { res: groupResponse, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.get,
        resourceType: TRPCResourceEnum.groups,
        requestBody: {
          group_id
        }
      });

      if (error) {
        navigate('/404');
        return {
          group: null,
          group_registrations: null
        };
      }
      const { group_members, ...rest } = groupResponse;

      return {
        group: rest,
        group_registrations: group_members
      };
    },
    [navigate, handleTRPCRequest]
  );

  const syncFromRouter = useCallback(async () => {
    const { state } = location;
    if (!state) {
      return;
    }

    const { group, groupToCopyId } = state;
    if (!group && !groupToCopyId) {
      return;
    }

    if (group) {
      const { group_registrations: copiedRegistrations, ...copiedGroup } =
        duplicateGroup(group, groupRegistrations);
      setGroup(copiedGroup);
      setGroupRegistrations(copiedRegistrations);
    }

    if (groupToCopyId) {
      const { group, group_registrations } = await fetchGroup(groupToCopyId);

      if (group && group_registrations) {
        setGroup(group);
        const groupRegistrationsState = group_registrations.reduce(
          (
            groupRegistrations: GroupRegistrations,
            groupRegistration: { [key: string]: string }
          ) => {
            // convert to GroupRegistration type
            const groupMemberIdName = GroupMemberTypeToPrimaryKeyName.get(
              group.type as TRPCResourceEnum
            );
            if (groupMemberIdName) {
              const groupMemberId = groupRegistration[groupMemberIdName];
              delete groupRegistration[groupMemberIdName];
              groupRegistrations[groupMemberId] = {
                ...groupRegistration,
                group_member_id: groupMemberId
              } as GroupRegistration;
            }

            return groupRegistrations;
          },
          {} as GroupRegistrations
        );
        const { group_registrations: copiedRegistrations, ...copiedGroup } =
          duplicateGroup(group, groupRegistrationsState);

        setGroup(copiedGroup);
        setGroupRegistrations(copiedRegistrations);
      }
      setUnsavedChanges(true);
    }
  }, [fetchGroup, groupRegistrations, location]);

  const syncFromApi = useCallback(async () => {
    if (params.group_id) {
      const { group, group_registrations } = await fetchGroup(params.group_id);

      if (group && groupRegistrations) {
        setGroup(group);

        const groupRegistrationsState = group_registrations.reduce(
          (
            groupRegistrations: GroupRegistrations,
            groupRegistration: { [key: string]: string }
          ) => {
            // convert to GroupRegistration type
            const groupMemberIdName = GroupMemberTypeToPrimaryKeyName.get(
              group.type as TRPCResourceEnum
            );
            if (groupMemberIdName) {
              const groupMemberId = groupRegistration[groupMemberIdName];
              delete groupRegistration[groupMemberIdName];
              groupRegistrations[groupMemberId] = {
                ...groupRegistration,
                group_member_id: groupMemberId
              } as GroupRegistration;
            }

            return groupRegistrations;
          },
          {} as GroupRegistrations
        );

        setGroupRegistrations(groupRegistrationsState);
      }
    }
  }, [params.group_id, fetchGroup, groupRegistrations]);

  const syncData = useCallback(async () => {
    setIsLoading(true);
    const groupData = create ? syncFromRouter() : syncFromApi();
    await groupData;
    setIsLoading(false);
  }, [create, setIsLoading, syncFromRouter, syncFromApi]);

  useEffect(() => {
    syncData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [create]);

  const handleUpdateGroupField = useCallback(
    (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      let newValue: string | boolean = event.target.value;
      if (key === GroupFields.client_filtering) {
        newValue = !group.client_filtering;
      }

      setGroup({ ...group, [key]: newValue });

      setFormErrors({ ...formErrors, [key]: null });
      setUnsavedChanges(true);
    },
    [group, formErrors, setGroup, setFormErrors]
  );

  const handleUpdateGroupType = (event: React.FormEvent<HTMLSelectElement>) => {
    setGroup({ ...group, type: event.currentTarget.value as GroupTypeEnum });
    setGroupRegistrations({});
    setUnsavedChanges(true);
  };

  const handleDuplicateGroup = useCallback(() => {
    navigate(`/groups/new`, { state: { group } });
    setUnsavedChanges(true);
  }, [group, navigate]);

  const handleConfirmedDeleteGroup = useCallback(async () => {
    setUnsavedChanges(false);
    setConfirmDelete(false);
    if (group.group_id) {
      const { res, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.delete,
        resourceType: TRPCResourceEnum.groups,
        requestBody: { group_id: group.group_id }
      });

      if (!error && res.success) {
        toast.success('Group deleted successfully!');
        navigate('/groups');
      }

      if (!res?.success) {
        setShowCannotDeleteModal(true);
        setDependencies(res.relatedEntities);
      }
    }
  }, [group.group_id, navigate, handleTRPCRequest]);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const handleSaveGroup = useCallback(async () => {
    setIsSaving(true);

    const { errors, validatedSchema } = validateSchemaAndNotifyErrors(
      GroupValidation.groupFormSchema,
      formatGroupRequestBody(group, groupRegistrations)
    );

    if (errors) {
      setFormErrors(errors as unknown as GroupErrors);
    } else {
      const { res, error } = await handleTRPCRequest({
        method: create ? TRPCMethodEnum.create : TRPCMethodEnum.update,
        resourceType: TRPCResourceEnum.groups,
        requestBody: validatedSchema
      });

      if (!error) {
        setUnsavedChanges(false);
        navigate(`/groups/${res?.group_id}`);
      }
    }

    setIsSaving(false);
  }, [
    create,
    group,
    groupRegistrations,
    setFormErrors,
    handleTRPCRequest,
    navigate
  ]);

  const handleUpdateGroupRegistrations = useCallback(
    (updatedGroupRegistrations: GroupRegistrations) => {
      setGroupRegistrations(updatedGroupRegistrations);
      setFormErrors({ ...formErrors, group_member_ids: false });
      setUnsavedChanges(true);
    },
    [formErrors, setGroupRegistrations, setFormErrors]
  );

  if (isLoading) {
    return <LoadingDiv />;
  }

  return (
    <>
      {create ? (
        <Title title="Create Group" />
      ) : (
        <Title title="Existing Group" />
      )}
      <div>
        <SystemNameComponent
          create={create}
          systemName={group.system_name}
          errors={{ system_name: formErrors.system_name }}
          onSystemNameChange={handleUpdateGroupField(GroupFields.system_name)}
          onDuplicate={handleDuplicateGroup}
          onDelete={() => setConfirmDelete(true)}
        />
        <div className="flex flex-row justify-between">
          <div className="mb-5 flex w-3/4 items-center gap-2">
            <div className="w-[60%]">
              <DisplayName
                className={''}
                displayName={group.display_name}
                displayNameError={formErrors.display_name}
                onDisplayNameChange={handleUpdateGroupField(
                  GroupFields.display_name
                )}
              />
            </div>

            {create ? (
              <TypeComponent
                type={group.type}
                typeError={formErrors.type}
                onTypeChange={handleUpdateGroupType}
              />
            ) : (
              <span>
                Type:{' '}
                {startCase(
                  group.type.endsWith('s')
                    ? group.type.slice(0, -1)
                    : group.type
                )}
              </span>
            )}
          </div>

          <div className="mb-5 flex items-center">
            <Checkbox
              label="Used for filtering?"
              error={formErrors.client_filtering}
              checked={group.client_filtering}
              onChange={handleUpdateGroupField(GroupFields.client_filtering)}
            />
          </div>
        </div>

        <ManageGroupRegistrationsComponent
          error={formErrors.group_member_ids}
          type={TRPCResourceEnum[group.type]}
          groupRegistrations={groupRegistrations}
          onUpdateGroupRegistrations={handleUpdateGroupRegistrations}
        />

        <div className="mt-10 flex gap-3">
          <BackButtonComponent destination="/groups" />
          <SaveButtonComponent isSaving={isSaving} onSave={handleSaveGroup} />
        </div>
      </div>
      <UnsavedChangesModalComponent
        unsavedChanges={unsavedChanges}
        unsavedChangesBlocker={unsavedChangesBlocker}
      />
      <ConfirmDeleteModalComponent
        isOpen={confirmDelete}
        entityName={group.system_name}
        onCancel={() => setConfirmDelete(false)}
        onConfirm={handleConfirmedDeleteGroup}
      />
      <CannotDeleteModalComponent
        entityName={group.system_name ?? ''}
        dependencies={dependencies}
        isOpen={showCannotDeleteModal}
        onCancel={() => setShowCannotDeleteModal(false)}
      />
    </>
  );
};
