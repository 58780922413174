import tailwindConfig from '../../tailwind.config.js';

/**
 * Retrieves the hexadecimal color code for a given color name from the Tailwind
 * CSS configuration.
 *
 * @param {string} color - The color name to convert into a hexadecimal color
 * code.
 * @returns {string|undefined} The hexadecimal color code if found, otherwise
 * undefined.
 */
export function getHexFromTailwindConfig(color: string) {
  const key = color.toLowerCase().replace(' ', '-');
  return tailwindConfig.theme.colors[key];
}
