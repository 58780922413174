import { useEffect } from 'react';
import Modal from 'react-modal';
import clsx from 'clsx';

interface ModalProps {
  isOpen: boolean;
  blockScroll?: boolean;
  onRequestClose?: () => void;
  contentLabel: string;
  children: React.ReactNode;
  className?: string;
  overrideClassName?: boolean;
  overlayClassName?: string;
  overrideOverlayClassName?: boolean;
}

export function ModalComponent({
  isOpen,
  blockScroll = true,
  onRequestClose,
  contentLabel,
  children,
  className,
  overrideClassName = false,
  overlayClassName,
  overrideOverlayClassName = false
}: ModalProps) {
  useEffect(() => {
    if (blockScroll && isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [blockScroll, isOpen]);

  const modalClassNames = overrideClassName
    ? className
    : [
        'bg-white',
        'text-center',
        'rounded',
        'h-auto',
        'p-[20px]',
        'pt-[40px]',
        'min-w-[500px]',
        'max-w-[80vw]',
        className
      ];

  const overlayClassNames = overrideOverlayClassName
    ? overlayClassName
    : [
        'fixed',
        'top-0',
        'left-0',
        'right-0',
        'bottom-0',
        'bg-black',
        'bg-opacity-50',
        'flex',
        'justify-center',
        'items-center',
        overlayClassName
      ];

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      className={clsx(modalClassNames)}
      overlayClassName={clsx(overlayClassNames)}
    >
      {children}
    </Modal>
  );
}
