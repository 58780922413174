import clsx from 'clsx';
import { BaseInput, BaseInputProps } from './BaseInput';

export interface CheckboxProps extends Omit<BaseInputProps, 'type'> {
  label?: string;
  error?: boolean;
}

export function Checkbox(props: CheckboxProps) {
  const { className, disabled, label, error, ...rest } = props;

  const checkboxClasses = ['accent-turquoise'];

  const wrapperClasses = [
    'flex',
    'gap-[10px]',
    'flex-shrink-0',
    'items-center',
    'rounded-[32px]',
    'p-1',
    'align-middle',
    'transition-all',
    'hover:bg-[#0000000f]'
  ];

  const dynamicClasses = {
    'cursor-pointer': !disabled,
    'cursor-not-allowed': disabled,
    'px-[10px]': !!label
  };

  const errorClassList = {
    'border-2': error,
    'border-blush': error
  };

  return (
    <label className={clsx(wrapperClasses, dynamicClasses, errorClassList)}>
      <BaseInput
        disabled={disabled}
        className={clsx(checkboxClasses, dynamicClasses, className)}
        type="checkbox"
        {...rest}
      />
      {label}
    </label>
  );
}
