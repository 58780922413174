import clsx from 'clsx';
import { BaseInput, BaseInputProps } from './BaseInput';

export interface RadioButtonProps
  extends Omit<BaseInputProps, 'type' | 'name'> {
  name: string;
  label?: string;
}

export function RadioButton(props: RadioButtonProps) {
  const { className, disabled, label, checked, name, ...rest } = props;

  const checkboxClasses = ['accent-navy'];

  const wrapperClasses = [
    'flex',
    'gap-[10px]',
    'flex-shrink-0',
    'items-center',
    'rounded-[32px]',
    'p-1',
    'align-middle',
    'transition-all'
  ];

  const dynamicClasses = {
    'hover:bg-[#0000000f]': !checked,
    'hover:bg-[#1976d20a]': checked,
    'cursor-pointer': !disabled,
    'cursor-not-allowed': disabled,
    'px-[10px]': !!label
  };

  return (
    <label className={clsx(wrapperClasses, dynamicClasses)}>
      <BaseInput
        name={name}
        checked={checked}
        disabled={disabled}
        className={clsx(checkboxClasses, dynamicClasses, className)}
        type="radio"
        {...rest}
      />
      {label}
    </label>
  );
}
