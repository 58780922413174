import Joi from 'joi';

const percent = Joi.number().min(0).max(100).precision(2);

const colorMappingSchema = {
  system_name: Joi.string().label('System Name').required(),
  hex_code: Joi.string()
    .label('Hex Code')
    .length(6)
    .regex(/^[a-fA-F0-9]+$/)
    .required(),
  c: percent.label('C').required(),
  m: percent.label('M').required(),
  y: percent.label('Y').required(),
  k: percent.label('K').required(),
  swatch_name: Joi.string().label('Swatch Name').optional()
};

const createColorMappingSchema = Joi.object(colorMappingSchema);

const updateColorMappingSchema = Joi.object({
  ...colorMappingSchema,
  color_mapping_id: Joi.string().label('Color Mapping Id').required()
});

export default {
  createColorMappingSchema,
  updateColorMappingSchema
};
