import { AssociatedEntityComponent } from '../../associatedEntities/AssociatedEntityComponent';
import { ContainedButton } from '../../buttons';

import { ModalComponent } from '../ModalComponent';

interface CannotDeleteModalProps {
  isOpen: boolean;
  entityName: string;
  onCancel: () => void;
  dependencies: { [key: string]: { name: string; id: string }[] };
}

const labels: { [key: string]: string } = {
  'text-fields': 'Text Fields:',
  groups: 'Groups:',
  styles: 'Styles:',
  labels: 'Labels:',
  products: 'Products:',
  'label-variants': 'Label Variants:'
};

export function CannotDeleteModalComponent({
  isOpen,
  entityName,
  onCancel,
  dependencies
}: CannotDeleteModalProps) {
  return (
    <ModalComponent
      isOpen={isOpen}
      onRequestClose={onCancel}
      contentLabel="Cannot Delete"
    >
      <h1 className="mb-3 flex justify-center overflow-hidden text-ellipsis">
        <p className="max-w-lg">
          ‘{entityName}’ can not be deleted until it’s disassociated with the
          following entities:
        </p>
      </h1>
      {dependencies
        ? Object.entries(dependencies).map(([key, values]) =>
            values.length ? (
              <div className="flex max-h-[60vh] flex-nowrap items-baseline overflow-y-auto">
                <span className="font-bold">{labels[key]}</span>{' '}
                <div
                  id={key}
                  className="m-2 flex flex-wrap items-baseline space-x-2 space-y-2"
                >
                  {values.map(val => (
                    <AssociatedEntityComponent
                      system_name={val.name}
                      link={
                        // Label Variants link back to their labels, not themselves.
                        key == 'label-variants'
                          ? `/labels/${val.id}`
                          : `/${key}/${val.id}`
                      }
                    />
                  ))}
                </div>
              </div>
            ) : null
          )
        : null}
      <div className="flex justify-center gap-3 pt-5	">
        <ContainedButton
          className="basis-1/2"
          color="secondary"
          onClick={onCancel}
          data-testid="delete-modal-back-button"
        >
          Back
        </ContainedButton>
      </div>
    </ModalComponent>
  );
}
