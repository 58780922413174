import { useCallback } from 'react';
import { Select } from '../../../components/inputs/Select';
import { getDefaultColorSlotOptions } from '../utils';
import { IconButton } from '../../../components/buttons';
import { DeleteIcon, DragIcon } from '../../../components/icons';
import { DraggableItem } from '../../shared/components';
import { SizeEnum } from '../../../types';

interface IconLayersListItemProps {
  index: number;
  printKey: string;
  colorSlotsCount: number;
  onDropItem(dragIndex: number, hoverIndex: number): void;
  onChangePrintKey(index: number, print_key: string): void;
  onDelete(index: number): void;
}

export const IconLayersListItemComponent = ({
  index,
  printKey,
  colorSlotsCount,
  onDropItem,
  onChangePrintKey,
  onDelete
}: IconLayersListItemProps) => {
  const handleChangePrintKey = useCallback(
    (event: React.FormEvent<HTMLSelectElement>) => {
      onChangePrintKey(index, event.currentTarget.value);
    },
    [index, onChangePrintKey]
  );

  const handleDeleteLayer = useCallback(() => {
    onDelete(index), [index];
  }, [index, onDelete]);

  return (
    <DraggableItem
      index={index}
      onDropItem={onDropItem}
      className="mb-2 flex justify-between rounded p-2"
    >
      <div>
        <DragIcon height="100%" />
      </div>
      <div className="w-4/5">
        <Select
          size={SizeEnum.small}
          value={printKey}
          options={getDefaultColorSlotOptions(colorSlotsCount)}
          onChange={handleChangePrintKey}
        />
      </div>
      <IconButton
        className="text-blush text-[24px]"
        icon={<DeleteIcon />}
        data-testid="delete-layer-button"
        onClick={handleDeleteLayer}
      />
    </DraggableItem>
  );
};
