import { useCallback, useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

import { ContainedButton } from '../../buttons';

import { ModalComponent } from '../ModalComponent';

interface UnsavedChangesModalProps {
  unsavedChanges: boolean;
  unsavedChangesBlocker: ReturnType<typeof useBlocker>;
}

export function UnsavedChangesModalComponent({
  unsavedChanges,
  unsavedChangesBlocker
}: UnsavedChangesModalProps) {
  useEffect(() => {
    if (!unsavedChanges) {
      unsavedChangesBlocker.proceed?.();
      return;
    }
  }, [unsavedChanges, unsavedChangesBlocker]);

  const handleCancel = useCallback(
    () => unsavedChangesBlocker.reset?.(),
    [unsavedChangesBlocker]
  );
  const handleConfirm = useCallback(
    () => unsavedChangesBlocker.proceed?.(),
    [unsavedChangesBlocker]
  );

  if (!unsavedChanges) {
    return;
  }

  return (
    <ModalComponent
      isOpen={unsavedChangesBlocker.state === 'blocked'}
      onRequestClose={handleCancel}
      contentLabel="Unsaved Changes"
      overlayClassName="z-[102]"
    >
      <h1 className="mb-3 block">Leave without saving?</h1>
      <p className="mb-3">There are unsaved changes on this page.</p>
      <div className="flex gap-3 pt-5">
        <ContainedButton
          className="flex-1"
          color="secondary"
          onClick={handleCancel}
          data-testid="unsaved-changes-modal-back-button"
        >
          Back
        </ContainedButton>

        <ContainedButton
          className="flex-1"
          onClick={handleConfirm}
          data-testid="unsaved-changes-modal-confirm-button"
        >
          Yes - Leave
        </ContainedButton>
      </div>
    </ModalComponent>
  );
}
