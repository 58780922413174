import { setToken, trpc } from '../../libs/trpc';

import {
  CreateFontRequest,
  DeleteFontRequest,
  GetFontRequest,
  ListFontRequest,
  UpdateFontRequest
} from './services/fonts/types';

import {
  CreateGroupRequest,
  DeleteGroupRequest,
  GetGroupRequest,
  ListGroupsRequest,
  UpdateGroupRequest
} from './services/groups/types';

import {
  CreateIconRequest,
  DeleteIconRequest,
  GetIconRequest,
  ListIconsRequest,
  UpdateIconRequest
} from './services/icons/types';

import {
  CreateMaterialRequest,
  UpdateMaterialRequest,
  DeleteMaterialRequest,
  GetMaterialRequest,
  ListMaterialsRequest
} from './services/materials/types';

import {
  CreatePaletteRequest,
  DeletePaletteRequest,
  GetPaletteRequest,
  ListPalettesByHexCodeRequest,
  ListPalettesRequest,
  UpdatePaletteRequest,
  UsesColorSlotPalettesRequest
} from './services/palettes/types';

import {
  CreateTextFieldRequest,
  GetTextFieldRequest,
  ListTextFieldsRequest,
  UpdateTextFieldRequest
} from './services/textFields/types';

import {
  CreateStyleRequest,
  GetStyleRequest,
  ListStylesRequest,
  UpdateStyleRequest
} from './services/styles/types';

import {
  RequestBody,
  TRPCMethodEnum,
  TRPCResourceEnum,
  TRPCRequestProps
} from './types';

import {
  CreateLabelRequest,
  CreateLabelVariantRequest,
  DeleteLabelRequest,
  DeleteLabelVariantRequest,
  GetLabelRequest,
  GetLabelVariantRequest,
  GetVariantsByLabelIdRequest,
  ListLabelsRequest,
  UpdateLabelRequest,
  UpdateLabelVariantRequest
} from './services/labels/types';
import {
  CreateProductRequest,
  DeleteProductRequest,
  GetProductRequest,
  ListProductsRequest,
  UpdateProductRequest,
  UsesLabelAndMaterialProductsRequest
} from './services/products/types';
import { GenerateReportRequest } from './services/reports/types';

import {
  CreateColorMappingRequest,
  DeleteColorMappingRequest,
  GetColorMappingRequest,
  ListByHexCodesRequest,
  ListColorMappingsRequest,
  UpdateColorMappingRequest
} from './services/colorMappings/types';
import { GetOrderRequest, ListOrderRequest } from './services/orders/types';
import { GetCartItemRequest } from '../../../../admin-api/src/features/cartItems/router/validation';
import {
  GeneratePdfByCartItemId,
  GeneratePdfByOrderId
} from './services/pdfs/types';
import { CartItemUpdateData } from '../../../../admin-api/src/features/cartItems/types';

export class TRPCRequest {
  private readonly method: TRPCMethodEnum;
  private readonly resourceType: TRPCResourceEnum;
  private readonly requestBody: RequestBody;
  private readonly token: string | null;

  constructor({ method, resourceType, requestBody, token }: TRPCRequestProps) {
    this.method = method;
    this.resourceType = resourceType;
    this.requestBody = requestBody;
    this.token = token;
  }

  async executeFontsRequest() {
    let response;
    switch (this.method) {
      case TRPCMethodEnum.create:
        response = trpc.fonts.create.mutate(
          this.requestBody as CreateFontRequest
        );
        break;
      case TRPCMethodEnum.get:
        response = trpc.fonts.get.query(this.requestBody as GetFontRequest);
        break;
      case TRPCMethodEnum.list:
        response = trpc.fonts.list.query(this.requestBody as ListFontRequest);
        break;
      case TRPCMethodEnum.update:
        response = trpc.fonts.update.mutate(
          this.requestBody as UpdateFontRequest
        );
        break;
      case TRPCMethodEnum.delete:
        response = trpc.fonts.delete.mutate(
          this.requestBody as DeleteFontRequest
        );
        break;
      default:
        throw new Error(`Fonts method ${this.method} not implemented.`);
    }
    return response;
  }

  async executeIconsRequest() {
    let response;
    switch (this.method) {
      case TRPCMethodEnum.create:
        response = trpc.icons.create.mutate(
          this.requestBody as CreateIconRequest
        );
        break;
      case TRPCMethodEnum.update:
        response = trpc.icons.update.mutate(
          this.requestBody as UpdateIconRequest
        );
        break;
      case TRPCMethodEnum.list:
        response = trpc.icons.list.query(this.requestBody as ListIconsRequest);
        break;
      case TRPCMethodEnum.get:
        response = trpc.icons.get.query(this.requestBody as GetIconRequest);
        break;
      case TRPCMethodEnum.delete:
        response = trpc.icons.delete.mutate(
          this.requestBody as DeleteIconRequest
        );
        break;
      default:
        throw new Error(`Icon method ${this.method} not implemented.`);
    }

    return response;
  }

  async executePalettesRequest() {
    let response;
    switch (this.method) {
      case TRPCMethodEnum.create:
        response = trpc.palettes.create.mutate(
          this.requestBody as CreatePaletteRequest
        );
        break;
      case TRPCMethodEnum.update:
        response = trpc.palettes.update.mutate(
          this.requestBody as UpdatePaletteRequest
        );
        break;
      case TRPCMethodEnum.list:
        response = trpc.palettes.list.query(
          this.requestBody as ListPalettesRequest
        );
        break;
      case TRPCMethodEnum.get:
        response = trpc.palettes.get.query(
          this.requestBody as GetPaletteRequest
        );
        break;
      case TRPCMethodEnum.delete:
        response = trpc.palettes.delete.mutate(
          this.requestBody as DeletePaletteRequest
        );
        break;
      case TRPCMethodEnum.blank:
        response = trpc.palettes.blank.query();
        break;
      case TRPCMethodEnum.usingColorSlot:
        response = trpc.palettes.usingColorSlot.query(
          this.requestBody as UsesColorSlotPalettesRequest
        );
        break;
      case TRPCMethodEnum.listByHexCode:
        response = trpc.palettes.listByHexCode.query(
          this.requestBody as ListPalettesByHexCodeRequest
        );
        break;
      default:
        throw new Error(`Palette method ${this.method} not implemented.`);
    }
    return response;
  }

  async executeMaterialsRequest() {
    let response;
    switch (this.method) {
      case TRPCMethodEnum.create:
        response = trpc.materials.create.mutate(
          this.requestBody as CreateMaterialRequest
        );
        break;
      case TRPCMethodEnum.update:
        response = trpc.materials.update.mutate(
          this.requestBody as UpdateMaterialRequest
        );
        break;
      case TRPCMethodEnum.list:
        response = trpc.materials.list.query(
          this.requestBody as ListMaterialsRequest
        );
        break;
      case TRPCMethodEnum.get:
        response = trpc.materials.get.query(
          this.requestBody as GetMaterialRequest
        );
        break;
      case TRPCMethodEnum.delete:
        response = trpc.materials.delete.mutate(
          this.requestBody as DeleteMaterialRequest
        );
        break;
      default:
        throw new Error(`Material method ${this.method} not implemented.`);
    }

    return response;
  }

  async executeGroupsRequest() {
    let response;
    switch (this.method) {
      case TRPCMethodEnum.create:
        response = trpc.groups.create.mutate(
          this.requestBody as CreateGroupRequest
        );
        break;
      case TRPCMethodEnum.update:
        response = trpc.groups.update.mutate(
          this.requestBody as UpdateGroupRequest
        );
        break;
      case TRPCMethodEnum.list:
        response = trpc.groups.list.query(
          this.requestBody as ListGroupsRequest
        );
        break;
      case TRPCMethodEnum.get:
        response = trpc.groups.get.query(this.requestBody as GetGroupRequest);
        break;
      case TRPCMethodEnum.delete:
        response = trpc.groups.delete.mutate(
          this.requestBody as DeleteGroupRequest
        );
        break;
      default:
        throw new Error(`Group method ${this.method} not implemented.`);
    }

    return response;
  }

  async executeTextFieldsRequest() {
    let response;
    switch (this.method) {
      case TRPCMethodEnum.create:
        response = trpc.textFields.create.mutate(
          this.requestBody as CreateTextFieldRequest
        );
        break;
      case TRPCMethodEnum.update:
        response = trpc.textFields.update.mutate(
          this.requestBody as UpdateTextFieldRequest
        );
        break;
      case TRPCMethodEnum.list:
        response = trpc.textFields.list.query(
          this.requestBody as ListTextFieldsRequest
        );
        break;
      case TRPCMethodEnum.get:
        response = trpc.textFields.get.query(
          this.requestBody as GetTextFieldRequest
        );
        break;
      case TRPCMethodEnum.delete:
        response = trpc.textFields.delete.mutate(
          this.requestBody as GetTextFieldRequest
        );
        break;
      default:
        throw new Error(`Text field method ${this.method} not implemented.`);
    }
    return response;
  }

  async executeStylesRequest() {
    let response;
    switch (this.method) {
      case TRPCMethodEnum.create:
        response = trpc.styles.create.mutate(
          this.requestBody as CreateStyleRequest
        );
        break;
      case TRPCMethodEnum.update:
        response = trpc.styles.update.mutate(
          this.requestBody as UpdateStyleRequest
        );
        break;
      case TRPCMethodEnum.list:
        response = trpc.styles.list.query(
          this.requestBody as ListStylesRequest
        );
        break;
      case TRPCMethodEnum.get:
        response = trpc.styles.get.query(this.requestBody as GetStyleRequest);
        break;
      case TRPCMethodEnum.delete:
        response = trpc.styles.delete.mutate(
          this.requestBody as GetStyleRequest
        );
        break;
      default:
        throw new Error(`Style method ${this.method} not implemented.`);
    }

    return response;
  }

  async executeLabelsRequest() {
    let response;
    switch (this.method) {
      case TRPCMethodEnum.get:
        response = trpc.labels.get.query(this.requestBody as GetLabelRequest);
        break;
      case TRPCMethodEnum.list:
        response = trpc.labels.list.query(
          this.requestBody as ListLabelsRequest
        );
        break;
      case TRPCMethodEnum.create:
        response = trpc.labels.create.mutate(
          this.requestBody as CreateLabelRequest
        );
        break;
      case TRPCMethodEnum.update:
        response = trpc.labels.update.mutate(
          this.requestBody as UpdateLabelRequest
        );
        break;
      case TRPCMethodEnum.delete:
        response = trpc.labels.delete.mutate(
          this.requestBody as DeleteLabelRequest
        );
        break;
      default:
        throw new Error(`Label method ${this.method} not implemented.`);
    }
    return response;
  }

  async executeProductsRequest() {
    let response;
    switch (this.method) {
      case TRPCMethodEnum.get:
        response = trpc.products.get.query(
          this.requestBody as GetProductRequest
        );
        break;
      case TRPCMethodEnum.list:
        response = trpc.products.list.query(
          this.requestBody as ListProductsRequest
        );
        break;
      case TRPCMethodEnum.create:
        response = trpc.products.create.mutate(
          this.requestBody as CreateProductRequest
        );
        break;
      case TRPCMethodEnum.update:
        response = trpc.products.update.mutate(
          this.requestBody as UpdateProductRequest
        );
        break;
      case TRPCMethodEnum.delete:
        response = trpc.products.delete.mutate(
          this.requestBody as DeleteProductRequest
        );
        break;
      case TRPCMethodEnum.usingLabelAndMaterial:
        response = trpc.products.usingLabelAndMaterial.query(
          this.requestBody as UsesLabelAndMaterialProductsRequest
        );
        break;
      default:
        throw new Error(`Product method ${this.method} not implemented.`);
    }
    return response;
  }

  async executeLabelVariantsRequest() {
    let response;
    switch (this.method) {
      case TRPCMethodEnum.get:
        response = trpc.labelVariants.get.query(
          this.requestBody as GetLabelVariantRequest
        );
        break;
      case TRPCMethodEnum.list:
        response = trpc.labelVariants.listByLabelId.query(
          this.requestBody as GetVariantsByLabelIdRequest
        );
        break;
      case TRPCMethodEnum.create:
        response = trpc.labelVariants.create.mutate(
          this.requestBody as CreateLabelVariantRequest
        );
        break;
      case TRPCMethodEnum.update:
        response = trpc.labelVariants.update.mutate(
          this.requestBody as UpdateLabelVariantRequest
        );
        break;
      case TRPCMethodEnum.delete:
        response = trpc.labelVariants.delete.mutate(
          this.requestBody as DeleteLabelVariantRequest
        );
        break;
      default:
        throw new Error(`Label variant method ${this.method} not implemented.`);
    }
    return response;
  }

  async executeReportsRequest() {
    let response;
    switch (this.method) {
      case TRPCMethodEnum.generateReport:
        response = trpc.reports.generateReport.query(
          this.requestBody as GenerateReportRequest
        );
        break;
      case TRPCMethodEnum.generateReportNoFinancials:
        response = trpc.reports.generateReportNoFinancials.query(
          this.requestBody as GenerateReportRequest
        );
        break;
      default:
        throw new Error(`Report method ${this.method} not implemented.`);
    }
    return response;
  }

  async executeColorMappingsRequest() {
    let response;
    switch (this.method) {
      case TRPCMethodEnum.create:
        response = trpc.colorMappings.create.mutate(
          this.requestBody as CreateColorMappingRequest
        );
        break;
      case TRPCMethodEnum.update:
        response = trpc.colorMappings.update.mutate(
          this.requestBody as UpdateColorMappingRequest
        );
        break;
      case TRPCMethodEnum.list:
        response = trpc.colorMappings.list.query(
          this.requestBody as ListColorMappingsRequest
        );
        break;
      case TRPCMethodEnum.get:
        response = trpc.colorMappings.get.query(
          this.requestBody as GetColorMappingRequest
        );
        break;
      case TRPCMethodEnum.listByHexCodes:
        response = trpc.colorMappings.listByHexCodes.query(
          this.requestBody as ListByHexCodesRequest
        );
        break;
      case TRPCMethodEnum.delete:
        response = trpc.colorMappings.delete.mutate(
          this.requestBody as DeleteColorMappingRequest
        );
        break;
      default:
        throw new Error(`Color mapping method ${this.method} not implemented.`);
    }
    return response;
  }

  async executeOrdersRequest() {
    let response;

    switch (this.method) {
      case TRPCMethodEnum.get:
        response = trpc.orders.getByShopifyOrderName.query(
          this.requestBody as GetOrderRequest
        );
        break;
      case TRPCMethodEnum.list:
        response = trpc.orders.list.query(this.requestBody as ListOrderRequest);
        break;
      default:
        throw new Error(`Order method ${this.method} not implemented.`);
    }

    return response;
  }

  async executeCartItemsRequest() {
    let response;

    switch (this.method) {
      case TRPCMethodEnum.get:
        response = trpc.cartItems.getByCartItemId.query(
          this.requestBody as GetCartItemRequest
        );
        break;
      case TRPCMethodEnum.update:
        response = trpc.cartItems.updateCartItem.mutate(
          this.requestBody as CartItemUpdateData
        );
        break;
      default:
        throw new Error(`Cart items method ${this.method} not implemented.`);
    }

    return response;
  }

  async executePdfsRequest() {
    let response;

    switch (this.method) {
      case TRPCMethodEnum.generatePdfsByCartItemId:
        response = trpc.pdfs.generateByCartItemId.query(
          this.requestBody as GeneratePdfByCartItemId
        );
        break;
      case TRPCMethodEnum.generatePdfsByOrderId:
        response = trpc.pdfs.generateByOrderId.query(
          this.requestBody as GeneratePdfByOrderId
        );
        break;
      default:
        throw new Error(`Pdfs method ${this.method} not implemented.`);
    }

    return response;
  }

  async execute() {
    try {
      if (!this.token) {
        throw new Error('No token');
      }

      setToken(this.token);

      let response;
      switch (this.resourceType) {
        case TRPCResourceEnum.icons:
          response = await this.executeIconsRequest();
          break;
        case TRPCResourceEnum.palettes:
          response = await this.executePalettesRequest();
          break;
        case TRPCResourceEnum.materials:
          response = await this.executeMaterialsRequest();
          break;
        case TRPCResourceEnum.textFields:
          response = await this.executeTextFieldsRequest();
          break;
        case TRPCResourceEnum.groups:
          response = await this.executeGroupsRequest();
          break;
        case TRPCResourceEnum.fonts:
          response = await this.executeFontsRequest();
          break;
        case TRPCResourceEnum.styles:
          response = await this.executeStylesRequest();
          break;
        case TRPCResourceEnum.labels:
          response = await this.executeLabelsRequest();
          break;
        case TRPCResourceEnum.labelVariants:
          response = await this.executeLabelVariantsRequest();
          break;
        case TRPCResourceEnum.products:
          response = await this.executeProductsRequest();
          break;
        case TRPCResourceEnum.reports:
          response = await this.executeReportsRequest();
          break;
        case TRPCResourceEnum.colorMappings:
          response = await this.executeColorMappingsRequest();
          break;
        case TRPCResourceEnum.orders:
          response = await this.executeOrdersRequest();
          break;
        case TRPCResourceEnum.cartItems:
          response = await this.executeCartItemsRequest();
          break;
        case TRPCResourceEnum.pdfs:
          response = await this.executePdfsRequest();
          break;
        default:
          throw new Error(
            `Resource type ${this.resourceType} not implemented.`
          );
      }

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
