import clsx from 'clsx';

interface TableCellProps {
  columnKey: string;
  columnClassNames?: string[];
  widthClassNames?: string[];
  Content: JSX.Element;
  onCellClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export function TableCell({
  columnKey,
  Content,
  columnClassNames,
  widthClassNames,
  onCellClick = () => () => {}
}: TableCellProps) {
  return (
    <div
      key={columnKey}
      className={clsx(columnClassNames, widthClassNames)}
      onClick={onCellClick}
    >
      {Content}
    </div>
  );
}
