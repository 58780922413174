import type { TableRecord, TableColumn } from '../../../types/record-table';

import { TableRow } from './TableRow';

interface TableBodyProps {
  idKey: string;
  columns: TableColumn[];
  data: TableRecord[];
  rowClassNames?: string[];
  onRowClick?: (
    record: TableRecord
  ) => (event: React.MouseEvent<HTMLElement>) => void;
  calculateRowHref?: (record: TableRecord) => string;
}

export function TableBody({
  idKey,
  rowClassNames,
  columns,
  data,
  onRowClick = () => () => {},
  calculateRowHref = () => ''
}: TableBodyProps) {
  return (
    <>
      {data.map(record => {
        const rowKey = record[idKey] as string;
        const handleRowClick = onRowClick(record);
        const href = calculateRowHref(record);
        return (
          <TableRow
            key={rowKey}
            rowKey={rowKey}
            record={record}
            columns={columns}
            rowClassNames={rowClassNames}
            onRowClick={handleRowClick}
            href={href}
          />
        );
      })}
    </>
  );
}
