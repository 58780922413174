import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { SearchBar } from '../../../components/search-bar';
import { RecordTable } from '../../../components/tables';
import { ListThumbnail } from '../../../components/images';
import {
  DuplicateAssetButton,
  DeleteAssetButton
} from '../../../components/buttons';
import {
  CannotDeleteModalComponent,
  ConfirmDeleteModalComponent,
  LoadingModalComponent
} from '../../../components/modals';
import type { TableRecord } from '../../../types/record-table';
import { useTRPCRequest, useLocalStorage } from '../../../hooks';
import {
  EntityDependencies,
  TRPCMethodEnum,
  TRPCResourceEnum
} from '../../../api/trpcApi/types';

import { Palette } from '../types';
import { toast } from 'react-toastify';
import { Title } from '../../../components/Title';

export function PalettesList() {
  const navigate = useNavigate();

  const { handleTRPCRequest } = useTRPCRequest();

  const [palettes, setPalettes] = useState<Palette[]>([]);
  const [searchTerm, setSearchTerm] = useLocalStorage<string>(
    window.location.pathname,
    ''
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);
  const [dependencies, setDependencies] = useState<EntityDependencies>({});
  const [paletteToDelete, setPaletteToDelete] = useState<Palette>({
    palette_id: '',
    system_name: '',
    display_name: '',
    thumbnail_path: ''
  });

  const fetchPalettes = useCallback(
    async (search?: string) => {
      setIsLoading(true);
      const trpcRequest = {
        method: TRPCMethodEnum.list,
        resourceType: TRPCResourceEnum.palettes,
        requestBody: {}
      };
      if (search) {
        trpcRequest.requestBody = { keyword: search };
      }

      const { res: palettes, error } = await handleTRPCRequest(trpcRequest);

      setIsLoading(false);

      if (error) {
        navigate('/404');
        return;
      }

      setPalettes(palettes);
    },
    [handleTRPCRequest, navigate]
  );

  const handleDeletePalette = useCallback(
    (palette: TableRecord) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setPaletteToDelete(palette as Palette);
      setConfirmDelete(true);
    },
    []
  );

  const handleConfirmedDeletePalette = useCallback(async () => {
    setConfirmDelete(false);
    setIsDeleting(true);
    const { res, error } = await handleTRPCRequest({
      method: TRPCMethodEnum.delete,
      resourceType: TRPCResourceEnum.palettes,
      requestBody: { palette_id: paletteToDelete.palette_id }
    });
    setIsDeleting(false);

    if (!error && res.success) {
      toast.success('Palette deleted successfully!');
      const filteredPalettes = palettes.filter(
        (palette: Palette) => palette.palette_id !== paletteToDelete.palette_id
      );
      setPalettes(filteredPalettes);
    }

    if (!res?.success) {
      setShowCannotDeleteModal(true);
      setDependencies(res.relatedEntities);
    }
  }, [palettes, paletteToDelete, handleTRPCRequest]);

  useEffect(() => {
    fetchPalettes(searchTerm ?? undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleAddNew = useCallback(() => {
    navigate('/palettes/new');
  }, [navigate]);

  const handleDuplicatePalette = useCallback(
    (palette: TableRecord) => async (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      navigate(`/palettes/new`, {
        state: { paletteIdToCopy: palette.palette_id }
      });
    },
    [navigate]
  );

  if (isDeleting) {
    return <LoadingModalComponent isOpen={isDeleting} message="Deleting..." />;
  }

  if (isLoading) {
    return <LoadingModalComponent isOpen={isLoading} />;
  }

  const noResults = palettes.length === 0;
  const noSearch = searchTerm === '';

  return (
    <>
      <Title title="Palettes Search" />
      <SearchBar
        searchTerm={searchTerm}
        onSearch={search => setSearchTerm(search)}
        onAddNew={handleAddNew}
        classNames={['pb-2']}
      />

      {noResults && noSearch ? (
        <>
          <h2>No palettes exist yet.</h2>
          <p> Click "+ Add New" to create your first palette!</p>
        </>
      ) : noResults ? (
        <h2>No palettes found for "{searchTerm}".</h2>
      ) : (
        <RecordTable
          data={palettes}
          idKey="palette_id"
          rowClassNames={[
            'card',
            'mb-[6px]',
            'flex',
            'h-[54px]',
            'cursor-pointer',
            'items-center',
            'align-middle'
          ]}
          calculateRowHref={palette => `/palettes/${palette.palette_id}`}
          columns={[
            {
              columnKey: 'thumbnail_path',
              onRender: (palette: TableRecord) => (
                <ListThumbnail src={palette.thumbnail_path} />
              ),
              widthClassNames: ['w-[54px]']
            },
            {
              columnKey: 'system_name',
              header: 'System Name',
              onRender: (palette: TableRecord) => palette.system_name,
              widthClassNames: ['w-[32%]', 'ml-[30px]']
            },
            {
              columnKey: 'display_name',
              header: 'Display Name',
              onRender: (palette: TableRecord) => palette.display_name
            },
            {
              columnKey: 'actions',
              widthClassNames: ['ml-auto'],
              onRender: (palette: TableRecord) => {
                return (
                  <div className="flex gap-3 pr-3">
                    <DuplicateAssetButton
                      onDuplicateAsset={handleDuplicatePalette(palette)}
                    />
                    <DeleteAssetButton
                      onDeleteAsset={handleDeletePalette(palette)}
                    />
                  </div>
                );
              }
            }
          ]}
        />
      )}
      <ConfirmDeleteModalComponent
        isOpen={confirmDelete}
        entityName={paletteToDelete.system_name}
        onConfirm={handleConfirmedDeletePalette}
        onCancel={() => setConfirmDelete(false)}
      />
      <CannotDeleteModalComponent
        entityName={paletteToDelete?.system_name ?? ''}
        dependencies={dependencies}
        isOpen={showCannotDeleteModal}
        onCancel={() => setShowCannotDeleteModal(false)}
      />
    </>
  );
}
