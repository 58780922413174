import { useCallback, useState } from 'react';
import { useTRPCRequest } from '../../../hooks';
import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';
import { SearchBar } from '../../../components/search-bar';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { StyleEntity, StyleEntityCandidates, StyleTypeEnum } from '../types';
import { ContainedButton, IconButton } from '../../../components/buttons';
import { CheckmarkIcon, PlusIcon } from '../../../components/icons';
import { LoadingDiv } from '../../../components/loading';
import clsx from 'clsx';
import { RegistrationCandidates } from '../../groups/types';

interface SearchStyleEntitiesProps {
  type: StyleTypeEnum;
  styleEntities: StyleEntityCandidates;
  onUpdateStyleEntity(
    updatedStyleEntity: StyleEntityCandidates,
    type: StyleTypeEnum
  ): void;
}

export const SearchStyleEntitiesComponent = ({
  type,
  styleEntities,
  onUpdateStyleEntity
}: SearchStyleEntitiesProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { handleTRPCRequest } = useTRPCRequest();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [entityCandidates, setEntityCandidates] =
    useState<RegistrationCandidates>({});

  const fetchCandidates = useCallback(
    async (search?: string) => {
      setIsLoading(true);
      const { res, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.list,
        resourceType: TRPCResourceEnum[type],
        requestBody: {
          keyword: search,
          getGroups: true
        }
      });

      if (res && !error) {
        const candidateState = res.reduce(
          (
            candidates: RegistrationCandidates,
            result: {
              registered_entity_id: string;
              system_name: string;
              is_group: boolean;
              selected: boolean;
            }
          ) => {
            const candidateId = result.registered_entity_id;

            if (candidateId) {
              const currentCandidateState = entityCandidates[candidateId] || {};

              candidates[candidateId] = {
                registered_entity_id: result.registered_entity_id,
                system_name: result.system_name,
                selected: Boolean(
                  styleEntities[candidateId] || currentCandidateState?.selected
                ),
                is_group: result.is_group
              };
              return candidates;
            }
          },
          {} as RegistrationCandidates
        );
        setEntityCandidates(candidateState);
        setIsLoading(false);
      }
      setIsLoading(false);
    },
    [entityCandidates, handleTRPCRequest, styleEntities, type]
  );

  const handleToggleEntity = useCallback(
    (candidateId: string) => () => {
      setIsEditing(true);
      const currentCandidateState = entityCandidates[candidateId];
      setEntityCandidates({
        ...entityCandidates,
        [candidateId]: {
          ...currentCandidateState,
          selected: !currentCandidateState.selected
        }
      });
    },
    [entityCandidates]
  );

  const handleUpdateEntities = useCallback(() => {
    const updatedStyleEntities = Object.values(styleEntities).reduce(
      (newStyleEntities: StyleEntityCandidates, styleEntity: StyleEntity) => {
        const entityId: string = styleEntity.registered_entity_id;
        const incomingCandidate = entityCandidates[entityId];
        if (
          !incomingCandidate ||
          (incomingCandidate && incomingCandidate.selected)
        ) {
          newStyleEntities[entityId] = {
            registered_entity_id: entityId,
            system_name: styleEntity.system_name,
            is_group: styleEntity.is_group
          };
        }

        return newStyleEntities;
      },
      {} as StyleEntityCandidates
    );

    Object.keys(entityCandidates).forEach((candidateId: string) => {
      const candidate = entityCandidates[candidateId];
      if (candidate.selected && !updatedStyleEntities[candidateId]) {
        updatedStyleEntities[candidateId] = {
          registered_entity_id: candidateId,
          system_name: candidate.system_name,
          is_group: candidate.is_group
        };
      }
    });

    onUpdateStyleEntity(updatedStyleEntities, type);
    setEntityCandidates({});
  }, [styleEntities, entityCandidates, onUpdateStyleEntity, type]);

  // used to close the search dropdown when the user clicks away
  const ref = useOutsideClick(() => setEntityCandidates({}));

  const entityCandidateIds = Object.keys(entityCandidates);

  return (
    <div ref={ref} className="relative">
      <SearchBar
        classNames={['mb-5']}
        searchTerm={''}
        onSearch={search => fetchCandidates(search)}
        placeHolder={type.charAt(0).toUpperCase() + type.slice(1)}
        searchOnChange={true}
      />

      {entityCandidateIds.length || isLoading ? (
        <div className="absolute flex w-full flex-col rounded bg-white shadow">
          <div className="flex max-h-60 flex-col overflow-y-scroll">
            {' '}
            {entityCandidateIds.length ? (
              entityCandidateIds.map(candidateId => {
                const candidate = entityCandidates[candidateId];
                return (
                  <div
                    className={clsx(
                      'align-center flex cursor-pointer flex-row justify-between p-2 shadow',
                      {
                        'bg-light-blue': candidate.selected
                      }
                    )}
                    onClick={handleToggleEntity(candidateId)}
                  >
                    <div>
                      {candidate.is_group
                        ? `${candidate.system_name} (Group)`
                        : candidate.system_name}
                    </div>
                    <IconButton
                      className="text-navy text-[24px]"
                      icon={
                        candidate.selected ? <CheckmarkIcon /> : <PlusIcon />
                      }
                      data-testid="search-result-button"
                    />
                  </div>
                );
              })
            ) : (
              <div className="m-2">
                <LoadingDiv />
              </div>
            )}
          </div>

          <div className="flex-row justify-center">
            <ContainedButton
              color="secondary"
              onClick={() => setEntityCandidates({})}
              className="m-5"
            >
              Cancel
            </ContainedButton>
            {isEditing && (
              <ContainedButton className="m-5" onClick={handleUpdateEntities}>
                Save
              </ContainedButton>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};
