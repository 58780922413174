import { useCallback } from 'react';

export const useRawImageData = () => {
  const getRawImageData = useCallback(async (url: string) => {
    try {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      const base64String = arrayBufferToBase64(arrayBuffer);
      return `data:${response.headers.get(
        'content-type'
      )};base64,${base64String}`;
    } catch (error) {
      console.error('Error fetching image data:', error);
      return undefined;
    }
  }, []);

  // Function to convert ArrayBuffer to Base64 string
  function arrayBufferToBase64(buffer: ArrayBuffer): string {
    const uint8Array = new Uint8Array(buffer);
    const binaryString = uint8Array.reduce(
      (dataString, byte) => dataString + String.fromCharCode(byte),
      ''
    );
    return btoa(binaryString);
  }

  return { getRawImageData };
};
