import { ContainedButton } from '../../buttons';

import { ModalComponent } from '../ModalComponent';

interface ConfirmDeleteModalProps {
  isOpen: boolean;
  entityName: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export function ConfirmDeleteModalComponent({
  isOpen,
  entityName,
  onConfirm,
  onCancel
}: ConfirmDeleteModalProps) {
  return (
    <ModalComponent
      isOpen={isOpen}
      onRequestClose={onCancel}
      contentLabel="Confirm Delete"
    >
      <h1 className="mb-3 block overflow-hidden text-ellipsis">
        Are you sure you want to delete
        <br />
        {entityName}?
      </h1>
      <div className="flex gap-3 pt-5">
        <ContainedButton
          className="flex-1"
          color="secondary"
          onClick={onCancel}
          data-testid="delete-modal-back-button"
        >
          Back
        </ContainedButton>
        <ContainedButton
          color="danger"
          onClick={onConfirm}
          className="flex-1"
          data-testid="delete-modal-confirm-button"
        >
          Yes - DELETE
        </ContainedButton>
      </div>
    </ModalComponent>
  );
}
