import { useEffect, useState } from 'react';
import { ContainedButton } from '..';
import { SizeEnum } from '../../../types';

interface SaveButtonProps {
  text?: string;
  secondary?: boolean;
  disabled?: boolean;
  isSaving: boolean;
  onSave: () => void;
}

export function SaveButtonComponent({
  text: initialText = 'Save',
  secondary = false,
  isSaving,
  disabled,
  onSave
}: SaveButtonProps) {
  const [text, setText] = useState<string>(initialText);

  useEffect(() => {
    setText(isSaving ? 'Saving...' : initialText);
  }, [initialText, isSaving, setText]);

  return (
    <ContainedButton
      disabled={disabled || isSaving}
      color={secondary ? 'secondary' : 'primary'}
      className="flex-1"
      onClick={onSave}
      size={SizeEnum.large}
      data-testid="save-button"
    >
      <span>{text}</span>
    </ContainedButton>
  );
}
