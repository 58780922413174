import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

interface AssociatedEntityComponentProps {
  className?: string;
  system_name: string;
  link?: string;
}
export function AssociatedEntityComponent({
  className,
  system_name,
  link
}: AssociatedEntityComponentProps) {
  const baseClassList = [
    'bg-white',
    'border-[1px]',
    'border-navy',
    'font-size-[15px]',
    'font-weight-[600]',
    'px-[16px]',
    'text-navy',
    'rounded-md',
    'm-2'
  ];

  const navigate = useNavigate();

  return (
    <button
      className={clsx(baseClassList, className)}
      onClick={() => link && navigate(link)}
    >
      {system_name}
    </button>
  );
}
