import { Icon, IconLayer } from '../types';
import { OptionProps } from '../../../components/inputs/Select';

export const duplicateIcon = (icon: Icon, iconLayers: IconLayer[]) => {
  const { display_name, system_name } = icon;

  const iconLayersToCopy = iconLayers.map(iconLayer => {
    const { print_key, svgText, index } = iconLayer;

    return {
      print_key,
      svgText,
      index
    };
  });

  return {
    display_name,
    system_name: `${system_name} - Copy`,
    iconLayers: iconLayersToCopy
  };
};

export const getDefaultColorSlotOptions = (
  numberOfSlots: number
): OptionProps[] => {
  const options: OptionProps[] = [
    {
      key: 'black',
      value: 'black',
      label: `Black`
    },
    {
      key: 'white',
      value: 'white',
      label: `White`
    }
  ];

  for (let i = 0; i < numberOfSlots; i++) {
    options.push({
      key: `${i}`,
      value: `color${i + 1}`,
      label: `Color ${i + 1}`
    } as OptionProps);
  }

  return options;
};

export const formatIconRequestBody = (
  create: boolean,
  icon: Icon,
  iconLayers: IconLayer[]
) => {
  const iconToValidate = {
    ...icon,
    icon_layers: iconLayers.map(iconLayer => {
      const { icon_id, icon_layer_id, print_key, svgText } = iconLayer;

      if (create) {
        return {
          print_key,
          svgText
        };
      }

      return {
        icon_id,
        icon_layer_id,
        print_key,
        svgText
      };
    })
  };

  return iconToValidate;
};
