import clsx from 'clsx';
import { StyleEntityCandidates, StyleTypeEnum } from '../types';
import { SearchStyleEntitiesComponent } from './SearchStyleEntitiesComponent';
import { StyeEntitiesChipsComponent } from './StyleEntitiesChipsComponent';

interface ManageStyleEntitiesProps {
  error: boolean;
  type: StyleTypeEnum;
  styleEntities: StyleEntityCandidates;
  onUpdateStyleEntities(
    updatedStyleEntity: StyleEntityCandidates,
    type: StyleTypeEnum
  ): void;
}
export function ManageStyleEntitiesComponenet({
  error,
  type,
  styleEntities,
  onUpdateStyleEntities
}: ManageStyleEntitiesProps) {
  return (
    <div
      className={clsx(
        {
          'bg-light-blue': !error,
          'bg-blush': error
        },
        'w-full p-2'
      )}
    >
      <SearchStyleEntitiesComponent
        type={type}
        styleEntities={styleEntities}
        onUpdateStyleEntity={onUpdateStyleEntities}
      />
      <StyeEntitiesChipsComponent
        type={type}
        styleEntityList={styleEntities}
        onRemoveEntityChip={onUpdateStyleEntities}
      />
    </div>
  );
}
