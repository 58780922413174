export interface ColorMapping {
  color_mapping_id?: string;
  system_name: string;
  hex_code: string;
  swatch_name?: string;
  c: number;
  m: number;
  y: number;
  k: number;
}

export const emptyColorMapping: ColorMapping = {
  system_name: '',
  hex_code: '',
  c: 0,
  m: 0,
  y: 0,
  k: 0
};

export interface ColorMappingErrors {
  system_name: boolean;
  hex_code: boolean;
  c: boolean;
  m: boolean;
  y: boolean;
  k: boolean;
}

export const defaultColorMappingErrors: ColorMappingErrors = {
  system_name: false,
  hex_code: false,
  c: false,
  m: false,
  y: false,
  k: false
};

export enum COLOR_MAPPING_FIELDS {
  system_name = 'system_name',
  hex_code = 'hex_code',
  swatch_name = 'swatch_name',
  c = 'c',
  m = 'm',
  y = 'y',
  k = 'k'
}
