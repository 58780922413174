import { useCallback, useEffect, useState } from 'react';
import { TextField } from '../types';
import { useTRPCRequest } from '../../../hooks';
import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';

export function useTextFields(search?: string) {
  const [isLoading, setIsLoading] = useState(true);
  const [textFields, setTextFields] = useState<TextField[]>([]);
  const { handleTRPCRequest } = useTRPCRequest();

  const fetchTextFields = useCallback(
    async (search?: string) => {
      const trpcRequest = {
        method: TRPCMethodEnum.list,
        resourceType: TRPCResourceEnum.textFields,
        requestBody: {}
      };
      if (search) {
        trpcRequest.requestBody = { keyword: search };
      }
      const { res: textFields } = await handleTRPCRequest(trpcRequest);
      setTextFields(textFields);
      setIsLoading(false);
    },
    [handleTRPCRequest]
  );

  useEffect(() => {
    fetchTextFields(search ?? undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  return { textFields, isLoading };
}
