import {
  BackButtonComponent,
  SaveButtonComponent
} from '../../../components/buttons';

interface DetailsFooterProps {
  isSaving: boolean;
  onSave: () => void;
}

export function DetailsFooter({ isSaving, onSave }: DetailsFooterProps) {
  return (
    <div className="">
      <div className="mt-10 flex gap-3">
        <BackButtonComponent destination="/palettes" />
        <SaveButtonComponent isSaving={isSaving} onSave={onSave} />
      </div>
    </div>
  );
}
