import clsx from 'clsx';
import { DeleteIcon } from '../../../../components/icons';

interface DeleteButtonProps {
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}

export function DeleteButton({
  onClick,
  className,
  disabled
}: DeleteButtonProps) {
  return (
    <button
      onClick={onClick}
      className={clsx(
        disabled ? 'text-gray cursor-not-allowed opacity-30' : 'text-error',
        className
      )}
      disabled={disabled}
    >
      <DeleteIcon />
    </button>
  );
}
