import { TextField } from '../types';

export function duplicateTextField(textField: TextField): TextField {
  const newTextField: TextField = {
    text_field_id: textField.text_field_id,
    display_name: textField.display_name,
    system_name: textField.system_name + ' - Copy',
    default_font: textField.default_font,
    default_length: textField.default_length,
    required_by_default: textField.required_by_default
  };
  return newTextField;
}
