import Joi from 'joi';

const iconFormSchema = Joi.object({
  icon_id: Joi.string().label('Icon Id'),
  system_name: Joi.string().label('System Name').required(),
  display_name: Joi.string().label('Display Name').required(),
  vdpcode: Joi.string()
    .regex(/^[Z0-9]{4}$/)
    .optional()
    .allow(null, '')
    .label('VDP Code')
    .messages({
      'string.pattern.base': `"VDP code" must be four numbers`
    }),
  icon_layers: Joi.array()
    .label('Icon Layers')
    .min(1)
    .items(
      Joi.object({
        icon_id: Joi.string(),
        icon_layer_id: Joi.string(),
        main_file_path: Joi.string(),
        print_key: Joi.string().required(),
        svgText: Joi.string().required(),
        index: Joi.number()
      })
    )
    .required(),
  thumbnail_path: Joi.string()
});

export default {
  iconFormSchema
};
