import clsx from 'clsx';

export interface TooltipProps extends React.HTMLAttributes<HTMLSpanElement> {
  text: string;
  arrow?: boolean;
  disabled?: boolean;
}

export function Tooltip(props: TooltipProps) {
  const { text, arrow = false, disabled = false, className, children } = props;

  const wrapperClasses = ['relative', 'inline-block'];

  const dynamicWrapperClasses = {
    'hover-child:visible': !disabled
  };

  const spanClasses = [
    'absolute',
    'bg-[#616161e6]',
    'body-3',
    'bottom-[125%]',
    'invisible',
    'left-[50%]',
    'px-[10px]',
    'py-[5px]',
    'rounded-[6px]',
    'text-center',
    'text-white',
    'translate-x-[-50%]',
    'whitespace-nowrap',
    'z-9999'
  ];

  const arrowClasses = {
    'after:content-[" "]': arrow,
    'after:absolute': arrow,
    'after:top-[100%]': arrow,
    'after:left-[50%]': arrow,
    'after:ml-[-5px]': arrow,
    'after:border-[5px]': arrow,
    'after:border-solid': arrow,
    'after:border-x-[#00000000]': arrow,
    'after:border-b-[#00000000]': arrow,
    'after:border-t-[#616161e6]': arrow
  };

  return (
    <div className={clsx(wrapperClasses, dynamicWrapperClasses)}>
      {children}
      <span className={clsx(spanClasses, arrowClasses, className)}>{text}</span>
    </div>
  );
}
