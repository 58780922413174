export enum StyleTypeEnum {
  palettes = 'palettes',
  fonts = 'fonts',
  icons = 'icons'
}

export interface Style {
  style_id?: string;
  display_name: string;
  system_name: string;
  thumbnail?: string;
  thumbnail_path?: string;
  vdpcode?: string;
}

export interface StyleErrors {
  display_name: boolean;
  system_name: boolean;
  thumbnail: boolean;
  icons: boolean;
  palettes: boolean;
  fonts: boolean;
  vdpcode: boolean;
}

export enum STYLE_FIELDS {
  display_name = 'display_name',
  system_name = 'system_name',
  thumbnail = 'thumbnail'
}

export interface RegisteredEntities {
  [key: string]: {
    registered_entity_id: string;
    system_name: string;
    is_group: boolean;
    selected: boolean;
  }[];
}

export interface StyleEntity {
  registered_entity_id: string;
  is_group?: boolean;
  system_name: string;
  style_entity_registration_id?: string;
}

export interface StyleEntityCandidates {
  [key: string]: StyleEntity;
}

export interface StyleEntities {
  palettes: StyleEntityCandidates;
  fonts: StyleEntityCandidates;
  icons: StyleEntityCandidates;
}

export type StyleType = Style;
