export function PackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_74_68)">
        <path
          d="M19 15H23V1H9V5M15 19H19V5H5V9M1 23H15V9H1V23Z"
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_74_68">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
