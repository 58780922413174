import { IconButton } from '..';
import { DuplicateIcon } from '../../icons';

interface DuplicateAssetButtonProps {
  onDuplicateAsset: (event: React.MouseEvent<HTMLElement>) => void;
}

export function DuplicateAssetButton({
  onDuplicateAsset: onDuplicateAsset
}: DuplicateAssetButtonProps) {
  return (
    <IconButton
      onClick={onDuplicateAsset}
      className="text-navy"
      data-testid="copy-asset-button"
      icon={<DuplicateIcon />}
    />
  );
}
