import { useCallback, useLayoutEffect, useState } from 'react';
import clsx from 'clsx';

import { ContainedButton } from '../../components/buttons';
import { SearchIcon } from '../../components/icons';
import { TextField } from '../../components/inputs';

interface SearchBarProps {
  title?: string;
  searchTerm: string;
  placeHolder?: string;
  classNames?: string[];
  onAddNew?: () => void;
  onSearch: (search: string) => void;
  searchOnChange?: boolean;
  overrideIcon?: React.ReactElement;
}

export function SearchBar({
  title,
  searchTerm,
  placeHolder,
  classNames = [],
  onAddNew,
  onSearch,
  searchOnChange,
  overrideIcon
}: SearchBarProps) {
  const [value, setValue] = useState<string>(searchTerm);

  useLayoutEffect(() => {
    setValue(searchTerm);
  }, [searchTerm, setValue]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    [setValue]
  );

  const handleSearch = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (searchOnChange || event.key === 'Enter') {
        const search = event.currentTarget.value;
        onSearch(search);
      }
    },
    [onSearch, searchOnChange]
  );
  const defaultClasses = ['flex', 'gap-[20px]'];

  return (
    <div className={clsx(defaultClasses.concat(classNames ?? 'mb-[20px]'))}>
      <div className="flex flex-1 items-center gap-[20px]">
        {title && <h1>{title}</h1>}
        <TextField
          size="small"
          className="flex-1"
          placeholder={placeHolder ? placeHolder : 'Search'}
          contentAfter={
            <div className="cursor-pointer" onClick={() => onSearch(value)}>
              {overrideIcon ? overrideIcon : <SearchIcon />}
            </div>
          }
          value={value}
          onChange={handleChange}
          onKeyUp={handleSearch}
        />
      </div>
      {onAddNew && (
        <div className="flex flex-1 flex-row-reverse">
          <ContainedButton onClick={onAddNew}>+ Add New</ContainedButton>
        </div>
      )}
    </div>
  );
}
