import clsx from 'clsx';

import { TableCell } from './TableCell';

import type { TableRecord, TableColumn } from '../../../types/record-table';

interface TableRowProps {
  rowKey: string;
  record: TableRecord;
  columns: TableColumn[];
  rowClassNames?: string[];
  onRowClick?: (event: React.MouseEvent<HTMLElement>) => void;
  href?: string;
}

export function TableRow({
  rowKey,
  record,
  columns,
  rowClassNames,
  onRowClick = () => () => {},
  href
}: TableRowProps) {
  const Cells = columns.map(column => {
    const {
      columnKey,
      onRender: render,
      columnClassNames,
      widthClassNames,
      onCellClick = () => () => {}
    } = column;

    const Content = render(record);

    const handleColumnClick = onCellClick(record);

    return (
      <TableCell
        key={columnKey}
        columnKey={columnKey}
        Content={Content}
        columnClassNames={columnClassNames}
        widthClassNames={widthClassNames}
        onCellClick={handleColumnClick}
      />
    );
  });

  return (
    <a
      href={href || '#'}
      key={rowKey}
      className={clsx(rowClassNames)}
      onClick={onRowClick}
    >
      {Cells}
    </a>
  );
}
