import { useNavigate } from 'react-router-dom';
import { SearchBar } from '../../../components/search-bar';
import { useState } from 'react';
import { useCallback } from 'react';
import { useTRPCRequest, useLocalStorage } from '../../../hooks';
import { Font } from '../types';
import { RecordTable } from '../../../components/tables';
import { TableRecord } from '../../../types/record-table';
import {
  DeleteAssetButton,
  DuplicateAssetButton
} from '../../palettes/components';
import {
  EntityDependencies,
  TRPCMethodEnum,
  TRPCResourceEnum
} from '../../../api/trpcApi/types';
import {
  CannotDeleteModalComponent,
  ConfirmDeleteModalComponent,
  LoadingModalComponent
} from '../../../components/modals';
import { toast } from 'react-toastify';
import { useFonts } from './hooks';
import { loadFont } from '../components/fonts';
import { Title } from '../../../components/Title';

export function FontsList() {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useLocalStorage<string>(
    window.location.pathname,
    ''
  );
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [fontToDelete, setFontToDelete] = useState<Font>();
  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);
  const [dependencies, setDependencies] = useState<EntityDependencies>({});

  const { fonts, isLoading, refetch } = useFonts(searchTerm);

  const { handleTRPCRequest } = useTRPCRequest();

  const handleAddNew = useCallback(() => {
    navigate('/fonts/new');
  }, [navigate]);

  const handleConfirmDelete = useCallback(
    (font: TableRecord) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setFontToDelete(font as Font);
      setConfirmDelete(true);
    },
    [setFontToDelete, setConfirmDelete]
  );

  const handleDeleteFont = useCallback(async () => {
    setConfirmDelete(false);
    setIsDeleting(true);

    const font_id = fontToDelete?.font_id;

    if (font_id) {
      const { res, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.delete,
        resourceType: TRPCResourceEnum.fonts,
        requestBody: { font_id }
      });

      if (!error && res.success) {
        toast.success('Font deleted successfully!');
        refetch();
      }

      if (!res?.success) {
        setShowCannotDeleteModal(true);
        setDependencies(res.relatedEntities);
      }
    }
    setIsDeleting(false);
  }, [refetch, handleTRPCRequest, fontToDelete]);

  const handleDuplicateFont = useCallback(
    (font: TableRecord) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();

      const newFont = {
        ...font,
        system_name: font.system_name + ' - Copy'
      };
      navigate(`/fonts/new`, { state: { font: newFont } });
    },
    [navigate]
  );

  const noResults = fonts?.length === 0;
  const noSearch = searchTerm === '';

  if (isDeleting) {
    return <LoadingModalComponent isOpen={isDeleting} message="Deleting..." />;
  }

  if (isLoading) {
    return <LoadingModalComponent isOpen={isLoading} />;
  }

  return (
    <>
      <Title title="Fonts Search" />
      <SearchBar
        searchTerm={searchTerm}
        onSearch={search => setSearchTerm(search)}
        onAddNew={handleAddNew}
      />

      {noResults && noSearch ? (
        <>
          <h2>No fonts exist yet.</h2>
          <p> Click "+ Add New" to create your first font!</p>
        </>
      ) : noResults ? (
        <h2>No fonts found for "{searchTerm}".</h2>
      ) : (
        <RecordTable
          data={fonts ?? []}
          idKey="font_id"
          rowClassNames={[
            'card',
            'mb-[6px]',
            'flex',
            'h-[54px]',
            'cursor-pointer',
            'items-center',
            'align-middle'
          ]}
          calculateRowHref={font => `/fonts/${font.font_id}`}
          columns={[
            {
              columnKey: 'preview',
              header: 'Preview',
              onRender: (font: TableRecord) => {
                const family = font.google_family + font.font_id;
                loadFont({
                  family,
                  weight: font.weight,
                  url: font.font_file_path
                });
                return (
                  <span
                    className="text-2xl"
                    style={{
                      fontFamily: family,
                      fontWeight: font.weight
                    }}
                  >
                    The quick brown fox
                  </span>
                );
              },
              widthClassNames: ['w-[40%]', 'ml-[30px]']
            },
            {
              columnKey: 'systemName',
              header: 'System Name',
              onRender: font => font.system_name,
              widthClassNames: [
                'w-[15%]',
                'ml-[30px]',
                'max-h-full',
                'overflow-auto'
              ]
            },
            {
              columnKey: 'displayName',
              header: 'Display Name',
              onRender: font => font.display_name,
              widthClassNames: [
                'w-[15%]',
                'ml-[30px]',
                'max-h-full',
                'overflow-auto'
              ]
            },
            {
              columnKey: 'fontAndWeight',
              header: 'Font & Weight',
              onRender: font => (
                <span>{font.google_family + ' / ' + font.weight}</span>
              )
            },
            {
              columnKey: 'actions',
              widthClassNames: ['ml-auto'],
              onRender: font => (
                <div className="flex gap-3 pr-3">
                  <DuplicateAssetButton
                    onDuplicateAsset={handleDuplicateFont(font)}
                  />
                  <DeleteAssetButton
                    onDeleteAsset={handleConfirmDelete(font)}
                  />
                </div>
              )
            }
          ]}
        />
      )}
      <ConfirmDeleteModalComponent
        isOpen={confirmDelete}
        entityName={fontToDelete?.system_name ?? ''}
        onConfirm={handleDeleteFont}
        onCancel={() => setConfirmDelete(false)}
      />
      <CannotDeleteModalComponent
        entityName={fontToDelete?.system_name ?? ''}
        dependencies={dependencies}
        isOpen={showCannotDeleteModal}
        onCancel={() => setShowCannotDeleteModal(false)}
      />
    </>
  );
}
