import { useState, useEffect } from 'react';
import clsx from 'clsx';

import { createSvgElement } from '../../utils';

type Layer = {
  svgText: string;
};

interface LayeredSVGComponentProps {
  layers: Layer[];
  svgRef?: React.Ref<HTMLDivElement>;
  containerStyle?: React.CSSProperties;
  svgStyle?: React.CSSProperties;
  containerClasses?: string[];
  svgClasses?: string[];
}

export const LayeredSVGComponent = ({
  layers,
  containerStyle = {},
  containerClasses,
  svgStyle,
  svgClasses = [],
  svgRef = null
}: LayeredSVGComponentProps) => {
  const [compositeSvgContent, setCompositeSvgContent] = useState<string>('');
  const [viewBox, setViewBox] = useState<string>('0 0 0 0');

  useEffect(() => {
    const svgNamespace = 'http://www.w3.org/2000/svg';
    const compositeSvg = document.createElementNS(svgNamespace, 'svg');

    let viewBox = null;
    for (const layer of layers) {
      const svgElement = createSvgElement(layer.svgText);

      svgClasses.forEach(svgClass => {
        svgElement.classList.add(svgClass);
      });

      const layerViewBox = svgElement.getAttribute('viewBox');
      viewBox = viewBox ?? layerViewBox;

      compositeSvg.appendChild(svgElement);
    }

    setViewBox(viewBox ?? '0 0 0 0');
    setCompositeSvgContent(compositeSvg.innerHTML);
  }, [layers, svgClasses]);

  const hasLayers = !!layers.length;

  const defaultContainerClasses = [
    'bg-white',
    'flex',
    'h-[250px]',
    'w-[250px]',
    'mt-2',
    'mr-2',
    'mb-2',
    'rounded-full',
    'justify-center',
    'items-center'
  ];

  return (
    hasLayers && (
      <div
        style={containerStyle}
        className={clsx(containerClasses || defaultContainerClasses)}
        ref={svgRef}
      >
        <svg
          className={clsx(svgClasses)}
          style={svgStyle}
          xmlns="http://www.w3.org/2000/svg"
          viewBox={viewBox}
          dangerouslySetInnerHTML={{ __html: compositeSvgContent }}
        />
      </div>
    )
  );
};
