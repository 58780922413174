import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import { ContainedButton } from '../../../../components/buttons';
import { DuplicateIcon } from '../../../../components/icons';
import {
  EditVdpCodeComponent,
  Select,
  TextField,
  NumberInput
} from '../../../../components/inputs';
import { useTRPCRequest } from '../../../../hooks';
import { prepThumbnailForSave } from '../../../../utils/createSvgElement';
import {
  TRPCMethodEnum,
  TRPCResourceEnum
} from '../../../../api/trpcApi/types';
import {
  ErrorValue,
  validateSchemaAndNotifyErrorsRecursive
} from '../../../../utils/validationUtils';
import { LoadingDiv } from '../../../../components/loading';

import { Style } from '../../../styles/types';

import { LabelUpdater, LabelVariantState } from '../../types/state';
import {
  variantStateToVariant,
  variantToVariantState
} from '../../types/convert';
import { upsertLabelVariantSchema } from '../../validations';

import { LabelVariantElementsEditor } from './LabelVariantElementsEditor';
import { DeleteButton } from './DeleteButton';
import { clearUnused, useUpdater } from './state/update';
import {
  PRINT_DIMENSIONS,
  checkVariantSize,
  cleanVariant
} from './variantUtils';
import { LabelVariantPreview } from './preview/LabelVariantPreview';

export interface LabelVariantEditorProps {
  labelId?: string;
  labelVariantId?: string;
  value?: LabelVariantState;
  errors?: ErrorValue<LabelVariantState>;
  styles: Style[];
  duplicate?: boolean;
  disableDelete?: boolean;
  onUpdate: LabelUpdater<LabelVariantState>;
  onDuplicate: () => void;
  onDelete: () => void;
}

export function LabelVariantEditor({
  labelId,
  labelVariantId,
  value,
  errors,
  duplicate,
  disableDelete,
  onUpdate,
  onDuplicate,
  onDelete,
  styles
}: LabelVariantEditorProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [updateErrors, setUpdateErrors] = useState<
    ErrorValue<LabelVariantState>
  >({});
  errors = errors ?? updateErrors;

  const { handleTRPCRequest } = useTRPCRequest();

  const { change: onChange, update } = useUpdater(onUpdate);

  const loadVariant = useCallback(
    async (label_variant_id: string) => {
      setIsLoading(true);
      const { res } = await handleTRPCRequest({
        method: TRPCMethodEnum.get,
        resourceType: TRPCResourceEnum.labelVariants,
        requestBody: { label_variant_id }
      });
      const variant = cleanVariant(variantToVariantState(res), duplicate);
      onUpdate(
        {
          ...variant,
          layers: clearUnused(value?.layers, variant.layers),
          text_fields: clearUnused(value?.text_fields, variant.text_fields),
          icons: clearUnused(value?.icons, variant.icons)
        },
        {
          save: true
        }
      );
      setIsLoading(false);
    },
    [
      handleTRPCRequest,
      duplicate,
      onUpdate,
      value?.layers,
      value?.text_fields,
      value?.icons
    ]
  );

  useEffect(() => {
    if (labelVariantId) loadVariant(labelVariantId);
    setUpdateErrors({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelVariantId]);

  // used to create the thumbnail
  const svgContainerRef = useRef<HTMLDivElement>(null);

  // we need to save the svg text before changing to a different label variant tab
  useEffect(() => {
    const thumbnail = prepThumbnailForSave(svgContainerRef);
    if (value && thumbnail !== value.thumbnail) {
      onUpdate(
        { thumbnail },
        // The thumbnail will always change as a result of something else changing
        {
          sideEffect: true
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    value?.system_name,
    value?.icons,
    value?.layers,
    value?.text_fields,
    value?.width,
    value?.height,
    value?.vdpcode
  ]);

  const saveVariant = useCallback(async () => {
    const { errors, validatedSchema } = validateSchemaAndNotifyErrorsRecursive(
      upsertLabelVariantSchema,
      value!
    );
    const sizeError = checkVariantSize(value!);

    if (errors || sizeError) {
      setUpdateErrors({
        ...errors,
        ...sizeError
      });
      return;
    }
    try {
      setIsSaving(true);
      const variantToSave = variantStateToVariant(validatedSchema);
      if (labelVariantId) {
        const thumbnail = prepThumbnailForSave(svgContainerRef);
        const { res } = await handleTRPCRequest({
          method: TRPCMethodEnum.update,
          resourceType: TRPCResourceEnum.labelVariants,
          requestBody: {
            ...variantToSave,
            thumbnail
          }
        });
        loadVariant(res.label_variant_id);
      } else {
        const { res } = await handleTRPCRequest({
          method: TRPCMethodEnum.create,
          resourceType: TRPCResourceEnum.labelVariants,
          requestBody: { ...variantToSave, label_id: labelId }
        });
        loadVariant(res.label_variant_id);
      }
      toast.success('Variant Saved');
    } catch (e) {
      toast.error('There was an error saving this variant');
    }
    setIsSaving(false);
  }, [handleTRPCRequest, labelId, labelVariantId, loadVariant, value]);

  const handleUpdateVdpCode = (newVdpCode: string) => {
    update('vdpcode')(newVdpCode);
  };

  if (isLoading && !value) {
    return (
      <div className="p-2">
        <LoadingDiv />
      </div>
    );
  }

  if (!value?.layers) {
    return null;
  }

  const maxSize = Math.max(PRINT_DIMENSIONS.x, PRINT_DIMENSIONS.y);

  return (
    <div className="flex grow justify-stretch gap-2 pl-2">
      <div className="flex w-1/2 flex-col gap-2">
        <div className="flex justify-stretch gap-2 px-2 py-4">
          <ContainedButton
            color="secondary"
            className="grow basis-1/3 !p-2"
            disabled={!labelId}
            onClick={() => {
              loadVariant(labelVariantId!);
            }}
          >
            Cancel Changes
          </ContainedButton>
          <ContainedButton
            className="grow basis-1/3 !p-2"
            disabled={!labelId || isSaving}
            onClick={saveVariant}
          >
            {isSaving ? 'Saving...' : 'Save Variant'}
          </ContainedButton>
          <button onClick={onDuplicate}>
            <DuplicateIcon />
          </button>
          <DeleteButton disabled={disableDelete} onClick={onDelete} />
        </div>
        <div className="grid">
          <EditVdpCodeComponent
            vdpCode={value.vdpcode ?? ''}
            handleUpdateVdpCode={handleUpdateVdpCode}
            error={!!errors.vdpcode}
          />
        </div>
        <div className="grid grid-cols-2 gap-2">
          <TextField
            placeholder="Name"
            value={value.system_name}
            onChange={e => {
              onChange('system_name', e.currentTarget.value);
            }}
          />
          <Select
            value={value.style_id}
            className={clsx(
              errors?.style_id?.error &&
                errors?.style_id?.value === value.style_id &&
                'border-error border-2'
            )}
            options={styles.map(style => ({
              value: style.style_id,
              label: style.display_name
            }))}
            onChange={e => {
              onChange('style_id', e.currentTarget.value);
            }}
          />
          <NumberInput
            contentBefore="W:"
            contentAfter="″"
            className={clsx('*:text-right', errors.width && 'outline-error')}
            value={value.width}
            min={0}
            step={0.05}
            max={maxSize}
            onChange={update('width')}
          />
          <NumberInput
            contentBefore="H:"
            contentAfter="″"
            className={clsx('*:text-right', errors.height && 'outline-error')}
            value={value.height}
            min={0}
            step={0.05}
            max={maxSize}
            onChange={update('height')}
          />
        </div>
        <LabelVariantElementsEditor
          value={value}
          errors={errors}
          onUpdate={onUpdate}
        />
      </div>
      <LabelVariantPreview value={value} svgContainerRef={svgContainerRef} />
    </div>
  );
}
