export function ChecklistIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.91153 3.23109C8.28346 3.60302 8.28346 4.20604 7.91153 4.57796L5.05439 7.43511C4.68246 7.80704 4.07945 7.80704 3.70752 7.43511L2.27895 6.00654C1.90702 5.63461 1.90702 5.03159 2.27895 4.65967C2.65087 4.28774 3.25389 4.28774 3.62582 4.65967L4.38095 5.4148L6.56466 3.23109C6.93659 2.85917 7.5396 2.85917 7.91153 3.23109ZM9.61905 5.3331C9.61905 4.80712 10.0454 4.38072 10.5714 4.38072H21.0476C21.5736 4.38072 22 4.80712 22 5.3331C22 5.85909 21.5736 6.28548 21.0476 6.28548H10.5714C10.0454 6.28548 9.61905 5.85909 9.61905 5.3331ZM7.91153 9.89776C8.28346 10.2697 8.28346 10.8727 7.91153 11.2446L5.05439 14.1018C4.68246 14.4737 4.07945 14.4737 3.70752 14.1018L2.27895 12.6732C1.90702 12.3013 1.90702 11.6983 2.27895 11.3263C2.65087 10.9544 3.25389 10.9544 3.62582 11.3263L4.38095 12.0815L6.56466 9.89776C6.93659 9.52583 7.5396 9.52583 7.91153 9.89776ZM9.61905 11.9998C9.61905 11.4738 10.0454 11.0474 10.5714 11.0474H21.0476C21.5736 11.0474 22 11.4738 22 11.9998C22 12.5258 21.5736 12.9521 21.0476 12.9521H10.5714C10.0454 12.9521 9.61905 12.5258 9.61905 11.9998ZM7.91153 16.5644C8.28346 16.9364 8.28346 17.5394 7.91153 17.9113L5.05439 20.7684C4.68246 21.1404 4.07945 21.1404 3.70752 20.7684L2.27895 19.3399C1.90702 18.9679 1.90702 18.3649 2.27895 17.993C2.65087 17.6211 3.25389 17.6211 3.62582 17.993L4.38095 18.7481L6.56466 16.5644C6.93659 16.1925 7.5396 16.1925 7.91153 16.5644ZM9.61905 18.6664C9.61905 18.1404 10.0454 17.7141 10.5714 17.7141H21.0476C21.5736 17.7141 22 18.1404 22 18.6664C22 19.1924 21.5736 19.6188 21.0476 19.6188H10.5714C10.0454 19.6188 9.61905 19.1924 9.61905 18.6664Z"
        fill="currentColor"
      />
    </svg>
  );
}
