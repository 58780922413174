import { createTRPCProxyClient, httpBatchLink } from '@trpc/client';
import type { AppRouter } from '../../../admin-api/src/trpc';

import { API_URL } from '../config';

let token: string | null = null;

export const trpc = createTRPCProxyClient<AppRouter>({
  links: [
    httpBatchLink({
      url: `${API_URL}/trpc`,
      headers: () => ({ authorization: `Bearer ${token}` })
    })
  ]
});

export function setToken(newToken: string | null) {
  token = newToken;
}
