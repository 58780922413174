import { SearchIcon } from '../../../components/icons';
import { TextField } from '../../../components/inputs';
import { GoogleFont } from '../types';
import { useCallback, useState } from 'react';
import { setToken, trpc } from '../../../libs/trpc';
import { handleTrpcError } from '../../../api/utils';
import { useAuth } from '@clerk/clerk-react';
import { FontVariants } from './FontVariants';
import { useEffect } from 'react';
import { UploadButtonComponent } from '../../../components/buttons';

interface GoogleFontsProps {
  // eslint-disable-next-line no-unused-vars
  onSelectFontVariant: (
    gfont: GoogleFont | null,
    variant: string,
    fontFile?: string
  ) => void;
  // eslint-disable-next-line no-unused-vars
  onChangeWeight: (event: React.ChangeEvent<HTMLInputElement>) => void;
  weight: string;
  fontError: boolean;
  weightError: boolean;
  font: GoogleFont | undefined;
  create: boolean | undefined;
  variant: string | undefined;
  id?: string;
}

export function GoogleFonts({
  onSelectFontVariant,
  onChangeWeight,
  weight,
  fontError,
  weightError,
  font,
  variant,
  id,
  create
}: GoogleFontsProps) {
  const auth = useAuth();
  const [googleFont, setGoogleFont] = useState<GoogleFont | null>(null);
  const [searchingGFont, setSearchingGFont] = useState<boolean>(false);
  const [googleSearchError, setGoogleSearchError] = useState<string | null>(
    null
  );
  const [searchTerm, setSearchTerm] = useState<string | undefined>(
    font?.family || ''
  );

  const searchGFont = useCallback(
    async (family: string) => {
      setGoogleFont(null);
      setSearchingGFont(true);
      try {
        setToken(await auth.getToken());
        const res = await trpc.fonts.searchGoogle.query(family);
        if (!res || res.length <= 0) {
          setGoogleSearchError(`Font '${family}' not found`);
          return;
        }
        setGoogleSearchError(null);
        const font = {
          family: res[0].family,
          variants: res[0].variants,
          files: res[0].files,
          lastModified: res[0].lastModified,
          version: res[0].version
        } as GoogleFont;
        setGoogleFont(font);
      } catch (error) {
        handleTrpcError(error);
      } finally {
        setSearchingGFont(false);
      }
    },
    [auth]
  );

  const handleSelectVariant = useCallback(
    (variant: string, fontFile?: string) => {
      onSelectFontVariant(googleFont!, variant, fontFile);
    },
    [googleFont, onSelectFontVariant]
  );

  const handleSearch = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        onSelectFontVariant(null, '');

        searchGFont(event.currentTarget.value);
      }
    },
    [onSelectFontVariant, searchGFont]
  );

  useEffect(() => {
    if (font?.family) {
      setSearchTerm(font.family);
      searchGFont(font.family);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [font?.family]);

  return (
    <>
      <div className="mb-[20px] flex items-baseline  gap-[20px]">
        <div className="flex flex-1 gap-[20px]">
          <TextField
            error={fontError}
            size="small"
            className="flex-1"
            placeholder="Enter a google font name to search for"
            contentAfter={<SearchIcon />}
            value={searchTerm}
            disabled={searchingGFont || !create}
            onChange={e => setSearchTerm(e.target.value)}
            onKeyUp={handleSearch}
          />
        </div>
        <div className="flex flex-1 flex-row-reverse">
          <TextField
            error={weightError}
            contentBefore="Font Weight:"
            size="small"
            className="mb-5 w-full text-base font-semibold"
            value={weight}
            onChange={onChangeWeight}
            disabled={true}
            pattern="[0-9]*"
            data-testid="font-weight"
          />
        </div>
        <UploadButtonComponent
          text="Upload font file"
          accept={['font/ttf']}
          disabled={!googleFont}
          onUpload={e => {
            const files = e.target.files;
            if (files?.length !== 1) {
              return;
            }
            const file = files[0];
            const reader = new FileReader();

            reader.onload = () => {
              const data = reader.result as string;
              handleSelectVariant(variant!, data);
            };

            reader.readAsDataURL(file);
          }}
        />
      </div>

      <div>
        Search for a google font and enter its name in the search field above:{' '}
        <a
          style={{ color: 'blue' }}
          href="https://fonts.google.com/"
          target="_BLANK"
        >
          fonts.google.com
        </a>
      </div>

      <div>
        {searchingGFont ? (
          'searching...'
        ) : googleSearchError ? (
          googleSearchError
        ) : !googleFont ? null : (
          <>
            <h1>Font {googleFont.family}:</h1>

            <FontVariants
              font={googleFont!}
              variant={variant}
              id={id}
              onSelectVariant={handleSelectVariant}
            />
          </>
        )}
      </div>
    </>
  );
}
