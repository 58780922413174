import Joi from 'joi';
import { toast } from 'react-toastify';

export const validateSchemaAndNotifyErrors = (
  schema: Joi.ObjectSchema | Joi.ArraySchema,
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  requestBody: any
) => {
  if ('created' in requestBody) {
    delete requestBody.created;
  }
  if ('updated' in requestBody) {
    delete requestBody.updated;
  }
  const { error: validationErrors, value: validatedSchema } = schema.validate(
    requestBody,
    { abortEarly: false }
  );

  if (validationErrors) {
    const errors: { [key: string]: boolean } = {};
    validationErrors.details.forEach(error => {
      let key = 'error';
      if (error.path[1]) {
        key = error.path[1] as string;
      } else {
        key = error.path[0] as string;
      }
      const message = error.context?.message || error.message;

      errors[key] = true;

      toast.error(message);
    });

    return {
      errors,
      validatedSchema
    };
  }

  return {
    errors: null,
    validatedSchema
  };
};

export type ErrorValue<T> = {
  error?: string;
  value?: T;
} & {
  [K in keyof T]?: ErrorValue<T[K]>;
};

export const validateSchemaAndNotifyErrorsRecursive = <T extends object>(
  schema: Joi.ObjectSchema | Joi.ArraySchema,
  requestBody: T
): {
  errors?: ErrorValue<T> | null;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  validatedSchema: any;
} => {
  const { error: validationErrors, value: validatedSchema } = schema.validate(
    requestBody,
    { abortEarly: false }
  );

  if (validationErrors) {
    const errors: ErrorValue<T> = {};
    validationErrors.details.forEach(error => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let errorTree: any = errors;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let errorValue: any = requestBody;
      error.path.forEach(path_step => {
        const step = path_step as keyof typeof errorTree;
        if (!(step in errorTree)) {
          errorTree[step] = {};
        }
        errorTree = errorTree[step];
        errorValue = errorValue?.[step];
      });

      const message = error.context?.message || error.message;
      errorTree.error = message;
      if (errorValue !== undefined) {
        errorTree.value = errorValue;
      }
      toast.error(message);
    });

    return {
      errors,
      validatedSchema
    };
  }

  return {
    errors: null,
    validatedSchema
  };
};
