import clsx from 'clsx';
import { GroupRegistrations } from '../types';
import { GroupRegistrationChipsComponent } from './GroupRegistrationChipsComponent';
import { SearchRegistrationCandidatesComponent } from './SearchRegistrationCandidatesComponent';
import { TRPCResourceEnum } from '../../../api/trpcApi/types';

interface ManageGroupRegistrationsProps {
  error: boolean;
  type: TRPCResourceEnum;
  groupRegistrations: GroupRegistrations;
  onUpdateGroupRegistrations(
    updatedGroupRegistrations: GroupRegistrations
  ): void;
}

export const ManageGroupRegistrationsComponent = ({
  error,
  type,
  groupRegistrations,
  onUpdateGroupRegistrations
}: ManageGroupRegistrationsProps) => {
  return (
    <div
      className={clsx(
        {
          'bg-light-blue': !error,
          'bg-blush': error
        },
        'p-2'
      )}
    >
      <SearchRegistrationCandidatesComponent
        type={type}
        groupRegistrations={groupRegistrations}
        onUpdateGroupRegistrations={onUpdateGroupRegistrations}
      />
      <GroupRegistrationChipsComponent
        groupRegistrations={groupRegistrations}
        onUpdateGroupRegistrations={onUpdateGroupRegistrations}
      />
    </div>
  );
};
