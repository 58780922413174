import { useEffect, useState } from 'react';

import {
  EntityDependencies,
  TRPCMethodEnum,
  TRPCResourceEnum
} from '../../../api/trpcApi/types';
import { IconButton } from '../../../components/buttons';
import { Chip } from '../../../components/chips';
import { CloseOutlineIcon } from '../../../components/icons';
import { useTRPCRequest } from '../../../hooks';

import { Material } from '../../materials/types';
import { CannotDeleteModalComponent } from '../../../components/modals';

interface LabelMaterialChipsProps {
  material: Material;
  label_id?: string;
  handleRemoveMaterial: (material: Material) => void;
}

interface ProductIdentifier {
  product_id: string;
  system_name: string;
}

export function LabelMaterialChipComponent({
  material,
  label_id,
  handleRemoveMaterial
}: LabelMaterialChipsProps) {
  const { handleTRPCRequest } = useTRPCRequest();

  const [productsUsing, setProductsUsing] = useState<ProductIdentifier[]>([]);
  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);
  const [dependencies, setDependencies] = useState<EntityDependencies>({});

  const [currentMaterialAndLabel, setCurrentMaterialAndLabel] = useState({
    material: undefined as string | undefined,
    label: undefined as string | undefined
  });

  useEffect(() => {
    if (
      label_id &&
      (label_id != currentMaterialAndLabel.label ||
        material.material_id != currentMaterialAndLabel.material)
    ) {
      handleTRPCRequest({
        resourceType: TRPCResourceEnum.products,
        method: TRPCMethodEnum.usingLabelAndMaterial,
        requestBody: {
          label_id,
          material_id: material.material_id
        }
      }).then(({ res }) => {
        if (res) setProductsUsing(res);
        setCurrentMaterialAndLabel({
          material: material.material_id,
          label: label_id
        });
      });
    }
  }, [
    handleTRPCRequest,
    label_id,
    material.material_id,
    currentMaterialAndLabel.label,
    currentMaterialAndLabel.material
  ]);

  const onClickHandler = () => {
    if (productsUsing.length > 0) {
      const dependencies: EntityDependencies = {
        products: productsUsing.map(p => ({
          name: p.system_name,
          id: p.product_id
        }))
      };
      setDependencies(dependencies);
      setShowCannotDeleteModal(true);
      return;
    }

    handleRemoveMaterial(material);
  };

  return (
    <>
      <Chip key={material.material_id} classes={['m-2']}>
        {material.system_name}
        <IconButton onClick={onClickHandler} icon={<CloseOutlineIcon />} />
      </Chip>
      <CannotDeleteModalComponent
        entityName={material.display_name ?? ''}
        dependencies={dependencies}
        isOpen={showCannotDeleteModal}
        onCancel={() => setShowCannotDeleteModal(false)}
      />
    </>
  );
}
