export function PlusIcon({
  width = '1em',
  height = '1em'
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4913 13.5037L10.4906 21.0039C10.4906 21.4019 10.6486 21.7835 10.93 22.0649C11.2114 22.3463 11.5931 22.5044 11.991 22.5044C12.389 22.5044 12.7706 22.3463 13.052 22.0649C13.3334 21.7835 13.4915 21.4019 13.4915 21.0039L13.4901 13.5029L20.9904 13.5037C21.1874 13.5036 21.3824 13.4648 21.5644 13.3893C21.7464 13.3139 21.9118 13.2034 22.051 13.064C22.1903 12.9247 22.3008 12.7593 22.3761 12.5773C22.4514 12.3953 22.4902 12.2002 22.4902 12.0032C22.4901 11.8062 22.4513 11.6111 22.3758 11.4291C22.3004 11.2471 22.1899 11.0818 22.0505 10.9425C21.9112 10.8033 21.7458 10.6928 21.5638 10.6175C21.3818 10.5421 21.1867 10.5034 20.9897 10.5034L13.4908 10.5041L13.4908 3.00453C13.4944 2.80539 13.4582 2.60753 13.3845 2.4225C13.3108 2.23748 13.2009 2.06899 13.0614 1.92686C12.9218 1.78474 12.7554 1.67183 12.5717 1.59473C12.3881 1.51762 12.1909 1.47786 11.9918 1.47777C11.7926 1.47767 11.5954 1.51725 11.4117 1.59418C11.2279 1.67111 11.0614 1.78386 10.9217 1.92585C10.782 2.06785 10.672 2.23623 10.5981 2.42119C10.5242 2.60615 10.4879 2.80397 10.4913 3.00312L10.492 10.5048L2.99168 10.5041C2.59373 10.5041 2.21208 10.6622 1.93068 10.9436C1.64929 11.225 1.4912 11.6066 1.4912 12.0046C1.4912 12.4025 1.64929 12.7842 1.93068 13.0656C2.21208 13.347 2.59373 13.5051 2.99168 13.5051L10.492 13.5029L10.4913 13.5037Z"
        fill="currentColor"
      />
    </svg>
  );
}
