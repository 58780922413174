import { CSSProperties, useCallback, useEffect, useState } from 'react';
import { Font } from '../types';
import { useTRPCRequest } from '../../../hooks';
import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';
import {
  type Font as FontObj,
  createFont
} from '../../../../../text-layout/src/layout';
import { loadFont } from '../components/fonts';

export function useFonts(search?: string) {
  const [fonts, setFonts] = useState<Font[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { handleTRPCRequest } = useTRPCRequest();

  const fetchFonts = useCallback(
    async (search?: string) => {
      setIsLoading(true);
      const trpcRequest = {
        method: TRPCMethodEnum.list,
        resourceType: TRPCResourceEnum.fonts,
        requestBody: {}
      };
      if (search) {
        trpcRequest.requestBody = { keyword: search };
      }

      const { res: fonts } = await handleTRPCRequest(trpcRequest);

      setFonts(fonts);
      setIsLoading(false);
    },
    [handleTRPCRequest]
  );

  useEffect(() => {
    fetchFonts(search ?? undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  return { fonts, isLoading, refetch: fetchFonts };
}

type FontState = {
  font: FontObj;
  style: CSSProperties;
};

const fonts: Map<string, FontState> = new Map();

export function useFontObject(fontId?: string) {
  const { handleTRPCRequest } = useTRPCRequest();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fontState, setFontState] = useState<FontState>();

  const fetchFont = useCallback(
    async (fontId?: string) => {
      if (!fontId) return;
      if (fonts.has(fontId)) {
        const state = fonts.get(fontId);
        setFontState(state);
        return state;
      }
      const { res: font, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.get,
        resourceType: TRPCResourceEnum.fonts,
        requestBody: {
          font_id: fontId
        },
        options: {
          disableNavigateOnError: true,
          disableErrorNotification: true
        }
      });
      if (error || !font?.font_file_path) {
        return;
      }
      const fontData = font as Font;
      const filePath = font.font_file_path;
      setIsLoading(true);
      const style = fontData.google_variant?.includes('italic')
        ? 'italic'
        : 'normal';
      if (!fontData.google_family) {
        return;
      }
      loadFont({
        url: filePath,
        family: fontData.google_family + fontData.font_id,
        weight: fontData.weight,
        style
      });
      const fontObj = await createFont(filePath);
      const state = {
        font: fontObj,
        style: {
          fontFamily: fontData.google_family + fontData.font_id,
          fontWeight: fontData.weight,
          fontStyle: style
        }
      };
      fonts.set(fontId, state);
      setFontState(state);
      setIsLoading(false);
      return state;
    },
    [handleTRPCRequest]
  );

  useEffect(() => {
    fetchFont(fontId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fontId]);
  return { isLoading, ...fontState };
}
