import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { toPx } from '../../labels/components/settings/preview/svgUtils';
import { LabelSlotValues } from '../types';

function lines(
  width: number,
  height: number,
  spacing: number,
  vertical?: boolean
) {
  const [axis, cross] = vertical ? [height, width] : [width, height];
  const count = Math.ceil(cross / spacing) - 1;
  const a2 = axis / 2;
  return new Array(count).fill(null).map((_, i) => {
    const idx = Math.floor((i + 1) / 2);
    const s = '#00487608';
    const d = idx * spacing * (i % 2 ? 1 : -1);
    return vertical ? (
      <line key={i} x1={d} y1={-a2} x2={d} y2={a2} stroke={s} />
    ) : (
      <line key={i} x1={-a2} y1={d} x2={a2} y2={d} stroke={s} />
    );
  });
}

export function GraphPaperComponent({
  labelSlotsList,
  children
}: {
  labelSlotsList: LabelSlotValues[];
  children: ReactNode;
}) {
  const containerRef = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(200);
  const [sHeight, setHeight] = useState(200);

  const updateSize = useCallback(() => {
    if (!containerRef.current) return;
    setWidth(containerRef.current?.clientWidth);
    setHeight(containerRef.current?.clientHeight);
  }, []);

  useEffect(() => {
    if (!containerRef.current) return;
    const observer = new ResizeObserver(updateSize);
    observer.observe(containerRef.current);
    return () => observer.disconnect();
  }, [updateSize]);

  useEffect(updateSize, [updateSize, labelSlotsList]);

  return (
    <div
      ref={containerRef}
      className="absolute left-0 top-0 z-0 h-full w-full overflow-hidden border"
    >
      <svg
        className="absolute left-0 top-0"
        width={width}
        height={sHeight}
        viewBox={`0 0 ${width} ${sHeight}`}
        strokeWidth={2}
        fill="#004876"
      >
        <g
          style={{
            transform: 'translate(50%, 50%)'
          }}
        >
          {lines(width, sHeight, toPx(3) / 10)}
          {lines(width, sHeight, toPx(3) / 10, true)}
        </g>
      </svg>
      {children}
    </div>
  );
}
