import Joi from 'joi';

const textFieldFormSchema = Joi.object({
  system_name: Joi.string().label('System Name').required(),
  display_name: Joi.string().label('Display Name').required(),
  display_name_2: Joi.string().label('Display Name 2').allow(null).allow(''),
  default_length: Joi.number()
    .min(1)
    .label('Default Character Limit')
    .required(),
  text_field_id: Joi.string().label('Text Field ID').optional(),
  default_font: Joi.string().label('Default Font').allow(null),
  required_by_default: Joi.boolean().label('Required by default')
});

export default {
  textFieldFormSchema
};
