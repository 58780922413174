import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useMemo } from 'react';
import { Dict } from '../../../types/state';

export type UpdateValue<T> = T extends object
  ? {
      [P in keyof T]?: T[P] extends (infer U)[]
        ? UpdateValue<U>[]
        : UpdateValue<T[P]>;
    }
  : T;

export type UpdaterFn<T, U = void, R = void> = (
  value: UpdateValue<T>,
  data?: U
) => R;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function updateHelper<T>(data: any, updater: any): T {
  data = clone(data);
  Object.entries(updater).forEach(([key, value]) => {
    if (!(key in data) || Array.isArray(data[key])) {
      data[key] = cloneDeep(value);
    } else if (value === undefined) {
      delete data[key];
    } else if (value && typeof value === 'object') {
      data[key] = updateHelper(data[key], value);
    } else {
      data[key] = value;
    }
  });
  return data;
}

export function update<T>(data: T, value: UpdateValue<T>): T {
  return updateHelper(data, value);
}

type UpdaterOptions<T, U> = {
  before?: UpdaterFn<T, U>;
};

export function updater<T extends object, U = void, R = void>(
  fn: UpdaterFn<T, U, R>,
  options: UpdaterOptions<T, U> = {}
) {
  return (key: keyof T) => {
    const child: UpdaterFn<T[typeof key], U> = (
      value: UpdateValue<T[typeof key]>,
      data?: U
    ) => {
      const val = {
        [key]: value
      } as UpdateValue<T>;
      options.before?.(val, data);
      return fn(val, data);
    };
    return child;
  };
}

export function useUpdater<T extends object, U = void, R = void>(
  fn: UpdaterFn<T, U, R>,
  options: UpdaterOptions<T, U> = {}
) {
  const update = useMemo(
    () => updater(fn, options),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fn, ...Object.values(options)]
  );
  const change = useCallback(
    (key: keyof T, value: UpdateValue<T[typeof key]>, data?: U) =>
      update(key)(value, data),
    [update]
  );
  return {
    update,
    change
  };
}

export function clearUnused<T>(
  value: Dict<T> | undefined,
  newValue: Dict<T>
): Dict<undefined | T> {
  return {
    ...Object.fromEntries(
      Object.keys(value ?? {}).map(key => [key, undefined])
    ),
    ...newValue
  };
}
