import { useCallback } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { TRPCClientError } from '@trpc/client';

import {
  RequestBody,
  TRPCMethodEnum,
  TRPCResourceEnum
} from '../api/trpcApi/types';
import { TRPCRequest } from '../api/trpcApi';
import { setToken } from '../libs/trpc';

interface HandleTRPCOptions {
  disableErrorNotification?: boolean;
  disableSuccessNotification?: boolean;
  disableNavigateOnError?: boolean;
  customErrorMessage?: string | boolean;
  customSuccessMessage?: string;
  getCreatedAndUpdated?: boolean;
  overrideMessage?: string;
  sendRealError?: boolean;
  autoCloseError?: number | false;
}

interface HandleTRPCProps {
  method: TRPCMethodEnum;
  resourceType: TRPCResourceEnum;
  requestBody: RequestBody;
  options?: HandleTRPCOptions;
}

interface UserResponseMessagesType {
  [key: string]: {
    [key: string]: {
      error?: string;
      success?: string;
    };
  };
}

const UserResponseMessages: UserResponseMessagesType = {
  [TRPCResourceEnum.fonts]: {
    [TRPCMethodEnum.create]: {
      error: 'An error occurred creating font',
      success: 'Font created successfully!'
    },
    [TRPCMethodEnum.get]: {
      error: 'An error occurred fetching font'
    },
    [TRPCMethodEnum.update]: {
      error: 'An error occurred updating font',
      success: 'Font updated successfully!'
    },
    [TRPCMethodEnum.delete]: {
      error: 'An error occurred deleting font'
    }
  },
  [TRPCResourceEnum.icons]: {
    [TRPCMethodEnum.create]: {
      error: 'An error occurred creating icon',
      success: 'Icon created successfully!'
    },
    [TRPCMethodEnum.get]: {
      error: 'An error occurred fetching icon'
    },
    [TRPCMethodEnum.update]: {
      error: 'An error occurred updating icon',
      success: 'Icon updated successfully!'
    },
    [TRPCMethodEnum.delete]: {
      error: 'An error occurred deleting icon'
    }
  },
  [TRPCResourceEnum.materials]: {
    [TRPCMethodEnum.create]: {
      error: 'An error occurred creating material',
      success: 'Material created successfully!'
    },
    [TRPCMethodEnum.get]: {
      error: 'An error occurred fetching material'
    },
    [TRPCMethodEnum.update]: {
      error: 'An error occurred updating material',
      success: 'Material updated successfully!'
    },
    [TRPCMethodEnum.delete]: {
      error: 'An error occurred deleting material'
    }
  },
  [TRPCResourceEnum.palettes]: {
    [TRPCMethodEnum.create]: {
      error: 'An error occurred creating palette',
      success: 'Palette created successfully!'
    },
    [TRPCMethodEnum.get]: {
      error: 'An error occurred fetching palette'
    },
    [TRPCMethodEnum.update]: {
      error: 'An error occurred updating palette',
      success: 'Palette updated successfully!'
    },
    [TRPCMethodEnum.delete]: {
      error: 'An error occurred deleting palette'
    },
    [TRPCMethodEnum.blank]: {
      error: 'An error occurred fetching blank palette data'
    },
    [TRPCMethodEnum.list]: {
      error: 'An error occurred fetching palettes'
    },
    [TRPCMethodEnum.usingColorSlot]: {
      error: 'An error occurred fetching palettes using color slot'
    }
  },
  [TRPCResourceEnum.groups]: {
    [TRPCMethodEnum.create]: {
      error: 'An error occurred creating group',
      success: 'Group created successfully!'
    },
    [TRPCMethodEnum.get]: {
      error: 'An error occurred fetching group'
    },
    [TRPCMethodEnum.update]: {
      error: 'An error occurred updating group',
      success: 'Group updated successfully!'
    },
    [TRPCMethodEnum.delete]: {
      error: 'An error occurred deleting group'
    }
  },
  [TRPCResourceEnum.textFields]: {
    [TRPCMethodEnum.create]: {
      error: 'An error occurred creating text field',
      success: 'Text field created successfully!'
    },
    [TRPCMethodEnum.get]: {
      error: 'An error occurred fetching text field'
    },
    [TRPCMethodEnum.update]: {
      error: 'An error occurred updating text field',
      success: 'Text field updated successfully!'
    },
    [TRPCMethodEnum.delete]: {
      error: 'An error occurred deleting text field'
    }
  },
  [TRPCResourceEnum.styles]: {
    [TRPCMethodEnum.create]: {
      error: 'An error occurred creating style',
      success: 'Style created successfully!'
    },
    [TRPCMethodEnum.get]: {
      error: 'An error occurred fetching style'
    },
    [TRPCMethodEnum.update]: {
      error: 'An error occurred updating style',
      success: 'Style updated successfully!'
    },
    [TRPCMethodEnum.delete]: {
      error: 'An error occurred deleting style'
    }
  },
  [TRPCResourceEnum.products]: {
    [TRPCMethodEnum.create]: {
      error: 'An error occurred creating product',
      success: 'Product created successfully!'
    },
    [TRPCMethodEnum.get]: {
      error: 'An error occurred fetching product'
    },
    [TRPCMethodEnum.update]: {
      error: 'An error occurred updating product',
      success: 'Product updated successfully!'
    },
    [TRPCMethodEnum.delete]: {
      error: 'An error occurred deleting product'
    },
    [TRPCMethodEnum.list]: {
      error: 'An error occurred fetching products'
    }
  }
};

enum NotificationTypeEnum {
  error = 'error',
  success = 'success'
}

interface SendUserNotificationProps {
  method: TRPCMethodEnum;
  resourceType: TRPCResourceEnum;
  options: HandleTRPCOptions;
  notificationType: 'error' | 'success';
}

const sendUserNotification = ({
  method,
  resourceType,
  options,
  notificationType
}: SendUserNotificationProps) => {
  let message = options.customErrorMessage;
  const isSuccess = notificationType === NotificationTypeEnum.success;

  if (!message) {
    const responseMessages = UserResponseMessages[resourceType];
    const { error: errorMessage, success: successMessage } =
      (responseMessages && responseMessages[method]) || {};

    if (!isSuccess && errorMessage) {
      message = errorMessage;
    }

    if (isSuccess && successMessage) {
      message = successMessage;
    }
  }

  if (message) {
    isSuccess
      ? toast.success(message)
      : toast.error(message, {
          autoClose: options.autoCloseError
        });
  }
};

export const useTRPCRequest = () => {
  const { getToken } = useAuth();
  const navigate = useNavigate();

  const handleTRPCRequest = useCallback(
    async ({
      method,
      resourceType,
      requestBody,
      options = {} /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    }: HandleTRPCProps): Promise<any> => {
      if (
        requestBody &&
        method in [TRPCMethodEnum.create, TRPCMethodEnum.update]
      ) {
        if ('created' in requestBody) {
          delete requestBody.created;
        }
        if ('updated' in requestBody) {
          delete requestBody.updated;
        }
      }

      const token = await getToken();
      setToken(token);

      const trpcRequest = new TRPCRequest({
        method,
        resourceType,
        requestBody,
        token
      });

      try {
        const response = await trpcRequest.execute();

        if (!options.disableSuccessNotification) {
          sendUserNotification({
            method,
            resourceType,
            options,
            notificationType: NotificationTypeEnum.success
          });
        }

        if (!options.getCreatedAndUpdated) {
          if ('created' in response!) {
            delete response.created;
          }
          if ('updated' in response!) {
            delete response.updated;
          }
        }

        return {
          res: response,
          error: false
        };
      } catch (error: unknown) {
        if (
          !options.disableNavigateOnError &&
          error instanceof TRPCClientError &&
          error?.data?.httpStatus === 404
        ) {
          navigate('/404', { replace: true });
          return {
            res: null,
            error: false
          };
        }

        if (!options.disableErrorNotification) {
          let customErrorMessage;
          if (
            error instanceof TRPCClientError &&
            error?.data?.httpStatus !== 500
          ) {
            if (error?.data?.httpStatus === 401) {
              customErrorMessage =
                'You are not authorized to perform this action';
            } else if (
              options.customErrorMessage === true &&
              error?.shape?.message
            ) {
              customErrorMessage = error.shape.message;
            }
          }

          sendUserNotification({
            method,
            resourceType,
            options: {
              ...options,
              customErrorMessage
            },
            notificationType: NotificationTypeEnum.error
          });
        }

        if (options.sendRealError) {
          return {
            res: null,
            error: error
          };
        }

        return {
          res: null,
          error: true
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getToken]
  );

  return {
    handleTRPCRequest
  };
};
