import { IconButton } from '../../../components/buttons';
import { Chip } from '../../../components/chips';
import { CloseOutlineIcon } from '../../../components/icons';
import { StyleEntity, StyleEntityCandidates, StyleTypeEnum } from '../types';

interface StyleEntitiesChipsComponentProps {
  type: StyleTypeEnum;
  styleEntityList: StyleEntityCandidates;
  onRemoveEntityChip(
    styleEntityToDelete: StyleEntityCandidates,
    type: StyleTypeEnum
  ): void;
}

export const StyeEntitiesChipsComponent = ({
  type,
  styleEntityList,
  onRemoveEntityChip
}: StyleEntitiesChipsComponentProps) => {
  const handleRemoveStyleEntity = (styleEntity: StyleEntity) => () => {
    const updatedStyleEntities = { ...styleEntityList };
    delete updatedStyleEntities[styleEntity.registered_entity_id];
    onRemoveEntityChip(updatedStyleEntities, type);
  };

  return (
    <div className="flex flex-wrap gap-2">
      {Object.values(styleEntityList).map(styleEntity => {
        return (
          <Chip
            backgroundColor={styleEntity.is_group ? 'bg-light-gray' : undefined}
            key={styleEntity.registered_entity_id}
          >
            {styleEntity.system_name}
            {styleEntity.is_group && ' (Group)'}
            <IconButton
              onClick={handleRemoveStyleEntity(styleEntity)}
              icon={<CloseOutlineIcon />}
            />
          </Chip>
        );
      })}
    </div>
  );
};
