import { useCallback, useMemo, useState } from 'react';
import { useTRPCRequest } from '../../../hooks';
import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';
import { SearchBar } from '../../../components/search-bar';
import { PlusIcon } from '../../../components/icons';
import { ContainedButton, IconButton } from '../../../components/buttons';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { LoadingDiv } from '../../../components/loading';
import { Material } from '../../materials/types';
import clsx from 'clsx';

interface SearchMaterialCandidatesProps {
  materials: Material[];
  onChange: (materials: Material[]) => void;
}

export const SearchMaterialCandidatesComponent = ({
  materials,
  onChange
}: SearchMaterialCandidatesProps) => {
  const [allMaterialCandidates, setMaterialCandidates] = useState<Material[]>(
    []
  );
  const [selectedCandidates, setSelectedCandidates] = useState<Material[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { handleTRPCRequest } = useTRPCRequest();

  const materialIds = useMemo(
    () => new Set(materials.map(m => m.material_id)),
    [materials]
  );

  const materialCandidates = useMemo(() => {
    return allMaterialCandidates.filter(m => !materialIds.has(m.material_id));
  }, [allMaterialCandidates, materialIds]);

  const fetchCandidates = useCallback(
    async (search?: string) => {
      setIsLoading(true);
      setIsEditing(true);
      const { res } = await handleTRPCRequest({
        method: TRPCMethodEnum.list,
        resourceType: TRPCResourceEnum.materials,
        requestBody: {
          keyword: search
        }
      });

      setMaterialCandidates(res);
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleTRPCRequest, setMaterialCandidates, setIsLoading]
  );

  // used to close the search dropdown when the user clicks away
  const ref = useOutsideClick(() => setIsEditing(false));

  return (
    <div className="relative" ref={ref}>
      <div
        onClick={() => {
          setIsEditing(true);
          fetchCandidates();
        }}
      >
        <SearchBar
          classNames={['mb-0']}
          searchTerm={''}
          onSearch={search => fetchCandidates(search)}
          searchOnChange={true}
        />
      </div>
      <div
        className="absolute z-30 flex w-full flex-col rounded shadow"
        style={{ backgroundColor: 'white' }}
      >
        {isEditing &&
          (materialCandidates.length ? (
            <>
              <div className="flex max-h-60 flex-col overflow-y-auto">
                {materialCandidates.map(candidate => {
                  const selected = selectedCandidates.some(
                    c => c.material_id === candidate.material_id
                  );
                  return (
                    <div
                      key={candidate.material_id}
                      className={clsx(
                        'align-center flex cursor-pointer flex-row justify-between p-2',
                        selected
                          ? 'bg-light-blue hover:bg-light-blue/80'
                          : 'hover:bg-light-blue/50'
                      )}
                      onClick={() => {
                        if (selected) {
                          setSelectedCandidates(
                            selectedCandidates.filter(
                              c => c.material_id !== candidate.material_id
                            )
                          );
                        } else {
                          setSelectedCandidates([
                            ...selectedCandidates,
                            candidate
                          ]);
                        }
                      }}
                    >
                      <div>{candidate.system_name}</div>
                      <IconButton
                        className="text-navy text-[24px]"
                        icon={<PlusIcon />}
                        data-testid="search-result-button"
                      />
                    </div>
                  );
                })}
              </div>

              <div className="flex gap-4 p-2">
                <ContainedButton
                  color="secondary"
                  onClick={() => {
                    setIsEditing(false);
                    setSelectedCandidates([]);
                  }}
                >
                  Cancel
                </ContainedButton>
                {selectedCandidates.length ? (
                  <ContainedButton
                    onClick={() => {
                      onChange([...materials, ...selectedCandidates]);
                      setIsEditing(false);
                      setSelectedCandidates([]);
                    }}
                  >
                    Save
                  </ContainedButton>
                ) : null}
              </div>
            </>
          ) : isLoading ? (
            <div className="m-2">
              <LoadingDiv />
            </div>
          ) : (
            <div className="p-2">No Results</div>
          ))}
      </div>
    </div>
  );
};
