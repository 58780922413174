import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TextFieldPreview } from './TextFieldPreview';
import { IconFieldPreview } from './IconFieldPreview';
import { toPx } from './svgUtils';
import { useVariantState } from '../VariantState';
import { LayeredSVGComponent } from '../../../../../components/svg';
import { LabelVariantState } from '../../../types/state';
import { sortByOrder } from '../../../types/convert';

export interface LabelVariantPreviewProps {
  svgContainerRef: React.Ref<HTMLDivElement>;
  labelVariantId?: string;
  value?: LabelVariantState;
  onChange?: (value: LabelVariantState) => void;
  onDuplicate?: () => void;
}

function lines(
  width: number,
  height: number,
  spacing: number,
  vertical?: boolean
) {
  const [axis, cross] = vertical ? [height, width] : [width, height];
  const count = Math.ceil(cross / spacing) - 1;
  const a2 = axis / 2;
  return new Array(count).fill(null).map((_, i) => {
    const idx = Math.floor((i + 1) / 2);
    const s = idx % 5 ? '#00487608' : '#00487670';
    const d = idx * spacing * (i % 2 ? 1 : -1);
    return vertical ? (
      <line key={i} x1={d} y1={-a2} x2={d} y2={a2} stroke={s} />
    ) : (
      <line key={i} x1={-a2} y1={d} x2={a2} y2={d} stroke={s} />
    );
  });
}

function unitHelper(
  l: number,
  s: number,
  x: number,
  y: number,
  rotation: number
) {
  return (
    <g transform={`translate(${x} ${y}) rotate(${rotation * 90})`}>
      <polyline
        points={`0,${s * 10} 0,${l - s * 5} ${-s * 2},${l} ${s * 2},${l} 0,${
          l - s * 5
        }`}
        stroke="#004876"
        strokeWidth={s * 1.5}
      />
    </g>
  );
}

function unitDisplay(
  widthIn: number,
  heightIn: number,
  padding: number,
  stroke: number
) {
  const w = toPx(widthIn) / 2;
  const h = toPx(heightIn) / 2;
  const wp = w * padding;
  const hp = h * padding;
  const s = stroke;

  const textHelper = (x: number, y: number, v: number) => {
    return (
      <text
        x={x}
        y={y}
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize={s * 14}
        fill="#004876"
      >
        {v}″
      </text>
    );
  };

  return (
    <>
      {unitHelper(w, s, 0, hp, 1)}
      {unitHelper(w, s, 0, hp, -1)}
      {unitHelper(w, s, 0, -hp, 1)}
      {unitHelper(w, s, 0, -hp, -1)}
      {unitHelper(h, s, wp, 0, 0)}
      {unitHelper(h, s, wp, 0, 2)}
      {unitHelper(h, s, -wp, 0, 0)}
      {unitHelper(h, s, -wp, 0, 2)}

      {textHelper(wp, 0, heightIn)}
      {textHelper(-wp, 0, heightIn)}
      {textHelper(0, hp, widthIn)}
      {textHelper(0, -hp, widthIn)}
    </>
  );
}

export function LabelVariantPreview({
  value,
  svgContainerRef
}: LabelVariantPreviewProps) {
  const ref = useRef<SVGSVGElement>(null);

  const layers = useMemo(() => {
    // Render bottom (last) layers on bottom
    return Object.values(value?.layers ?? {}).sort(sortByOrder);
  }, [value?.layers]);
  const text_fields = useMemo(() => {
    return Object.entries(value?.text_fields ?? {});
  }, [value?.text_fields]);
  const icons = useMemo(() => {
    return Object.entries(value?.icons ?? {});
  }, [value?.icons]);

  const {
    selectedTextFields,
    selectedIconFields,
    toggleTextField,
    toggleIconField,
    textFields,
    exampleFont
  } = useVariantState();

  const [width, setWidth] = useState(200);
  const [height, setHeight] = useState(200);

  const updateSize = useCallback(() => {
    if (!ref.current) return;
    setWidth(ref.current?.clientWidth);
    setHeight(ref.current?.clientHeight);
  }, []);

  useEffect(updateSize, [updateSize]);
  useEffect(() => {
    if (!ref.current) return;
    const observer = new ResizeObserver(updateSize);
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [updateSize]);

  const padding = 1.24;

  const tagWidth = Math.max(toPx(value?.width ?? 2), 0.05);
  const tagHeight = Math.max(toPx(value?.height ?? 2), 0.05);
  const aspect = width / height;
  let viewWidth = tagWidth * padding;
  let viewHeight = viewWidth / aspect;
  let pxWidth = width / padding;
  let pxHeight = (pxWidth * tagHeight) / tagWidth;
  if (viewHeight < tagHeight + padding) {
    viewHeight = tagHeight * padding;
    viewWidth = viewHeight * aspect;
    pxHeight = height / padding;
    pxWidth = (pxHeight * tagWidth) / tagHeight;
  }
  const padX = (width - pxWidth) / 2;
  const padY = (height - pxHeight) / 2;
  const strokeWidth = viewWidth / 200;

  return (
    <div className="relative h-[30rem] w-1/2 min-w-[30%]">
      <div className="absolute z-0 h-full w-full">
        <div className="absolute h-full w-full">
          <svg
            ref={ref}
            className="h-full w-full"
            viewBox={`0 0 ${viewWidth} ${viewHeight}`}
            onResize={updateSize}
            strokeWidth={strokeWidth}
            fill="#004876"
          >
            <g
              transform={`translate(${viewWidth / 2 - tagWidth / 2}, ${
                viewHeight / 2 - tagHeight / 2
              })`}
            >
              <rect
                width={tagWidth}
                height={tagHeight}
                rx={10}
                fill="rgb(223	237	237)"
                fillOpacity={10}
                stroke="#004876"
                strokeDasharray={strokeWidth * 2}
              />
            </g>
            <g
              style={{
                transform: 'translate(50%, 50%)'
              }}
            >
              {lines(viewWidth, viewHeight, toPx(1) / 10)}
              {lines(viewWidth, viewHeight, toPx(1) / 10, true)}
              <ellipse
                rx={tagWidth / 2}
                ry={tagHeight / 2}
                stroke="#004876"
                fill="rgb(223	237	237)"
                fillOpacity={0.6}
              />
            </g>
          </svg>
        </div>
        {value?.layers && (
          <LayeredSVGComponent
            containerStyle={{
              transform: `translate(${padX}px, ${padY}px)`,
              width: pxWidth,
              height: pxHeight
            }}
            containerClasses={['relative']}
            svgClasses={['absolute', 'h-full', 'w-full']}
            layers={layers}
            svgRef={svgContainerRef}
          />
        )}
      </div>
      <svg
        ref={ref}
        className="relative z-auto h-full w-full"
        viewBox={`0 0 ${viewWidth} ${viewHeight}`}
        onResize={updateSize}
        strokeWidth={strokeWidth}
        fill="#004876"
      >
        <g
          style={{
            transform: 'translate(50%, 50%)'
          }}
        >
          {unitDisplay(
            value?.width ?? 2,
            value?.height ?? 2,
            1 + (padding - 1) / 2,
            strokeWidth
          )}

          {text_fields.map(([key, variantField]) => {
            const textField = textFields.find(
              f => f.text_field_id === variantField.text_field_id
            );
            return (
              <TextFieldPreview
                key={key}
                text={textField?.display_name ?? 'Name'}
                fontId={
                  variantField.override_font_id ??
                  textField?.default_font ??
                  exampleFont?.font_id ??
                  undefined
                }
                value={{
                  ...variantField,
                  x: variantField.x,
                  y: variantField.y
                }}
                focus={selectedTextFields.includes(key)}
                onClick={() => toggleTextField(key)}
              />
            );
          })}
          {icons.map(([key, field]) => {
            return (
              <IconFieldPreview
                key={key}
                x={field.x}
                y={field.y}
                radius={field.radius}
                rotation={field.rotation}
                focus={selectedIconFields.includes(key)}
                onClick={() => toggleIconField(key)}
              />
            );
          })}
        </g>
      </svg>
    </div>
  );
}
