import { useState, useCallback } from 'react';

import { ContainedButton } from '../../buttons';
import { TextField } from '../../inputs';

import { ModalComponent } from '../ModalComponent';

interface TypeDeleteModalProps {
  isOpen: boolean;
  title: string;
  onConfirm: () => void;
  onCancel: () => void;
  children: React.ReactNode;
}

export function TypeDeleteModalComponent({
  isOpen,
  title,
  onConfirm,
  onCancel,
  children
}: TypeDeleteModalProps) {
  const [deleteIsTyped, setDeleteIsTyped] = useState(false);

  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value.toLowerCase();
      setDeleteIsTyped(value === 'delete');
    },
    []
  );

  return (
    <ModalComponent
      isOpen={isOpen}
      onRequestClose={onCancel}
      contentLabel="Confirm Delete"
    >
      <h1 className="mb-3 block">{title}</h1>
      <p>Confirm by typing the word 'Delete' below.</p>
      <div className="flex gap-3 pt-5">
        <TextField
          className="w-full"
          placeholder="Type 'Delete'"
          onKeyUp={handleKeyUp}
        />
      </div>
      {children}
      <div className="flex gap-3 pt-5">
        <ContainedButton
          className="flex-1"
          color="secondary"
          onClick={onCancel}
          data-testid="complex-delete-modal-back-button"
        >
          Back
        </ContainedButton>
        <ContainedButton
          disabled={!deleteIsTyped}
          color="danger"
          onClick={onConfirm}
          className="flex-1"
          data-testid="complex-delete-modal-confirm-button"
        >
          Yes - DELETE
        </ContainedButton>
      </div>
    </ModalComponent>
  );
}
