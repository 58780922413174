import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ContainedButton } from '..';
import { SizeEnum } from '../../../types';

interface BackButtonProps {
  destination: string;
}

export function BackButtonComponent({ destination }: BackButtonProps) {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(destination);
  }, [navigate, destination]);

  return (
    <ContainedButton
      className="flex-1"
      onClick={handleClick}
      size={SizeEnum.large}
      color="secondary"
      data-testid="back-button"
    >
      <span>Back to Search</span>
    </ContainedButton>
  );
}
