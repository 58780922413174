import { Select } from '../../../components/inputs';
import { SizeEnum } from '../../../types';
import { GroupTypeEnum } from '../types';

interface TypeProps {
  typeError: boolean;
  type: GroupTypeEnum;
  onTypeChange(event: React.FormEvent<HTMLSelectElement>): void;
}

export const TypeComponent = ({ typeError, type, onTypeChange }: TypeProps) => {
  const types = [
    { key: GroupTypeEnum.icons, value: GroupTypeEnum.icons, label: 'Icon' },
    {
      key: GroupTypeEnum.palettes,
      value: GroupTypeEnum.palettes,
      label: 'Palette'
    },
    {
      key: GroupTypeEnum.fonts,
      value: GroupTypeEnum.fonts,
      label: 'Font'
    },
    {
      key: GroupTypeEnum.styles,
      value: GroupTypeEnum.styles,
      label: 'Style'
    },
    {
      key: GroupTypeEnum.labelVariants,
      value: GroupTypeEnum.labelVariants,
      label: 'Label Variants'
    }
  ];

  return (
    <div>
      <Select
        error={typeError}
        contentBefore="Type:"
        size={SizeEnum.medium}
        className="flex-1"
        value={type}
        options={types}
        onChange={onTypeChange}
        data-testid="type-field"
      />
    </div>
  );
};
