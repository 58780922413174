import { TRPCClientError } from '@trpc/client';

export function handleTrpcError(error: unknown) {
  const messages: string[] = consolidateTrpcErrorMessages(error);

  throw new Error(messages.join('; '));
}

export function consolidateTrpcErrorMessages(error: unknown) {
  let messages: string[] = [];

  const isZodError =
    error instanceof TRPCClientError && error.shape?.data?.zodError;

  if (isZodError) {
    const { formErrors, fieldErrors } = error.shape.data.zodError;

    messages = [
      ...formErrors,
      ...Object.entries(fieldErrors).map(
        ([key, errors]) => `${key}: ${(errors as string[]).join(', ')}`
      )
    ];
  } else if (error instanceof Error) {
    messages = [error.message];
  } else {
    messages = ['Unknown error'];
  }
  return messages;
}
