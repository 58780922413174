export function TypeSizeIcon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 3388">
        <path
          id="Vector"
          d="M8.00003 4.80005H9.60003V2.40005H14.4V14H12.4V15.6H19.6V14H17.6V2.40005H22.4V4.80005H24V0.800049H8.00003V4.80005Z"
          fill="currentColor"
        />
        <path
          id="Vector_2"
          d="M0 9.60015H1.6V8.00015H4.4V15.6001H2.8V17.2001H8.4V15.6001H6.8V8.00015H10V9.60015H11.6V6.40015H0V9.60015Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
