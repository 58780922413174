import { z } from 'zod';

// As returned by www.googleapis.com/webfonts/v1/webfonts
export type GoogleFontResult = {
  items: GoogleFont[];
};

export type GoogleFont = {
  family: string;
  variants: string[];
  version: string;
  lastModified: string;
  files: {
    [key: string]: string; // key is a value from the variants array
  };
};

export interface Font {
  font_id?: string;
  weight: string;
  system_name: string;
  display_name: string;
  google_family?: string;
  google_variant?: string;
  google_version?: string;
  font_file_path?: string;
  vdpcode?: string | null;
}

export const emptyFont: Font = {
  font_id: '',
  weight: '',
  system_name: '',
  display_name: '',
  google_family: '',
  google_variant: '',
  google_version: '',
  font_file_path: ''
};

export const fontSchema = z.object({
  font_id: z.string(),
  weight: z.string(),
  system_name: z.string(),
  display_name: z.string(),
  google_family: z.string(),
  google_variant: z.string(),
  google_version: z.string(),
  font_file_path: z.string()
});

export enum UserRolesEnum {
  admin = 'admin',
  super_admin = 'super admin',
  customer_service = 'customer service',
  order_processor = 'order processor',
  asset_manager = 'asset manager'
}
