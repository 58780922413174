import { cmyk } from 'color-convert';
import { ColorMapping } from '../types';
import { InvalidColorSample } from '../../palettes/components/InvalidColorSample';
import { ColorSample } from '../../palettes/components/ColorSample';

type ColorProps = {
  colorMapping: ColorMapping;
};

export function RGBColor({ colorMapping: { hex_code } }: ColorProps) {
  if (!hex_code) {
    return <InvalidColorSample className="h-8 w-8" />;
  }
  return <ColorSample colorHex={`#${hex_code}`} className="h-8 w-8" />;
}

export function CMYKColor({ colorMapping: { c, m, y, k } }: ColorProps) {
  if ([c, m, y, k].some(val => isNaN(parseFloat('' + val)))) {
    return <InvalidColorSample className="h-8 w-8" />;
  }
  return (
    <ColorSample colorHex={`#${cmyk.hex([c, m, y, k])}`} className="h-8 w-8" />
  );
}
