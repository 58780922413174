import { Group, GroupRegistration, GroupRegistrations } from '../types';

export const formatGroupRequestBody = (
  group: Group,
  groupRegistrations: GroupRegistrations
) => {
  return {
    ...group,
    group_member_ids: Object.keys(groupRegistrations)
  };
};

export const duplicateGroup = (
  group: Group,
  groupRegistrations: GroupRegistrations
) => {
  const { display_name, system_name, type, client_filtering } = group;

  const groupRegistrationsToCopy = Object.values(groupRegistrations).reduce(
    (
      copiedGroupRegistrations: GroupRegistrations,
      groupRegistration: GroupRegistration
    ) => {
      const { group_member_id, system_name } = groupRegistration;

      copiedGroupRegistrations[group_member_id] = {
        group_member_id,
        system_name
      };

      return copiedGroupRegistrations;
    },
    {} as GroupRegistrations
  );

  return {
    display_name,
    system_name: `${system_name} - Copy`,
    type,
    client_filtering,
    group_registrations: groupRegistrationsToCopy
  };
};
