import { useCallback, useEffect, useState } from 'react';
import { useTRPCRequest } from '../../../hooks';
import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';
import { toast } from 'react-toastify';
import { Style } from '../types';

export function useStyles(search?: string) {
  const { handleTRPCRequest } = useTRPCRequest();

  const [styles, setStyles] = useState<Style[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchStyles = useCallback(
    async (search?: string) => {
      setIsLoading(true);
      const trpcRequest = {
        method: TRPCMethodEnum.list,
        resourceType: TRPCResourceEnum.styles,
        requestBody: {}
      };
      if (search) {
        trpcRequest.requestBody = { keyword: search };
      }
      const { res: styles, error } = await handleTRPCRequest(trpcRequest);

      if (error) {
        toast.error('Error fetching styles');
      }
      setStyles(styles as Style[]);
      setIsLoading(false);
    },
    [handleTRPCRequest]
  );

  useEffect(() => {
    fetchStyles(search ?? undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  return { styles, isLoading, refetch: fetchStyles };
}
