import { NumberInput } from '../../../components/inputs/NumberInput';
import { SizeEnum } from '../../../types';

import { LABEL_SLOT_FIELDS, LabelSlotErrors, LabelSlotValues } from '../types';

interface QuantityInputProps {
  errors: [
    {
      options: LabelSlotErrors[];
    }
  ];
  index: number;
  label: LabelSlotValues;
  handleUpdateLabelSlot: (
    label_id: string,
    updatedValue: number,
    attributeKey: LABEL_SLOT_FIELDS
  ) => void;
}

export function QuantityInput({
  errors,
  index,
  label,
  handleUpdateLabelSlot
}: QuantityInputProps) {
  return (
    <NumberInput
      contentBefore={<span className="text-lighter-gray text-sm">QTY:</span>}
      error={errors && errors[0]?.options[index]?.quantity}
      value={label.quantity}
      size={SizeEnum.xSmall}
      className="w-[4.75rem] shrink"
      onChange={val =>
        handleUpdateLabelSlot(label.label_id, val, LABEL_SLOT_FIELDS.quantity)
      }
      min={0}
      max={99}
      step={1}
    />
  );
}
