// CSS uses 96, (see here) https://developer.mozilla.org/en-US/docs/Learn/CSS/Building_blocks/Values_and_units
// NameBubbles have historically used 72
const inToPxConversionRatio = 72;

export type CSSUnit = 'in' | 'px';

export function toPx(value: number, unit: CSSUnit = 'in') {
  if (unit === 'in') {
    return value * inToPxConversionRatio;
  }
  return value;
}
