import { LoadingModalComponent } from '../../../components/modals/variants/LoadingModalComponent';
import {
  EntityDependencies,
  TRPCMethodEnum,
  TRPCResourceEnum
} from '../../../api/trpcApi/types';
import { useLocalStorage, useTRPCRequest } from '../../../hooks';
import { ListThumbnail } from '../../../components/images';
import { SearchBar } from '../../../components/search-bar';
import { TableRecord } from '../../../types/record-table';
import { useCallback, useState } from 'react';
import { RecordTable } from '../../../components/tables';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Style } from '../types';
import {
  DuplicateAssetButton,
  DeleteAssetButton
} from '../../../components/buttons';
import { useStyles } from './hooks';
import {
  CannotDeleteModalComponent,
  ConfirmDeleteModalComponent
} from '../../../components/modals';
import { Title } from '../../../components/Title';

export function StylesList() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useLocalStorage<string>(
    window.location.pathname,
    ''
  );
  const { handleTRPCRequest } = useTRPCRequest();
  const [styleToDelete, setStyleToDelete] = useState<TableRecord | null>(null);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);
  const [dependencies, setDependencies] = useState<EntityDependencies>({});

  const { styles, isLoading, refetch } = useStyles(searchTerm);

  const handleAddNew = useCallback(() => {
    navigate(`/styles/new`);
  }, [navigate]);

  const handleDuplicateStyle = useCallback(
    (style: TableRecord) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      navigate('/styles/new', { state: { styleIdToCopy: style.style_id } });
    },
    [navigate]
  );
  const handleDeleteStyle = useCallback(
    (style: TableRecord) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setStyleToDelete(style);
      setConfirmDelete(true);
    },
    []
  );

  const handleConfirmDeleteStyle = useCallback(async () => {
    if (styleToDelete) {
      setConfirmDelete(false);
      const { style_id } = styleToDelete as Style;
      const { res, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.delete,
        resourceType: TRPCResourceEnum.styles,
        requestBody: { style_id }
      });

      if (!error && res.success) {
        toast.success('Style deleted successfully!');
        refetch();
      }

      if (!res?.success) {
        setShowCannotDeleteModal(true);
        setDependencies(res.relatedEntities);
      }
    }
  }, [styleToDelete, handleTRPCRequest, refetch]);

  const TableComponent = (
    <RecordTable
      idKey="style_id"
      data={styles ?? []}
      calculateRowHref={style => `/styles/${style.style_id}`}
      rowClassNames={[
        'card',
        'mb-[6px]',
        'flex',
        'h-[54px]',
        'cursor-pointer',
        'items-center',
        'align-middle'
      ]}
      columns={[
        {
          columnKey: 'thumbnail_path',
          onRender: (style: TableRecord) => (
            <ListThumbnail src={style.thumbnail_path} />
          ),
          widthClassNames: ['w-[54px]']
        },
        {
          columnKey: 'system_name',
          header: 'System Name',
          onRender: (style: TableRecord) => style.system_name,
          widthClassNames: ['w-[32%]', 'ml-[30px]']
        },
        {
          columnKey: 'display_name',
          header: 'Display Name',
          onRender: (style: TableRecord) => style.display_name
        },
        {
          columnKey: 'actions',
          widthClassNames: ['ml-auto'],
          onRender: (style: TableRecord) => {
            return (
              <div className="flex gap-3 pr-3">
                <DuplicateAssetButton
                  onDuplicateAsset={handleDuplicateStyle(style)}
                />
                <DeleteAssetButton onDeleteAsset={handleDeleteStyle(style)} />
              </div>
            );
          }
        }
      ]}
    />
  );

  if (isLoading) {
    return <LoadingModalComponent isOpen={isLoading} />;
  }

  return (
    <>
      <Title title="Styles Search" />
      <SearchBar
        searchTerm={searchTerm}
        onSearch={search => setSearchTerm(search)}
        onAddNew={handleAddNew}
        classNames={['pb-2']}
      />

      {styles.length === 0 && searchTerm === '' ? (
        <>
          <h2>No styles exist yet.</h2>
          <p> Click "+ Add New" to create your first style!</p>
        </>
      ) : null}

      {styles.length === 0 && searchTerm !== '' ? (
        <>
          <h2>No styles found for "{searchTerm}".</h2>
        </>
      ) : null}

      {styles.length > 0 && TableComponent}
      <ConfirmDeleteModalComponent
        entityName={styleToDelete?.system_name}
        isOpen={confirmDelete}
        onConfirm={handleConfirmDeleteStyle}
        onCancel={() => {
          setStyleToDelete(null);
          setConfirmDelete(false);
        }}
      />
      <CannotDeleteModalComponent
        entityName={styleToDelete?.system_name ?? ''}
        dependencies={dependencies}
        isOpen={showCannotDeleteModal}
        onCancel={() => setShowCannotDeleteModal(false)}
      />
    </>
  );
}
