import { UnderConstruction } from '../../../app/routes/UnderConstruction';

export function Users() {
  return (
    <>
      <h1>Users</h1>
      <UnderConstruction />
    </>
  );
}
