import { CSSProperties, useId } from 'react';

export interface TextFieldPreviewProps {
  text: string;
  size?: number;
  style?: CSSProperties;
  curve: number | string;
  width: number;
  height: number;
  focus?: boolean;
}

function arc(curve: number, width: number, height: number) {
  const w2 = width / 2;
  const h2 = height / 2;

  const theta = (Math.PI * curve) / 100;
  const sign = Math.sign(theta);
  let r = w2 / Math.abs(Math.sin(theta));

  let x = w2;
  let y = 0;

  // The radius is at most the width (curves over 50% need a different arc)
  if (Math.abs(curve) > 50) {
    r = width / 2;
    x = Math.max(Math.abs(Math.sin(theta)) * r, 0.01);
    y = -Math.cos(theta) * sign * r;
  }

  // Return bottom arc (where text is) as 1, top arc as 2
  // Use `sign` to give the bottom arc the larger radius
  // r, x, y is for the text centerline
  return {
    r,
    x,
    y,
    x1: x - Math.sin(theta) * h2,
    y1: y + Math.cos(theta) * h2,
    r1: r - h2 * sign,
    x2: x + Math.sin(theta) * h2,
    y2: y - Math.cos(theta) * h2,
    r2: r + h2 * sign
  };
}

export function CurvedTextFieldPreview({
  curve,
  width,
  height,
  focus
}: TextFieldPreviewProps) {
  const pathId = useId();

  if (typeof curve == 'string') {
    curve = parseInt(curve);
  }

  // Some tags for rendering using paths https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths
  let largeArc = 0;
  let sweepArc = 1;

  if (curve < 0) {
    sweepArc = 0;
  }
  if (Math.abs(curve) > 50) {
    largeArc = 1;
  }

  const { x, y, r, x1, y1, r1, x2, y2, r2 } = arc(curve, width, height);

  return (
    <>
      <defs>
        <path
          id={pathId}
          d={`M ${-x} ${y} A ${r} ${r} 0 ${largeArc} ${sweepArc} ${x} ${y}`}
        />
      </defs>
      {focus && (
        <>
          <path
            d={`M ${-x1} ${y1} A ${r1} ${r1} 0 ${largeArc} ${sweepArc} ${x1} ${y1}`}
            stroke={focus ? '#004876' : '#00000000'}
            fillOpacity={0}
          />
          <path
            id={pathId}
            d={`M ${-x2} ${y2} A ${r2} ${r2} 0 ${largeArc} ${sweepArc} ${x2} ${y2}`}
            stroke="#004876"
            fillOpacity={0}
          />
          <line x1={-x1} y1={y1} x2={-x2} y2={y2} stroke="#004876" />
          <line x1={x1} y1={y1} x2={x2} y2={y2} stroke="#004876" />
        </>
      )}
    </>
  );
}
