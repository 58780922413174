import { ModalComponent } from '../ModalComponent';

export interface ModalComponentProps {
  message?: string;
  isOpen: boolean;
}

export function LoadingModalComponent({
  isOpen,
  message = 'Loading...'
}: ModalComponentProps) {
  return (
    <ModalComponent
      isOpen={isOpen}
      contentLabel="Loading..."
      className="flex justify-center align-middle"
    >
      <div className="m-5 flex flex-col justify-center align-middle">
        <h1 className="mb-10 text-2xl font-bold">{message}</h1>
        <div className="border-navy h-32 w-32 animate-spin rounded-full border-b-2"></div>
      </div>
    </ModalComponent>
  );
}
