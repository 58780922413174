import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { BaseInput, BaseInputProps } from './BaseInput';
import { SizeEnum } from '../../types';

export interface NumberInputProps
  extends Omit<BaseInputProps, 'type' | 'size' | 'onChange'> {
  error?: boolean;
  size?: SizeEnum;
  tight?: boolean;
  contentBefore?: React.ReactNode;
  contentAfter?: React.ReactNode;
  onChange?: (value: number) => void;
  min?: number;
  max?: number;
}

export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  (props, ref) => {
    const {
      error,
      className,
      size = 'large',
      tight,
      disabled,
      contentBefore,
      contentAfter,
      value,
      onChange,
      onKeyDown,
      max = Infinity,
      min = -Infinity,
      ...rest
    } = props;
    const [trueValue, setTrueValue] = useState(value);
    const integer = Number(props.step) === 1;

    useEffect(() => {
      if ((Number(trueValue) || 0) !== Number(value)) {
        setTrueValue((value || 0)?.toString());
      }
    }, [trueValue, value]);

    const wrapperClassList = [
      'flex',
      'flex-shrink-0',
      'items-center',
      !tight && 'gap-[10px]',
      'rounded-[4px]',
      'px-[12px]',
      'py-[8px]',
      'align-middle',
      'text-input-shadow'
    ];

    const errorClassList = {
      'border-2': error,
      'border-blush': error
    };

    const disabledClassList = {
      'cursor-not-allowed': disabled,
      'bg-white': !disabled,
      'bg-[#e0e0e0]': disabled,
      'text-[#a6a6a6]': disabled
    };

    const sizeClass = {
      'h-[30px] px-[8px]': size === 'x-small',
      'h-[40px]': size === 'small',
      'h-[58px]': size === 'large'
    };

    const inputClasses = [
      'flex-[1_0_0]',
      'bg-transparent',
      'outline-none',
      'w-full'
    ];

    const clamp = useCallback(
      (num: number) => {
        num = Math.min(Math.max(min, num), max);
        if (integer) {
          num = Math.round(num);
        }
        return num;
      },
      [min, max, integer]
    );

    return (
      <div
        className={clsx(
          wrapperClassList,
          disabledClassList,
          errorClassList,
          sizeClass,
          className
        )}
      >
        {contentBefore}
        <BaseInput
          value={trueValue}
          onKeyDown={e => {
            if (integer && e.key === '.') {
              e.preventDefault();
              return;
            }
            onKeyDown?.(e);
          }}
          onChange={e => {
            const val = e.target.value;
            setTrueValue(val);
            const numberVal = Number(val);
            if (!isNaN(numberVal)) {
              onChange?.(clamp(numberVal));
            }
          }}
          onBlur={e => {
            const numberVal = clamp(Number(e.target.value) || 0);
            setTrueValue(numberVal.toString());
            onChange?.(numberVal);
          }}
          ref={ref}
          disabled={disabled}
          className={clsx(inputClasses, disabledClassList)}
          type="number"
          {...rest}
        />
        {contentAfter}
      </div>
    );
  }
);
