import { SetStateAction, useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { TRPCClientError } from '@trpc/client';
import { useSession } from '@clerk/clerk-react';

import { UserRolesEnum } from '../../../fonts/types';

import { ContainedButton } from '../../../../components/buttons';
import { CalendarIcon } from '../../../../components/icons';
import { useTRPCRequest } from '../../../../hooks';
import { Checkbox } from '../../../../components/inputs';
import {
  TRPCMethodEnum,
  TRPCResourceEnum
} from '../../../../api/trpcApi/types';

import { exportCsvToFile } from '../../utils';

export function Reports() {
  const [error, setError] = useState(false);
  const [legacyReport, setLegacyReport] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null
  ]);
  const [startDate, endDate] = dateRange;
  const { handleTRPCRequest } = useTRPCRequest();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const session = useSession();

  const userRole = session.session?.user?.publicMetadata.role;

  const handleExportCSV = useCallback(async () => {
    setIsLoading(true);
    if (dateRange[0] && dateRange[1]) {
      const endDate = new Date(dateRange[1]);
      endDate.setHours(23, 59, 59);

      const method = [
        UserRolesEnum.super_admin,
        UserRolesEnum.admin,
        UserRolesEnum.customer_service
      ].includes(userRole as UserRolesEnum)
        ? TRPCMethodEnum.generateReport
        : TRPCMethodEnum.generateReportNoFinancials;

      const { res, error } = await handleTRPCRequest({
        method,
        resourceType: TRPCResourceEnum.reports,
        requestBody: {
          startDate: dateRange[0],
          endDate: endDate,
          legacyReport
        },
        options: {
          getCreatedAndUpdated: true,
          sendRealError: true,
          disableNavigateOnError: true
        }
      });

      if (error) {
        if (error instanceof TRPCClientError) {
          if (error?.data?.httpStatus === 404) {
            toast.error(
              'No new configurator products were found in the selected timeframe'
            );
          } else {
            toast.error('Error generating report');
          }
        } else {
          toast.error(error.message);
        }
        setIsLoading(false);
        return;
      }

      setIsLoading(false);

      try {
        const response = await fetch(res.csvKey);
        const csvData = await response.text();

        const startDateString = dateRange[0].toLocaleDateString();
        const endDateString = endDate.toLocaleDateString();

        let fileName = `Report-${startDateString}-${endDateString}`;
        if (legacyReport) fileName = `Legacy-${fileName}`;

        exportCsvToFile(csvData, fileName);
        setIsLoading(false);
      } catch (error) {
        toast.error('Error fetching CSV data');
        setIsLoading(false);
      }
    } else {
      toast.error('Please specify a date range');
      setError(true);
      setIsLoading(false);
    }
  }, [dateRange, userRole, handleTRPCRequest, legacyReport]);

  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex w-full justify-between">
        <h1>Reports</h1>
        <div
          className={clsx(
            'flex w-1/3 items-center gap-3 p-2 shadow',
            error && 'border-blush border-2'
          )}
        >
          <div className="w-full">
            <DatePicker
              className="w-full pl-2"
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(
                update: SetStateAction<[Date | null, Date | null]>
              ) => {
                setDateRange(update);
                setError(false);
              }}
              minDate={new Date(2024, 2, 7)} // GoLive date - '3/6/2024': year, monthIndex, day
              placeholderText="Select Date Range"
              dateFormat={'MMM dd YYYY'}
              isClearable={true}
            />
          </div>

          <CalendarIcon />
        </div>
      </div>

      <div className="flex w-full justify-end">
        <Checkbox
          label="Legacy Report"
          checked={legacyReport}
          onChange={() => setLegacyReport(!legacyReport)}
        />
      </div>

      <ContainedButton onClick={handleExportCSV} disabled={isLoading}>
        Generate Report
      </ContainedButton>
      {isLoading && <span>Content loading...</span>}
    </div>
  );
}
