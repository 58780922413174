import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useBlocker,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  DisplayName,
  EditVdpCodeComponent,
  SystemNameComponent
} from '../../../components/inputs';
import {
  CannotDeleteModalComponent,
  ConfirmDeleteModalComponent,
  UnsavedChangesModalComponent
} from '../../../components/modals';
import {
  BackButtonComponent,
  SaveButtonComponent
} from '../../../components/buttons';
import {
  EntityDependencies,
  TRPCMethodEnum,
  TRPCResourceEnum
} from '../../../api/trpcApi/types';
import { useTRPCRequest } from '../../../hooks';
import {
  ErrorValue,
  validateSchemaAndNotifyErrorsRecursive
} from '../../../utils/validationUtils';
import { Title } from '../../../components/Title';
import {
  CreateLabelRequest,
  UpdateLabelRequest
} from '../../../api/trpcApi/services/labels/types';

import { useMaterials } from '../../materials/routes/hooks';

import { VariantStateContext } from '../components/settings/VariantStateContext';
import { update, useUpdater } from '../components/settings/state/update';
import {
  checkVariantSize,
  removeNullishValues
} from '../components/settings/variantUtils';
import { LabelState, LabelUpdaterData } from '../types/state';
import { labelStateToLabel, labelToLabelState } from '../types/convert';
import { LabelVariants, ManageLabelMaterialsComponent } from '../components';
import { createLabelSchema, updateLabelSchema } from '../validations';

export function LabelDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const labelToDuplicateId = location.state?.labelToDuplicateId;
  let { label_id: labelId } = useParams();
  const { handleTRPCRequest } = useTRPCRequest();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const unsavedChangesBlocker = useBlocker(unsavedChanges);

  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);
  const [dependencies, setDependencies] = useState<EntityDependencies>({});

  const onSave = useCallback(() => setUnsavedChanges(false), []);

  const create = !labelId;
  const duplicate = !!labelToDuplicateId;
  labelId = labelId || labelToDuplicateId;

  const [label, setLabel] = useState<LabelState>({
    display_name: '',
    system_name: '',
    thumbnail: '',
    material_ids: [],
    iterative_style_ids: [],
    iterative_defaults: {},
    label_variants: {},
    vdpcode: ''
  });

  const { materials } = useMaterials();

  const labelMaterials = useMemo(() => {
    const materialIdToMaterial = new Map(
      materials.map(material => [material.material_id!, material])
    );
    return (
      label.material_ids
        ?.map(materialId => materialIdToMaterial.get(materialId)!)
        .filter(x => x) ?? []
    );
  }, [materials, label.material_ids]);

  const [formErrors, setFormErrors] = useState<ErrorValue<LabelState>>({});

  useEffect(() => {
    if (!labelId) return;
    handleTRPCRequest({
      method: TRPCMethodEnum.get,
      resourceType: TRPCResourceEnum.labels,
      requestBody: { label_id: labelId }
    }).then(async ({ res }) => {
      if (res.thumbnail_path) {
        const thumbnail = await fetch(res.thumbnail_path);
        if (thumbnail.ok) {
          res.thumbnail = await thumbnail.text();
        }
        delete res.thumbnail_path;
      }
      const converted = labelToLabelState(res);
      setLabel(label => {
        if (duplicate) {
          converted.system_name = res.system_name + ' - Copy';
        }

        // If an iterative style has no default, set the first variant as the default
        const iterativeDefaults: Record<string, string> =
          converted.iterative_defaults ?? {};
        for (const styleId of converted.iterative_style_ids) {
          const hasDefault = !!iterativeDefaults[styleId];
          if (!hasDefault) {
            const styleVariants = Object.values(
              label.label_variants ?? {}
            ).filter(v => v.style_id === styleId);
            if (styleVariants.length) {
              const defaultVariant = styleVariants[0];
              iterativeDefaults[styleId] = defaultVariant.label_variant_id!;
            }
          }
        }
        converted.iterative_defaults = iterativeDefaults;

        return {
          ...label,
          ...converted
        };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelId, duplicate]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { update: updateLabel, change: onChange } = useUpdater<
    LabelState,
    LabelUpdaterData
  >((value, data) => {
    setLabel(label => {
      const newLabel = update<LabelState>(label, value);
      return newLabel;
    });
    if (!data?.sideEffect) {
      setUnsavedChanges(!data?.save);
    }
  });

  const handleDeleteLabel = useCallback(async () => {
    if (labelId) {
      const { res, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.delete,
        resourceType: TRPCResourceEnum.labels,
        requestBody: { label_id: labelId }
      });

      if (!error && res.success) {
        toast.success('Label deleted successfully!');
        navigate('/labels', { replace: true });
      }
      if (!res?.success) {
        setShowCannotDeleteModal(true);
        setDependencies(res.relatedEntities);
      }
    }
  }, [handleTRPCRequest, navigate, labelId]);

  const handleSaveLabel = useCallback(
    async (create: boolean) => {
      setIsSaving(true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any, prefer-const
      let { thumbnail_path: _, ...labelToSave }: any =
        removeNullishValues(label);
      if (!create) {
        const { label_variants: _, ...rest } = labelToSave;
        labelToSave = {
          label_id: labelId,
          ...rest
        };
      } else {
        delete labelToSave.label_id;
      }

      const { errors: validationErrors, validatedSchema } =
        validateSchemaAndNotifyErrorsRecursive(
          create ? createLabelSchema : updateLabelSchema,
          labelToSave
        );
      const variantErrors: ErrorValue<LabelState> = { label_variants: {} };
      if (create) {
        for (const [variantId, variant] of Object.entries(
          label.label_variants ?? {}
        )) {
          const err = checkVariantSize(variant);
          if (err) {
            variantErrors.label_variants![variantId] = {
              ...variantErrors.label_variants![variantId],
              ...err
            };
          }
        }
      }
      if (
        validationErrors ||
        Object.keys(variantErrors.label_variants!).length
      ) {
        setFormErrors({
          ...validationErrors,
          ...variantErrors
        });
      } else {
        setFormErrors({});
        const labelToUpsert = labelStateToLabel(validatedSchema) as
          | CreateLabelRequest
          | UpdateLabelRequest;
        if (!create) {
          delete labelToUpsert.label_variants;
        }
        const { res, error } = await handleTRPCRequest({
          method: create ? TRPCMethodEnum.create : TRPCMethodEnum.update,
          resourceType: TRPCResourceEnum.labels,
          requestBody: labelToUpsert
        });

        if (!error) {
          navigate(`/labels/${res?.label_id}`, { replace: true });
          toast.success('Label Saved');
        } else {
          toast.error(
            'There was an error saving the label, the label system name may already be in use'
          );
        }
      }
      onSave();
      setIsSaving(false);
    },
    [label, onSave, labelId, handleTRPCRequest, navigate]
  );

  const handleDuplicate = useCallback(() => {
    navigate('/labels/new', {
      state: {
        labelToDuplicateId: labelId
      }
    });
  }, [navigate, labelId]);

  const handleUpdateVdpCode = (newVdpCode: string) => {
    setLabel(prevLabel => ({ ...prevLabel, vdpcode: newVdpCode }));
  };

  useEffect(() => {
    if (labelToDuplicateId) {
      // Clear label variants when a label is duplicated
      onChange('label_variants', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelToDuplicateId]);

  return (
    <>
      <div className="flex flex-col border-b border-[#D3D3D3] pb-4">
        {create ? (
          <Title title="Create Label" />
        ) : (
          <Title title="Existing Label" />
        )}
        <SystemNameComponent
          onThumbnailUpload={e => onChange('thumbnail', e.target.value)}
          thumbnailPath={label.thumbnail}
          svg
          uploadButtonText={label.thumbnail ? 'Update' : 'Upload Thumbnail'}
          systemName={label.system_name}
          onDelete={() => setConfirmDelete(true)}
          onDuplicate={handleDuplicate}
          create={create}
          onSystemNameChange={e => onChange('system_name', e.target.value)}
          errors={{
            system_name: !!formErrors.system_name?.error,
            thumbnail: !!formErrors.thumbnail?.error
          }}
          className="mb-5"
        />
        <div className={'mb-5 flex gap-2 pb-5'}>
          <div className={'w-[75%]'}>
            <DisplayName
              displayName={label.display_name}
              onDisplayNameChange={e =>
                onChange('display_name', e.target.value)
              }
              displayNameError={!!formErrors.display_name?.error}
            />
          </div>
          <EditVdpCodeComponent
            className={'flex w-[25%] flex-col gap-2'}
            vdpCode={label.vdpcode ?? ''}
            handleUpdateVdpCode={handleUpdateVdpCode}
            overrideClassName={true}
            error={!!formErrors.vdpcode}
          />
        </div>
        <ManageLabelMaterialsComponent
          materials={labelMaterials}
          onChange={materials => {
            setUnsavedChanges(true);
            onChange(
              'material_ids',
              materials.map(m => m.material_id!)
            );
          }}
          error={!!formErrors.material_ids?.error}
          label_id={labelId}
        />
      </div>
      <VariantStateContext>
        <LabelVariants
          labelId={duplicate ? undefined : labelId}
          value={label.label_variants}
          errors={formErrors.label_variants}
          iterative_style_ids={label.iterative_style_ids}
          iterative_defaults={label.iterative_defaults}
          setIterativeDefault={(style_id, variant_id) => {
            const updatedIterative_defaults = {
              ...label.iterative_defaults,
              [style_id]: variant_id
            };
            onChange('iterative_defaults', updatedIterative_defaults);
          }}
          toggleIterative={(style_id: string) => () => {
            const exists = label.iterative_style_ids.find(
              iterativeStyleId => iterativeStyleId === style_id
            );
            let updatedIterativeStyleIds: string[] = [];
            if (!exists) {
              updatedIterativeStyleIds = [
                ...label.iterative_style_ids,
                style_id
              ];
            } else {
              updatedIterativeStyleIds = label.iterative_style_ids.filter(
                iterativeStyleId => iterativeStyleId !== style_id
              );
            }
            const updatedIterative_defaults = {
              ...label.iterative_defaults
            };
            if (exists) {
              delete updatedIterative_defaults[style_id];
            } else {
              const defaultVariant = Object.entries(
                label.label_variants ?? {}
              ).filter(([_, lv]) => lv.style_id == style_id)?.[0][0];
              if (defaultVariant)
                updatedIterative_defaults[style_id] = defaultVariant;
            }

            setUnsavedChanges(true);
            onChange('iterative_defaults', updatedIterative_defaults);
            onChange('iterative_style_ids', updatedIterativeStyleIds);
          }}
          onUpdate={updateLabel('label_variants')}
        />
      </VariantStateContext>
      <div className="mt-10 flex gap-3">
        <BackButtonComponent destination="/labels" />
        <SaveButtonComponent
          isSaving={isSaving}
          onSave={() => handleSaveLabel(create)}
        />
      </div>
      <UnsavedChangesModalComponent
        unsavedChanges={unsavedChanges}
        unsavedChangesBlocker={unsavedChangesBlocker}
      />
      <ConfirmDeleteModalComponent
        isOpen={confirmDelete}
        entityName={label.system_name}
        onCancel={() => setConfirmDelete(false)}
        onConfirm={() => {
          setConfirmDelete(false);
          handleDeleteLabel();
        }}
      />
      <CannotDeleteModalComponent
        entityName={label?.system_name ?? ''}
        dependencies={dependencies}
        isOpen={showCannotDeleteModal}
        onCancel={() => setShowCannotDeleteModal(false)}
      />
    </>
  );
}
