import { RegistrationCandidates } from '../../groups/types';
import {
  RegisteredEntities,
  Style,
  StyleEntities,
  StyleEntityCandidates,
  StyleTypeEnum
} from '../types';

export const duplicateStyle = async (
  style: Style,
  styleEntities: StyleEntities,
  rawImage?: string
) => {
  const { display_name, system_name, thumbnail_path } = style;
  return {
    display_name,
    system_name: `${system_name} - Copy`,
    thumbnail: rawImage,
    thumbnail_path: thumbnail_path,
    registered_entities: styleEntities
  };
};

export function formatRegisteredEntities(
  registered_entities: RegisteredEntities
) {
  const candidateState: StyleEntities = {
    palettes: {},
    fonts: {},
    icons: {}
  };
  for (const type in registered_entities) {
    const entityCandidateState: RegistrationCandidates = registered_entities[
      type as StyleTypeEnum
    ].reduce(
      (
        candidates: RegistrationCandidates,
        result: {
          registered_entity_id: string;
          system_name: string;
          is_group: boolean;
          selected: boolean;
        }
      ) => {
        const candidateId = result.registered_entity_id;
        if (candidateId) {
          candidates[candidateId] = {
            system_name: result.system_name,
            selected: true,
            is_group: result.is_group,
            registered_entity_id: result.registered_entity_id
          };
        }
        return candidates;
      },
      {} as RegistrationCandidates
    );
    candidateState[type as StyleTypeEnum] = entityCandidateState;
  }
  return candidateState;
}

export const generateList = (entities: StyleEntityCandidates) => {
  return Object.values(entities).map(value => ({
    is_group: value.is_group,
    registered_entity_id: value.registered_entity_id
  }));
};
