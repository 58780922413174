import { useCallback, useState } from 'react';
import {
  RegistrationCandidates,
  GroupRegistrations,
  GroupRegistration
} from '../types';
import { useTRPCRequest } from '../../../hooks';
import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';
import { SearchBar } from '../../../components/search-bar';
import { GroupMemberTypeToPrimaryKeyName } from '../conts';
import { CheckmarkIcon, PlusIcon } from '../../../components/icons';
import { ContainedButton, IconButton } from '../../../components/buttons';
import { getHexFromTailwindConfig } from '../../../utils';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { LoadingDiv } from '../../../components/loading';
import { debounce } from 'lodash';

interface SearchRegistrationCandidatesProps {
  type: TRPCResourceEnum;
  groupRegistrations: GroupRegistrations;
  onUpdateGroupRegistrations(
    updatedGroupRegistrations: GroupRegistrations
  ): void;
}

export const SearchRegistrationCandidatesComponent = ({
  type,
  groupRegistrations,
  onUpdateGroupRegistrations
}: SearchRegistrationCandidatesProps) => {
  const [registrationCandidates, setRegistrationCandidates] =
    useState<RegistrationCandidates>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { handleTRPCRequest } = useTRPCRequest();

  const fetchCandidates = useCallback(
    async (search?: string) => {
      setIsLoading(true);
      if (!(type in TRPCResourceEnum)) {
        throw new Error(`Unexpected group type: ${type}`);
      }
      const { res, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.list,
        resourceType: type,
        requestBody: {
          keyword: search
        }
      });

      if (res && !error) {
        const registrationCandidatesState = res.reduce(
          (
            candidates: RegistrationCandidates,
            result: {
              system_name: string;
              registered_entitiy_id: string;
              [key: string]: string;
            }
          ) => {
            const primaryKeyName =
              GroupMemberTypeToPrimaryKeyName.get(type) || '';
            const groupMemberId = result[primaryKeyName];

            if (groupMemberId) {
              const currentCandidateState =
                registrationCandidates[groupMemberId] || {};

              candidates[groupMemberId] = {
                registered_entity_id: result.registered_entitiy_id,
                system_name: result.system_name,
                selected: Boolean(
                  groupRegistrations[groupMemberId] ||
                    currentCandidateState?.selected
                )
              };
            }
            return candidates;
          },
          {} as RegistrationCandidates
        );

        setRegistrationCandidates(registrationCandidatesState);
        setIsLoading(false);
      }
    },
    [
      type,
      registrationCandidates,
      groupRegistrations,
      handleTRPCRequest,
      setRegistrationCandidates,
      setIsLoading
    ]
  );

  const handleToggleCandidate = useCallback(
    (candidateId: string) => () => {
      setIsEditing(true);
      const currentCandidateState = registrationCandidates[candidateId];
      setRegistrationCandidates({
        ...registrationCandidates,
        [candidateId]: {
          ...currentCandidateState,
          selected: !currentCandidateState.selected
        }
      });
    },
    [registrationCandidates, setIsEditing, setRegistrationCandidates]
  );

  const handleUpdateGroupRegistrations = useCallback(() => {
    const updatedGroupRegistrations = Object.values(groupRegistrations).reduce(
      (
        newGroupRegistrations: GroupRegistrations,
        groupRegistration: GroupRegistration
      ) => {
        const groupMemberId: string = groupRegistration.group_member_id;
        const incomingCandidate = registrationCandidates[groupMemberId];

        // if the group registration was not modified or if it has been explicitly selected again we keep it
        if (
          !incomingCandidate ||
          (incomingCandidate && incomingCandidate.selected)
        ) {
          newGroupRegistrations[groupMemberId] = {
            group_member_id: groupMemberId,
            system_name: groupRegistration.system_name
          };
        }

        return newGroupRegistrations;
      },
      {} as GroupRegistrations
    );

    // add all newly selected group members
    Object.keys(registrationCandidates).forEach((candidateId: string) => {
      const candidate = registrationCandidates[candidateId];
      if (candidate.selected && !updatedGroupRegistrations[candidateId]) {
        updatedGroupRegistrations[candidateId] = {
          group_member_id: candidateId,
          system_name: candidate.system_name
        };
      }
    });

    onUpdateGroupRegistrations(updatedGroupRegistrations);
    setRegistrationCandidates({});
  }, [
    groupRegistrations,
    registrationCandidates,
    onUpdateGroupRegistrations,
    setRegistrationCandidates
  ]);

  const handleCancel = () => {
    setRegistrationCandidates({});
  };

  const registrationCandidateIds = Object.keys(registrationCandidates);

  // used to close the search dropdown when the user clicks away
  const ref = useOutsideClick(handleCancel);

  return (
    <div className="relative" ref={ref}>
      <SearchBar
        classNames={['mb-0']}
        placeHolder="Search by Group Type"
        searchTerm={''}
        onSearch={debounce(search => fetchCandidates(search), 500)}
        searchOnChange={true}
        overrideIcon={
          <div className="text-aqua">
            <PlusIcon />
          </div>
        }
      />

      {registrationCandidateIds.length || isLoading ? (
        <div className="absolute flex w-full flex-col rounded bg-white shadow">
          <div className="flex max-h-60 flex-col overflow-y-scroll">
            {' '}
            {registrationCandidateIds.length ? (
              registrationCandidateIds.map(candidateId => {
                const candidate = registrationCandidates[candidateId];
                return (
                  <div
                    key={candidateId}
                    className="align-center flex cursor-pointer flex-row justify-between p-2 shadow"
                    style={{
                      backgroundColor: candidate.selected
                        ? getHexFromTailwindConfig('Light Blue')
                        : ''
                    }}
                    onClick={handleToggleCandidate(candidateId)}
                  >
                    <div>{candidate.system_name}</div>
                    <IconButton
                      className="text-navy text-[24px]"
                      icon={
                        candidate.selected ? <CheckmarkIcon /> : <PlusIcon />
                      }
                      data-testid="search-result-button"
                    />
                  </div>
                );
              })
            ) : (
              <div className="m-2">
                <LoadingDiv />
              </div>
            )}
          </div>

          <div className="flex-row justify-center">
            <ContainedButton
              color="secondary"
              onClick={handleCancel}
              className="m-5"
            >
              Cancel
            </ContainedButton>
            {isEditing && (
              <ContainedButton
                className="m-5"
                onClick={handleUpdateGroupRegistrations}
              >
                Save
              </ContainedButton>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};
