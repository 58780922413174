import { useCallback, useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useBlocker
} from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  SystemNameComponent,
  DisplayName,
  EditVdpCodeComponent
} from '../../../components/inputs';
import { OutlinedButton } from '../../../components/buttons';
import { Title } from '../../../components/Title';
import {
  UnsavedChangesModalComponent,
  ConfirmDeleteModalComponent,
  ConfirmModalComponent,
  LoadingModalComponent,
  TypeDeleteModalComponent,
  CannotDeleteModalComponent
} from '../../../components/modals';
import { useRawImageData, useTRPCRequest } from '../../../hooks';
import {
  EntityDependencies,
  TRPCMethodEnum,
  TRPCResourceEnum
} from '../../../api/trpcApi/types';
import { validateSchemaAndNotifyErrors } from '../../../utils';

import {
  type Palette,
  type ColorSlot,
  type PaletteFormErrors,
  PALETTE_FIELDS,
  ColorMappings,
  ColorMapping
} from '../types';
import { buildNewColorSlot, duplicatePalette, formatHexColor } from '../utils';
import { ColorsList, DetailsFooter } from '../components';
import { BLANK_PALETTE, BLANK_COLOR_SLOT } from '../config';
import {
  createPaletteDetailsSchema,
  colorSlotFormSchema,
  colorSlotsArrayFormSchema,
  updatePaletteDetailsSchema
} from '../validations';

interface PaletteDetailsProps {
  create?: boolean;
}

export function PaletteDetails({ create = false }: PaletteDetailsProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { palette_id = '' } = useParams();
  const { handleTRPCRequest } = useTRPCRequest();
  const { getRawImageData } = useRawImageData();
  const [palette, setPalette] = useState<Palette>(BLANK_PALETTE);
  const [colorMappings, setColorMappings] = useState<ColorMappings>({});
  const [palettesUsingColorSlot, setPalettesUsingColorSlot] = useState<
    { palette_id: string; system_name: string }[]
  >([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);
  const [dependencies, setDependencies] = useState<EntityDependencies>({});

  const [formErrors, setFormErrors] = useState<PaletteFormErrors>({
    system_name: false,
    display_name: false,
    color_slots: [],
    vdpcode: false
  });

  const [confirmAddColorSlot, setConfirmAddColorSlot] =
    useState<boolean>(false);

  const [confirmSimpleColorSlotDelete, setConfirmSimpleColorSlotDelete] =
    useState<boolean>(false);
  const [confirmComplexColorSlotDelete, setConfirmComplexColorSlotDelete] =
    useState<boolean>(false);

  const [colorSlotToDelete, setColorSlotToDelete] =
    useState<ColorSlot>(BLANK_COLOR_SLOT);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const unsavedChangesBlocker = useBlocker(unsavedChanges);

  const [confirmPaletteDelete, setConfirmPaletteDelete] =
    useState<boolean>(false);

  const getPalette = useCallback(
    async (palette_id: string) => {
      const { res: palette, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.get,
        resourceType: TRPCResourceEnum.palettes,
        requestBody: { palette_id }
      });

      if (error) {
        navigate('/404');
        return;
      }

      return palette;
    },
    [handleTRPCRequest, navigate]
  );

  const getColorMappings = useCallback(
    async (hexCodes: string[]) => {
      const { res: colorMappings, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.listByHexCodes,
        resourceType: TRPCResourceEnum.colorMappings,
        requestBody: { hexCodes }
      });

      if (error) {
        navigate('/404');
        return;
      }

      return colorMappings.reduce(
        (result: ColorMappings, colorMapping: ColorMapping) => {
          const { hex_code } = colorMapping;
          const formattedHex = formatHexColor(hex_code);
          result[formattedHex] = colorMapping;
          return result;
        },
        {}
      );
    },
    [handleTRPCRequest, navigate]
  );

  const getBlankPalette = useCallback(async () => {
    const { res: palette, error } = await handleTRPCRequest({
      method: TRPCMethodEnum.blank,
      resourceType: TRPCResourceEnum.palettes,
      requestBody: {}
    });

    if (error) {
      navigate('/404');
      return;
    }

    return palette;
  }, [navigate, handleTRPCRequest]);

  const getPalettesUsingColorSlot = useCallback(
    async (index: number) => {
      const { res: palettesUsingColorSlot } = await handleTRPCRequest({
        method: TRPCMethodEnum.usingColorSlot,
        resourceType: TRPCResourceEnum.palettes,
        requestBody: { index }
      });

      return palettesUsingColorSlot;
    },
    [handleTRPCRequest]
  );

  const syncExistingPalette = useCallback(
    async (palette_id: string) => {
      setIsLoading(true);
      const palette = await getPalette(palette_id);

      const hexCodes = palette.color_slots
        .filter((color_slot: ColorSlot) => color_slot.color_hex)
        .map((color_slot: ColorSlot) =>
          formatHexColor(color_slot.color_hex ?? '', false)
        );
      const colorMappings = await getColorMappings(hexCodes);
      const rawImage = await getRawImageData(palette.thumbnail_path);

      setColorMappings(colorMappings);
      setPalette({ thumbnail: rawImage, ...palette });

      setIsLoading(false);
    },
    [getPalette, getRawImageData, getColorMappings]
  );

  const syncBlankPalette = useCallback(async () => {
    setIsLoading(true);
    const palette = await getBlankPalette();
    setPalette(palette);
    setUnsavedChanges(true);
    setIsLoading(false);
  }, [getBlankPalette, setPalette]);

  const syncDuplicatePalette = useCallback(async () => {
    setIsLoading(true);
    const { state } = location;
    if (!state) {
      setIsLoading(false);
      return;
    }

    const { paletteIdToCopy } = state;
    if (!paletteIdToCopy) {
      setIsLoading(false);
      return;
    }

    const palette = await getPalette(paletteIdToCopy);
    const rawImage = await getRawImageData(palette.thumbnail_path);
    const newPalette = await duplicatePalette(palette, rawImage);
    setPalette(newPalette);
    setUnsavedChanges(true);
    setIsLoading(false);
  }, [location, getPalette, getRawImageData]);

  useEffect(() => {
    if (create && location.state) {
      syncDuplicatePalette();
    } else if (create) {
      syncBlankPalette();
    } else {
      syncExistingPalette(palette_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [create, location, palette_id]);

  const handleUpdatePalette = useCallback(
    (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setPalette({ ...palette, [key]: event.target.value });
      setFormErrors({ ...formErrors, [key]: null });
      setUnsavedChanges(true);
    },
    [palette, formErrors, setPalette, setFormErrors]
  );

  const handleDuplicatePalette = useCallback(() => {
    navigate('/palettes/new', {
      state: { paletteIdToCopy: palette_id },
      replace: true
    });
  }, [palette_id, navigate]);

  const handleConfirmedAddColorSlot = useCallback(() => {
    const newColor = buildNewColorSlot(palette);
    setPalette({
      ...palette,
      color_slots: [...palette.color_slots!, newColor]
    });
    setFormErrors({ ...formErrors, color_slots: [] });
    setUnsavedChanges(true);
    setConfirmAddColorSlot(false);
  }, [palette, formErrors]);

  const handleChangeColorHex = useCallback(
    (colorSlot: ColorSlot) => {
      return async (event: React.ChangeEvent<HTMLInputElement>) => {
        const color_hex = formatHexColor(event.currentTarget.value);
        const color_slots = palette.color_slots?.map((c: ColorSlot) => {
          if (c.print_key === colorSlot.print_key) {
            return { ...c, color_hex };
          }
          return c;
        });

        setPalette({ ...palette, color_slots });
        setFormErrors({ ...formErrors, color_slots: [] });

        const formattedWithoutPoundSymbol = formatHexColor(
          event.currentTarget.value,
          false
        );

        if (formattedWithoutPoundSymbol.length === 6) {
          const updatedColorMappings = await getColorMappings([
            formattedWithoutPoundSymbol
          ]);

          const newColorMappings = {
            ...colorMappings,
            ...updatedColorMappings
          };

          setColorMappings(newColorMappings);
        }

        setUnsavedChanges(true);
      };
    },
    [palette, formErrors, getColorMappings, colorMappings]
  );

  const handleUpdateVdpCode = (newVdpCode: string) => {
    setPalette(prevPalette => ({ ...prevPalette, vdpcode: newVdpCode }));
  };

  const handleDeleteColorSlot = useCallback(
    (color: ColorSlot) => async () => {
      setIsLoading(true);
      setColorSlotToDelete(color);
      const fetchedPalettesData = await getPalettesUsingColorSlot(color.index);
      setPalettesUsingColorSlot(fetchedPalettesData);
      setIsLoading(false);

      const showComplexModal =
        fetchedPalettesData.length > 1 ||
        (fetchedPalettesData.length === 1 &&
          fetchedPalettesData[0].palette_id !== palette_id);

      showComplexModal
        ? setConfirmComplexColorSlotDelete(true)
        : setConfirmSimpleColorSlotDelete(true);
    },
    [getPalettesUsingColorSlot, palette_id]
  );

  const handleCancelSimpleDeleteColorSlot = useCallback(() => {
    setColorSlotToDelete(BLANK_COLOR_SLOT);
    setConfirmSimpleColorSlotDelete(false);
  }, []);

  const navigateToPalette = useCallback(
    (palette_id: string) => {
      navigate(`/palettes/${palette_id}`, { replace: true });
    },
    [navigate]
  );

  const prepPaletteForSave = useCallback(
    (palette: Palette) => {
      const toSave = {
        ...palette,
        color_slots: palette.color_slots!.map(color => {
          const newColorSlot = {
            ...color,
            color_hex: color.color_hex === '#' ? null : color.color_hex
          };
          delete newColorSlot.created;
          delete newColorSlot.updated;
          return newColorSlot;
        })
      };
      if (create) {
        delete toSave.palette_id;
        toSave.color_slots?.forEach(color => {
          delete color.palette_id;
          delete color.color_slot_id;
        });
      } else {
        toSave.color_slots?.forEach(color => {
          color.palette_id = toSave.palette_id;
        });
      }
      delete toSave.thumbnail_path;
      return toSave;
    },
    [create]
  );

  const validatePaletteAndNotifyErrors = useCallback(
    (palette: Palette) => {
      const { color_slots, ...paletteDetails } = palette;

      const { errors: paletteDetailErrors } = validateSchemaAndNotifyErrors(
        create ? createPaletteDetailsSchema : updatePaletteDetailsSchema,
        paletteDetails
      );

      const hasPaletteDetailErrors = !!paletteDetailErrors;

      // validate color slots as an array
      const { errors: colorSlotArrayError } = validateSchemaAndNotifyErrors(
        colorSlotsArrayFormSchema,
        color_slots
      );
      const hasColorSlotArrayError = !!colorSlotArrayError;

      // if the array is invalid, we need to mark all slots as invalid
      // if the array is valid, validate each color slot individually
      const colorSlotErrors = hasColorSlotArrayError
        ? color_slots?.map(_colorSlot => true)
        : color_slots?.map(colorSlot => {
            const { errors } = validateSchemaAndNotifyErrors(
              colorSlotFormSchema,
              colorSlot
            );
            return !!errors;
          });
      const hasColorSlotErrors = colorSlotErrors?.some(error => !!error);

      const hasErrors = hasPaletteDetailErrors || hasColorSlotErrors;
      if (hasErrors) {
        // consolidate errors
        const errors = { color_slots: colorSlotErrors };
        if (hasPaletteDetailErrors) {
          Object.assign(errors, paletteDetailErrors);
        }
        setFormErrors(errors as unknown as PaletteFormErrors);
        return false;
      }
      return true;
    },
    [create]
  );

  const savePalette = useCallback(
    async (toSave: Palette) => {
      const { res: savedPalette } = await handleTRPCRequest({
        method: create ? TRPCMethodEnum.create : TRPCMethodEnum.update,
        resourceType: TRPCResourceEnum.palettes,
        requestBody: toSave
      });
      setUnsavedChanges(false);
      setIsSaving(false);
      return savedPalette;
    },
    [create, handleTRPCRequest]
  );

  const handleSavePalette = useCallback(
    async (paletteToSave: Palette) => {
      setIsSaving(true);

      const toSave = prepPaletteForSave(paletteToSave);

      const isValid = validatePaletteAndNotifyErrors(toSave);

      if (!isValid) {
        setIsSaving(false);
        return;
      }
      setFormErrors({
        system_name: false,
        display_name: false,
        color_slots: [],
        vdpcode: false
      });
      const savedPalette = await savePalette(toSave);

      navigateToPalette(savedPalette.palette_id!);
    },
    [
      prepPaletteForSave,
      validatePaletteAndNotifyErrors,
      savePalette,
      navigateToPalette
    ]
  );

  const handleConfirmedDeleteColorSlot = useCallback(
    (color: ColorSlot) => {
      return () => {
        const color_slots = palette.color_slots?.filter(
          c => c.print_key !== color.print_key
        );
        const updatedPalette = { ...palette, color_slots };
        setPalette(updatedPalette);
        setFormErrors({ ...formErrors, color_slots: [] });
        setUnsavedChanges(true);
        setConfirmSimpleColorSlotDelete(false);
        setConfirmComplexColorSlotDelete(false);
        setUnsavedChanges(false);
        handleSavePalette(updatedPalette);
      };
    },
    [palette, formErrors, handleSavePalette]
  );

  const handleDeletePalette = useCallback(() => {
    setConfirmPaletteDelete(true);
  }, []);

  const handleConfirmedDeletePalette = useCallback(async () => {
    setUnsavedChanges(false);
    setConfirmPaletteDelete(false);
    setIsDeleting(true);
    const { res, error } = await handleTRPCRequest({
      method: TRPCMethodEnum.delete,
      resourceType: TRPCResourceEnum.palettes,
      requestBody: { palette_id }
    });
    setIsDeleting(false);

    if (!error && res.success) {
      toast.success('Palette deleted successfully!');
      navigate('/palettes');
    }

    if (!res?.success) {
      setShowCannotDeleteModal(true);
      setDependencies(res.relatedEntities);
    }
  }, [handleTRPCRequest, navigate, palette_id]);

  if (isSaving) {
    return <LoadingModalComponent isOpen={isSaving} message="Saving..." />;
  }

  if (isDeleting) {
    return <LoadingModalComponent isOpen={isDeleting} message="Deleting..." />;
  }

  if (isLoading) {
    return <LoadingModalComponent isOpen={isLoading} />;
  }

  return (
    <>
      {create ? (
        <Title title="Create Palette" />
      ) : (
        <Title title="Existing Palette" />
      )}
      <div>
        <SystemNameComponent
          create={create}
          systemName={palette.system_name}
          errors={formErrors}
          onSystemNameChange={handleUpdatePalette(PALETTE_FIELDS.system_name)}
          uploadButtonText={
            palette.thumbnail_path?.length || palette.thumbnail
              ? 'Update'
              : 'Upload Thumbnail'
          }
          onThumbnailUpload={handleUpdatePalette(PALETTE_FIELDS.thumbnail)}
          thumbnailPath={palette.thumbnail_path}
          onDuplicate={handleDuplicatePalette}
          onDelete={handleDeletePalette}
        />
        <div className={'mb-5 flex gap-2 pb-5'}>
          <div className={'w-[75%]'}>
            <DisplayName
              displayName={palette.display_name}
              displayNameError={formErrors.display_name}
              onDisplayNameChange={handleUpdatePalette(
                PALETTE_FIELDS.display_name
              )}
            />
          </div>
          <EditVdpCodeComponent
            className={'flex w-[25%] flex-col gap-2'}
            overrideClassName={true}
            vdpCode={palette.vdpcode ?? ''}
            handleUpdateVdpCode={handleUpdateVdpCode}
            error={formErrors.vdpcode}
          />
        </div>
        <ColorsList
          colors={palette.color_slots!}
          colorMappings={colorMappings}
          colorErrors={formErrors.color_slots!}
          onAddColorSlot={() => setConfirmAddColorSlot(true)}
          onChangeColorHex={handleChangeColorHex}
          onDeleteColorSlot={handleDeleteColorSlot}
        />
        <DetailsFooter
          isSaving={isSaving}
          onSave={() => handleSavePalette(palette)}
        />
      </div>
      <UnsavedChangesModalComponent
        unsavedChanges={unsavedChanges}
        unsavedChangesBlocker={unsavedChangesBlocker}
      />
      <ConfirmModalComponent
        isOpen={confirmAddColorSlot}
        contentLabel="Confirm Add Color Slot"
        title="This slot will be added to ALL palettes."
        message="Are you sure you'd like to add an additional color slot to all palettes?"
        cancelText="Back"
        confirmText="Yes - Add"
        onCancel={() => setConfirmAddColorSlot(false)}
        onConfirm={handleConfirmedAddColorSlot}
      />
      <ConfirmDeleteModalComponent
        isOpen={confirmSimpleColorSlotDelete}
        entityName={colorSlotToDelete.display_name}
        onCancel={handleCancelSimpleDeleteColorSlot}
        onConfirm={handleConfirmedDeleteColorSlot(colorSlotToDelete)}
      />
      <TypeDeleteModalComponent
        isOpen={confirmComplexColorSlotDelete}
        title={`${colorSlotToDelete.display_name} will be deleted for ALL palettes:`}
        onConfirm={handleConfirmedDeleteColorSlot(colorSlotToDelete)}
        onCancel={() => setConfirmComplexColorSlotDelete(false)}
      >
        <div className="mt-4 flex flex-wrap gap-5 align-middle">
          <h2>Palettes using {colorSlotToDelete.display_name}:</h2>
          {palettesUsingColorSlot.map(palette => {
            return (
              <OutlinedButton
                key={palette.palette_id}
                className="rounded-md"
                onClick={() =>
                  window.open(
                    `${window.location.origin}/palettes/${palette.palette_id}`,
                    '_blank'
                  )
                }
              >
                {palette.system_name}
              </OutlinedButton>
            );
          })}
        </div>
      </TypeDeleteModalComponent>
      <ConfirmDeleteModalComponent
        isOpen={confirmPaletteDelete}
        entityName={palette.system_name}
        onCancel={() => setConfirmPaletteDelete(false)}
        onConfirm={handleConfirmedDeletePalette}
      />
      <CannotDeleteModalComponent
        entityName={palette.system_name ?? ''}
        dependencies={dependencies}
        isOpen={showCannotDeleteModal}
        onCancel={() => setShowCannotDeleteModal(false)}
      />
    </>
  );
}
