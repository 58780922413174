import { useCallback, useState } from 'react';

import { IconButton } from '../../../components/buttons';
import { CloseOutlineIcon, PreviewIcon } from '../../../components/icons';
import { ModalComponent } from '../../../components/modals';
import { Checkbox, Select, TextField } from '../../../components/inputs';

import { LabelSlotValues } from '../types';

interface EndcapOptionsProps {
  onUpdateLabelSlots(updatedLabelSlot: LabelSlotValues, labelId: string): void;
  label: LabelSlotValues;
}

export function EndcapOptions({
  onUpdateLabelSlots,
  label
}: EndcapOptionsProps) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [endcapText, setEndcapText] = useState<string>(label.endcap_text ?? '');
  const [endcapIsFooter, setEndcapIsFooter] = useState<boolean>(
    label.endcap_is_footer ?? false
  );
  const [endcapPrintMode, setEndcapPrintMode] = useState<string>(
    label.endcap_print_mode ?? 'once'
  );

  const saveAndClose = useCallback(() => {
    const trimmedText = endcapText?.trim();
    onUpdateLabelSlots(
      {
        ...label,
        endcap_text: trimmedText || null,
        endcap_is_footer: trimmedText ? endcapIsFooter : null,
        endcap_print_mode: trimmedText ? endcapPrintMode : null
      },
      label.label_id
    );
    setModalOpen(false);
  }, [endcapText, endcapIsFooter, endcapPrintMode, label, onUpdateLabelSlots]);

  const buttonTitle = endcapText
    ? endcapIsFooter
      ? `${endcapText}\n(footer, ${endcapPrintMode})`
      : `${endcapText}\n(header, ${endcapPrintMode})`
    : 'Add Label Header/Footer';

  return (
    <>
      <IconButton
        title={buttonTitle}
        className={label.endcap_text ? 'text-coral' : 'text-light-gray'}
        onClick={() => {
          console.log('label', label);
          setModalOpen(true);
        }}
        icon={<PreviewIcon />}
      />
      <ModalComponent
        isOpen={modalOpen}
        onRequestClose={saveAndClose}
        contentLabel="Label Header/Footer"
        className="flex flex-col gap-4"
      >
        <h1>Label Header/Footer</h1>
        <TextField
          contentBefore="Text:"
          value={endcapText}
          onChange={event => {
            setEndcapText(event.target.value);
          }}
          // on enter key, save and close
          onKeyUp={event => {
            if (event.key === 'Enter') {
              saveAndClose();
            }
          }}
          contentAfter={
            endcapText && (
              <IconButton
                icon={<CloseOutlineIcon />}
                onClick={() => {
                  setEndcapText('');
                }}
              />
            )
          }
        />
        {endcapText && (
          <>
            <Select
              value={endcapPrintMode}
              contentBefore="Print Mode:"
              options={[
                { value: 'once', label: 'First Group' },
                { value: 'all', label: 'All Groups' }
              ]}
              onChange={event => setEndcapPrintMode(event.target.value)}
            />

            <Checkbox
              checked={endcapIsFooter}
              label="Use as Footer"
              onChange={event => setEndcapIsFooter(event.target.checked)}
            />
          </>
        )}
      </ModalComponent>
    </>
  );
}
