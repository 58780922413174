import React from 'react';

export interface BaseInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

export const BaseInput = React.forwardRef<HTMLInputElement, BaseInputProps>(
  (props: BaseInputProps, ref) => {
    return <input ref={ref} {...props} />;
  }
);
