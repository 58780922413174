import { useEffect, useState } from 'react';
import { LabelVariantTextField } from '../../../../../api/trpcApi/services/labels/types';
import { CSSUnit, toPx } from './svgUtils';
import { CurvedTextFieldPreview } from './CurvedTextFieldPreview';
import { useFontObject } from '../../../../fonts/routes/hooks';
import {
  PreparedText,
  layoutText
} from '../../../../../../../text-layout/src/layout';

export interface TextFieldPreviewProps {
  text: string;
  fontId?: string;
  value: LabelVariantTextField;
  focus?: boolean;
  positionUnit?: CSSUnit;
  sizeUnit?: CSSUnit;
  onClick: () => void;
}

export function TextFieldPreview({
  text,
  fontId,
  value,
  focus,
  positionUnit = 'px',
  sizeUnit = 'in',
  onClick
}: TextFieldPreviewProps) {
  const {
    width: unitWidth,
    height: unitHeight,
    x,
    y,
    align_x,
    align_y,
    curve,
    rotation,
    wrap
  } = value;
  const { font, style } = useFontObject(fontId);
  const [lines, setLines] = useState<PreparedText[]>([]);

  const width = toPx(unitWidth, sizeUnit);
  const height = toPx(unitHeight, sizeUnit);
  const w2 = width / 2;
  const h2 = height / 2;
  const left = -w2;
  const top = -h2;

  useEffect(() => {
    if (!font) return;
    setLines(
      layoutText({
        value: text,
        font,
        width,
        height,
        align_x,
        align_y,
        wrap: wrap && !curve,
        curve
      })
    );
  }, [align_x, align_y, curve, font, height, text, width, wrap]);

  return (
    <g
      onClick={onClick}
      transform={`translate(${toPx(x, positionUnit)}, ${toPx(
        -y,
        positionUnit
      )}) rotate(${rotation})`}
    >
      {focus && !curve && (
        <>
          <rect
            x={left}
            y={top}
            width={width}
            height={height}
            stroke="#004876"
            fillOpacity={0}
          />
        </>
      )}
      {curve ? (
        <CurvedTextFieldPreview
          text={text}
          size={lines[0]?.size}
          style={style}
          width={width}
          height={height}
          curve={curve}
          focus={focus}
        />
      ) : null}
      {lines.length ? (
        lines.map((line, i) => {
          return (
            <text
              key={i}
              x={line.x}
              y={-line.y}
              rotate={`${line.rotate}deg`}
              fontSize={line.size}
              style={style}
            >
              {line.value}
            </text>
          );
        })
      ) : (
        <text fontSize={height} dominantBaseline="middle" textAnchor="middle">
          {text}
        </text>
      )}
    </g>
  );
}
