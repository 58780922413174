import { useCallback, useEffect, useState } from 'react';
import { SearchBar } from '../../../components/search-bar';
import { useLocalStorage, useTRPCRequest } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import { RecordTable } from '../../../components/tables';
import { TableRecord } from '../../../types/record-table';
import {
  DeleteAssetButton,
  DuplicateAssetButton
} from '../../../components/buttons';
import { Group } from '../types';
import {
  EntityDependencies,
  TRPCMethodEnum,
  TRPCResourceEnum
} from '../../../api/trpcApi/types';
import {
  CannotDeleteModalComponent,
  ConfirmDeleteModalComponent
} from '../../../components/modals';
import { toast } from 'react-toastify';
import { Title } from '../../../components/Title';
import { startCase } from 'lodash';

export function Groups() {
  const navigate = useNavigate();
  const [groups, setGroups] = useState<Group[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [groupToDelete, setGroupToDelete] = useState<TableRecord | null>(null);
  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);
  const [dependencies, setDependencies] = useState<EntityDependencies>({});

  const [searchTerm, setSearchTerm] = useLocalStorage<string>(
    window.location.pathname,
    ''
  );
  const { handleTRPCRequest } = useTRPCRequest();

  const fetchGroups = useCallback(
    async (search?: string) => {
      setIsLoading(true);
      const trpcRequest = {
        method: TRPCMethodEnum.list,
        resourceType: TRPCResourceEnum.groups,
        requestBody: {}
      };
      if (search) {
        trpcRequest.requestBody = { keyword: search };
      }

      const { res: groups } = await handleTRPCRequest(trpcRequest);
      setGroups(groups);
      setIsLoading(false);
    },
    [handleTRPCRequest]
  );

  useEffect(() => {
    fetchGroups(searchTerm ?? undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleAddNew = useCallback(() => {
    navigate('/groups/new');
  }, [navigate]);

  const handleDuplicateGroup = useCallback(
    (group: TableRecord) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      navigate(`/groups/new`, { state: { groupToCopyId: group.group_id } });
    },
    [navigate]
  );

  const handleDeleteGroup = useCallback(
    (icon: TableRecord) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setGroupToDelete(icon);
      setConfirmDelete(true);
    },
    []
  );

  const handleConfirmDeleteGroup = useCallback(async () => {
    if (groupToDelete) {
      setConfirmDelete(false);
      const { group_id } = groupToDelete as Group;
      const { res, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.delete,
        resourceType: TRPCResourceEnum.groups,
        requestBody: { group_id }
      });

      if (!error && res.success) {
        toast.success('Group deleted successfully!');
        const newGroups = groups.filter(group => group.group_id !== group_id);
        setGroups(newGroups);
      }

      if (!res?.success) {
        setShowCannotDeleteModal(true);
        setDependencies(res.relatedEntities);
      }
    }
  }, [groups, handleTRPCRequest, groupToDelete]);

  const noResults = groups.length === 0;
  const noSearch = searchTerm === '';

  return (
    <>
      <Title title="Groups Search" />
      <SearchBar
        searchTerm={searchTerm}
        onSearch={search => setSearchTerm(search)}
        onAddNew={handleAddNew}
      />
      {isLoading ? (
        <h2>Loading...</h2>
      ) : noResults && noSearch ? (
        <>
          <h2>No groups exist yet.</h2>
          <p> Click "+ Add New" to create your first group!</p>
        </>
      ) : noResults ? (
        <h2>No groups found for "{searchTerm}".</h2>
      ) : (
        <RecordTable
          data={groups}
          idKey="group_id"
          rowClassNames={[
            'card',
            'mb-[6px]',
            'flex',
            'h-[54px]',
            'cursor-pointer',
            'items-center',
            'align-middle'
          ]}
          calculateRowHref={group => `/groups/${group.group_id}`}
          columns={[
            {
              columnKey: 'systemName',
              header: 'System Name',
              onRender: group => group.system_name,
              widthClassNames: ['w-[32%]', 'ml-[30px]']
            },
            {
              columnKey: 'displayName',
              header: 'Display Name',
              onRender: group => group.display_name,
              widthClassNames: ['w-[32%]', 'ml-[30px]']
            },
            {
              columnKey: 'groupType',
              header: 'Type',
              onRender: group => (
                <>
                  {startCase(
                    group.type.endsWith('s')
                      ? group.type.slice(0, -1)
                      : group.type
                  )}
                </>
              )
            },
            {
              columnKey: 'actions',
              widthClassNames: ['ml-auto'],
              onRender: group => (
                <div className="flex gap-3 pr-3">
                  <DuplicateAssetButton
                    onDuplicateAsset={handleDuplicateGroup(group)}
                  />
                  <DeleteAssetButton onDeleteAsset={handleDeleteGroup(group)} />
                </div>
              )
            }
          ]}
        />
      )}
      <ConfirmDeleteModalComponent
        entityName={groupToDelete?.system_name}
        isOpen={confirmDelete}
        onConfirm={handleConfirmDeleteGroup}
        onCancel={() => {
          setGroupToDelete(null);
          setConfirmDelete(false);
        }}
      />
      <CannotDeleteModalComponent
        entityName={groupToDelete?.system_name ?? ''}
        dependencies={dependencies}
        isOpen={showCannotDeleteModal}
        onCancel={() => setShowCannotDeleteModal(false)}
      />
    </>
  );
}
